import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import localforage from "localforage";
import Swal from "sweetalert2";

import CustomTable from "../../../components/CustomTable";
import { Box, Typography, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { TextField, Menu } from "@mui/material";
import { filterList } from "../../../libs/utils";
import {
  getRemittanceBlockedUsers,
  unBlockRemittanceUser,
} from "../../../services/blockedUsersService";
import { setBlockedUsers } from "../../../redux/slices/remittanceBlockedUserSlice";

const BlockedUserList = () => {
  const { reset } = useForm();

  const [isDynamicTable, setIsDynamicTable] = useState(false);
  const [rows, setRows] = useState();
  const [loading, setLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const blockedUsersData = useSelector((state) => state.blockedUsersData) || {};
  const { email } = blockedUsersData;

  const columns = [
    { id: "email", label: "Email", minWidth: 90, enableSort: true },
    { id: "recovery_amount", label: "Recovery Amount", minWidth: 100 },
    {
      id: "Action",
      label: "Action",
      minWidth: 80,
      align: "center",
    },
  ];

  const handleClose = () => {
    setAnchorEl(null);
  };
  const setDynamicTable = (parentWidth, tableWidth) => {
    setIsDynamicTable(true);
  };

  const searchData = (e) => {
    setSearchKey(e.target.value);
  };

  const handleUnblock = (email) => {
    setLoading(true);
    unBlockRemittanceUser(email)
      .then((response) => {
        setLoading(false);
        Swal.fire({
          title: "Success",
          text: "User has been unblocked successfully",
          icon: "success",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate(0);
            reset();
          }
        });
      })
      .catch((error) => {
        if(error.code === 'NB_0091'){
            Swal.fire({
                title: "Oops",
                text: "User is already unblocked",
                icon: "error",
                confirmButtonText: "Ok",
              }).then((result) => {});
              throw error;
        }
     else{
        Swal.fire({
            title: "Oops",
            text: "Something went wrong",
            icon: "error",
            confirmButtonText: "Ok",
          }).then((result) => {});
          throw error;
     }
      });
  };

  const dataGenerator = (data) => {
    const { email, recovery_amount } = data || {};
    return {
      email: (
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Link
              to={`/app/blocked-user/${email}`}
              style={{ color: "black", textDecoration: "none" }}
            >
              {email}
            </Link>
          </div>
        </>
      ),
      recovery_amount,
      Action: (
        <Link
          to={`/app/blocked-remittance-users`}
          style={{ textDecoration: "none" }}
        >
          <Button
            variant="contained"
            size="small"
            color="warning"
            onClick={() => handleUnblock(email)}
          >
            Unblock
          </Button>
        </Link>
      ),
    };
  };

  useEffect(() => {
    setLoading(true);
    localforage.getItem("@blockedUsers", (error, data) => {
      if (data) {
        if (data?.length) {
          setRows(data.map((data) => dataGenerator(data)));
        } else {
          setRows([]);
        }
      }
    });
    getRemittanceBlockedUsers()
      .then((response) => {
        setLoading(false);
        dispatch(setBlockedUsers(response));
        if (response?.length) {
          setRows(response.map((data) => dataGenerator(data)));
        } else {
          setRows([]);
        }
        localforage.setItem("@blockedUsers", response, (error, data) => {
          if (error) {
            throw error;
          }
        });
      })
      .catch((error) => {
        setLoading(false);
        throw error;
      });
  }, []);

  return (
    <>
      <Box
        sx={{ px: 6 }}
        className={`main-box ${isDynamicTable ? "dynamicTableStyle" : ""}`}
      >
        <div
          className={`bankAddRuleHeader mb-3 ${
            isDynamicTable ? "listHeaderPaddingRight" : ""
          }`}
        >
          <div
            style={{
              display: "flex",
              padding: "1.5rem 0",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: "#33343a" }} variant="h4" component="h4">
              Blocked Users
            </Typography>
            {loading && (
              <div
                style={{
                  marginLeft: "12px",
                  color: "#a0a0a0",
                  fontSize: "24px",
                }}
              >
                <i
                  className={`fa fa-refresh ${
                    loading === true ? "fa-spin" : ""
                  }`}
                  aria-hidden="true"
                ></i>
              </div>
            )}
          </div>

          <div style={{ display: "flex", alignItems: "end" }}>
            <TextField
              id="standard-basic"
              label="Search"
              variant="standard"
              sx={{ mr: 1 }}
              onKeyUp={(e) => searchData(e)}
              InputProps={{
                endAdornment: <SearchIcon sx={{ color: "#8a8a8a" }} />,
              }}
            />
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
            </Menu>
          </div>
        </div>

        <CustomTable
          setDynamicTable={setDynamicTable}
          data={filterList(rows, searchKey) || []}
          columns={columns}
          showTooltip={false}
        />
      </Box>
    </>
  );
};
export default BlockedUserList;
