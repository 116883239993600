import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  getActiveCoupon,
  getActiveCouponList,
} from "../../services/activeCoupons";

export const getActiveCouponDataList = createAsyncThunk(
  "activeCoupon/getActiveCouponDataList",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await getActiveCouponList();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getActiveCouponById = createAsyncThunk(
  "activeCoupons/getActiveCouponById",
  async (obj, { rejectWithValue }) => {
    try {
      const { id } = obj;
      const response = await getActiveCoupon(id);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  list: {
    data: [],
    error: null,
    status: null,
  },
  single: {
    data: null,
    error: null,
    status: null,
  },
};

export const activeCouponSlice = createSlice({
  name: "activeCoupon",
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getActiveCouponDataList.pending, (state, action) => {
        state.list = { ...state.list, status: "loading" };
      })
      .addCase(getActiveCouponDataList.fulfilled, (state, action) => {
        state.list = {
          ...state.list,
          status: "succeeded",
          data: action.payload,
          error: null,
        };
      })
      .addCase(getActiveCouponDataList.rejected, (state, action) => {
        state.list = {
          ...state.list,
          status: "failed",
          error: action.payload,
          data: [],
        };
      })
      .addCase(getActiveCouponById.pending, (state, action) => {
        state.single = { ...state.single, status: "loading" };
      })
      .addCase(getActiveCouponById.fulfilled, (state, action) => {
        state.single = {
          ...state.single,
          status: "succeeded",
          data: action.payload,
          error: null,
        };
      })
      .addCase(getActiveCouponById.rejected, (state, action) => {
        state.single = {
          ...state.single,
          status: "failed",
          error: action.payload,
          data: [],
        };
      });
  },
});

export default activeCouponSlice.reducer;
