import * as React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Swal from "sweetalert2";
import { getcategorybyId, updateCategory } from "../../../services/category";
import ImageUploadUtility from "../../../utils/ImageUploadUtility";
import ImageData from "../../../utils/DisplayImage";
import { setUploadImageLink } from "../../../redux/slices/offersSlice";

function EditCategory() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const { id } = useParams();
  const [categoryData, setcategorydata] = useState();
  const dispatch = useDispatch();

  const navigation = useNavigate();
  const imageData = useSelector((state) => state.imageData);
  const [savedResponse, setSavedResponse] = useState({});
  const [logoUrl, setLogoUrl] = useState();
  const link = imageData?.imageData;

  useEffect(() => {
    if (id) {
      getcategorybyId(id)
        .then((response) => {
          setcategorydata(response);
          if (response) {
            reset({
              name: response?.name,
              categoryType: response?.categoryType,
              popularity: response?.popularity,
              active: response?.active,
              id: response?.id,
              description: response?.description,
              showOnHome: response?.showOnHome,
              logo: response.logo,
            });
            setLogoUrl(response.logo);
          }
        })
        .catch((error) => {
          throw error;
        });
    }
  }, [id]);

  const updateCategoryData = (data) => {
    let updatedData = { ...data, logo: link };
    const buttonType = window.event.submitter.name;
    updateCategory(updatedData)
      .then((response) => {
        setSavedResponse(response);
        Swal.fire({
          title: "Success",
          text: "Category has been saved successfully",
          icon: "success",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            if (buttonType === "save") {
              navigation(-1);
            }
            if (buttonType === "savegoanother") {
              navigation("/app/category/0");
            }
          }
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Oops",
          text: "Something went wrong",
          icon: "warning",
          confirmButtonText: "Ok",
        }).then((result) => {});
        throw error;
      });
  };

  return (
    <>
      <Box sx={{ ml: "4", pt: "14 !important", pb: "5", px: 3 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "25px",
            marginTop: "20px",
            gap: "16px",
          }}
        >
          <div onClick={() => dispatch(setUploadImageLink(""))}>
            <Link to="/app/list-category">
              <ArrowBackIcon
                fontSize="large"
                color="secondary"
                sx={{ background: "#040721", p: 1, borderRadius: "50%" }}
              />
            </Link>
          </div>

          <Typography
            variant="h5"
            component="h5"
            fontWeight={"bold"}
            color={"#3d3d3d"}
          >
            Edit Category
          </Typography>
        </div>
        <Box sx={{ background: "#ffffff", p: 2, mb: 4, pb: 4 }}>
          <form onSubmit={handleSubmit(updateCategoryData)}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="name"
                  >
                    Name: <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("name", { required: true })}
                    className={`form-control ${
                      errors.name && "invalid-form-input"
                    }`}
                    type="text"
                    id="name"
                  />
                </div>
              </div>
              <input
                {...register("id")}
                className={`form-control`}
                type="hidden"
                id="name"
              />
            

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="name"
                  >
                    Popularity: <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("popularity", { required: true })}
                    className={`form-control ${
                      errors.popularity && "invalid-form-input"
                    }`}
                    type="number"
                    id="name"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="name"
                  >
                    Category Type: <small className="text-danger fs-6">*</small>
                  </label>
                  <select
                    {...register("categoryType", { required: true })}
                    className={`form-control ${
                      errors.categoryType && "invalid-form-input"
                    }`}
                    type="select"
                    id="name"
                  >
                    <option value="">Select</option>
                    <option value="o">Online</option>
                    <option value="l">Local</option>
                    <option value="b">Both</option>
                    <option value="r">Reward</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="name"
                  >
                    Description:
                  </label>
                  <input
                    {...register("description")}
                    className={`form-control`}
                    type="text"
                    id="name"
                  />
                </div>
              </div>
              <div className="row">
                <ImageUploadUtility
                  uploadType="CATEGORY"
                  name="categoryName"
                  inputFiletype="LOGO"
                  label="Logo"
                />
                <ImageData
                  inputFiletype="LOGO"
                  imageUrl={logoUrl ? logoUrl : savedResponse.logo}
                />
              </div>

              <div className="col-md-3 d-flex align-items-end">
                <div className="form-groupform-check d-flex align-items-center gap-4 ">
                  <input
                    {...register("active")}
                    className={`form-check-input`}
                    type="checkbox"
                    id="is_active"
                  />
                  <label
                    className="form-check-label fw-bold text-secondary"
                    htmlFor="is_active"
                    style={{ lineHeight: "1" }}
                  >
                    Is Active
                  </label>
                </div>
              </div>

              <div className="col-md-3 d-flex align-items-end">
                <div className="form-groupform-check d-flex align-items-center gap-4 ">
                  <input
                    {...register("showOnHome")}
                    className={`form-check-input`}
                    type="checkbox"
                    id="showonhome"
                  />
                  <label
                    className="form-check-label fw-bold text-secondary"
                    htmlFor="showonhome"
                    style={{ lineHeight: "1" }}
                  >
                    Show On Home
                  </label>
                </div>
              </div>
            </div>
            <hr style={{ height: "0.5px", marginTop: "32px" }} />
            <div>
              <button
                type="submit"
                className="btn btn-primary py-2 px-5"
                name="save"
                variant="contained"
                sx={{ width: "25%" }}
              >
                Save
              </button>
              <button
                variant="contained"
                type="submit"
                className="btn btn-primary py-2 px-5"
                name="savegoanother"
                sx={{ width: "25%" }}
              >
                Save And Add Another
              </button>
              <button
                variant="contained"
                type="submit"
                className="btn btn-primary py-2 px-5"
                name="savecontinue"
                sx={{ width: "25%" }}
              >
                Save and Continue Editing
              </button>
            </div>
          </form>
        </Box>
      </Box>
    </>
  );
}

export default EditCategory;
