import appConfig from "../config/app.config";
import axios from "axios";
import { getUserToken } from "../libs/user";
import {
  getPureJSON,
  handleAPIError,
  startLoader,
  stopLoader,
} from "../libs/utils";

const listRules = () => {
  startLoader();
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: appConfig.apiUrl.rules,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader();
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader();
        handleAPIError(error.status);
        reject(getPureJSON(error.response || error));
      });
  });
};

const getRulesById = (id) => {
  startLoader(1);
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${appConfig.apiUrl.rules}/${id}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader(1);
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader(1);
        reject(getPureJSON(error.response || error));
      });
  });
};

const updateRules = (obj) => {
  startLoader(1);
  return new Promise((resolve, reject) => {
    var data = JSON.stringify(obj);

    var config = {
      method: "post",
      url: `${appConfig.apiUrl.rules}/save`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        stopLoader(1);
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader(1);
        reject(getPureJSON(error.response || error));
      });
  });
};

const getRules = ()=>{
  return new Promise((resolve,reject)=>{
    var config = {
      method : "get",
      url : appConfig.apiUrl.eligibleCsmRules,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      }
    };
    axios(config)
    .then(function (response) {
      resolve(response.data);
    })
    .catch(function (error) {
      reject(error);
    });
});
}
export { listRules , getRulesById , updateRules, getRules };
