import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  OffersUpload,
  getOffersDataById as getOffersDataSingle,
} from "../../services/offers";

export const getOffersDataById = createAsyncThunk(
  "reward/getOffersDataById",
  async (obj, { rejectWithValue }) => {
    try {
      const { id } = obj;
      const response = await getOffersDataSingle(id);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const setUploadedImage = createAsyncThunk(
  "reward/upload",
  async (obj, { rejectWithValue }) => {
    try {
      const { type, file, id, name, uploadType } = obj;
      const response = await OffersUpload(type, file, id, name, uploadType);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  list: {
    data: [],
    error: null,
    status: null,
  },
  single: {
    data: null,
    error: null,
    status: null,
  },
  uploadedImage: {
    data: null,
    error: null,
    status: null,
  },
};

export const OffersDataSlice = createSlice({
  name: "imageData",
  initialState: initialState,
  reducers: {
    setUploadImageLink: (state, action) => {
      state.imageData = action.payload;
    },
    setSelectedFileType: (state, action) => {
      state.selectedFileType = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getOffersDataById.pending, (state, action) => {
        state.single = { ...state.single, status: "loading" };
      })
      .addCase(getOffersDataById.fulfilled, (state, action) => {
        state.single = {
          ...state.single,
          status: "succeeded",
          data: action.payload,
          error: null,
        };
      })
      .addCase(getOffersDataById.rejected, (state, action) => {
        state.single = {
          ...state.single,
          status: "failed",
          error: action.payload,
          data: [],
        };
      })

      .addCase(setUploadedImage.pending, (state, action) => {
        state.uploadedImage = { ...state.uploadedImage, status: "loading" };
      })
      .addCase(setUploadedImage.fulfilled, (state, action) => {
        state.uploadedImage = {
          ...state.uploadedImage,
          status: "succeeded",
          data: action.payload,
          error: null,
        };
      })
      .addCase(setUploadedImage.rejected, (state, action) => {
        state.uploadedImage = {
          ...state.uploadedImage,
          status: "failed",
          error: action.payload,
          data: [],
        };
      });
  },
});
export const { setUploadImageLink, setSelectedFileType } = OffersDataSlice.actions;

export default OffersDataSlice.reducer;
