import React, { useEffect } from "react";
import "./App.css";
import RouteController from "./app/routes/RouteController";
import { useDispatch } from "react-redux";
import { getAuth } from "./app/redux/slices/authSlice";
import "../src/app/assets/css/style.css";
import "nprogress/nprogress.css";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAuth());
  }, [dispatch]);

  return <RouteController />;
}

export default App;
