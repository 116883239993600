import * as React from "react";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";

import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getAppConfigsDataById } from "../../../redux/slices/appConfigSlice";
import { updateAppConfigRecord } from "../../../services/application-config";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function EditApplicationConfig(props) {
  const dispatch = useDispatch();
  const appConfigsDataId = useSelector((state) => state.appConfigsData);
  const { single: AppConfigsDataItem } = appConfigsDataId;
  const { register, handleSubmit, reset } = useForm();
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      getAppConfigsDataById({
        id: id,
      })
    );
  }, [dispatch, id]);
  useEffect(() => {
    if (AppConfigsDataItem.data) {
      reset({
        key: AppConfigsDataItem.data?.key,
        value: AppConfigsDataItem.data?.value,
      });
    }
  }, [AppConfigsDataItem]);

  const updateAppConfigs = (data) => {
    const buttonType = window.event.submitter.name;

    if (buttonType === "save") {
      updateAppConfigRecord({
        id: data.id,
        key: data.key,
        value: data.value,
      })
        .then((response) => {
          navigate("/app/list-app-config");
        })
        .catch((error) => {
          throw error;
        });
    }
    if (buttonType === "savegoanother") {
      updateAppConfigRecord({
        id: data.id,
        key: data.key,
        value: data.value,
      })
        .then((response) => {
          navigate("/app/app-config/0");
        })
        .catch((error) => {
          throw error;
        });
    }
    if (buttonType === "savecontinue") {
      updateAppConfigRecord({
        id: data.id,
        key: data.key,
        value: data.value,
      })
        .then((response) => {})
        .catch((error) => {
          throw error;
        });
    }
  };

  return (
    <>
      <Box sx={{ ml: "4", pt: "14 !important", pb: "5", px: 3 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "25px",
            marginTop: "20px",
            gap: "16px",
          }}
        >
          <Link to="/app/list-app-config">
            <ArrowBackIcon
              fontSize="large"
              l
              color="secondary"
              sx={{ background: "#040721", p: 1, borderRadius: "50%" }}
            />
          </Link>
          <Typography
            variant="h5"
            component="h5"
            fontWeight={"bold"}
            color={"#3d3d3d"}
          >
            Edit Application Configuration
          </Typography>
        </div>
        <Box sx={{ background: "#ffffff", p: 2, mb: 4, pb: 4 }}>
          <form onSubmit={handleSubmit(updateAppConfigs)}>
            <div className="row">
              <div className="col-md-7">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="key"
                  >
                    Key:
                  </label>
                  <input
                    defaultValue={AppConfigsDataItem.data?.id}
                    {...register("id")}
                    type="hidden"
                  />

                  <input
                    {...register("key", { required: true })}
                    className={`form-control `}
                    type="text"
                    id="key"
                    readOnly
                  />
                </div>
              </div>
              <div className="col-md-7">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="value"
                  >
                    Value:
                  </label>
                  <input
                    {...register("value", { required: true })}
                    className={`form-control`}
                    type="text"
                    id="value"
                  />
                </div>
              </div>
            </div>
            <hr style={{ height: "0.5px", marginTop: "32px" }} />
            <div>
              <button
                type="submit"
                className="btn btn-primary py-2 px-5"
                name="save"
                variant="contained"
                sx={{ width: "25%" }}
              >
                Save
              </button>
              <button
                variant="contained"
                type="submit"
                className="btn btn-primary py-2 px-5"
                name="savegoanother"
                sx={{ width: "25%" }}
              >
                Save And Add Another
              </button>
              <button
                variant="contained"
                type="submit"
                className="btn btn-primary py-2 px-5"
                name="savecontinue"
                sx={{ width: "25%" }}
              >
                Save and Continue Editing
              </button>
            </div>
          </form>
        </Box>
      </Box>
    </>
  );
}

export default EditApplicationConfig;
