import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import localforage from "localforage";
import { TextField, Tooltip, IconButton, Menu, Button } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { getcategorylist } from "../../../services/category";
import { filterList } from "../../../libs/utils";
import CustomTable from "../../../components/CustomTable";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";

function Listcategory() {
  const [rows, setRows] = useState();
  const [loading, setLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [isDynamicTable, setIsDynamicTable] = useState(false);

  const columns = [
    {
      id: "name",
      label: "Name",
      minWidth: 80,
      enableSort: true,
    },
    {
      id: "Active",
      label: "Active",
      minWidth: 100,
      align: "center",
      enableSort: true,
    },
    {
      id: "Logo",
      label: "Logo",
      minWidth: 100,
      align: "center",
    },
    {
      id: "Popularity",
      label: "Popularity",
      minWidth: 100,
      align: "center",
      enableSort: true,
    },
    {
      id: "categoryType",
      label: "Category Type",
      minWidth: 100,
      align: "center",
    },
    {
      id: "showonHome",
      label: "Show On Home",
      minWidth: 100,
      align: "center",
      enableSort: true,
    },

    {
      id: "Action",
      label: "Action",
      minWidth: 35,
      align: "center",
    },
  ];

  const dataGenerator = (data) => {
    return {
      name: data.name,
      Active: String(data.active),
      Logo: (
        <>
          {data.logo && (
            <img src={data.logo} style={{ height: "50px", width: "50px" }} />
          )}
        </>
      ),
      Popularity: data.popularity,
      categoryType: (
        <>
          {data.categoryType === "o" && <>Online</>}
          {data.categoryType === "l" && <>Local</>}
          {data.categoryType === "b" && <>Both</>}
          {data.categoryType === "r" && <>Reward</>}
        </>
      ),
      showonHome: String(data.showOnHome),

      Action: (
        <Link
          to={`/app/category/${data.id}`}
          style={{ textDecoration: "none" }}
        >
          <Button variant="contained" size="small" color="warning">
            Edit
          </Button>
        </Link>
      ),
    };
  };

  useEffect(() => {
    setLoading(true);
    localforage.getItem("@category", (error, data) => {
      if (data) {
        if (data?.length > 0 && typeof data === "object") {
          setRows(data.map((data) => dataGenerator(data)));
        } else {
          setRows([]);
        }
      }
    });
    getcategorylist()
      .then((response) => {
        setLoading(false);
        if (response?.length > 0 && typeof response === "object") {
          setRows(response.map((data) => dataGenerator(data)));
        } else {
          setRows([]);
        }
        localforage.setItem("@category", response, (error, data) => {
          if (error) {
            throw error;
          }
        });
      })
      .catch((error) => {
        setLoading(false);
        throw error;
      });
  }, []);

  const setDynamicTable = (parentWidth, tableWidth) => {
    setIsDynamicTable(true);
  };

  const searchData = (e) => {
    setSearchKey(e.target.value);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        sx={{ px: 6 }}
        className={`main-box ${isDynamicTable ? "dynamicTableStyle" : ""}`}
      >
        <div
          className={`bankAddRuleHeader mb-3 ${
            isDynamicTable ? "listHeaderPaddingRight" : ""
          }`}
        >
          <div
            style={{
              display: "flex",
              padding: "1.5rem 0",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: "#33343a" }} variant="h4" component="h4">
              Category List
            </Typography>
            {loading && (
              <div
                style={{
                  marginLeft: "12px",
                  color: "#a0a0a0",
                  fontSize: "24px",
                }}
              >
                <i
                  className={`fa fa-refresh ${
                    loading === true ? "fa-spin" : ""
                  }`}
                  aria-hidden="true"
                ></i>
              </div>
            )}
          </div>
          <div style={{ display: "flex", alignItems: "end" }}>
            <TextField
              id="standard-basic"
              label="Search"
              variant="standard"
              sx={{ mr: 1 }}
              onKeyUp={(e) => searchData(e)}
              InputProps={{
                endAdornment: <SearchIcon sx={{ color: "#8a8a8a" }} />,
              }}
            />

            <Tooltip title="Choose Option">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <MoreVertIcon sx={{ width: 32, height: 32 }} />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem
                sx={{
                  "&:hover": {
                    background: "#f9da8792",
                    color: "#000",
                  },
                }}
              >
                <Link to="/app/category/0" style={{ color: "#040721" }}>
                  Add New Category
                </Link>
              </MenuItem>
            </Menu>
          </div>
        </div>

        <CustomTable
          setDynamicTable={setDynamicTable}
          data={filterList(rows, searchKey) || []}
          columns={columns}
        />
      </Box>
    </>
  );
}

export default Listcategory;
