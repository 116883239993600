import { Box, Button, TextField, Typography } from "@mui/material";
import localforage from "localforage";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CustomTable from "../../../components/CustomTable";
import { filterList } from "../../../libs/utils";
import { getCardSequenceList } from "../../../services/cardSequence";

import SearchIcon from "@mui/icons-material/Search";

function CardSequenceList() {
  const [rows, setRows] = useState();
  const [loading, setLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  const columns = [
    { id: "BigCard", label: "Big Card", minWidth: 100 },
    { id: "MediumCard", label: "Medium Card", minWidth: 100 },
    { id: "SmallCard", label: "Small Card", minWidth: 100 },
    { id: "LocalCard", label: "Local Card", minWidth: 100 },
    { id: "CategoryOfferCard", label: "Category Offer Card", minWidth: 100 },
    { id: "CategoryListCard", label: "Category List Card", minWidth: 100 },
    { id: "OfferListCard", label: "Offer List Card", minWidth: 100 },
    { id: "ReferralCard", label: "Referral Card", minWidth: 100 },
    { id: "LogoCard", label: "Logo Card", minWidth: 100 },

    {
      id: "Action",
      label: "Action",
      minWidth: 100,
    },
  ];

  const searchData = (e) => {
    setSearchKey(e.target.value);
  };

  const dataGenerator = (d) => {
    return {
      BigCard: d.bigCard,
      MediumCard: d.mediumCard,
      SmallCard: d.smallCard,
      LocalCard: d.localCard,
      CategoryOfferCard: d.categoryOfferCard,
      CategoryListCard: d.categoryListCard,
      OfferListCard: d.offerListCard,
      ReferralCard: d.referralCard,
      LogoCard: d.logoCard,
      Action: (
        <Link
          to={`/app/card-sequence/${d.objectId}`}
          style={{ textDecoration: "none" }}
        >
          <Button variant="contained" size="small" color="warning">
            Edit
          </Button>
        </Link>
      ),
    };
  };

  useEffect(() => {
    setLoading(true);
    localforage.getItem("@cardSequence", (error, data) => {
      if (data) {
        if (data?.length > 0 && typeof data === "object") {
          setRows(data.map((d) => dataGenerator(d)));
        } else {
          setRows([]);
        }
      }
    });
    getCardSequenceList()
      .then((response) => {
        setLoading(false);
        if (response?.length > 0 && typeof response === "object") {
          setRows(response.map((d) => dataGenerator(d)));
        } else {
          setRows([]);
        }
        localforage.setItem("@cardSequence", response, (error, data) => {
          if (error) {
            throw error;
          }
        });
      })
      .catch((error) => {
        setLoading(false);
        throw error;
      });
  }, []);

  return (
    <>
      <Box sx={{ px: 3 }}>
        <div className="bankAddRuleHeader mb-3">
          <div
            style={{
              display: "flex",
              padding: "1.5rem 0",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: "#33343a" }} variant="h4" component="h4">
              Card Sequence List
            </Typography>
            {loading && (
              <div
                style={{
                  marginLeft: "12px",
                  color: "#a0a0a0",
                  fontSize: "24px",
                }}
              >
                <i
                  className={`fa fa-refresh ${
                    loading === true ? "fa-spin" : ""
                  }`}
                  aria-hidden="true"
                ></i>
              </div>
            )}
          </div>
          <div style={{ display: "flex", alignItems: "end" }}>
            <TextField
              id="standard-basic"
              label="Search"
              variant="standard"
              sx={{ mr: 1 }}
              onKeyUp={(e) => searchData(e)}
              InputProps={{
                endAdornment: <SearchIcon sx={{ color: "#8a8a8a" }} />,
              }}
            />
          </div>
        </div>

        <CustomTable data={filterList(rows, searchKey)} columns={columns} />
      </Box>
    </>
  );
}

export default CardSequenceList;
