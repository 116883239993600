import appConfig from "../config/app.config";
import axios from "axios";
import { getUserToken } from "../libs/user";
import { getPureJSON, startLoader, stopLoader } from "../libs/utils";

const getPPOList = () => {
  startLoader();
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: appConfig.apiUrl.ppo,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader();
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader();
        reject(getPureJSON(error.response || error));
      });
  });
};

const getPPOById = (id) => {
  startLoader(1);
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${appConfig.apiUrl.ppo}/${id}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader(1);
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader(1);
        reject(getPureJSON(error.response || error));
      });
  });
};

const editPPO = (id, obj) => {
  startLoader(1);
  return new Promise((resolve, reject) => {
    const data = JSON.stringify(Object.assign({ id: id }, obj));

    var config = {
      method: "post",
      url: `${appConfig.apiUrl.ppo}/save`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        stopLoader(1);
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader(1);
        reject(getPureJSON(error.response || error));
      });
  });
};

const addPPO = (obj) => {
  return new Promise((resolve, reject) => {
    const data = JSON.stringify(obj);

    var config = {
      method: "post",
      url: `${appConfig.apiUrl.ppo}/save`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(getPureJSON(error.response || error));
      });
  });
};

const fileUpload = (type, file, id) => {
  return new Promise((resolve, reject) => {
    const data = new FormData();
    data.append("type", type);
    data.append("file", file);
    data.append("id", id);
    const config = {
      method: "post",
      url: appConfig.apiUrl.ppoFileUpload,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        // Accept: "application/json, text/plain, */*",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(getPureJSON(error.response || error));
      });
  });
};

const fileDownload = (type, id) => {
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${appConfig.apiUrl.ppoFileDownload}?type=${type}&id=${id}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export { getPPOList, getPPOById, editPPO, addPPO, fileUpload, fileDownload };
