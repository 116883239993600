import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AnonymousUserLayout from "../../common/anonymous-user-layout/anonymous-user-layout";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import "react-toastify/dist/ReactToastify.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Colors from "../../../config/colors";
import { getUserToken } from "../../../libs/user";
import { resetPassword } from "../../../services/auth";

function ResetPassword() {
  const navigate = useNavigate();

  const [loginError, setLoginError] = useState("");
  const [message, setMessage] = useState("");
  const [isLogin, setIsLogin] = useState(false);

  const { token } = useParams();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const resetPass = (data) => {
    setIsLogin(true);
    setLoginError("");
    setMessage("");

    if (data.password !== data.confirmPassword) {
      setLoginError("Password does not match");
      setIsLogin(false);
    } else {
      resetPassword(data.password, data.confirmPassword, token)
        .then((response) => {
          setIsLogin(false);
          reset({
            password: "",
            confirmPassword: "",
          });
          setMessage("Your password has been reset succesfully");

          setTimeout(() => {
            navigate("/login");
          }, 2000);
        })
        .catch((error) => {
          setIsLogin(false);
          try {
            setLoginError(error.data.error.message);
          } catch (err) {
            setLoginError("Something went wrong");
          }
        });
    }
  };

  useEffect(() => {
    if (getUserToken()) {
      navigate("/app/offers");
    }
  }, []);

  const theme = createTheme({
    palette: {
      primary: {
        main: Colors.primary,
      },
      secondary: {
        main: Colors.secondary,
      },
    },
    components: {
      MuiInputLabel: {
        defaultProps: {
          sx: {
            fontSize: "20px",
          },
        },
      },
      MuiOutlinedInput: {
        defaultProps: {
          sx: {
            fontSize: "20px",
          },
        },
      },
    },
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <AnonymousUserLayout />
        <Container
          component="main"
          sx={{
            height: "calc(100vh - 64px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "64px",
          }}
        >
          <Box
            style={{
              width: "fit-content",
            }}
          >
            <Box
              style={{
                width: "500px",
                padding: "54px 28px",
                border: "1px solid rgb(204, 204, 204)",
                borderRadius: "8px",
                background: "#fff",
              }}
            >
              <form onSubmit={handleSubmit(resetPass)}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="h4"
                    style={{
                      color: "#000",
                      marginBottom: "24px",
                      fontWeight: "bold",
                    }}
                  >
                    Reset Password
                  </Typography>
                </Box>

                <TextField
                  {...register("password", {
                    required: true,
                  })}
                  error={Boolean(errors.password)}
                  helperText={
                    errors.password ? "Please Enter New Password" : ""
                  }
                  margin="normal"
                  fullWidth
                  label="New Password"
                  autoFocus
                  type="password"
                  InputProps={{
                    endAdornment: <LockOutlinedIcon sx={{ color: "#ccc" }} />,
                    autoComplete: "off",
                  }}
                />

                <TextField
                  {...register("confirmPassword", {
                    required: true,
                  })}
                  error={Boolean(errors.confirmPassword)}
                  helperText={
                    errors.confirmPassword
                      ? "Please Enter Confirm Password"
                      : ""
                  }
                  margin="normal"
                  fullWidth
                  label="Confirm Password"
                  type="password"
                  InputProps={{
                    endAdornment: <LockOutlinedIcon sx={{ color: "#ccc" }} />,
                    autoComplete: "off",
                  }}
                />

                <Button
                  fullWidth
                  size="large"
                  sx={{
                    fontSize: "20px",
                    marginTop: "16px",
                    color: "#edc865",
                    fontWeight: "bold",
                    height: "56px",
                    "&:hover": {
                      background: "#edc865",
                      color: "#7a5700",
                    },
                  }}
                  variant="contained"
                  type="submit"
                >
                  Reset
                  {isLogin === true && (
                    <i
                      style={{ marginLeft: "auto" }}
                      className={`fa fa-circle-o-notch ${
                        true ? "fa-spin" : ""
                      }`}
                      aria-hidden="true"
                    ></i>
                  )}
                  {isLogin === false && (
                    <ArrowForwardIcon style={{ marginLeft: "auto" }} />
                  )}
                </Button>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ mt: 2, color: "#676767" }}>
                    <span
                      style={{ textDecoration: "none" }}
                      className="text-danger"
                    >
                      &nbsp;{loginError}
                    </span>
                  </Typography>
                  {message && (
                    <p
                      style={{ textDecoration: "none" }}
                      className="text-success"
                    >
                      &nbsp;{message}
                    </p>
                  )}
                  <Link
                    to="/login"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "32px",
                      textDecoration: "none",
                      color: "#676767",
                    }}
                  >
                    <Typography sx={{ color: "#676767" }}>Login</Typography>
                    <ArrowForwardIcon
                      style={{ height: "16px", marginLeft: "auto" }}
                    />
                  </Link>
                </Box>
              </form>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </>
  );
}

export default ResetPassword;
