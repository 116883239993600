import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {getOfferStoreDataById as getOfferStoreDataSingle} from "../../services/stores";

export const getOfferStoresDataById = createAsyncThunk(
    "reward/getOfferStoresDataById",
    async (obj, { rejectWithValue }) => {
      try {
        const { id } = obj;
        const response = await getOfferStoreDataSingle(id);
        return response;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  
const initialState = {
  list: {
    data: [],
    error: null,
    status: null,
  },
  single: {
    data: null,
    error: null,
    status: null,
  },
};

export const OfferStoresDataSlice = createSlice({
  name: "offerStoresData",
  initialState: initialState,
  extraReducers: (builder) => {
    builder
   
      .addCase(getOfferStoresDataById.pending, (state, action) => {
        state.single = { ...state.single, status: "loading" };
      })
      .addCase(getOfferStoresDataById.fulfilled, (state, action) => {
        state.single = {
          ...state.single,
          status: "succeeded",
          data: action.payload,
          error: null,
        };
      })
      .addCase(getOfferStoresDataById.rejected, (state, action) => {
        state.single = {
          ...state.single,
          status: "failed",
          error: action.payload,
          data: [],
        };
      });
  },
});

export default OfferStoresDataSlice.reducer;
