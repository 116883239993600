import React from "react";
import AnonymousUserLayout from "../common/anonymous-user-layout/anonymous-user-layout";
import styles from "../../assets/css/notfound.module.css";
import { useNavigate } from "react-router-dom";

function Unauthorized() {
  const navigate = useNavigate();
  return (
    <>
      {/* <AnonymousUserLayout /> */}
      <div className={styles.body}>
        <div className={styles.title}>401</div>
        <p className={styles.message}>Unauthorized Page</p>

        <button className={styles.backButton} onClick={() => navigate(-1)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
            />
          </svg>
        </button>
      </div>
    </>
  );
}

export default Unauthorized;
