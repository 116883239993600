import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { updateTierBenefit } from "../../../services/tierBenefit";
import { Box, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getTiers } from "../../../services/offers";
import Swal from "sweetalert2";
import ImageUploadUtility from "../../../utils/ImageUploadUtility";

function AddTierBenefits() {
  const [geTtiers, setgetTiers] = useState([]);
  const navigation = useNavigate();
  const { id } = useParams();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const addTierBenefit = (data) => {
    if (data.active === "") data.active = false;
    if (data.tierId === "") data.tierId = null;
    data.id = null;

    updateTierBenefit(data)
      .then((response) => {
        Swal.fire({
          title: "Success",
          text: "Tier benefit has been saved successfully",
          icon: "success",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            navigation(-1);
          }
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Oops",
          text: "Something went wrong",
          icon: "warning",
          confirmButtonText: "Ok",
        }).then((result) => {});
        throw error;
      });
  };

  useEffect(() => {
    getTiers()
      .then((response) => {
        setgetTiers(response);
      })
      .catch(function (error) {
        throw error;
      });
  }, []);


  return (
    <Box sx={{ ml: "4", pt: "14 !important", pb: "5", px: 3 }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "25px",
          marginTop: "20px",
          gap: "16px",
        }}
      >
        <Link to="/app/list-benefits">
          <ArrowBackIcon
            fontSize="large"
            color="secondary"
            sx={{ background: "#040721", p: 1, borderRadius: "50%" }}
          />
        </Link>
        <Typography
          variant="h5"
          component="h5"
          fontWeight={"bold"}
          color={"#3d3d3d"}
        >
          Add Tier Benefit
        </Typography>
      </div>
      <Box sx={{ background: "#ffffff", p: 2, mb: 4, pb: 4 }}>
        <form onSubmit={handleSubmit(addTierBenefit)} noValidate>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none"
                  htmlFor="title"
                >
                  Title <small className="text-danger fs-6">*</small>
                </label>
                <input
                  {...register("title", { required: true })}
                  className={`form-control ${
                    errors.title && "invalid-form-input"
                  }`}
                  type="text"
                  id="title"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none"
                  htmlFor="subTitle"
                >
                  Sub Title <small className="text-danger fs-6">*</small>
                </label>
                <input
                  {...register("subTitle", { required: true })}
                  className={`form-control ${
                    errors.subTitle && "invalid-form-input"
                  }`}
                  type="text"
                  id="subTitle"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none"
                  htmlFor="fineCopy"
                >
                  Fine Copy
                </label>
                <input
                  {...register("fineCopy")}
                  className={`form-control ${
                    errors.fineCopy && "invalid-form-input"
                  }`}
                  type="text"
                  id="fineCopy"
                />
              </div>
            </div>
            <div className="row">
                <ImageUploadUtility
                  uploadType="TIER_BENEFITS"
                  name="tierName"
                  inputFiletype="IMAGE"
                  label="Offer Icon"
                  disabled={true}
                />
              </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none"
                  htmlFor="tiers"
                >
                  Tiers <small className="text-danger fs-6">*</small>
                </label>
                <select
                  {...register("tierId", { required: true })}
                  className={`form-select ${
                    errors.tierId && "invalid-form-input"
                  }`}
                  id="tiers"
                >
                  <option value="">Select</option>
                  {geTtiers.map((value) => (
                    <option key={value.id} value={value.id}>
                      {value.name} | {String(value.active)}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none"
                  htmlFor="deepLink"
                >
                  Deep Link
                  <small className="text-danger fs-6">*</small>
                </label>
                <input
                  {...register("deepLink", { required: true })}
                  className={`form-control ${
                    errors.deepLink && "invalid-form-input"
                  }`}
                  type="text"
                  id="deepLink"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none"
                  htmlFor="active"
                >
                  Visible <small className="text-danger fs-6">*</small>
                </label>
                <select
                  {...register("active", { required: true })}
                  className={`form-select ${
                    errors.active && "invalid-form-input"
                  }`}
                  id="active"
                >
                  <option value="">Select</option>
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
            </div>
          </div>
          <hr style={{ height: "0.5px", marginTop: "32px" }} />
          <div>
            <button className="btn btn-primary py-2 px-5" name="save">
              Save
            </button>
          </div>
        </form>
      </Box>
    </Box>
  );
}

export default AddTierBenefits;
