import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useForm } from "react-hook-form";

import {
  getActiveOffers,
  giveCashbackToUser,
} from "../../../services/cashback";
import { startLoader, stopLoader } from "../../../libs/utils";
import Swal from "sweetalert2";

function Cashback() {
  const [activeOffersList, setActiveOffersList] = useState([]);
  const [isDisabledCbAmountField, setIsDisabledCbAmountField] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    resetField,
    formState: { errors },
  } = useForm();

  const giveCashback = (data) => {
    startLoader(1);
    if (data.amount_type === "Spend") {
      data.status = "w";
    } else if (data.amount_type === "Earned") {
      data.status = "r";
    }
    giveCashbackToUser({
      action_date: data.action_date || "",
      amount: data.amount || 0,
      amounttype: data.amount_type || "",
      commision_id: data.commision_id || "",
      email: data.email || "",
      item_id: data.item_id || "",
      offer_id: data.offer_id || "",
      pending: data.pending || false,
      remark: data.remark || "",
      spent_amount: data.spent_amount || 0,
      status: data.status || "",
    })
      .then((response) => {
        stopLoader(1);
        if (response === "processed") {
          reset({
            action_date: "",
            amount: "",
            amount_type: "",
            commision_id: "",
            email: "",
            item_id: "",
            offer_id: "",
            pending: false,
            remark: "",
            spent_amount: "",
            status: "",
          });
          Swal.fire({
            title: "Success",
            text: "Cashback Successfully Credited",
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
            }
          });
        } else {
          Swal.fire({
            title: "Oops",
            text: response,
            icon: "warning",
            confirmButtonText: "Ok",
          }).then((result) => {});
        }
      })
      .catch((error) => {
        stopLoader(1);
        if (error.status === 404) {
          Swal.fire({
            title: "Oops",
            text: error?.data?.error?.message || "Data not found",
            icon: "warning",
            confirmButtonText: "Ok",
          }).then((result) => {});
        } else {
          Swal.fire({
            title: "Oops",
            text: "Something went wrong",
            icon: "warning",
            confirmButtonText: "Ok",
          }).then((result) => {});
        }
        throw error;
      });
  };

  useEffect(() => {
    getActiveOffers()
      .then((response) => {
        setActiveOffersList(response);
      })
      .catch((error) => {
        throw error;
      });
  }, []);

  useEffect(() => {
    if (watch("amount_type") === "Earned") {
      reset({
        status: "Cashback Reward",
        amount_type: watch("amount_type"),
      });
    } else if (watch("amount_type") === "Spend") {
      reset({
        status: "Withdrawal",
        amount_type: watch("amount_type"),
      });
    }
  }, [watch("amount_type")]);

  useEffect(() => {
    const offetId = watch("offer_id");
    if (offetId === "Withdrawal") {
      resetField("amount");
      setIsDisabledCbAmountField(false);
      return;
    } else if (offetId !== "Miscellaneous") {
      resetField("amount");
      setIsDisabledCbAmountField(true);
      return;
    }
    resetField("amount");
    setIsDisabledCbAmountField(false);
  }, [watch("offer_id")]);

  return (
    <>
      <Box sx={{ ml: "4", pt: "14 !important", pb: "5", px: 3 }}>
        <h3 className="mx-3 pt-3">Give cashback to user</h3>
        <Box sx={{ background: "#ffffff", p: 2, mb: 4, pb: 4 }}>
          <form onSubmit={handleSubmit(giveCashback)}>
            <div className="row mx-2">
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label fw-bold text-secondary user-select-none">
                    Amount Type <small className="text-danger fs-6">*</small>
                  </label>

                  <select
                    {...register("amount_type", { required: true })}
                    className={`form-control ${
                      errors.amount_type ? "invalid-form-input" : ""
                    }`}
                  >
                    <option value="">Select</option>
                    <option value="Earned">Earned</option>
                    <option value="Spend">Spend</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label fw-bold text-secondary user-select-none">
                    User Email <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("email", {
                      required: true,
                      pattern: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
                    })}
                    type="email"
                    className={`form-control ${
                      errors.email ? "invalid-form-input" : ""
                    }`}
                    placeholder="Enter User email"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label fw-bold text-secondary user-select-none">
                    Offer <small className="text-danger fs-6">*</small>
                  </label>

                  <select
                    className={`form-control ${
                      errors.offer_id ? "invalid-form-input" : ""
                    }`}
                    {...register("offer_id", { required: true })}
                  >
                    <option value="">Select</option>
                    <option value="Withdrawal">Withdrawal | N/A</option>
                    <option value="Miscellaneous">Miscellaneous | N/A</option>
                    {activeOffersList.map((data) => {
                      return (
                        <option key={data.id} value={data.id}>
                          {data.advertiserName} | {data.commission}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              {watch("amount_type") === "Earned" && (
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label fw-bold text-secondary user-select-none">
                      Amount Spent{" "}
                    </label>
                    <input
                      {...register("spent_amount")}
                      type="number"
                      step=".0000000001"
                      className={`form-control `}
                      placeholder="Enter Amount Spent"
                    />
                  </div>
                </div>
              )}
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label fw-bold text-secondary user-select-none">
                    Cashback Amount{" "}
                    {!isDisabledCbAmountField && (
                      <small className="text-danger fs-6">*</small>
                    )}
                  </label>
                  <input
                    {...register("amount", {
                      required: !isDisabledCbAmountField,
                    })}
                    step=".0000000001"
                    type="number"
                    className={`form-control ${
                      errors.amount ? "invalid-form-input" : ""
                    }`}
                    placeholder="Enter Cashback Amount"
                    disabled={isDisabledCbAmountField}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label fw-bold text-secondary user-select-none">
                    Transaction Date{" "}
                    <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("action_date", { required: true })}
                    type="date"
                    className={`form-control ${
                      errors.action_date ? "invalid-form-input" : ""
                    }`}
                    placeholder="Enter Transaction Date"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label fw-bold text-secondary user-select-none">
                    Status <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    style={{ background: "#fff" }}
                    readOnly
                    {...register("status", { required: true })}
                    type="text"
                    className={`form-control ${
                      errors.status ? "invalid-form-input" : ""
                    }`}
                    placeholder="Enter Status"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label fw-bold text-secondary user-select-none">
                    Item Id
                  </label>
                  <input
                    {...register("item_id")}
                    type="text"
                    className={`form-control ${
                      errors.item_id ? "invalid-form-input" : ""
                    }`}
                    placeholder="Enter Item Id"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label fw-bold text-secondary user-select-none">
                    Commission Id
                  </label>
                  <input
                    {...register("commision_id")}
                    type="text"
                    className={`form-control ${
                      errors.commision_id ? "invalid-form-input" : ""
                    }`}
                    placeholder="Enter Commission Id"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label fw-bold text-secondary user-select-none">
                    Remark
                  </label>
                  <input
                    {...register("remark")}
                    type="text"
                    className={`form-control ${
                      errors.remark ? "invalid-form-input" : ""
                    }`}
                    placeholder="Enter Remark"
                  />
                </div>
              </div>
              {watch("amount_type") === "Earned" && (
                <div
                  className="col-md-12 d-flex align-items-end"
                  style={{ marginBottom: "12px" }}
                >
                  <div className="form-groupform-check d-flex align-items-center gap-3 ">
                    <input
                      {...register("pending")}
                      className={`form-check-input ${
                        errors.newSignUpFlow && "invalid-form-input"
                      }`}
                      type="checkbox"
                      id="pending"
                    />
                    <label
                      className="form-check-label fw-bold text-secondary"
                      htmlFor="pending"
                      style={{ lineHeight: "1" }}
                    >
                      Pending
                    </label>
                  </div>
                </div>
              )}
              <hr style={{ height: "0.5px", marginTop: "20px" }} />
              <div className="col-md-12">
                <button type="submit" className="btn btn-primary">
                  Cashback
                </button>
              </div>
            </div>
          </form>
        </Box>
      </Box>
    </>
  );
}

export default Cashback;
