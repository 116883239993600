import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPendingBankList } from "../../../redux/slices/bankSlice";
import moment from "moment";
import Modal from "@mui/material/Modal";
import { useForm } from "react-hook-form";
import { updateBankRecord, getPendingBankLists } from "../../../services/bank";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { filterList } from "../../../libs/utils";
import { Box, Button, TextField, Typography } from "@mui/material";
import localforage from "localforage";
import CustomTable from "../../../components/CustomTable";
import SearchIcon from "@mui/icons-material/Search";
import Swal from "sweetalert2";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function PendingBanks() {
  const dispatch = useDispatch();
  const bank = useSelector((state) => state.bank);
  const { list: bankList } = bank;

  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [searchKey, setSearchKey] = useState("");
  const [opens, setOpens] = useState(false);
  const [isDynamicTable, setIsDynamicTable] = useState(false);
  const loading = false;
  const setDynamicTable = (parentWidth, tableWidth) => {
    setIsDynamicTable(true);
  };

  const handleOpen = (e, requestId, currentUserId) => {
    reset({
      status: "",
      approval_comment: "",
      bankAddRequestId: requestId,
      currentUserId: currentUserId,
    });
    setOpens(true);
    setSelectedRow({
      bankAddRequestId: requestId,
      currentUserId: currentUserId,
    });
  };
  const handleCloses = () => setOpens(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const dataGenerator = (v) => {
    return {
      UniqueIdentifier: v.uniqueIdentifier,
      FailureReason: v.failureReason,
      Email: v.email,
      Status: v.status,
      CreatedAt: moment(v.createdAt).format("MMM D, YYYY"),
      CurrentuserId: v.currentUserId,
      ConflictingUserId: v.userId,
      Action: (
        <Button
          style={{ whiteSpace: "nowrap" }}
          onClick={(e) => handleOpen(e, v.bankAddRequestId, v.currentUserId)}
          color="success"
          variant="contained"
        >
          Approve Bank
        </Button>
      ),
    };
  };

  const updateBank = (data) => {
    updateBankRecord({
      status: data.status,
      approval_comment: data.approval_comment,
      bankAddRequestId: data.bankAddRequestId,
      currentUserId: data.currentUserId,
    })
      .then((response) => {
        setOpens(false);
        if (response.status === "Card registered successfully") {
          Swal.fire({
            title: "Success",
            text: "Bank status has been updated",
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              getPendingBankLists()
                .then((response) => {
                  setRows(response.map((v) => dataGenerator(v)));
                })
                .catch((error) => {
                  Swal.fire({
                    title: "Oops",
                    text: "Something went wrong",
                    icon: "warning",
                    confirmButtonText: "Ok",
                  }).then((result) => {});
                  throw error;
                });
            }
          });
        } else {
          Swal.fire({
            title: "Oops",
            text: "Something went wrong",
            icon: "warning",
            confirmButtonText: "Ok",
          }).then((result) => {});
        }
      })
      .catch((error) => {
        setOpens(false);
        Swal.fire({
          title: "Oops",
          text: "Something went wrong",
          icon: "warning",
          confirmButtonText: "Ok",
        }).then((result) => {});
        throw error;
      });
  };

  const columns = [
    {
      id: "UniqueIdentifier",
      label: "Unique Identifier",
      minWidth: 100,
      align: "left",
    },
    {
      id: "FailureReason",
      label: "Failure Reason",
      minWidth: 100,
      align: "center",
      enableSort: true,
    },
    {
      id: "Email",
      label: "Email",
      minWidth: 100,
      align: "center",
    },
    {
      id: "Status",
      label: "Status",
      minWidth: 100,
      align: "center",
      enableSort: true,
    },
    {
      id: "CreatedAt",
      label: "Created At",
      minWidth: 100,
      align: "center",
      enableSort: true,
    },
    {
      id: "CurrentuserId",
      label: "Current User Id",
      minWidth: 100,
      align: "center",
      enableSort: true,
    },

    {
      id: "ConflictingUserId",
      label: "Conflicting User Id",
      minWidth: 100,
      align: "center",
      enableSort: true,
    },
    {
      id: "Action",
      label: "Action",
      minWidth: 100,
      align: "center",
    },
  ];

  const searchData = (e) => {
    setSearchKey(e.target.value);
  };

  useEffect(() => {
    dispatch(getPendingBankList());
  }, [dispatch]);

  useEffect(() => {
    localforage.getItem("@pendingBank", (error, data) => {
      if (data) {
        if (data?.length > 0 && typeof data === "object") {
          setRows(data.map((v) => dataGenerator(v)));
        } else {
          setRows([]);
        }
      }
    });
    setRows(bankList.data.map((v) => dataGenerator(v)));
    if (bankList.data.length > 0) {
      localforage.setItem("@pendingBank", bankList.data, (error, data) => {
        if (error) {
          throw error;
        }
      });
    }
  }, [bankList]);

  return (
    <>
      <Box
        sx={{ px: 6 }}
        className={`main-box ${isDynamicTable ? "dynamicTableStyle" : ""}`}
      >
        <div
          className={`bankAddRuleHeader mb-3 ${
            isDynamicTable ? "listHeaderPaddingRight" : ""
          }`}
        >
          <div
            style={{
              display: "flex",
              padding: "1.5rem 0",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: "#33343a" }} variant="h4" component="h4">
              Failed bank Add Request
            </Typography>
            {loading && (
              <div
                style={{
                  marginLeft: "12px",
                  color: "#a0a0a0",
                  fontSize: "24px",
                }}
              >
                <i
                  className={`fa fa-refresh ${
                    loading === true ? "fa-spin" : ""
                  }`}
                  aria-hidden="true"
                ></i>
              </div>
            )}
          </div>
          <div style={{ display: "flex", alignItems: "end" }}>
            <TextField
              id="standard-basic"
              label="Search"
              variant="standard"
              sx={{ mr: 1 }}
              onKeyUp={(e) => searchData(e)}
              InputProps={{
                endAdornment: <SearchIcon sx={{ color: "#8a8a8a" }} />,
              }}
            />
          </div>
        </div>

        <CustomTable
          setDynamicTable={setDynamicTable}
          data={filterList(rows, searchKey) || []}
          columns={columns}
          defaultSortOrder="desc"
        />
      </Box>

      {/* ll */}

      <Modal
        open={opens}
        onClose={handleCloses}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="d-flex justify-content-between">
            <Typography
              id="modal-modal-title"
              variant="h4"
              component="h1"
              sx={{ mb: 2 }}
            >
              Update Bank Status
            </Typography>
            <div onClick={handleCloses} style={{ cursor: "pointer" }}>
              <i className="fa fa-times fa-2x text-danger"></i>
            </div>
          </div>

          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ mb: 2 }}
          >
            ID: {selectedRow.bankAddRequestId}
          </Typography>

          <form onSubmit={handleSubmit(updateBank)}>
            <p className="mb-1">
              Update Status: <small className="text-danger fs-6">*</small>
            </p>
            <div className="mb-3">
              <select
                {...register("status", {
                  required: true,
                })}
                className={`form-select ${
                  errors.status && "invalid-form-input"
                }`}
              >
                <option value="">Select</option>
                <option value="APPROVED">Approve</option>
                <option value="DISAPPROVED">Disapprove</option>
              </select>
              {/* {errors.status && (
                <div className="error-msg">This field is required</div>
              )} */}
            </div>

            <input
              type="hidden"
              defaultValue={selectedRow.bankAddRequestId}
              {...register("bankAddRequestId")}
            />
            <input
              type="hidden"
              defaultValue={selectedRow.currentUserId}
              {...register("currentUserId")}
            />

            <div className="mb-4">
              Approval Comment: <small className="text-danger fs-6">*</small>
              <br />
              <textarea
                {...register("approval_comment", {
                  required: true,
                })}
                className={`form-control ${
                  errors.approval_comment && "invalid-form-input"
                }`}
                style={{ width: "100%", height: "150px" }}
              ></textarea>
              {/* {errors.approval_comment && (
                <div className="error-msg">This field is required</div>
              )} */}
            </div>
            <Button type="submit" variant="contained">
              Update
            </Button>
          </form>
        </Box>
      </Modal>
      <ToastContainer />
    </>
  );
}

export default PendingBanks;
