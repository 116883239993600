import React, { useEffect } from "react";
import { Link,  useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import {  getMerchantById } from "../../../services/merchants";
import { Box,  TextField, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Swal from "sweetalert2";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { processTransactions } from "../../../services/bank";
import moment from "moment";

function ProcessTransactions() {
  const { id } = useParams();

  const [startDate, setstartDate] = React.useState(new Date());
  const [enddate, setenddate] = React.useState(new Date());

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const runTransactionProcess = (data) => {
    data.startDateTime = moment(data.startDateTime).unix() * 1000;
    data.endDateTime = moment(data.endDateTime).unix() * 1000;

    processTransactions(data.startDateTime, data.endDateTime, data.positiveNo)
      .then((response) => {
        if (response.success === true) {
          Swal.fire({
            title: "Process Transaction",
            text: response.message,
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
            }
          });
        } else {
          Swal.fire({
            title: "Oops",
            text: "Something went wrong",
            icon: "warning",
            confirmButtonText: "Ok",
          }).then((result) => {});
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Oops",
          text: "Something went wrong",
          icon: "warning",
          confirmButtonText: "Ok",
        }).then((result) => {});
        throw error;
      });
  };

  useEffect(() => {
    if (id) {
      getMerchantById(id)
        .then((response) => {
          if (response) {
            reset({
              merchant_name: response[0].merchant_name,
              merchant_id: response[0].merchant_id,
              linked_store_ids: JSON.stringify(
                response[0].linked_store_ids,
                null,
                2
              ),
              stores: JSON.stringify(response[0].stores, null, 4),
            });
          }
        })
        .catch((error) => {
          throw error;
        });
    }
  }, [id]);

  return (
    <Box sx={{ ml: "4", pt: "14 !important", pb: "5", px: 3 }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "25px",
          marginTop: "20px",
          gap: "16px",
        }}
      >
        <Link to="/app/list-merchants">
          <ArrowBackIcon
            fontSize="large"
            color="secondary"
            sx={{ background: "#040721", p: 1, borderRadius: "50%" }}
          />
        </Link>
        <Typography
          variant="h5"
          component="h5"
          fontWeight={"bold"}
          color={"#3d3d3d"}
        >
          Re Process Transactions
        </Typography>
      </div>
      <Box sx={{ background: "#ffffff", p: 2, mb: 4, pb: 4 }}>
        <form onSubmit={handleSubmit(runTransactionProcess)} noValidate>
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <Controller
                  name="startDateTime"
                  defaultValue={startDate}
                  control={control}
                  render={({ field: { onChange, ...restField } }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker
                        label="Start Date And Time"
                        onChange={(event) => {
                          onChange(event);
                          setstartDate(event);
                        }}
                        renderInput={(params) => (
                          <TextField fullWidth sx={{ mt: 2 }} {...params} />
                        )}
                        {...restField}
                        helperText={
                          errors.startDateTime
                            ? "Please Enter Start Date And Time"
                            : ""
                        }
                      />
                    </LocalizationProvider>
                  )}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <Controller
                  name="endDateTime"
                  defaultValue={enddate}
                  control={control}
                  render={({ field: { onChange, ...restField } }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker
                        label="End Date And Time"
                        onChange={(event) => {
                          onChange(event);
                          setenddate(event);
                        }}
                        renderInput={(params) => (
                          <TextField fullWidth sx={{ mt: 2 }} {...params} />
                        )}
                        {...restField}
                        helperText={
                          errors.endDateTime
                            ? "Please Enter End Date And Time"
                            : ""
                        }
                      />
                    </LocalizationProvider>
                  )}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <TextField
                  {...register("positiveNo", {
                    required: true,
                  })}
                  error={Boolean(errors.positiveNo)}
                  margin="normal"
                  fullWidth
                  label="Positive Limit Value"
                />
              </div>
            </div>
          </div>
          <hr style={{ height: "0.5px", marginTop: "32px" }} />
          <div>
            <button className="btn btn-primary py-2 px-5" name="save">
              Run
            </button>
          </div>
        </form>
      </Box>
    </Box>
  );
}

export default ProcessTransactions;
