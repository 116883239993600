import * as React from "react";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import localforage from "localforage";
import { getCMSUsers, send2faEmail } from "../../services/user";
import { filterList } from "../../libs/utils";
import CustomTable from "../../components/CustomTable";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import Swal from "sweetalert2";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3, pt: 0 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ListCmsUsers() {
  const [rows, setRows] = useState();
  const [deleteduser, setdeleteduser] = useState();
  const [loading, setLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  const [value, setValue] = React.useState(0);

  const navigation = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const sendEmail = (id) => {
    send2faEmail(id)
      .then((response) => {
        Swal.fire({
          title: "Success",
          text: "Mail has been send successfully",
          icon: "success",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            navigation(1);
          }
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Oops",
          text: "Something went wrong",
          icon: "warning",
          confirmButtonText: "Ok",
        }).then((result) => {});
      });
  };
  const columns = [
    { id: "userName", label: "userName", minWidth: 90, enableSort: true },
    { id: "Email", label: "Email", minWidth: 80 },
    {
      id: "LastLogin",
      label: "Last Login",
      minWidth: 60,
      align: "right",
      enableSort: true,
    },
    {
      id: "DateJoined",
      label: "Date Joined",
      minWidth: 50,
      align: "right",
      enableSort: true,
    },
    {
      id: "Enabled",
      label: "Enabled",
      minWidth: 60,
      align: "center",
      enableSort: true,
    },
    {
      id: "isActive",
      label: "Active",
      minWidth: 70,
      align: "center",
      enableSort: true,
    },
    {
      id: "Send2FAMail",
      label: "Send 2FA Mail",
      minWidth: 70,
      align: "center",
    },
    {
      id: "Action",
      label: "Action",
      minWidth: 70,
      align: "right",
    },
  ];

  function createData(
    userName,
    Email,
    LastLogin,
    DateJoined,
    Enabled,
    isActive,
    Send2FAMail,
    Action
  ) {
    return {
      userName,
      Email,
      LastLogin,
      DateJoined,
      Enabled,
      isActive,
      Send2FAMail,
      Action,
    };
  }

  useEffect(() => {
    setLoading(true);

    localforage.getItem("@cmsusers", (error, data) => {
      if (data) {
        if (
          data?.activeUser.length > 0 &&
          typeof data.activeUser === "object"
        ) {
          setRows(
            data.activeUser.map((d) => {
              return createData(
                d.username,
                d.email,
                d.last_login,
                d.date_joined,
                String(d.enabled),
                String(d.is_active),
                <>
                  {d.is_active ? (
                    <Button
                      variant="contained"
                      size="small"
                      color="error"
                      onClick={() => sendEmail(d.id)}
                    >
                      Send 2FA Mail
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      size="small"
                      color="warning"
                      disabled
                    >
                      Send 2FA Mail
                    </Button>
                  )}
                </>,
                <>
                  <Link
                    to={`/app/cms-user/${d.id}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Button variant="contained" size="small" color="warning">
                      Edit
                    </Button>
                  </Link>
                </>
              );
            })
          );
        } else {
          setRows([]);
        }
      }
    });
    getCMSUsers()
      .then((response) => {
        setLoading(false);
        if (
          response?.activeUser.length > 0 &&
          typeof response.activeUser === "object"
        ) {
          setRows(
            response.activeUser.map((d) => {
              return createData(
                d.username,
                d.email,
                d.last_login,
                d.date_joined,
                String(d.enabled),
                String(d.is_active),
                <>
                  {d.is_active ? (
                    <Button
                      variant="contained"
                      size="small"
                      color="error"
                      onClick={() => sendEmail(d.id)}
                    >
                      Send 2FA Mail
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      size="small"
                      color="warning"
                      disabled
                    >
                      Send 2FA Mail
                    </Button>
                  )}
                </>,
                <>
                  <Link
                    to={`/app/cms-user/${d.id}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Button variant="contained" size="small" color="warning">
                      Edit
                    </Button>
                  </Link>
                </>
              );
            })
          );
        } else {
          setRows([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        throw error;
      });

    localforage.getItem("@cmsusers", (error, data) => {
      if (data) {
        if (
          data?.deletedUsers.length > 0 &&
          typeof data.deletedUsers === "object"
        ) {
          setdeleteduser(
            data.deletedUsers.map((d) => {
              return createData(
                d.username,
                d.email,
                d.last_login,
                d.date_joined,
                <>
                  {d.enabled === false && <span>False</span>}
                  {d.enabled === true && <span>True</span>}
                </>,
                <>
                  {d.is_active === false && <span>False</span>}
                  {d.is_active === true && <span>True</span>}
                </>,
                <>
                  {d.is_active ? (
                    <Button
                      variant="contained"
                      size="small"
                      color="success"
                      onClick={() => sendEmail(d.id)}
                    >
                      Send 2FA Mail
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      size="small"
                      color="warning"
                      disabled
                    >
                      Send 2FA Mail
                    </Button>
                  )}
                </>,
                <>
                  <Link
                    to={`/app/cms-user/${d.id}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Button variant="contained" size="small" color="warning">
                      Edit
                    </Button>
                  </Link>
                </>
              );
            })
          );
        } else {
          setdeleteduser([]);
        }
      }
    });
    getCMSUsers()
      .then((response) => {
        setLoading(false);
        if (
          response?.deletedUsers.length > 0 &&
          typeof response.deletedUsers === "object"
        ) {
          setdeleteduser(
            response.deletedUsers.map((d) => {
              return createData(
                d.username,
                d.email,
                d.last_login,
                d.date_joined,
                <>
                  {d.enabled === false && <span>False</span>}
                  {d.enabled === true && <span>True</span>}
                </>,
                <>
                  {d.is_active === false && <span>False</span>}
                  {d.is_active === true && <span>True</span>}
                </>,
                <>
                  <Button
                    variant="contained"
                    size="small"
                    color="warning"
                    disabled
                  >
                    Send 2FA Mail
                  </Button>
                </>,
                <>
                  <Link
                    to={`/app/cms-user/${d.id}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Button variant="contained" size="small" color="warning">
                      Edit
                    </Button>
                  </Link>
                </>
              );
            })
          );
        } else {
          setdeleteduser([]);
        }
        localforage.setItem("@cmsusers", response, (error, data) => {
          if (error) {
            throw error;
          }
        });
      })
      .catch((error) => {
        setLoading(false);
        throw error;
      });
  }, []);

  const searchData = (e) => {
    setSearchKey(e.target.value);
  };

  return (
    <>
      <Box sx={{ px: 6 }}>
        <div className="bankAddRuleHeader mb-3">
          <div
            style={{
              display: "flex",
              padding: "1.5rem 0",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: "#33343a" }} variant="h4" component="h4">
              CMS Users List
            </Typography>
            {loading && (
              <div
                style={{
                  marginLeft: "12px",
                  color: "#a0a0a0",
                  fontSize: "24px",
                }}
              >
                <i
                  className={`fa fa-refresh ${
                    loading === true ? "fa-spin" : ""
                  }`}
                  aria-hidden="true"
                ></i>
              </div>
            )}
          </div>
          <div style={{ display: "flex", alignItems: "end" }}>
            <TextField
              id="standard-basic"
              label="Search"
              variant="standard"
              sx={{ mr: 1 }}
              onKeyUp={(e) => searchData(e)}
              InputProps={{
                endAdornment: <SearchIcon sx={{ color: "#8a8a8a" }} />,
              }}
            />

            <Tooltip title="Choose Option">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <MoreVertIcon sx={{ width: 32, height: 32 }} />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem
                sx={{
                  "&:hover": {
                    background: "#f9da8792",
                    color: "#000",
                  },
                }}
              >
                <Link to="/app/cms-user/0" style={{ color: "#040721" }}>
                  Add Cms User
                </Link>
              </MenuItem>
            </Menu>
          </div>
        </div>
      </Box>
      <Box sx={{ px: 3 }}>
        <Box sx={{}}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Active Users" {...a11yProps(0)} sx={{ ml: 3 }} />
            <Tab label="Deleted Users" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Paper>
            <CustomTable
              data={filterList(rows, searchKey) || []}
              columns={columns}
            />
          </Paper>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Paper>
            <CustomTable
              data={filterList(deleteduser, searchKey) || []}
              columns={columns}
            />
          </Paper>
        </TabPanel>
      </Box>
      {/* ll */}

      {/* ll */}
    </>
  );
}
