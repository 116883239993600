import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Swal from "sweetalert2";
import { updateOfferStores } from "../../../services/offerStores";

function AddOffersStores(props) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const navigation = useNavigate();
  const updateofferStore = (data) => {
    const payload = {
      location: {
        type: "Point",
        coordinates: [Number(data.latitude) || 0, Number(data.longitude) || 0],
      },
      addressObject: {
        city: String(data.city),
        state: String(data.state),
        zipcode: String(data.zipcode),
        country: String(data.country),
        addressLine1: String(data.addressLine1),
        addressLine2: String(data.addressLine2),
      },
      name: String(data.name),
      active: Boolean(data.active),
      address: String(data.address),
    };

    const buttonType = window.event.submitter.name;

    if (buttonType === "save") {
      updateOfferStores(payload)
        .then((response) => {
          Swal.fire({
            title: "Success",
            text: "Offer Store has been saved successfully",
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              navigation(-1);
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "Oops",
            text: "Something went wrong",
            icon: "warning",
            confirmButtonText: "Ok",
          }).then((result) => {});
        });
    }
    if (buttonType === "savegoanother") {
      updateOfferStores(payload)
        .then((response) => {
          Swal.fire({
            title: "Success",
            text: "Offer Store has been saved successfully",
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              reset({
                longitude: "",
                latitude: "",
                city: "",
                state: "",
                zipcode: "",
                country: "",
                addressLine1: "",
                addressLine2: "",
                id: null,
                name: "",
                active: "",
                address: "",
              });
              navigation(`/app/offer-store/0`);
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "Oops",
            text: "Something went wrong",
            icon: "warning",
            confirmButtonText: "Ok",
          }).then((result) => {});
        });
    }
    if (buttonType === "savecontinue") {
      updateOfferStores(payload)
        .then((response) => {
          Swal.fire({
            title: "Success",
            text: "Offer Store has been saved successfully",
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {
            const ids = response.id;
            navigation(`/app/offer-store/${ids}`);
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "Oops",
            text: "Something went wrong",
            icon: "warning",
            confirmButtonText: "Ok",
          }).then((result) => {});
        });
    }
  };

  return (
    <>
      <Box sx={{ ml: "4", pt: "14 !important", pb: "5", px: 3 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "25px",
            marginTop: "20px",
            gap: "16px",
          }}
        >
          <Link to="/app/list-offer-store">
            <ArrowBackIcon
              fontSize="large"
              color="secondary"
              sx={{ background: "#040721", p: 1, borderRadius: "50%" }}
            />
          </Link>
          <Typography
            variant="h5"
            component="h5"
            fontWeight={"bold"}
            color={"#3d3d3d"}
          >
            Add Offer Store
          </Typography>
        </div>
        <Box sx={{ background: "#ffffff", p: 2, mb: 4, pb: 4 }}>
          <form onSubmit={handleSubmit(updateofferStore)}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="name"
                  >
                    Name <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("name", { required: true })}
                    className={`form-control ${
                      errors.name && "invalid-form-input"
                    }`}
                    type="text"
                    id="name"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="name"
                  >
                    Latitude <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("latitude", { required: true })}
                    className={`form-control ${
                      errors.latitude && "invalid-form-input"
                    }`}
                    step=".0000000001"
                    type="number"
                    id="name"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="name"
                  >
                    Longitude <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("longitude", { required: true })}
                    className={`form-control ${
                      errors.longitude && "invalid-form-input"
                    }`}
                    step=".0000000001"
                    type="number"
                    id="name"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="name"
                  >
                    Address <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("address", { required: true })}
                    className={`form-control ${
                      errors.address && "invalid-form-input"
                    }`}
                    type="text"
                    id="name"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="name"
                  >
                    AddressLine1
                  </label>
                  <input
                    {...register("addressLine1")}
                    className={`form-control ${
                      errors.addressLine1 && "invalid-form-input"
                    }`}
                    type="text"
                    id="name"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="name"
                  >
                    AddressLine2:
                  </label>
                  <input
                    {...register("addressLine2")}
                    className={`form-control ${
                      errors.addressLine2 && "invalid-form-input"
                    }`}
                    type="text"
                    id="name"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="name"
                  >
                    City <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("city", { required: true })}
                    className={`form-control ${
                      errors.city && "invalid-form-input"
                    }`}
                    type="text"
                    id="name"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="name"
                  >
                    State <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("state", { required: true })}
                    className={`form-control ${
                      errors.state && "invalid-form-input"
                    }`}
                    type="text"
                    id="name"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="name"
                  >
                    Country <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("country", { required: true })}
                    className={`form-control ${
                      errors.country && "invalid-form-input"
                    }`}
                    type="text"
                    id="name"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="name"
                  >
                    Zipcode <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("zipcode", { required: true })}
                    className={`form-control ${
                      errors.zipcode && "invalid-form-input"
                    }`}
                    type="text"
                    id="name"
                  />
                </div>
              </div>

              <div
                className="col-md-3 d-flex align-items-end"
                style={{ marginTop: "32px" }}
              >
                <div className="form-groupform-check d-flex align-items-center gap-4 ">
                  <input
                    {...register("active")}
                    className={`form-check-input`}
                    type="checkbox"
                    id="is_active"
                  />
                  <label
                    className="form-check-label fw-bold text-secondary"
                    htmlFor="is_active"
                    style={{ lineHeight: "1" }}
                  >
                    Is Active
                  </label>
                </div>
              </div>
            </div>
            <hr style={{ height: "0.5px", marginTop: "32px" }} />
            <div>
              <button
                type="submit"
                className="btn btn-primary py-2 px-5"
                name="save"
                variant="contained"
                sx={{ width: "25%" }}
              >
                Save
              </button>
              <button
                variant="contained"
                type="submit"
                className="btn btn-primary py-2 px-5"
                name="savegoanother"
                sx={{ width: "25%" }}
              >
                Save And Add Another
              </button>
              <button
                variant="contained"
                type="submit"
                className="btn btn-primary py-2 px-5"
                name="savecontinue"
                sx={{ width: "25%" }}
              >
                Save and Continue Editing
              </button>
            </div>
          </form>
        </Box>
      </Box>
    </>
  );
}

export default AddOffersStores;
