import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  updateTierBenefit,
  getTierBenefitById,
} from "../../../services/tierBenefit";
import { Box, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getTiers } from "../../../services/offers";
import Swal from "sweetalert2";
import ImageUploadUtility from "../../../utils/ImageUploadUtility";
import ImageData from "../../../utils/DisplayImage";
import { setUploadImageLink } from "../../../redux/slices/offersSlice";

function EditTierBenefits() {
  const [geTtiers, setgetTiers] = useState([]);
  const [response, setResponse] = useState();
  const [savedResponse, setSavedResponse] = useState({});
  const [iconUrl, setIconUrl] = useState();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const imageData = useSelector((state) => state.imageData);
  const link = imageData?.imageData;

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const editTierBenefit = (data) => {
    // data.linked_store_ids = JSON.parse(data.linked_store_ids);
    // data.stores = null;
    if (data.active === "") data.active = false;
    if (data.tierId === "") data.tierId = null;
    data.id = id;
    let updatedData = { ...data, icon: link };
    updateTierBenefit(updatedData)
      .then((response) => {
        Swal.fire({
          title: "Success",
          text: "Tier benefit has been saved successfully",
          icon: "success",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            navigation(-1);
          }
        });
        setSavedResponse(response);
      })
      .catch((error) => {
        Swal.fire({
          title: "Oops",
          text: "Something went wrong",
          icon: "warning",
          confirmButtonText: "Ok",
        }).then((result) => {});
        throw error;
      });
  };

  useEffect(() => {
    if (response) {
      setValue("tierId", response[0].tierId);
    }
  }, [response, geTtiers]);

  useEffect(() => {
    if (id) {
      getTierBenefitById(id)
        .then((response) => {
          setResponse(response);
          if (response) {
            reset({
              title: response[0].title,
              subTitle: response[0].subTitle,
              fineCopy: response[0].fineCopy,
              icon: response[0].icon,
              deepLink: response[0].deepLink,
              active: response[0].active,
            });
            setIconUrl(response[0].icon);
          }
        })
        .catch((error) => {
          throw error;
        });
    }
  }, [id]);

  useEffect(() => {
    getTiers()
      .then((response) => {
        setgetTiers(response);
      })
      .catch(function (error) {
        throw error;
      });
    return () => {
      dispatch(setUploadImageLink(""));
    };
  }, []);

  return (
    <Box sx={{ ml: "4", pt: "14 !important", pb: "5", px: 3 }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "25px",
          marginTop: "20px",
          gap: "16px",
        }}
      >
        <div>
          <Link to="/app/list-benefits">
            <ArrowBackIcon
              fontSize="large"
              color="secondary"
              sx={{ background: "#040721", p: 1, borderRadius: "50%" }}
            />
          </Link>
        </div>

        <Typography
          variant="h5"
          component="h5"
          fontWeight={"bold"}
          color={"#3d3d3d"}
        >
          Edit Tier Benefit
        </Typography>
      </div>
      <Box sx={{ background: "#ffffff", p: 2, mb: 4, pb: 4 }}>
        <form onSubmit={handleSubmit(editTierBenefit)} noValidate>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none"
                  htmlFor="title"
                >
                  Title <small className="text-danger fs-6">*</small>
                </label>
                <input
                  {...register("title", { required: true })}
                  className={`form-control ${
                    errors.title && "invalid-form-input"
                  }`}
                  type="text"
                  id="title"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none"
                  htmlFor="subTitle"
                >
                  Sub Title <small className="text-danger fs-6">*</small>
                </label>
                <input
                  {...register("subTitle", { required: true })}
                  className={`form-control ${
                    errors.subTitle && "invalid-form-input"
                  }`}
                  type="text"
                  id="subTitle"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none"
                  htmlFor="fineCopy"
                >
                  Fine Copy
                </label>
                <input
                  {...register("fineCopy")}
                  className={`form-control ${
                    errors.fineCopy && "invalid-form-input"
                  }`}
                  type="text"
                  id="fineCopy"
                />
              </div>
            </div>
            <input
              {...register("icon")}
              className={`form-control`}
              type="hidden"
              id="icon"
            />
            <div className="row">
              <ImageUploadUtility
                uploadType="TIER_BENEFITS"
                name="tierName"
                inputFiletype="IMAGE"
                label="Offer Icon"
              />
              <ImageData
                inputFiletype="IMAGE"
                imageUrl={iconUrl ? iconUrl : savedResponse.logo}
              />
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none"
                  htmlFor="tiers"
                >
                  Tiers <small className="text-danger fs-6">*</small>
                </label>
                <select
                  {...register("tierId", { required: true })}
                  className={`form-select ${
                    errors.tierId && "invalid-form-input"
                  }`}
                  id="tiers"
                >
                  <option value="">Select</option>
                  {geTtiers.map((value) => (
                    <option key={value.id} value={value.id}>
                      {value.name} | {String(value.active)}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none"
                  htmlFor="deepLink"
                >
                  Deep Link
                  <small className="text-danger fs-6">*</small>
                </label>
                <input
                  {...register("deepLink", { required: true })}
                  className={`form-control ${
                    errors.deepLink && "invalid-form-input"
                  }`}
                  type="text"
                  id="deepLink"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none"
                  htmlFor="active"
                >
                  Visible <small className="text-danger fs-6">*</small>
                </label>
                <select
                  {...register("active", { required: true })}
                  className={`form-select ${
                    errors.active && "invalid-form-input"
                  }`}
                  id="active"
                >
                  <option value="">Select</option>
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
            </div>
          </div>
          <hr style={{ height: "0.5px", marginTop: "32px" }} />
          <div>
            <button className="btn btn-primary py-2 px-5" name="save">
              Save
            </button>
          </div>
        </form>
      </Box>
    </Box>
  );
}

export default EditTierBenefits;
