import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CustomTable from "../../../components/CustomTable";
import { bankAddRuleList } from "../../../services/bankAddRule";
import SearchIcon from "@mui/icons-material/Search";
import { filterList } from "../../../libs/utils";
import localforage from "localforage";

function ListBankAddRule() {
  const [rows, setRows] = useState();
  const [loading, setLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [isDynamicTable, setIsDynamicTable] = useState(false);

  const setDynamicTable = (parentWidth, tableWidth) => {
    setIsDynamicTable(true);
  };

  const columns = [
    {
      id: "RuleVersion",
      label: "Rule Version",
      minWidth: 100,
      align: "center",
      enableSort: true,
    },
    {
      id: "TransactionCount",
      label: "Transaction Count",
      minWidth: 100,
      align: "center",
      enableSort: true,
    },
    {
      id: "TransactionDays",
      label: "Transaction Days",
      minWidth: 100,
      align: "center",
      enableSort: true,
    },
    {
      id: "CreditAmountRule",
      label: "Credit Amount Rule",
      minWidth: 100,
      align: "center",
      enableSort: true,
    },
    {
      id: "DebitAmountRule",
      label: "Debit Amount Rule",
      minWidth: 100,
      align: "center",
      enableSort: true,
    },
    {
      id: "MerchantAccountRule",
      label: "Merchant Account Rule",
      minWidth: 100,
      align: "center",
      enableSort: true,
    },
    {
      id: "UserAcquiredChannelRule",
      label: "User Acquired Channel Rule",
      minWidth: 100,
      align: "center",
      enableSort: true,
    },
    {
      id: "Action",
      label: "Action",
      minWidth: 100,
    },
  ];

  const dataGenerator = (data) => {
    return {
      RuleVersion: data.rule_version,
      TransactionCount: data.transactions_count,
      TransactionDays: data.transaction_days,
      CreditAmountRule: data.credit_amount_rule,
      DebitAmountRule: data.debit_amount_rule,
      MerchantAccountRule: data.merchant_count_rule,
      UserAcquiredChannelRule: data.user_acquired_channel_rule,
      Action: (
        <Link
          to={`/app/bank-add-rule-engine/${data.id}`}
          className="textDecorationNone"
        >
          <Button variant="contained" size="small" color="warning">
            Edit
          </Button>
        </Link>
      ),
    };
  };

  useEffect(() => {
    setLoading(true);
    localforage.getItem("@bankRule", (error, data) => {
      if (data) {
        if (data?.length > 0 && typeof data === "object") {
          setRows(data.map((data) => dataGenerator(data)));
        } else {
          setRows([]);
        }
      }
    });
    bankAddRuleList()
      .then((response) => {
        setLoading(false);
        if (response?.length > 0 && typeof response === "object") {
          setRows(response.map((data) => dataGenerator(data)));
        } else {
          setRows([]);
        }
        localforage.setItem("@bankRule", response, (error, data) => {
          if (error) {
            throw error;
          }
        });
      })
      .catch((error) => {
        setLoading(false);
        throw error;
      });
  }, []);

  const searchData = (e) => {
    setSearchKey(e.target.value);
  };

  return (
    <>
      <Box
        sx={{ px: 6 }}
        className={`main-box ${isDynamicTable ? "dynamicTableStyle" : ""}`}
      >
        <div
          className={`bankAddRuleHeader mb-3 ${
            isDynamicTable ? "listHeaderPaddingRight" : ""
          }`}
        >
          <div
            style={{
              display: "flex",
              padding: "1.5rem 0",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: "#33343a" }} variant="h4" component="h4">
              Bank Add Rule Engine List
            </Typography>
            {loading && (
              <div
                style={{
                  marginLeft: "12px",
                  color: "#a0a0a0",
                  fontSize: "24px",
                }}
              >
                <i
                  className={`fa fa-refresh ${
                    loading === true ? "fa-spin" : ""
                  }`}
                  aria-hidden="true"
                ></i>
              </div>
            )}
          </div>
          <div style={{ display: "flex", alignItems: "end" }}>
            <TextField
              id="standard-basic"
              label="Search"
              variant="standard"
              sx={{ mr: 1 }}
              onKeyUp={(e) => searchData(e)}
              InputProps={{
                endAdornment: <SearchIcon sx={{ color: "#8a8a8a" }} />,
              }}
            />
          </div>
        </div>
        <CustomTable
          setDynamicTable={setDynamicTable}
          data={filterList(rows, searchKey) || []}
          columns={columns}
        />
      </Box>
    </>
  );
}

export default ListBankAddRule;
