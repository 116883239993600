import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import moment from "moment";
import { useSelector } from "react-redux";

function AchDetails() {
  const { email } = useParams();

  const [userAchData, setUserAchData] = useState({});
  const neobankConfigsData = useSelector((state) => state.neobankConfigsData);
  const { achData } = neobankConfigsData || {};
  const { data } = achData || {};

  const { status, status_description, transaction_date } = userAchData || {};

  const location = useLocation();
  const { request_id: requestId } = location.state || {};

  useEffect(() => {
    if (email && requestId) {
      const filteredData = data?.filter(
        (achItem) => achItem.request_id === requestId
      );
      filteredData?.map((retryItem) => setUserAchData(retryItem));
    }
  }, [email, requestId]);

  const achRetryData = (type) => {
    const mapping = [
      {
        key: " Retry Transaction Date",
        value: transaction_date
         ? moment.unix(transaction_date).format("MMM DD, YYYY")
          : "",
      },
      { key: " Retry Status", value: status },
      { key: " Retry Description", value: status_description },
    ];
    return mapping.map((retryItem) => {
      return (
        <div className="row">
          <div className="col-md-6">
            <div className="mb-3">
              <label className="form-label fw-bold text-secondary user-select-none">
                {retryItem.key}
              </label>
              <input
                value={retryItem.value}
                type="text"
                className={`form-control `}
                disabled
              />
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <Box sx={{ ml: "4", pt: "14 !important", pb: "5", px: 3 }}>
      <div className="achLedgerContainer">
        <Link to={-1}>
          <ArrowBackIcon
            fontSize="large"
            color="secondary"
            sx={{ background: "#040721", p: 1, borderRadius: "50%" }}
          />
        </Link>
        <Typography
          variant="h5"
          component="h5"
          fontWeight={"bold"}
          color={"#3d3d3d"}
        >
          Request ID ({requestId})
        </Typography>
      </div>
      <Box sx={{ background: "#ffffff", p: 2, mb: 4, pb: 4 }}>
        <div>{achRetryData()}</div>
      </Box>
    </Box>
  );
}

export default AchDetails;
