import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Swal from "sweetalert2";
import {
  gettransactionDataById,
  getMerchantUser,
  getOfferDatas,
  getpartnerOffers,
  updatetransactionsList,
} from "../../../services/merchant-mapping";

function EditTransactions() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const { id } = useParams();
  const [transactiondata, settransactiondata] = useState([]);
  const [partneroffers, setpartneroffers] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const a = "Direct";
    getpartnerOffers(a)
      .then((response) => {
        reset({
          localoffers: transactiondata.localOffersId,
        });
        setpartneroffers(response);
      })
      .catch((error) => {
        throw error;
      });
  }, [transactiondata]);

  useEffect(() => {
    gettransactionDataById(id)
      .then((response) => {
        settransactiondata(response);
        reset({
          status: response?.status,

          name: response?.name,
          date: response?.date,
          commission: response?.commission,
          address: response?.address,
          city: response?.city,
          amount: response?.amount,
          actionTime: response?.actionTime,
          OfferData: "",
          advertiserName: response?.advertiserName,
          email: response?.email,
          bankname: response?.bankName,
          accountId: response?.accountId,
          processed: response?.processed,
          approvedBy: response?.approvedBy,
          entryTime: response?.entryTime,
          rejectionreasons: response?.rejectionList,
        });
        getMerchantUser(response?.email)
          .then((response) => {
            reset({
              UserJoinDate: response?.entry_time,
            });

            getOfferDatas(response?.id)
              .then((response) => {})
              .catch((error) => {
                throw error;
              });
          })
          .catch((error) => {
            throw error;
          });
      })
      .catch((error) => {
        throw error;
      });
  }, [id]);

  const updateTransaction = (data) => {
    updatetransactionsList({
      id: data.id,
      localOffersId: data.localoffers,
      status: data.status,
    })
      .then((response) => {
        Swal.fire({
          title: "Success",
          text: "Transactions has been Saved successfully",
          icon: "success",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/app/transactions");
          }
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Oops",
          text: "Something went wrong",
          icon: "error",
          confirmButtonText: "Ok",
        }).then((result) => {});
        throw error;
      });
  };

  return (
    <>
      <Box sx={{ ml: "4", pt: "14 !important", pb: "5", px: 3 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "25px",
            marginTop: "20px",
            gap: "16px",
          }}
        >
          <Link to="/app/transactions">
            <ArrowBackIcon
              fontSize="large"
              l
              color="secondary"
              sx={{ background: "#040721", p: 1, borderRadius: "50%" }}
            />
          </Link>
          <Typography
            variant="h5"
            component="h5"
            fontWeight={"bold"}
            color={"#3d3d3d"}
          >
            Edit Transaction
          </Typography>
        </div>
        <Box sx={{ background: "#ffffff", p: 2, mb: 4, pb: 4 }}>
          <form onSubmit={handleSubmit(updateTransaction)}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="businessStatus"
                  >
                    Status <small className="text-danger fs-6">*</small>
                  </label>
                  <select
                    {...register("status", {
                      required: true,
                    })}
                    className={`form-control ${
                      errors.status && "invalid-form-input"
                    }`}
                    id="businessStatus"
                  >
                    <option>Select</option>
                    <option value="Accept">Accept</option>
                    <option value="Reject">Reject</option>
                    <option value="Cancelled">Cancelled</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="businessStatus"
                  >
                    Local Offers <small className="text-danger fs-6">*</small>
                  </label>
                  <select
                    {...register("localoffers", {
                      required: true,
                    })}
                    defaultValue={transactiondata.localOffersId}
                    className={`form-control ${
                      errors.localoffers && "invalid-form-input"
                    }`}
                    id="businessStatus"
                  >
                    <option>Select</option>
                    {partneroffers.map((value) => (
                      <option key={value.id} value={value.id}>
                        {value.advertiserName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <input
                defaultValue={transactiondata?.id}
                {...register("id")}
                type="hidden"
              />

              <div className="col-md-4">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="name"
                  >
                    Name
                  </label>
                  <input
                    {...register("name")}
                    className={`form-control`}
                    type="text"
                    id="name"
                    readOnly
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="formattedAddress"
                  >
                    Date:
                  </label>
                  <input
                    {...register("date")}
                    className={`form-control`}
                    type="text"
                    id="formattedAddress"
                    readOnly
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="placeId"
                  >
                    Commission:
                  </label>
                  <input
                    {...register("commission")}
                    className={`form-control`}
                    type="text"
                    id="placeId"
                    readOnly
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="stateName"
                  >
                    Action Time:
                  </label>
                  <input
                    {...register("actionTime")}
                    className={`form-control`}
                    type="text"
                    id="stateName"
                    readOnly
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="cityName"
                  >
                    Address:
                  </label>
                  <input
                    {...register("address")}
                    className={`form-control`}
                    type="text"
                    id="cityName"
                    readOnly
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="rating"
                  >
                    City:
                  </label>
                  <input
                    {...register("city")}
                    className={`form-control`}
                    type="text"
                    id="rating"
                    readOnly
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="userRatingsTotal"
                  >
                    Amount:
                  </label>
                  <input
                    {...register("amount")}
                    className={`form-control`}
                    type="text"
                    id="userRatingsTotal"
                    readOnly
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="keywords"
                  >
                    UserJoinDate:
                  </label>
                  <input
                    {...register("UserJoinDate")}
                    className={`form-control`}
                    type="text"
                    id="keywords"
                    readOnly
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="geometry"
                  >
                    Offer Data:
                  </label>
                  <input
                    {...register("OfferData")}
                    className={`form-control`}
                    type="text"
                    id="geometry"
                    readOnly
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="adver"
                  >
                    AdvertiserName:
                  </label>
                  <input
                    {...register("AdvertiserName")}
                    className={`form-control`}
                    type="text"
                    id="adver"
                    rows="4"
                    cols="60"
                    readOnly
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="Email"
                  >
                    Email:
                  </label>
                  <input
                    {...register("email")}
                    className={`form-control`}
                    type="text"
                    id="Email"
                    readOnly
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="bankname"
                  >
                    BankName:
                  </label>
                  <input
                    {...register("bankname")}
                    className={`form-control`}
                    type="text"
                    id="bankname"
                    readOnly
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="AccountId"
                  >
                    AccountId:
                  </label>
                  <input
                    {...register("accountId")}
                    className={`form-control`}
                    type="text"
                    id="AccountId"
                    readOnly
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="Processed"
                  >
                    Processed:
                  </label>
                  <input
                    {...register("processed")}
                    className={`form-control`}
                    type="text"
                    id="Processed"
                    readOnly
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="ApprovedBy"
                  >
                    Approved By:
                  </label>
                  <input
                    {...register("approvedBy")}
                    className={`form-control`}
                    type="text"
                    id="ApprovedBy"
                    readOnly
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="EntryTime"
                  >
                    Entry Time:
                  </label>
                  <input
                    {...register("entryTime")}
                    className={`form-control`}
                    type="text"
                    id="EntryTime"
                    readOnly
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="types"
                  >
                    Rejection Reasons:
                  </label>
                  <textarea
                    {...register("rejectionreasons")}
                    className={`form-control`}
                    type="text"
                    id="types"
                    rows="4"
                    cols="40"
                    readOnly
                  >
                    {" "}
                  </textarea>
                </div>
              </div>
            </div>
            <hr style={{ height: "0.5px", marginTop: "32px" }} />
            <div>
              <button
                type="submit"
                className="btn btn-primary py-2 px-5"
                name="save"
              >
                Update
              </button>
            </div>
          </form>
        </Box>
      </Box>
    </>
  );
}

export default EditTransactions;
