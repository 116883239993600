import './list-reward.css';
import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { TextField, Button } from '@mui/material';

function ListReward() {
    const columns = [
        { id: 'rewardName', label: 'Reward Name', minWidth: 120 },
        { id: 'title', label: 'Title', minWidth: 100 },
        {
          id: 'RewardPeriod',
          label: 'Reward Period',
          minWidth: 140,
          align: 'right',
          format: (value) => value.toLocaleString('en-US'),
        },
        {
          id: 'RewardType',
          label: 'Reward Type',
          minWidth: 140,
          align: 'right',
          format: (value) => value.toLocaleString('en-US'),
        },
        {
          id: 'RequiredSteps',
          label: 'Required Steps',
          minWidth: 130,
          align: 'right',
          format: (value) => value.toFixed(2),
        },
        {
            id: 'ExpiryDate',
            label: 'Expiry Date',
            minWidth: 130,
            align: 'right',
            format: (value) => value.toFixed(2),
          },
          {
            id: 'description',
            label: 'Description',
            minWidth: 180,
            align: 'right',
            format: (value) => value.toFixed(2),
          },
          {
            id: 'previousSteps',
            label: 'Previous Steps',
            minWidth: 120,
            align: 'right',
            format: (value) => value.toFixed(2),
          },
      ];
      
      function createData(rewardName, title, RewardPeriod, RewardType,RequiredSteps,ExpiryDate,description,previousSteps) {
        return { rewardName, title, RewardPeriod, RewardType,RequiredSteps,ExpiryDate,description,previousSteps };
      }
      
      const rows = [
        createData('India', 'IN', 1324171354, 3287263,'yes','24july2022','this is test data','No'),
        createData('China', 'CN', 1403500365, 9596961,'yes','24july2022','this is test data','No'),
        createData('Italy', 'IT', 60483973, 301340,'yes','24july2022','this is test data','No'),
        createData('United States', 'US', 327167434, 9833520,'yes','24july2022','this is test data','No'),
        createData('Canada', 'CA', 37602103, 9984670,'yes','24july2022','this is test data','No'),
        createData('Australia', 'AU', 25475400, 7692024,'yes','24july2022','this is test data','No'),
        createData('Germany', 'DE', 83019200, 357578,'yes','24july2022','this is test data','No'),
        createData('Ireland', 'IE', 4857000, 70273,'yes','24july2022','this is test data','No'),
        createData('Mexico', 'MX', 126577691, 1972550,'yes','24july2022','this is test data','No'),
        createData('Japan', 'JP', 126317000, 377973,'yes','24july2022','this is test data','No'),
        createData('France', 'FR', 67022000, 640679,'yes','24july2022','this is test data','No'),
        createData('United Kingdom', 'GB', 67545757, 242495,'yes','24july2022','this is test data','No'),
        createData('Russia', 'RU', 146793744, 17098246,'yes','24july2022','this is test data','No'),
        createData('Nigeria', 'NG', 200962417, 923768,'yes','24july2022','this is test data','No'),
        createData('Brazil', 'BR', 210147125, 8515767,'yes','24july2022','this is test data','No'),
      ];
      
        const [page, setPage] = useState(0);
        const [rowsPerPage, setRowsPerPage] = useState(10);
      
        const handleChangePage = (event, newPage) => {
          setPage(newPage);
        };
      
        const handleChangeRowsPerPage = (event) => {
          setRowsPerPage(+event.target.value);
          setPage(0);
        };
    return (
        <>
            <Box sx={{mt:2}}>
                <Typography
                    component="h4"
                    variant="h4"
                    color="inherit"
                    noWrap
                    sx={{ ml:3,fontSize:'22px',fontWeight:'700'}}
                >
                    Select Rewards to Change
                </Typography>
                <Toolbar
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between' // keep right padding when drawer closed
                    }}
                >
                    <TextField
                        margin="normal"
                        id="filter"
                        label="Filter"
                        name="filter"
                        placeholder="ex walmart"
                        autoFocus
                        variant="standard"
                    />
                    <Link to="/app/rewards/id"  >
                    <Button
                        type="submit"
                        variant="contained"
                        sx={{ mt: 3, mb: 2, py: 2, px: 5 }}
                    >
                        Add Rewards +
                    </Button>
                    </Link>

                </Toolbar>
                {/* ll */}

    <Paper sx={{ width: '98%', overflow: 'hidden',mx:2,
     boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)'
}}>
      <TableContainer sx={{ maxHeight: 440,
    }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20,50,75,100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  


                {/* ll */}
            </Box>
        </>
    )
}

export default ListReward