import React, { useState, useEffect, useLayoutEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import localforage from "localforage";
import { TextField,  Button } from "@mui/material";
import {
  getOfferStoresList,
  uploadofferStore,
} from "../../../services/offerStores";
import { useForm } from "react-hook-form";
import { filterList, startLoader } from "../../../libs/utils";
import CustomTable from "../../../components/CustomTable";
import SearchIcon from "@mui/icons-material/Search";
import Swal from "sweetalert2";

function ListOffersStores() {
  const { register, handleSubmit } = useForm();
  const [rows, setRows] = useState();
  const [loading, setLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [isDynamicTable, setIsDynamicTable] = useState(false);
  const [uploadedF, setuploadedF] = useState();
  const [isUploading, setIsUploading] = useState(false);

  const columns = [
    {
      id: "StoreName",
      label: "Store Name",
      minWidth: 80,
      enableSort: true,
    },
    {
      id: "Active",
      label: "Active",
      minWidth: 100,
      align: "center",
      enableSort: true,
    },
    {
      id: "Longitude",
      label: "Longitude",
      minWidth: 100,
      align: "center",
      enableSort: true,
    },
    {
      id: "Latitude",
      label: "Latitude",
      minWidth: 100,
      align: "center",
      enableSort: true,
    },
    {
      id: "Address",
      label: "Address",
      minWidth: 100,
      align: "center",
      enableSort: true,
    },

    {
      id: "Action",
      label: "Action",
      minWidth: 35,
      align: "center",
    },
  ];

  const dataGenerator = (d) => {
    return {
      StoreName: d.name,
      Active: String(d.active),
      Longitude: d.location.coordinates[0],
      Latitude: d.location.coordinates[1],
      Address: d.address,
      Action: (
        <Button disabled variant="contained" size="small" color="warning">
          <Link
            to={`/app/offer-store/${d.id}`}
            className="textDecorationNone linkColor"
          >
            Edit
          </Link>
        </Button>
      ),
    };
  };

  useEffect(() => {
    setLoading(true);
    localforage.getItem("@offerstores", (error, data) => {
      if (data) {
        if (data?.length > 0 && typeof data === "object") {
          setRows(data.map((d) => dataGenerator(d)));
        } else {
          setRows([]);
        }
      }
    });
    getOfferStoresList()
      .then((response) => {
        setLoading(false);
        if (response?.length > 0 && typeof response === "object") {
          setRows(response.map((d) => dataGenerator(d)));
        } else {
          setRows([]);
        }
        localforage.setItem("@offerstores", response, (error, data) => {
          if (error) {
            throw error;
          }
        });
      })
      .catch((error) => {
        setLoading(false);
        throw error;
      });
  }, []);

  const setDynamicTable = (parentWidth, tableWidth) => {
    setIsDynamicTable(true);
  };

  useLayoutEffect(() => {
    startLoader();
  }, []);

  const searchData = (e) => {
    setSearchKey(e.target.value);
  };



  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  const handleFileSelect = (evt) => {
    const files = evt.target.files;
    if (files[0].size > 5e6) {
      Swal.fire({
        title: "Oops",
        text: "File size exceeded, Allowed maximum size is 5 MB",
        icon: "warning",
        confirmButtonText: "Ok",
      }).then((result) => {});
    }
    setuploadedF(files[0]);
  };

  const uploadofferStoreData = () => {
    let flag = true;
    if (!uploadedF) {
      flag = false;
      Swal.fire({
        title: "Oops",
        text: "Please choose file",
        icon: "warning",
        confirmButtonText: "Ok",
      }).then((result) => {});
    } else if (uploadedF?.size > 5e6) {
      flag = false;
      Swal.fire({
        title: "Oops",
        text: "File size exceeded, Allowed maximum size is 5 MB",
        icon: "warning",
        confirmButtonText: "Ok",
      }).then((result) => {});
    }

    if (flag === true) {
      setIsUploading(true);
      uploadofferStore(uploadedF)
        .then((response) => {
          setIsUploading(false);
          Swal.fire({
            title: "Success",
            text: `Stores uploaded successfully`,
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {});
        })
        .catch((error) => {
          setIsUploading(false);
          throw error;
        });
    }
  };

  return (
    <>
      <Box
        sx={{ px: 6 }}
        className={`main-box ${isDynamicTable ? "dynamicTableStyle" : ""}`}
      >
        <div
          className={`bankAddRuleHeader mb-3 ${
            isDynamicTable ? "listHeaderPaddingRight" : ""
          }`}
        >
          <div className="headerTitle">
            <Typography sx={{ color: "#33343a" }} variant="h4" component="h4">
              Offers Stores List
            </Typography>
            {loading && (
              <div className="loaderBox">
                <i
                  className={`fa fa-refresh ${
                    loading === true ? "fa-spin" : ""
                  }`}
                  aria-hidden="true"
                ></i>
              </div>
            )}
          </div>
          <div className="filterBox">
            <TextField
              id="standard-basic"
              label="Search"
              variant="standard"
              sx={{ mr: 1 }}
              onKeyUp={(e) => searchData(e)}
              InputProps={{
                endAdornment: <SearchIcon sx={{ color: "#8a8a8a" }} />,
              }}
            />

            {/* <Tooltip title="Choose Option">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <MoreVertIcon sx={{ width: 32, height: 32 }} />
              </IconButton>
            </Tooltip> */}
            {/* <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem
                sx={{
                  "&:hover": {
                    background: "#f9da8792",
                    color: "#000",
                  },
                }}
              >
                <Link to="/app/offer-store/0" className="linkColor">
                  Add New Offer Store
                </Link>
              </MenuItem>
            </Menu> */}
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <form onSubmit={handleSubmit(uploadofferStoreData)}>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <div className="input-group mb-3">
                    <div style={{ display: "flex" }}>
                      <input
                        disabled
                        type="file"
                        className="form-control height38"
                        id="image"
                        accept=".csv"
                        onInput={(e) => handleFileSelect(e)}
                        {...register("file")}
                      />
                      {isUploading === true && (
                        <button
                          style={{ width: "200px" }}
                          className="btn btn-primary btn-block"
                          type="button"
                        >
                          Uploading{" "}
                          <i
                            className={`fa fa-refresh ${true ? "fa-spin" : ""}`}
                            aria-hidden="true"
                          ></i>
                        </button>
                      )}
                      {isUploading === false && (
                        <button
                          disabled
                          style={{ width: "200px" }}
                          className="btn btn-primary btn-block"
                        >
                          Upload Stores
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div>
            <a
              title="Click to download sample"
              className="btn btn-secondary storeSampleDownload"
              style={{ marginTop: "0px" }}
              href={null}
            >
              Download
            </a>
            {/* <a
              title="Click to download sample"
              className="btn btn-secondary storeSampleDownload"
              style={{ marginTop: "0px" }}
              href={sampleStoreFile}
              download
            >
              Download
            </a> */}
          </div>
        </div>

        <CustomTable
          setDynamicTable={setDynamicTable}
          data={filterList(rows, searchKey) || []}
          columns={columns}
        />
      </Box>
    </>
  );
}

export default ListOffersStores;
