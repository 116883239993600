import axios from "axios";
import appConfig from "../config/app.config";
import { decodeCMSUserToken, getUserToken, setUserToken } from "../libs/user";
import { getPureJSON, startLoader, stopLoader } from "../libs/utils";

const userLogin = (username, password,code) => {
  startLoader(1);
  return new Promise((resolve, reject) => {
    const data = new FormData();
    data.append("username", username);
    data.append("password", password);
    data.append("code" , code)
    const config = {
      method: "post",
      url: appConfig.apiUrl.userLogin,
      headers: {},
      data: data,
    };

    axios(config)
      .then(function (response) {
        stopLoader(1);
        setUserToken(response.headers["cms-token"]);
        decodeCMSUserToken(response.headers["cms-token"]);
        resolve(getPureJSON(response));
      })
      .catch(function (error) {
        stopLoader(1);
        reject(getPureJSON(error));
      });
  });
};

const userLogout = (token) => {
  return new Promise((resolve, reject) => {
    const config = {
      method: "delete",
      url: appConfig.apiUrl.userLogout,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

const forgotPassword = (email) => {
  return new Promise((resolve, reject) => {
    const data = JSON.stringify({
      email: email,
    });

    const config = {
      method: "post",
      url: appConfig.apiUrl.forgotPassword,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(getPureJSON(error.response || error));
      });
  });
};

const resetPassword = (password, confirmPassword, token) => {
  return new Promise((resolve, reject) => {
    const data = JSON.stringify({
      token: token,
      password: password,
      confirm_password: confirmPassword,
    });

    const config = {
      method: "post",
      url: appConfig.apiUrl.resetPassword,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(getPureJSON(error.response || error));
      });
  });
};

export { userLogin, userLogout, forgotPassword, resetPassword };
