import { useState, useEffect } from "react";
import moment from "moment";
import Box from "@mui/material/Box";
import localforage from "localforage";
import Typography from "@mui/material/Typography";
import { Link, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { filterList } from "../../../libs/utils";
import { getUserTransactionsList } from "../../../services/plaidTransactionService";
import CustomTable from "../../../components/CustomTable";
import styles from "../../../../app/assets/css/userTxnHistory.module.scss";

function ShowTransactions() {
  const {
    formState: { errors },
  } = useForm();

  const location = useLocation();
  const [page, setPage] = useState(0);

  const {
    account_id,
    month,
    year,
    customer_id,
    identifier,
    transaction_count
  } = location?.state || {};

  const [searchKey, setSearchKey] = useState("");
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [visited, setVisited] = useState([]);

  const columns = [
    { id: "bankName", label: "Bank Name", minWidth: 110, align: "left" },
    { id: "storeName", label: "Store Name", minWidth: 110, align: "left" },
    { id: "amount", label: "Amount", minWidth: 110, align: "left" },
    { id: "currency", label: "Currency", minWidth: 110, align: "left" },
    {
      id: "transactionType",
      label: "Transaction Type",
      minWidth: 110,
      align: "left",
    },
    {
      id: "accountOwner",
      label: "Account Owner",
      minWidth: 110,
      align: "left",
    },
    {
      id: "transactionId",
      label: "Transaction Id",
      minWidth: 110,
      align: "left",
    },
    {
      id: "paymentChannel",
      label: "Payment Channel",
      minWidth: 110,
      align: "left",
    },
    { id: "createDate", label: "Create Date", minWidth: 110, align: "left" },
    { id: "createdBy", label: "Created By", minWidth: 110, align: "left" },
  ];

  const dataGenerator = (data) => {
    return {
      ...data,
      createDate: moment(data.createDate).format("MMM DD, YYYY"),
    };
  };

  const getUserTxns = function (currentPage) {
    if (!visited.includes(currentPage)) {
      setLoading(true);
      getUserTransactionsList({
        customer_id,
        account_id,
        month,
        year,
        page_number: currentPage,
        page_size: 10,
      })
        .then((response) => {
          setVisited([...visited, currentPage]);
          setLoading(false);
          if (response.length > 0) {
            setRows([...rows,...response?.map((data) => dataGenerator(data))]);
            setPage(currentPage++);
          }
        })
        .catch((error) => {
          setLoading(false);
          throw error;
        });
      return;
    }
    setLoading(false);
    setPage(currentPage--);
  };

  useEffect(() => {
    getUserTxns(page);
  }, []);

  const handleChangePage = (e, page) => {
    getUserTxns(page);
  };

  return (
    <>
      <Box sx={{ ml: "4", pt: "14 !important", pb: "5", px: 3 }}>
        <div className={styles.container}>
          <Link to={`/app/user?identifier=${identifier}`}>
            <ArrowBackIcon
              fontSize="large"
              l
              color="secondary"
              sx={{ background: "#040721", p: 1, borderRadius: "50%" }}
            />
          </Link>
          <Typography component="h5" variant="5" className={styles.header}>
            Transaction Details
          </Typography>
          {loading && (
            <div className={styles.loader}>
              <i
                className={`fa fa-refresh ${loading === true ? "fa-spin" : ""}`}
                aria-hidden="true"
              ></i>
            </div>
          )}
        </div>
        <Box sx={{ background: "#ffffff", p: 2, mb: 4, pb: 4 }}>
          <div className="row">
            {rows && (
              <CustomTable
                data={filterList(rows, searchKey) || []}
                columns={columns}
                defaultPageChange={false}
                handleChange={(e, page) => handleChangePage(e, page)}
                defaultRowPerPage={10}
                rowCount={transaction_count}
                selectedPage={page}
              />
            )}
          </div>
        </Box>
      </Box>
    </>
  );
}

export default ShowTransactions;
