import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  editBankAddRule,
  getBankAddRuleById,
} from "../../../services/bankAddRule";
import { useForm } from "react-hook-form";
import { Box } from "@mui/system";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Swal from "sweetalert2";
import { Typography } from "@mui/material";

function EditBankAddRule(props) {
  const navigation = useNavigate();
  const { id } = useParams();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const updateBankAddRule = (data) => {
    data.cash_back_rules = JSON.parse(data.cash_back_rules);
    data.bank_priority_number_rule = JSON.parse(data.bank_priority_number_rule);

    editBankAddRule(id, data)
      .then((response) => {
        Swal.fire({
          title: "Success",
          text: "Bank rule has been saved successfully",
          icon: "success",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            navigation(-1);
          }
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Oops",
          text: "Something went wrong",
          icon: "warning",
          confirmButtonText: "Ok",
        }).then((result) => {});
        throw error;
      });
  };

  useEffect(() => {
    if (id) {
      getBankAddRuleById(id)
        .then((response) => {
          if (response) {
            reset({
              rule_version: response.rule_version,
              transactions_count: response.transactions_count,
              transaction_days: response.transaction_days,
              credit_amount_rule: response.credit_amount_rule,
              debit_amount_rule: response.debit_amount_rule,
              merchant_count_rule: response.merchant_count_rule,
              cash_back_rules: JSON.stringify(response.cash_back_rules),
              bank_priority_number_rule: JSON.stringify(
                response.bank_priority_number_rule
              ),
              user_acquired_channel_rule: response.user_acquired_channel_rule,
            });
          }
        })
        .catch((error) => {
          throw error;
        });
    }
  }, [id]);

  return (
    <>
      <Box sx={{ ml: "4", pt: "14 !important", pb: "5", px: 3 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "25px",
            marginTop: "20px",
            gap: "16px",
          }}
        >
          <Link to="/app/list-bank-add-rule">
            <ArrowBackIcon
              fontSize="large"
              color="secondary"
              sx={{ background: "#040721", p: 1, borderRadius: "50%" }}
            />
          </Link>
          <Typography
            variant="h5"
            component="h5"
            fontWeight={"bold"}
            color={"#3d3d3d"}
          >
            Edit Bank Add Rule Engine
          </Typography>
        </div>
        <Box sx={{ background: "#ffffff", p: 2, mb: 4, pb: 4 }}>
          <form onSubmit={handleSubmit(updateBankAddRule)} noValidate>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="rule_version"
                  >
                    Rule Version <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("rule_version", { required: true })}
                    className={`form-control ${
                      errors.rule_version && "invalid-form-input"
                    }`}
                    type="text"
                    id="rule_version"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    style={{ width: "fit-content" }}
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="transactions_count"
                  >
                    Transactions Count{" "}
                    <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("transactions_count", { required: true })}
                    className={`form-control ${
                      errors.transactions_count && "invalid-form-input"
                    }`}
                    type="text"
                    id="transactions_count"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    style={{ width: "fit-content" }}
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="transaction_days"
                  >
                    Transaction Days{" "}
                    <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("transaction_days", { required: true })}
                    className={`form-control ${
                      errors.transaction_days && "invalid-form-input"
                    }`}
                    type="text"
                    id="transaction_days"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    style={{ width: "fit-content" }}
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="credit_amount_rule"
                  >
                    Credit Amount Rule{" "}
                    <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("credit_amount_rule", { required: true })}
                    className={`form-control ${
                      errors.credit_amount_rule && "invalid-form-input"
                    }`}
                    type="text"
                    id="credit_amount_rule"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    style={{ width: "fit-content" }}
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="debit_amount_rule"
                  >
                    Debit Amount Rule{" "}
                    <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("debit_amount_rule", { required: true })}
                    className={`form-control ${
                      errors.debit_amount_rule && "invalid-form-input"
                    }`}
                    type="text"
                    id="debit_amount_rule"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    style={{ width: "fit-content" }}
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="merchant_count_rule"
                  >
                    Merchant Count Rule{" "}
                    <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("merchant_count_rule", { required: true })}
                    className={`form-control ${
                      errors.merchant_count_rule && "invalid-form-input"
                    }`}
                    type="text"
                    id="merchant_count_rule"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    style={{ width: "fit-content" }}
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="cash_back_rules"
                  >
                    Cashback Rules <small className="text-danger fs-6">*</small>
                  </label>
                  <textarea
                    rows="4"
                    {...register("cash_back_rules", { required: true })}
                    className={`form-control ${
                      errors.cash_back_rules && "invalid-form-input"
                    }`}
                    type="text"
                    id="cash_back_rules"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    style={{ width: "fit-content" }}
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="bank_priority_number_rule"
                  >
                    Bank Priority Number Rule{" "}
                    <small className="text-danger fs-6">*</small>
                  </label>
                  <textarea
                    rows="4"
                    {...register("bank_priority_number_rule", {
                      required: true,
                    })}
                    className={`form-control ${
                      errors.bank_priority_number_rule && "invalid-form-input"
                    }`}
                    type="text"
                    id="bank_priority_number_rule"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    style={{ width: "fit-content" }}
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="user_acquired_channel_rule"
                  >
                    User Acquired Channel Rule{" "}
                    <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("user_acquired_channel_rule", {
                      required: true,
                    })}
                    className={`form-control ${
                      errors.user_acquired_channel_rule && "invalid-form-input"
                    }`}
                    type="text"
                    id="user_acquired_channel_rule"
                  />
                </div>
              </div>
            </div>
            <hr style={{ height: "0.5px", marginTop: "32px" }} />
            <div>
              <button className="btn btn-primary py-2 px-5" name="save">
                Save
              </button>
            </div>
          </form>
        </Box>
      </Box>
    </>
  );
}

export default EditBankAddRule;
