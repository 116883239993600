import appConfig from "../config/app.config";
import axios from "axios";
import { getUserToken } from "../libs/user";
import { getPureJSON, startLoader, stopLoader } from "../libs/utils";

const getPendingBankList = () => {
  startLoader();
  return new Promise((resolve, reject) => {
    var config = {
      method: "get",
      url: appConfig.apiUrl.getPendingBankList,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader();
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader();
        reject(getPureJSON(error.response || error));
      });
  });
};
const getPendingBankLists = () => {
  return new Promise((resolve, reject) => {
    var config = {
      method: "get",
      url: appConfig.apiUrl.getPendingBankList,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(getPureJSON(error.response || error));
      });
  });
};

const updateBankRecord = (obj) => {
  startLoader();
  return new Promise((resolve, reject) => {
    const data = JSON.stringify({
      status: obj.status,
      approval_comment: obj.approval_comment,
      bankAddRequestId: obj.bankAddRequestId,
      currentUserId: obj.currentUserId,
    });

    const config = {
      method: "post",
      url: appConfig.apiUrl.updateBankRecord,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        stopLoader();
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader();
        reject(getPureJSON(error.response || error));
      });
  });
};

const getBankList = () => {
  startLoader();
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${appConfig.apiUrl.bank}/all`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader();
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader();
        reject(getPureJSON(error.response || error));
      });
  });
};

const getBankById = (id) => {
  startLoader(1);
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${appConfig.apiUrl.bank}/${id}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader(1);
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader(1);
        reject(getPureJSON(error.response || error));
      });
  });
};

const getBankStatusList = () => {
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${appConfig.apiUrl.bank}/status`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(getPureJSON(error.response || error));
      });
  });
};

const updateBank = (obj) => {
  return new Promise((resolve, reject) => {
    const data = JSON.stringify(obj);

    const config = {
      method: "put",
      url: `${appConfig.apiUrl.bank}/update`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(getPureJSON(error.response || error));
      });
  });
};

const processTransactions = (startDate, endDate, positiveNo) => {
  startLoader(1);
  return new Promise((resolve, reject) => {
    const config = {
      method: "put",
      url: `${appConfig.apiUrl.processTransaction}/${startDate}/${endDate}/${positiveNo}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader(1);
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader(1);
        reject(getPureJSON(error.response || error));
      });
  });
};

const getRemittanceReturnList = () => {
  startLoader();
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${appConfig.apiUrl.returnEligibleTxn}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader();
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader();
        reject(getPureJSON(error.response || error));
      });
  });
};

const returnTransaction = (
  transaction_ref_id,
  return_refId = "",
  ignore_return = false
) => {
  startLoader();
  return new Promise((resolve, reject) => {
    const config = {
      method: "post",
      url: `${appConfig.apiUrl.returnTxn}/${transaction_ref_id}?return_ref_id=${return_refId}&ignore_return=${ignore_return}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader();
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader();
        reject(getPureJSON(error.response || error));
      });
  });
};

export {
  getPendingBankList,
  updateBankRecord,
  getPendingBankLists,
  getBankList,
  getBankById,
  getBankStatusList,
  updateBank,
  processTransactions,
  getRemittanceReturnList,
  returnTransaction,
};
