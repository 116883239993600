import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Box, Modal, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Swal from "sweetalert2";
import { getActiveOffers } from "../../../services/cashback";
import {
  editPPO,
  fileDownload,
  fileUpload,
  getPPOById,
} from "../../../services/ppo";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  minHeight: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
};

function EditPartnerPromotionalOffers() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [response, setResponse] = useState({});
  const [activeGroups, setActiveGroups] = useState([]);
  const [fileType, setFileType] = useState("");
  const [uploadImage, setuploadImage] = useState();
  const [isUploading, setIsUploading] = useState(false);
  const [opens, setOpens] = useState(false);
  const [imageLink, setImageLink] = useState(null);

  const handleOpen = (e, r) => {
    setOpens(true);
  };

  const handleCloses = () => setOpens(false);

  const fileTypeArray = [
    { label: "Image URL", value: "M_WEB", key: "imageUrl" },
    { label: "Desktop Image URL", value: "D_WEB", key: "desktopImageUrl" },
  ];

  const handleFileSelect = (evt) => {
    var files = evt.target.files;

    if (files[0].size > 5e6) {
      Swal.fire({
        title: "Oops",
        text: "File size exceeded, Allowed maximum size is 5 MB",
        icon: "warning",
        confirmButtonText: "Ok",
      }).then((result) => {});
    }
    setuploadImage(files[0]);
  };

  const imageUpload = () => {
    let flag = true;

    if (fileType === "") {
      flag = false;
      Swal.fire({
        title: "Oops",
        text: "Please choose file type",
        icon: "warning",
        confirmButtonText: "Ok",
      }).then((result) => {});
    } else if (!uploadImage) {
      flag = false;
      Swal.fire({
        title: "Oops",
        text: "Please choose file",
        icon: "warning",
        confirmButtonText: "Ok",
      }).then((result) => {});
    } else if (uploadImage?.size > 5e6) {
      flag = false;
      Swal.fire({
        title: "Oops",
        text: "File size exceeded, Allowed maximum size is 5 MB",
        icon: "warning",
        confirmButtonText: "Ok",
      }).then((result) => {});
    }

    if (flag === true) {
      setIsUploading(true);
      fileUpload(fileType, uploadImage, id)
        .then((response) => {
          updateImages(id);
          setIsUploading(false);
          setFileType("");
          Swal.fire({
            title: "Success",
            text: `${fileType} uploaded successfully`,
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {});
        })
        .catch(function (error) {
          setIsUploading(false);
          Swal.fire({
            title: "Oops",
            text: "Something went wrong",
            icon: "error",
            confirmButtonText: "Ok",
          }).then((result) => {});
          throw error;
        });
    }
  };

  const updateImages = () => {
    getPPOById(id)
      .then((response) => {
        setResponse((prev) => ({
          ...prev,
          imageUrl: response.imageUrl,
          desktopImageUrl: response.desktopImageUrl,
        }));
        reset({
          imageUrl: response.imageUrl,
          desktopImageUrl: response.desktopImageUrl,
        });
      })
      .catch(function (error) {
        throw error;
      });
  };

  const getImage = (e, type) => {
    handleOpen(e);
    setImageLink(null);
    fileDownload(type, id)
      .then((response) => {
        setImageLink(response.content);
      })
      .catch(function (error) {
        setOpens(false);
        setImageLink(null);
        Swal.fire({
          title: "Oops",
          text: "This file cannot be displayed",
          icon: "warning",
          confirmButtonText: "Ok",
        }).then((result) => {});
        throw error;
      });
  };

  const {
    register,
    handleSubmit,

    reset,
    formState: { errors },
  } = useForm();

  const updatePPO = (data) => {
    const buttonType = window.event.submitter.name;
    if (buttonType === "save") {
      editPPO(id, data)
        .then((response) => {
          Swal.fire({
            title: "Success",
            text: `${data.offerName} has been saved successfully`,
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate(-1);
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "Oops",
            text: "Something went wrong",
            icon: "warning",
            confirmButtonText: "Ok",
          }).then((result) => {});
          throw error;
        });
    }

    if (buttonType === "savegoanother") {
      editPPO(id, data)
        .then((response) => {
          Swal.fire({
            title: "Success",
            text: `${data.offerName} has been saved successfully`,
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate(`/app/partner-promotional-offer/0`);
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "Oops",
            text: "Something went wrong",
            icon: "warning",
            confirmButtonText: "Ok",
          }).then((result) => {});
          throw error;
        });
    }
    if (buttonType === "savecontinue") {
      editPPO(id, data)
        .then((response) => {
          Swal.fire({
            title: "Success",
            text: `${data.offerName} has been saved successfully`,
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate(`/app/partner-promotional-offer/${response.id}`);
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "Oops",
            text: "Something went wrong",
            icon: "warning",
            confirmButtonText: "Ok",
          }).then((result) => {});
          throw error;
        });
    }
  };

  useEffect(() => {
    getActiveOffers()
      .then((list) => {
        setActiveGroups(list);

        reset({
          offerId: response.offerId,
        });
      })
      .catch(function (error) {
        throw error;
      });
  }, [response]);

  useEffect(() => {
    if (id) {
      getPPOById(id)
        .then((response) => {
          setResponse(response);
          if (response) {
            reset({
              title: response.title,
              subTitle: response.subTitle,
              offerName: response.offerName,
              partnerName: response.partnerName,
              clickUrl: response.clickUrl,
              imageUrl: response.imageUrl,
              desktopImageUrl: response.desktopImageUrl,
              priority: response.priority,
              active: response.active,
            });
          }
        })
        .catch((error) => {
          throw error;
        });
    }
  }, [id]);

  return (
    <>
      <Box sx={{ ml: "4", pt: "14 !important", pb: "5", px: 3 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "25px",
            marginTop: "20px",
            gap: "16px",
          }}
        >
          <Link to="/app/list-partner-promotional-offers">
            <ArrowBackIcon
              fontSize="large"
              color="secondary"
              sx={{ background: "#040721", p: 1, borderRadius: "50%" }}
            />
          </Link>
          <Typography
            variant="h5"
            component="h5"
            fontWeight={"bold"}
            color={"#3d3d3d"}
          >
            Edit Partner Promotional Offer
          </Typography>
        </div>
        <Box sx={{ background: "#ffffff", p: 2, mb: 4, pb: 4 }}>
          <form onSubmit={handleSubmit(updatePPO)} noValidate>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="title"
                  >
                    Title <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("title", { required: true })}
                    className={`form-control ${
                      errors.title && "invalid-form-input"
                    }`}
                    type="text"
                    id="title"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="subTitle"
                  >
                    Sub Title <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("subTitle", { required: true })}
                    className={`form-control ${
                      errors.subTitle && "invalid-form-input"
                    }`}
                    type="text"
                    id="subTitle"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="offerName"
                  >
                    Offer Name <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("offerName", { required: true })}
                    className={`form-control ${
                      errors.offerName && "invalid-form-input"
                    }`}
                    type="text"
                    id="offerName"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="offerId"
                  >
                    Offer Linked
                  </label>
                  <select
                    {...register("offerId")}
                    className={`form-select ${
                      errors.offerId && "invalid-form-input"
                    }`}
                    type="text"
                    id="offerId"
                  >
                    <option value="">Select</option>
                    {activeGroups.map((d, index) => (
                      <option key={index} value={d.id}>
                        {d.advertiserName} | {d.affiliatePartner}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="partnerName"
                  >
                    Partner Name <small className="text-danger fs-6">*</small>
                  </label>

                  <select
                    {...register("partnerName", { required: true })}
                    className={`form-select ${
                      errors.partnerName && "invalid-form-input"
                    }`}
                    type="text"
                    id="partnerName"
                  >
                    <option value="">Select</option>
                    <option value="TOI">TOI</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="clickUrl"
                  >
                    Click Url <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("clickUrl", { required: true })}
                    className={`form-control ${
                      errors.clickUrl && "invalid-form-input"
                    }`}
                    type="text"
                    id="clickUrl"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="priority"
                  >
                    Priority <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("priority", { required: true })}
                    className={`form-control ${
                      errors.priority && "invalid-form-input"
                    }`}
                    type="text"
                    id="priority"
                  />
                </div>
              </div>
              <div
                className="col-md-3 d-flex align-items-end mt-4"
                style={{ marginBottom: "12px" }}
              >
                <div className="form-groupform-check d-flex align-items-center gap-3 ">
                  <input
                    {...register("active")}
                    className={`form-check-input ${
                      errors.active && "invalid-form-input"
                    }`}
                    type="checkbox"
                    id="active"
                  />
                  <label
                    className="form-check-label fw-bold text-secondary"
                    htmlFor="active"
                    style={{ lineHeight: "1" }}
                  >
                    Is Active
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="file_type"
                  >
                    File Type
                  </label>
                  <select
                    className={`form-select`}
                    id="file_type"
                    value={fileType}
                    onChange={(e) => setFileType(e.target.value)}
                  >
                    <option value="">Select</option>
                    {fileTypeArray.map((data, index) => {
                      return (
                        <option key={index} value={data.value}>
                          {data.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-8">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="image"
                  >
                    File
                  </label>
                  <div className="input-group mb-3">
                    <input
                      onChange={(event) => handleFileSelect(event)}
                      type="file"
                      className="form-control"
                      id="image"
                      accept="image/*"
                    />
                    {isUploading === true && (
                      <button
                        style={{ width: "150px" }}
                        className="btn btn-primary btn-block"
                        type="button"
                      >
                        Uploading{" "}
                        <i
                          className={`fa fa-refresh ${true ? "fa-spin" : ""}`}
                          aria-hidden="true"
                        ></i>
                      </button>
                    )}
                    {isUploading === false && (
                      <button
                        style={{ width: "150px" }}
                        className="btn btn-primary btn-block"
                        type="button"
                        onClick={() => imageUpload()}
                      >
                        Upload
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <input type="hidden" {...register("imageUrl")} />
              <input type="hidden" {...register("desktopImageUrl")} />

              {fileTypeArray.map((data, index) => {
                return (
                  response &&
                  response[data.key] && (
                    <div className="col-md-12 mb-4" key={index}>
                      <div
                        className="d-flex rounded-3 justify-content-between"
                        style={{ background: "#eee" }}
                      >
                        <div
                          className="text-truncate text-secondary p-2 bg-primary text-white rounded-3 text-center"
                          style={{ width: "250px" }}
                        >
                          {data.label}
                        </div>
                        <div className="d-flex justify-content-between">
                          <div
                            className="text-truncate text-secondary p-2"
                            style={{ cursor: "pointer" }}
                            title="Click to Copy"
                          >
                            {response && response[data.key]}
                          </div>
                          <button
                            type="button"
                            onClick={(e) => getImage(e, data.value)}
                            className="btn download-btn"
                            style={{ display: "inline-flex" }}
                          >
                            <CloudDownloadIcon />
                          </button>
                        </div>
                      </div>
                    </div>
                  )
                );
              })}
            </div>

            <hr style={{ height: "0.5px", marginTop: "32px" }} />
            <div>
              <button className="btn btn-primary py-2 px-5" name="save">
                Save
              </button>
              <button
                className="btn btn-warning py-2 px-5"
                name="savegoanother"
              >
                Save And Add Another
              </button>
              <button className="btn btn-success py-2 px-5" name="savecontinue">
                Save and Continue Editing
              </button>
            </div>
          </form>
        </Box>
      </Box>

      <Modal
        open={opens}
        onClose={handleCloses}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {imageLink === null && (
            <>
              <i
                className={`mt-4 fa fa-circle-o-notch fa-2x ${
                  true ? "fa-spin" : ""
                }`}
                aria-hidden="true"
              ></i>
            </>
          )}
          {imageLink !== null && (
            <img
              style={{ maxWidth: "100%", maxHeight: "80vh" }}
              src={`data:image/png;base64, ${imageLink}`}
            />
          )}
        </Box>
      </Modal>
    </>
  );
}

export default EditPartnerPromotionalOffers;
