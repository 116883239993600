import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUser, removeUserToken } from "../../libs/user";
import { userLogin as login } from "../../services/auth";
import { updateRiskProfileById } from "../../services/user";

export const userLogin = createAsyncThunk(
  "auth/userLogin",
  async (obj, { rejectWithValue }) => {
    try {
      const { username, password, code } = obj;
      const response = await login(username, password, code);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAuth = createAsyncThunk(
  "auth/getAuth",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await getUser();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateUserRiskProfile = createAsyncThunk(
  "reward/getAppConfigsDataById",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await updateRiskProfileById(obj);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  userLogin: {
    data: null,
    error: null,
    status: null,
  },
  user: {
    data: null,
    error: null,
    status: null,
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    extraReducers: (builder) => {
      builder

        .addCase(userLogin.pending, (state, action) => {
          state.userLogin = { ...state.userLogin, status: "loading" };
        })
        .addCase(userLogin.fulfilled, (state, action) => {
          state.userLogin = {
            ...state.userLogin,
            status: "succeeded",
            data: action.payload,
            error: null,
          };
        })
        .addCase(userLogin.rejected, (state, action) => {
          state.userLogin = {
            ...state.userLogin,
            status: "failed",
            error: action.payload.error,
            data: null,
          };
        })
        .addCase(getAuth.pending, (state, action) => {
          state.user = { ...state.user, status: "loading" };
        })
        .addCase(getAuth.fulfilled, (state, action) => {
          state.user = {
            ...state.user,
            status: "succeeded",
            data: action.payload,
            error: null,
          };
        })
        .addCase(getAuth.rejected, (state, action) => {
          state.user = {
            ...state.user,
            status: "failed",
            error: action.payload,
            data: null,
          };
          removeUserToken();
        });
    },
  },
});

export const { otpValidateReset, resetPasswordReset } = authSlice.actions;

export default authSlice.reducer;
