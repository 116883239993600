import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { editMerchant, getMerchantById } from "../../../services/merchants";
import { Box, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Swal from "sweetalert2";
import { getOffersStoresList } from "../../../services/stores";

function EditMerchant() {
  const navigation = useNavigate();
  const { id } = useParams();
  const [linkedGroupsList, setLinkedGroupsList] = useState([]);
  const [linkedGroups, setLinkedGroups] = useState("");
  const [getAllOffergroups, setgetAllOffergroups] = useState([]);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [mount, setMount] = useState(false);
  const [storeslinked, getstoreslinked] = useState([]);
  const updateMerchant = (data) => {
    const linked_store_ids = linkedGroupsList.map((d) => {
      return d.id;
    });
    data.linked_store_ids = "[" + linked_store_ids.toString() + "]";

    data.stores = null;

    editMerchant(id, data)
      .then((response) => {
        Swal.fire({
          title: "Success",
          text: "Merchant has been saved successfully",
          icon: "success",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            navigation(-1);
          }
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Oops",
          text: "Something went wrong",
          icon: "warning",
          confirmButtonText: "Ok",
        }).then((result) => {});
        throw error;
      });
  };

  useEffect(() => {
    getOffersStoresList()
      .then((response) => {
        setgetAllOffergroups(response);
      })
      .catch(function (error) {
        throw error;
      });
  }, []);
  useEffect(() => {
    if (id) {
      getMerchantById(id)
        .then((response) => {
          if (response) {
            reset({
              merchant_name: response[0].merchant_name,
              merchant_id: response[0].merchant_id,
              event_name: response[0].event_name,

              stores: JSON.stringify(response[0].stores, null, 4),
            });
            getstoreslinked(response[0]?.linked_store_ids);
          }
        })
        .catch((error) => {
          throw error;
        });
    }
  }, [id]);

  useEffect(() => {
    setMount(true);
    if (storeslinked.length > 0 && mount === true) {
      const lg = storeslinked.replace("[", "").replace("]", "").split(",");

      if (lg !== "") {
        lg.map((d) => {
          setLinkedGroupsArray(String(d).trim());
        });
      }
    }
  }, [mount, storeslinked, getAllOffergroups]);

  const setLinkedGroupsArray = (key) => {
    setLinkedGroupsList((prev) => {
      const map = new Map();
      prev.forEach((d, i) => {
        if (d) {
          map.set(d.id, d);
        }
      });
      const findOne = getAllOffergroups.filter((d) => d.id === key)[0];

      if (findOne) {
        map.set(key, getAllOffergroups.filter((d) => d.id === key)[0]);
      }

      const arr = [];
      for (const [key, value] of map) {
        arr.push(value);
      }
      return arr;
    });
  };

  const removeLinkedGroups = (index) => {
    setLinkedGroupsList(
      linkedGroupsList.filter((d, i) => {
        setLinkedGroups("");
        return i !== index;
      })
    );
  };
  return (
    <>
      <Box sx={{ ml: "4", pt: "14 !important", pb: "5", px: 3 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "25px",
            marginTop: "20px",
            gap: "16px",
          }}
        >
          <Link to="/app/list-merchants">
            <ArrowBackIcon
              fontSize="large"
              color="secondary"
              sx={{ background: "#040721", p: 1, borderRadius: "50%" }}
            />
          </Link>
          <Typography
            variant="h5"
            component="h5"
            fontWeight={"bold"}
            color={"#3d3d3d"}
          >
            Edit Merchant
          </Typography>
        </div>
        <Box sx={{ background: "#ffffff", p: 2, mb: 4, pb: 4 }}>
          <form onSubmit={handleSubmit(updateMerchant)} noValidate>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="merchant_name"
                  >
                    Merchant Name <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("merchant_name", { required: true })}
                    className={`form-control ${
                      errors.merchant_name && "invalid-form-input"
                    }`}
                    type="text"
                    id="merchant_name"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="merchant_id"
                  >
                    Merchant ID <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("merchant_id", { required: true })}
                    className={`form-control ${
                      errors.merchant_id && "invalid-form-input"
                    }`}
                    type="text"
                    id="merchant_id"
                    readOnly
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="event_name"
                  >
                    Event Name
                  </label>
                  <input
                    {...register("event_name")}
                    className={`form-control ${
                      errors.event_name && "invalid-form-input"
                    }`}
                    type="text"
                    id="event_name"
                  />
                </div>
              </div>
              {/* <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="linked_stores_id"
                  >
                    Linked Store IDs:{" "}
                    <small className="text-danger fs-6">*</small>
                  </label>
                  <textarea
                    rows={10}
                    {...register("linked_store_ids", { required: true })}
                    className={`form-control ${
                      errors.linked_store_ids && "invalid-form-input"
                    }`}
                    type="text"
                    id="linked_stores_id"
                  />
                </div>
              </div> */}
              <div className="col-md-12">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="stores"
                  >
                    Stores
                  </label>
                  <textarea
                    rows={10}
                    {...register("stores")}
                    className={`form-control ${
                      errors.stores && "invalid-form-input"
                    }`}
                    type="text"
                    id="stores"
                    readOnly
                  />
                </div>
              </div>
              {/* // */}

              <div className="row mt-4 mb-2">
                <div className="col-md-12">
                  <label
                    className="form-label fw-bold text-secondary user-select-none mb-0"
                    htmlFor="linked_groups"
                    style={{ lineHeight: "1" }}
                  >
                    Linked Store IDs:
                  </label>
                </div>
                {linkedGroupsList.length > 0 && (
                  <div
                    className="col-auto mb-2 "
                    style={{
                      maxHeight: "259px",
                      overflowY: "auto",
                      scrollBehavior: "smooth",
                      border: "solid 1px #ccc",
                      marginLeft: "12px",
                      paddingTop: "9px",
                      paddingBottom: "9px",
                      borderRadius: "4px",
                    }}
                  >
                    <div className="d-flex gap-2 flex-wrap">
                      {linkedGroupsList.map((value, index) => {
                        return (
                          <span
                            key={index}
                            className="badge py-2 px-3 d-flex align-items-center gap-2"
                            style={{
                              background: "#040721",
                              color: "#f8d269",
                              fontSize: "13px",
                            }}
                          >
                            {`${value.id} | ${value.name}  `}
                            <span
                              className="badge bg-danger py-2 px-2"
                              style={{ cursor: "pointer" }}
                              onClick={() => removeLinkedGroups(index)}
                            >
                              &#x2715;
                            </span>
                          </span>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <select
                    className={`form-select`}
                    id="linked_groups"
                    value={linkedGroups}
                    onChange={(e) => setLinkedGroupsArray(e.target.value)}
                  >
                    <option value="">Select Store</option>
                    {getAllOffergroups.map((store, index) => {
                      return (
                        <option
                          key={index}
                          value={store.id}
                        >{`${store.id} | ${store.name}`}</option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>

            <hr style={{ height: "0.5px", marginTop: "32px" }} />
            <div>
              <button className="btn btn-primary py-2 px-5" name="save">
                Save
              </button>
            </div>
          </form>
        </Box>
      </Box>
    </>
  );
}

export default EditMerchant;
