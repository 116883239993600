import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link, useParams, useSearchParams } from "react-router-dom";

import { TextField } from "@mui/material";
import { filterList } from "../../../libs/utils";
import DupCustomTable from "../../../components/DupCustomTable";
import { getmerchantMappingList } from "../../../services/merchant-mapping";
import { useForm } from "react-hook-form";
import CircularProgress from "@mui/material/CircularProgress";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate, useLocation } from "react-router-dom";

function ListTransactionMerchantMapping() {
  const navigate = useNavigate();
  const location = useLocation();
  const [rows, setRows] = useState();
  const [loading, setLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const {
    reset,
    formState: { errors },
  } = useForm();
  const [isLoading, setisLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [searchParams] = useSearchParams();
  const transactionStatus = searchParams.get("transactionStatus");
  const startTime = searchParams.get("startTime");
  const endTime = searchParams.get("endTime");
  const params = useParams();

  // const convertTime = (timestamp) => {
  //   return timestamp ? moment.unix(timestamp / 1000).format() : null;
  // };

  const [filter, setFilter] = useState({
    offset: page,
    size: rowsPerPage,
    startTime: "",
    endTime: "",
    transactionStatus: "MANUALLY",
  });

  const columns = [
    {
      id: "transactionName",
      label: "Transaction Name",
      minWidth: 250,
      align: "left",
    },
    {
      id: "transactionMerchantName",
      label: "Transaction Merchant Name",
      maxWidth: 60,
      align: "center",
    },

    {
      id: "ratioScore",
      label: "Ratio Score",
      maxWidth: 50,
      align: "center",
    },
    {
      id: "transactionStatus",
      label: "Transaction Status",
      maxWidth: 50,
      align: "center",
    },
    {
      id: "insertEpochMillis",
      label: "Insert Epoch Millis",
      maxWidth: 50,
      align: "center",
    },
    {
      id: "mappedmerchantName",
      label: "Mapped Merchant Name",
      maxWidth: 50,
      align: "center",
    },
    {
      id: "partialratioScore",
      label: "Partial Ratio Score",
      maxWidth: 45,
      align: "center",
    },
    {
      id: "MerchantId",
      label: "Merchant Id",
      maxWidth: 50,
      align: "center",
    },
  ];

  const pageHandler = () => {
    console.log("called");
  };

  const dataGenerator = (data) => {
    return {
      transactionName: (
        <Link to={`/app/transaction-merchant-mapping-audit/${data.id}`}>
          <b style={{ color: "#000" }}>{data.transactionName}</b>
        </Link>
      ),
      transactionMerchantName: data.transactionMerchantName,
      mappedMerchantName: data.mappedMerchantName,
      partialRatioScore: data.partialRatioScore,
      ratioScore: data.ratioScore,
      transactionStatus: data.transactionStatus,
      merchantId: data.merchantId,
      insertEpochMillis: data.insertEpochMillis,
    };
  };

  const getTxnList = (params) => {
    getmerchantMappingList(params)
      .then((response) => {
        setLoading(false);
        if (response?.length > 0 && typeof response === "object") {
          setRows(response.map((data) => dataGenerator(data)));
        } else {
          setRows([]);
        }
      })
      .catch((error) => {
        setRows([]);
        setLoading(false);
        throw error;
      });
  };

  useEffect(() => {
    setLoading(true);

    getTxnList({
      offset: page,
      size: rowsPerPage,
      startTime: startTime,
      endTime: endTime,
      transactionStatus: transactionStatus,
    });
    reset({
      status: transactionStatus || "MANUALLY",
      startTime: startTime || "",
      endTime: endTime || "",
    });
  }, []);

  useEffect(() => {
    setFilter({
      ...filter,
      offset: params.page,
      size: params.pagesize,
    });

    getTxnList({
      offset: params.page,
      size: params.pagesize,
      startTime: filter.startTime,
      endTime: filter.endTime,
      transactionStatus: filter.transactionStatus,
    });
  }, [location]);

  useEffect(() => {
    // ${
    //   transactionStatus !== null
    //     ? "transactionStatus=" + transactionStatus
    //     : "transactionStatus=MANUALLY"
    // }
    navigate(
      `/app/list-transaction-merchant-mapping-audit/0/50?${
        startTime !== null ? "&startTime=" + startTime : ""
      }${endTime !== null ? "&endTime=" + endTime : ""}`
    );
  }, []);

  const searchData = (e) => {
    setSearchKey(e.target.value);
  };

  return (
    <>
      <Box sx={{ px: 6 }} className="dynamicTableStyle">
        {isLoading === true && (
          <Box
            sx={{
              display: "flex",
              position: "absolute",
              top: "20% !important",
              width: "40%",
              left: "50%",
            }}
          >
            <CircularProgress fontSize="large" />
          </Box>
        )}
        <div
          className="bankAddRuleHeader mb-3"
          style={{ paddingRight: "48px" }}
        >
          <div
            style={{
              display: "flex",
              padding: "1.5rem 0",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: "#33343a" }} variant="h4" component="h4">
              Transactions Merchant Mapping
            </Typography>
            {loading && (
              <div
                style={{
                  marginLeft: "12px",
                  color: "#a0a0a0",
                  fontSize: "24px",
                }}
              >
                <i
                  className={`fa fa-refresh ${
                    loading === true ? "fa-spin" : ""
                  }`}
                  aria-hidden="true"
                ></i>
              </div>
            )}
          </div>
          <div style={{ display: "flex", alignItems: "end" }}>
            <TextField
              id="standard-basic"
              label="Search"
              variant="standard"
              sx={{ mr: 1 }}
              onKeyUp={(e) => searchData(e)}
              InputProps={{
                endAdornment: <SearchIcon sx={{ color: "#8a8a8a" }} />,
              }}
            />
          </div>
        </div>

        {/* <Box sx={{ marginBottom: "40px" }} style={{ paddingRight: "48px" }}>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChanges("panel1")}
            sx={{
              boxShadow: "none !important",
              background: "white",
              border: "2px solid #f1f1f1",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Filter Transactions</Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <form onSubmit={handleSubmit(updateFilter)} noValidate>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label>By Status</label>
                  <select
                    className="form-select"
                    {...register("status")}
                    style={{
                      width: "200px",
                      marginLeft: "-50px",
                      marginTop: "10px",
                      border: "none",
                      borderBottom: "inset 1px #949494",
                      borderRadius: 0,
                      height: "39px",
                    }}
                  >
                    <option value="MANUALLY">MANUALLY</option>
                    <option value="REJECTED">REJECTED</option>
                    <option value="ACCEPTED">ACCEPTED</option>
                  </select>
                  <Controller
                    name="startTime"
                    defaultValue={startDate}
                    control={control}
                    render={({ field: { onChange, ...restField } }) => (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                          label="Start Date And Time"
                          onChange={(event) => {
                            onChange(event);
                            setStartDate(event);
                          }}
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              sx={{ mt: 2, mr: 2 }}
                              {...params}
                              error={false}
                            />
                          )}
                          {...restField}
                        />
                      </LocalizationProvider>
                    )}
                  />
                  <Controller
                    name="endTime"
                    defaultValue={endDate}
                    control={control}
                    render={({ field: { onChange, ...restField } }) => (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                          label="End Date And Time"
                          onChange={(event) => {
                            onChange(event);
                            setEndDate(event);
                          }}
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              sx={{ mt: 2, mr: 2 }}
                              {...params}
                              error={false}
                            />
                          )}
                          {...restField}
                        />
                      </LocalizationProvider>
                    )}
                  />
                 

                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ padding: "0 48px", height: "56px", mr: 2, mt: 2 }}
                  >
                    Submit
                  </Button>

                  <Button
                    type="button"
                    variant="contained"
                    sx={{ padding: "0 48px", height: "56px", mr: 2, mt: 2 }}
                    onClick={clearFilter}
                    color="warning"
                  >
                    Clear
                  </Button>
                </div>
              </form>
            </AccordionDetails>
          </Accordion>
        </Box> */}

        <DupCustomTable
          data={filterList(rows, searchKey) || []}
          columns={columns}
          pageHandler={pageHandler}
        />
      </Box>
    </>
  );
}

export default ListTransactionMerchantMapping;
