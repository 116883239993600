import appConfig from "../config/app.config";
import axios from "axios";
import { getUserToken } from "../libs/user";
import {
  startLoader,
  stopLoader,
} from "../libs/utils";

const getUploadedImagesList= () => {
    startLoader();
    return new Promise((resolve, reject) => {
      var config = {
        method: "get",
        url: `${appConfig.apiUrl.getUploadedImages}`,
        headers: {
          Authorization: `Bearer ${getUserToken()}`,
        },
      };
  
      axios(config)
        .then(function (response) {
          stopLoader();
          resolve(response.data);
        })
        .catch(function (error) {
          stopLoader();
          reject(error);
        });
    });
  };
  

  export {getUploadedImagesList};