import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  return (
    <Box>
      <div>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
      </div>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function DupCustomTable({ data, columns, pageHandler = () => {} }) {
  const navigation = useNavigate();
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  useEffect(() => {
    let rowList = [];
    let dataList = data;
    if (!dataList) {
      dataList = [];
    }
    if (typeof dataList !== "object") {
      dataList = [];
    }
    dataList.forEach((data) => {
      let rowData = {};
      columns.forEach((column) => {
        rowData[column.id] = data[column.id];
      });
      rowList.push(rowData);
    });
    setRows(rowList);
  }, [data, columns]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    navigation(
      `/app/list-transaction-merchant-mapping-audit/${newPage}/${rowsPerPage}`
    );
  };

  const handleChangeRowsPerPage = (event) => {
    const vals = parseInt(event.target.value, 10);
    setRowsPerPage(vals);
    setPage(0);
    navigation(`/app/list-transaction-merchant-mapping-audit/0/${vals}`);
  };

  return (
    <>
      <TableContainer>
        <Table
          stickyHeader
          aria-label="sticky table"
          style={{ border: "1px solid #cccccc", borderBottom: "none" }}
        >
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "#2e2e2e",
                    whiteSpace: "nowrap",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {rows.length > 0 &&
                rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{
                                fontSize: "16px",
                                whiteSpace: "normal",
                                wordWrap: "break-word",
                              }}
                            >
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              {rows.length <= 0 && (
                <TableRow hover role="checkbox" tabIndex={-1}>
                  <TableCell
                    align="center"
                    style={{
                      fontSize: "16px",
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                      color: "#5e5e5e",
                    }}
                    colSpan={columns.length}
                  >
                    No Data Found
                  </TableCell>
                </TableRow>
              )}
            </>
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 50, 500, 1000]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        colSpan={3}
        SelectProps={{
          inputProps: {
            "aria-label": "rows per page",
          },
          native: true,
        }}
        ActionsComponent={TablePaginationActions}
        sx={{ marginLeft: 100 }}
      />
    </>
  );
}

export default DupCustomTable;
