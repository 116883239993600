import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { TextField } from "@mui/material";
function ListRewardsFaq() {
  const columns = [
    { id: "Question", label: "Question", minWidth: 100 },
    {
      id: "Answer",
      label: "Answer",
      minWidth: 170,
      align: "right",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "Rewards",
      label: "Rewards",
      minWidth: 170,
      align: "right",
      format: (value) => value.toFixed(2),
    },
  ];

  function createData(Question, Answer, Rewards) {
    return { Question, Answer, Rewards };
  }

  const rows = [
    createData("India", "IN", 1324171354),
    createData("China", "CN", 1403500365),
    createData("Italy", "IT", 60483973),
    createData("United States", "US", 327167434),
  ];

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <>
      <Box sx={{ mt: 2 }}>
        <Typography
          component="h4"
          variant="h4"
          color="inherit"
          noWrap
          sx={{ ml: 3, fontSize: "22px", fontWeight: "700" }}
        >
          Rewards FAQ{" "}
        </Typography>
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between", // keep right padding when drawer closed
          }}
        >
          <TextField
            margin="normal"
            id="filter"
            label="Filter"
            name="filter"
            placeholder="ex walmart"
            autoFocus
            variant="standard"
          />
        </Toolbar>
      </Box>

      {/* ll */}

      <Paper
        sx={{
          width: "98%",
          overflow: "hidden",
          mx: 3,
          boxShadow:
            "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
        }}
      >
        <TableContainer
          sx={{
            maxHeight: 440,
          }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[20, 50, 75, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      {/* ll */}
    </>
  );
}

export default ListRewardsFaq;
