import React, { useEffect, useLayoutEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import { Fade } from "@mui/material";
import $ from "jquery";
import moment from "moment";
import EnhancedTableHead from "./EnhancedTableHead";

function CustomTable({
  data,
  columns,
  setDynamicTable,
  defaultRowPerPage = 10,
  defaultSortOrder = "asc",
  showTooltip = true,
  defaultPageChange = true,
  handleChange,
  rowCount,
  selectedPage = 0,
  isPaginationRequired = true,
}) {
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(selectedPage);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowPerPage);
  const [isFade, setIsFade] = useState(false);
  const [isReady, setIsReady] = useState(false);

  const [order, setOrder] = React.useState(defaultSortOrder);
  const [orderBy, setOrderBy] = React.useState("");

  useEffect(() => {
    let rowList = [];
    let dataList = data;
    if (!dataList) {
      dataList = [];
    }
    if (typeof dataList !== "object") {
      dataList = [];
    }
    dataList.forEach((d) => {
      let rowData = {};
      columns.forEach((column) => {
        rowData[column.id] = d[column.id];
      });
      rowList.push(rowData);
    });
    setRows(rowList);
    const timer2 = setTimeout(() => {
      setIsFade(true);
    }, 150);

    return () => {
      clearTimeout(timer2);
    };
  }, [data]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [rowsPerPage]);

  useLayoutEffect(() => {
    const t4 = setTimeout(() => {
      setIsReady(true);
    }, 1500);

    const t1 = setTimeout(() => {
      const tableParentWidth = $(".main-box").width();
      const tableWidth = $(".MuiTable-root").width();
      if (tableParentWidth < tableWidth) {
        if (setDynamicTable) {
          setDynamicTable(tableParentWidth, tableWidth);
        }
      }
    }, 500);
    return () => {
      clearTimeout(t1);
    };
  }, [rows]);

  const handleChangePage = (event, newPage) => {
    if (!defaultPageChange) {
      handleChange(event, selectedPage);
      return;
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const isValidDate = (d1, d2) => {
    if (!d1 && !d2) {
      return false;
    }
    const momentDate1 = moment(d1);
    const momentDate2 = moment(d2);
    return momentDate1.isValid() && momentDate2.isValid();
  };

  function descendingComparator(a, b, orderBy) {
    const isDate = isValidDate(a[orderBy], b[orderBy]);
    if (isDate) {
      const momentDate1 = moment(a[orderBy]).valueOf();
      const momentDate2 = moment(b[orderBy]).valueOf();
      return momentDate2 - momentDate1;
    }
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
    // return b[orderBy] - a[orderBy];
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const renderTableCell = (value, column) => {
    if (column.format && typeof value === "number") {
      return column.format(value);
    }
    if (value && value.length > 40 && showTooltip) {
      return (
        <Tooltip title={value} arrow>
          <span>{`${String(value).substring(0, 40)}...`}</span>
        </Tooltip>
      );
    }
    return value;
  };
  useEffect(() => {
    setPage(selectedPage);
  }, [selectedPage]);

  return (
    <>
      {rows.length > 0 && (
        <>
          <TableContainer sx={{ border: "1px solid #cccccc" }}>
            <Table
              stickyHeader
              aria-label="sticky table"
              style={{ borderBottom: "none" }}
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                columns={columns}
              />
              <Fade in={isFade}>
                <TableBody>
                  <>
                    {rows.length > 0 &&
                      stableSort(
                        rows.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        ),
                        getComparator(order, orderBy)
                      ).map((row, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={index}
                          >
                            {columns.map((column) => {
                              const value = row[column.id];
                              return (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  style={{
                                    fontSize: "16px",
                                    whiteSpace: "normal",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  {renderTableCell(value, column)}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                    {rows.length <= 0 && (
                      <TableRow hover role="checkbox" tabIndex={-1}>
                        <TableCell
                          align="center"
                          style={{
                            fontSize: "16px",
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                            color: "#5e5e5e",
                          }}
                          colSpan={columns.length}
                        >
                          No Data Found
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                </TableBody>
              </Fade>
            </Table>
          </TableContainer>
          {isPaginationRequired && (
            <TablePagination
              rowsPerPageOptions={[10, 50, 500, 1000]}
              component="div"
              count={rowCount ? rowCount : rows.length}
              rowsPerPage={rowsPerPage}
              page={selectedPage ? selectedPage : page}
              onPageChange={(e,newPage) => handleChangePage(e,newPage)}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </>
      )}
      {rows.length <= 0 && isReady === true && (
        <>
          <TableContainer
            sx={{
              border: "1px solid #cccccc",
              overflow: isReady ? "auto" : "hidden",
            }}
          >
            <Table
              stickyHeader
              aria-label="sticky table"
              style={{ borderBottom: "none" }}
            >
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        fontSize: "18px",
                        fontWeight: "bold",
                        color: "#2e2e2e",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <Fade in={isFade}>
                <TableBody>
                  <>
                    {rows.length <= 0 && (
                      <TableRow hover role="checkbox" tabIndex={-1}>
                        <TableCell
                          align="center"
                          style={{
                            fontSize: "16px",
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                            color: "#5e5e5e",
                          }}
                          colSpan={columns.length}
                        >
                          No Data Found
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                </TableBody>
              </Fade>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
}

CustomTable.defaultProps = {
  setDynamicTable: null,
};

export default CustomTable;
