import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  editcmsUsers,
  getCMSusersById,
  deletecmsUsers,
} from "../../services/user";
import Swal from "sweetalert2";
import { ROLES } from "../../constants";

function EditCmsUser(props) {
  const { register, handleSubmit, reset } = useForm();
  const { id } = useParams();
  const navigate = useNavigate();
  const [CMSusers, setCMSusers] = useState([]);

  const getSelectedRoles = (data) => {
    const selectedRoles = [];
    for (let role of ROLES) {
      if (data[role]) {
        selectedRoles.push(role);
      }
    }
    return selectedRoles;
  };

  useEffect(() => {
    getCMSusersById(id)
      .then((response) => {
        setCMSusers(response);
        const roles = response?.roles.reduce(
          (acc, ci) => ({ ...acc, [ci]: true }),
          {}
        );
        reset({
          username: response.username,
          email: response.email,
          is_active: response.is_active,
          id: response.id,
          ...roles,
        });
      })
      .catch((error) => {
        throw error;
      });
  }, []);
  const updateCMSusers = (data) => {
    const selectedRoles = getSelectedRoles(data);
    if (!selectedRoles.length) {
      Swal.fire({
        title: "Oops",
        text: "Please select at least one role to update CMS user.",
        icon: "warning",
        confirmButtonText: "Ok",
      });
      return;
    }

    editcmsUsers({
      id: data.id,
      roles: selectedRoles,
      is_active: data.is_active,
    })
      .then((response) => {
        Swal.fire({
          title: "Success",
          text: "CMS user has been updated successfully",
          icon: "success",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/app/list-cms-users");
          }
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Oops",
          text: error?.data?.error?.message || "Something went wrong",
          icon: "warning",
          confirmButtonText: "Ok",
        }).then((result) => {});
        throw error;
      });
  };

  const deleteCmsUser = () => {
    deletecmsUsers({
      id: id,
    })
      .then((response) => {
        Swal.fire({
          title: "Success",
          text: "CMS user has been deleted successfully",
          icon: "success",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/app/list-cms-users");
          }
        });
      })
      .catch((error) => {
        throw error;
      });
  };

  return (
    <Box sx={{ ml: "4", pt: "14 !important", pb: "5", px: 3 }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "25px",
          marginTop: "20px",
          gap: "16px",
        }}
      >
        <Link to="/app/list-cms-users">
          <ArrowBackIcon
            fontSize="large"
            color="secondary"
            sx={{ background: "#040721", p: 1, borderRadius: "50%" }}
          />
        </Link>
        <Typography
          variant="h5"
          component="h5"
          fontWeight={"bold"}
          color={"#3d3d3d"}
        >
          Edit CMS User
        </Typography>
      </div>
      <Box sx={{ background: "#ffffff", p: 2, mb: 4, pb: 4 }}>
        <form onSubmit={handleSubmit(updateCMSusers)}>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none"
                  htmlFor="username"
                >
                  Username: <small className="text-danger fs-6">*</small>
                </label>
                <input
                  type="hidden"
                  {...register("id")}
                  defaultValue={CMSusers.id}
                />
                <input
                  {...register("username", { required: true })}
                  className={`form-control `}
                  type="text"
                  id="username"
                  readOnly
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none"
                  htmlFor="email"
                >
                  Email: <small className="text-danger fs-6">*</small>
                </label>
                <input
                  {...register("email")}
                  className={`form-control`}
                  type="text"
                  id="email"
                  readOnly
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div
              className="col-md-7 d-flex align-items-end"
              style={{ marginTop: "22px" }}
            >
              <div className="form-group form-check d-flex align-items-center  ">
                <label
                  className="form-check-label fw-bold text-secondary"
                  htmlFor="auto_approval"
                  style={{ lineHeight: "1", marginLeft: "-25px" }}
                >
                  is_active
                </label>
                <input
                  {...register("is_active")}
                  // onChange={handleChangeAutoApproval}
                  className="form-check-input"
                  type="checkbox"
                  id="auto_approval"
                  defaultChecked={CMSusers?.is_active}
                  style={{ marginLeft: "35px" }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none"
                  htmlFor="roles"
                >
                  Roles: <small className="text-danger fs-6">*</small>
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            {ROLES.map((role) => (
              <div className="col-md-4 mt-3">
                <div className="row">
                  <div className="col-md-6 d-flex align-items-center">
                    <label
                      className="form-check-label fw-bold text-secondary"
                      htmlFor="roles"
                      style={{ lineHeight: "1" }}
                    >
                      {role}
                    </label>
                  </div>
                  <div className="col-md-6">
                    <input
                      {...register(`${role}`)}
                      className="form-check-input"
                      type="checkbox"
                      id={role}
                      style={{ marginLeft: "35px" }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <hr style={{ height: "0.5px", marginTop: "32px" }} />
          <div style={{ display: "flex" }}>
            <button
              type="submit"
              className="btn btn-primary py-2 px-5"
              name="save"
            >
              Edit
            </button>

            <button
              style={{ marginLeft: "10%" }}
              onClick={deleteCmsUser}
              type="button"
              className="btn btn-primary py-2 px-5"
              name="delete"
            >
              Delete
            </button>
          </div>
        </form>
      </Box>
    </Box>
  );
}

export default EditCmsUser;
