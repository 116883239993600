import React, { useState, useEffect, useLayoutEffect, Fragment } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import localforage from "localforage";
import { TextField, Button } from "@mui/material";
import { filterList, startLoader } from "../../../libs/utils";
import { getPlaidBanksList } from "../../../services/plaidBanks";
import CustomTable from "../../../components/CustomTable";
import SearchIcon from "@mui/icons-material/Search";

function PlaidBanksList() {
  const [rows, setRows] = useState();
  const [loading, setLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [isDynamicTable, setIsDynamicTable] = useState(false);

  const columns = [
    {
      id: "accountDetails",
      label: "Account Details",
      minWidth: 190,
      enableSort: true,
    },
    {
      id: "firstAddedDate",
      label: "First Added Date",
      minWidth: 300,
      align: "center",
      enableSort: true,
    },

    {
      id: "lastAddedDate",
      label: "Last Added Date",
      minWidth: 300,
      align: "center",
      enableSort: true,
    },
    {
      id: "count",
      label: "Count",
      minWidth: 100,
      align: "center",
      enableSort: true,
    },
    {
      id: "isIgnored",
      label: "Is Ignored",
      minWidth: 100,
      align: "center",
      enableSort: true,
    },
    {
      id: "isBlocked",
      label: "Is Blocked",
      minWidth: 100,
      align: "center",
      enableSort: true,
    },
    {
      id: "Action",
      label: "Action",
      minWidth: 35,
      align: "center",
    },
  ];

  const dataGenerator = (d) => {
    return {
      accountDetails: Array.isArray(d.accountDetails)
        ? d.accountDetails.map((m, index) => {
            return (
              <Fragment key={index}>
                <div>
                  {m.bankName}|{m.subType}|{m.mask}|{m.bankType}
                </div>
              </Fragment>
            );
          })
        : "",
      firstAddedDate: d.firstAddedDate,
      lastAddedDate: d.lastAddedDate,
      count: d.count,
      isIgnored: String(d.ignored),
      isBlocked: String(d.blocked),

      Action: (
        <Link to={`/app/plaidbank/${d.id}`} style={{ textDecoration: "none" }}>
          <Button variant="contained" size="small" color="warning">
            Edit
          </Button>
        </Link>
      ),
      accountDetailsData: Array.isArray(d.accountDetails)
        ? String(
            d.accountDetails.map((m, index) => {
              return `${m.bankName}|${m.subType}|${m.mask}|${m.bankType}`;
            })
          )
        : "",
    };
  };

  useEffect(() => {
    setLoading(true);
    localforage.getItem("@getplaidbankslist", (error, data) => {
      if (data) {
        if (data?.length > 0 && typeof data === "object") {
          setRows(data.map((d) => dataGenerator(d)));
        } else {
          setRows([]);
        }
      }
    });
    getPlaidBanksList()
      .then((response) => {
        setLoading(false);
        if (response?.length > 0 && typeof response === "object") {
          setRows(response.map((d) => dataGenerator(d)));
        } else {
          setRows([]);
        }
        localforage.setItem("@getplaidbankslist", response, (error, data) => {
        });
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  const setDynamicTable = (parentWidth, tableWidth) => {
    setIsDynamicTable(true);
  };

  useLayoutEffect(() => {
    startLoader();
  }, []);

  const searchData = (e) => {
    setSearchKey(e.target.value);
  };

  return (
    <>
      <Box
        sx={{ px: 6 }}
        className={`main-box ${isDynamicTable ? "dynamicTableStyle" : ""}`}
      >
        <div
          className={`bankAddRuleHeader mb-3 ${
            isDynamicTable ? "listHeaderPaddingRight" : ""
          }`}
        >
          <div
            style={{
              display: "flex",
              padding: "1.5rem 0",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: "#33343a" }} variant="h4" component="h4">
              Plaid Banks List
            </Typography>
            {loading && (
              <div
                style={{
                  marginLeft: "12px",
                  color: "#a0a0a0",
                  fontSize: "24px",
                }}
              >
                <i
                  className={`fa fa-refresh ${
                    loading === true ? "fa-spin" : ""
                  }`}
                  aria-hidden="true"
                ></i>
              </div>
            )}
          </div>
          <div style={{ display: "flex", alignItems: "end" }}>
            <TextField
              id="standard-basic"
              label="Search"
              variant="standard"
              sx={{ mr: 1 }}
              onKeyUp={(e) => searchData(e)}
              InputProps={{
                endAdornment: <SearchIcon sx={{ color: "#8a8a8a" }} />,
              }}
            />
          </div>
        </div>

        <CustomTable
          setDynamicTable={setDynamicTable}
          data={filterList(rows, searchKey) || []}
          columns={columns}
        />
      </Box>
    </>
  );
}

export default PlaidBanksList;
