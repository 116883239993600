import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {getAppConfigDataById as getAppConfigDataSingle} from "../../services/application-config";


export const getAppConfigsDataById = createAsyncThunk(
    "reward/getAppConfigsDataById",
    async (obj, { rejectWithValue }) => {
      try {
        const { id } = obj;
        const response = await getAppConfigDataSingle(id);
        return response;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  
const initialState = {
  list: {
    data: [],
    error: null,
    status: null,
  },
  single: {
    data: null,
    error: null,
    status: null,
  },
};

export const AppConfigsDataSlice = createSlice({
  name: "appConfigsData",
  initialState: initialState,
  extraReducers: (builder) => {
    builder
   
      .addCase(getAppConfigsDataById.pending, (state, action) => {
        state.single = { ...state.single, status: "loading" };
      })
      .addCase(getAppConfigsDataById.fulfilled, (state, action) => {
        state.single = {
          ...state.single,
          status: "succeeded",
          data: action.payload,
          error: null,
        };
      })
      .addCase(getAppConfigsDataById.rejected, (state, action) => {
        state.single = {
          ...state.single,
          status: "failed",
          error: action.payload,
          data: [],
        };
      });
  },
});

export default AppConfigsDataSlice.reducer;
