import { TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getActiveCouponById } from "../../../redux/slices/activeCouponSlice";

function ListActiveCouponSingle(props) {
  const dispatch = useDispatch();
  const activeCouponData = useSelector((state) => state.activeCoupon);
  const { single: item } = activeCouponData;

  const { id } = useParams();

  useEffect(() => {
    dispatch(
      getActiveCouponById({
        id: id,
      })
    );
  }, [dispatch, id]);

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <Typography
          component="h4"
          variant="h4"
          color="inherit"
          noWrap
          sx={{ ml: 3, fontSize: "22px", fontWeight: "700" }}
        >
          Times Prime Data
        </Typography>
      </Box>
      <Box sx={{ mt: 2, p: 3 }}>
        <div className="formGroup">
          <TextField
            style={{ minWidth: "500px" }}
            label="Coupon id"
            variant="outlined"
            value={item.data?.couponId || ""}
          />
        </div>
        <div className="formGroup">
          <TextField
            style={{ minWidth: "500px" }}
            label="Expiry date"
            variant="outlined"
            value={item.data?.expiryDate || ""}
          />
        </div>
        <div className="formGroup">
          <TextField
            style={{ minWidth: "500px" }}
            label="Activated on"
            variant="outlined"
            value={item.data?.activatedOn || ""}
          />
        </div>
        <div className="formGroup">
          <TextField
            style={{ minWidth: "500px" }}
            label="Email"
            variant="outlined"
            value={item.data?.email || ""}
          />
        </div>
        <div className="formGroup">
          <TextField
            style={{ minWidth: "500px" }}
            label="Reward type"
            variant="outlined"
            value={item.data?.rewardType || ""}
          />
        </div>
        <div className="formGroup">
          <TextField
            style={{ minWidth: "500px" }}
            label="Status"
            variant="outlined"
            value={item.data?.status || ""}
          />
        </div>
      </Box>
    </>
  );
}

export default ListActiveCouponSingle;
