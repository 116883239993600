import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { TextField, Button, styled } from "@mui/material";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CircularProgress from "@mui/material/CircularProgress";
import SearchIcon from "@mui/icons-material/Search";
import { getTransactionCount } from "../../../services/plaidTransactionService";
import moment from "moment";
import CustomTable from "../../../components/CustomTable";
import Swal from "sweetalert2";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import styles from "../../../../app/assets/css/userTxnHistory.module.scss";
import { MONTH_MAPPING } from "../../../constants";

function PlaidTransactionList({ userAccountDetails, customer_id, identifier }) {
  const [rows, setRows] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const { register, handleSubmit, reset } = useForm();
  const [isLoading, setisLoading] = useState(false);
  const [expanded, setExpanded] = useState("panel1");
  const [buttondisabled, setbuttondisabled] = useState(false);
  const [accountId, setAccountId] = useState("");
  const [date, setDate] = useState("");

  const columns = [
    { id: "month_year", label: "Date", minWidth: 110, align: "left" },
    {
      id: "transaction_count",
      label: "Transaction Count",
      minWidth: 50,
      align: "center",
    },
    {
      id: "first_linked_month",
      label: "First Linked Month",
      minWidth: 45,
      align: "center",
    },

    { id: "Action", label: "Action", minWidth: 50, align: "center" },
  ];

  const StyledDatePicker = styled(DesktopDatePicker)({
    ".MuiInput-underline:before": {
      borderBottom: "1px solid #6F719B !important",
    },
    ".MuiInput-underline:after": {
      borderBottom: "2px solid #F9DA87",
    },
  });

  const DateTextField = styled(TextField)({
    width: "200px",
    marginRight: "28px",
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
    "& .MuiSvgIcon-root": {
      color: "#6F719B",
    },
  });
  const searchData = (e) => {
    setSearchKey(e.target.value);
  };

  const handleDateChange = (date) => {
    const formattedDate = date.format("ll");
    setDate(formattedDate);
  };
  const dataGenerator = (data) => {
    const date = new Date(data.date);
    const year = date.getFullYear();
    return {
      month_year: MONTH_MAPPING[data.month],
      transaction_count: data.count === 0 ? "No Transaction" : data.count,
      first_linked_month: data.bank_link_date
        ? moment(data.bank_link_date).format("MMM D, YYYY")
        : "",
      Action: (
        <Link
          to={`/app/show-transactions?identifier=${identifier}`}
          state={{
            account_id: data.account_id,
            month:(data.month+1),
            year: year,
            customer_id: customer_id,
            identifier: identifier,
            transaction_count: data.count,
          }}
        >
          <Button variant="contained" size="small" color="warning">
            Show Details
          </Button>
        </Link>
      ),
    };
  };

  const updateFilter = (data) => {
    setisLoading(true);
    setbuttondisabled(true);
    const formattedDate = new Date(date);
    const year = formattedDate?.getFullYear();
    setAccountId(data.account_id);

    const selectedBank = userAccountDetails.filter(
      (item) => data.account_id === item.AccountId
    );
    const bankData = selectedBank.find((item) => item.BankLinkDate);
    const bank_link_date = bankData?.BankLinkDate;
    getTransactionCount({
      year: year? year:"",
      account_id: data.account_id,
    })
      .then((response) => {
        setbuttondisabled(false);
        if (response === 0) {
          setisLoading(false);
          Swal.fire({
            title: "Oops",
            text: "No data found",
            icon: "warning",
            confirmButtonText: "Ok",
          }).then((result) => {
            Swal.close();
          });
          return;
        }
        const inputData = {
          year: year,
          date: date,
          bank_link_date: bank_link_date,
          account_id:data.account_id
        };
        setisLoading(false);
        setRows(
          response.map((data, index) =>
            dataGenerator({ count: data, month: index, ...inputData })
          )
        );
      })
      .catch((error) => {
        setisLoading(false);
        Swal.fire({
          title: "Oops",
          text: "Something went wrong",
          icon: "warning",
          confirmButtonText: "Ok",
        }).then((result) => {
          Swal.close();
        });
        setbuttondisabled(false);
        throw error;
      });
  };

  const clearFilter = () => {
    setbuttondisabled(false);
    reset({
      account_id: "",
    });
    setDate("")
    setRows([]);
  };

  const handleChanges = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <Box sx={{ px: 6 }} className="dynamicTableStyle">
        {isLoading === true && (
          <Box
            sx={{
              display: "flex",
              position: "absolute",
              top: "60% !important",
              width: "40%",
              left: "50%",
            }}
          >
            <CircularProgress fontSize="large" />
          </Box>
        )}
        <div
          className="bankAddRuleHeader mb-3"
          style={{ paddingRight: "48px" }}
        >
          <div
            style={{
              display: "flex",
              padding: "1.5rem 0",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: "#33343a" }} variant="h4" component="h4">
              User Plaid Transactions
            </Typography>

            {isLoading && (
              <div
                style={{
                  marginLeft: "12px",
                  color: "#a0a0a0",
                  fontSize: "24px",
                }}
              >
                <i
                  className={`fa fa-refresh ${
                    isLoading === true ? "fa-spin" : ""
                  }`}
                  aria-hidden="true"
                ></i>
              </div>
            )}
          </div>
          <div style={{ display: "flex", alignItems: "end" }}>
            <TextField
              id="standard-basic"
              label="Search"
              variant="standard"
              sx={{ mr: 1 }}
              onKeyUp={(e) => searchData(e)}
              InputProps={{
                endAdornment: <SearchIcon sx={{ color: "#8a8a8a" }} />,
              }}
            />
          </div>
        </div>

        <Box sx={{ marginBottom: "40px" }} style={{ paddingRight: "48px" }}>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChanges("panel1")}
            sx={{
              boxShadow: "none !important",
              background: "white",
              border: "2px solid #f1f1f1",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Filter Transactions</Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <form onSubmit={handleSubmit(updateFilter)}>
                <div style={{ display: "flex" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ position: "absolute", left: 40, top: 44 }}>
                      <label style={{ fontSize: "14px" }}>By Account</label>
                    </div>

                    <select
                      className="form-control"
                      {...register("account_id")}
                      style={{
                        width: "162px",
                        marginLeft: "",
                        marginTop: "10px",
                      }}
                    >
                      {userAccountDetails?.map((item) => {
                        return (
                          <option value={item.AccountId}>
                            {item.BankName}-{item.Mask}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <div>
                    <div
                      style={{
                        position: "absolute",
                        top: 60,
                        fontSize: "14px",
                        left: "20",
                        marginBottom: "6px",
                      }}
                    >
                      Date
                    </div>
                    <div className={styles.dateStyle}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <StyledDatePicker
                          inputFormat="YYYY"
                          views={["year"]}
                          value={date}
                          onChange={handleDateChange}
                          componentsProps={{
                            actionBar: {
                              actions: ["clear"],
                            },
                          }}
                          renderInput={(params) => {
                            return (
                              <DateTextField
                                fullWidth
                                variant="standard"
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: "Date",
                                }}
                              />
                            );
                          }}
                          disableFuture
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ width: "16%", height: "45px" }}
                    disabled={buttondisabled}
                  >
                    Submit
                  </Button>
                  &nbsp; &nbsp; &nbsp; &nbsp;
                  <Button
                    type="button"
                    variant="contained"
                    sx={{ width: "16%", height: "45px" }}
                    onClick={clearFilter}
                    disabled={buttondisabled}
                  >
                    Clear
                  </Button>
                </div>
              </form>
            </AccordionDetails>
          </Accordion>
        </Box>
        {rows && <CustomTable data={rows} columns={columns} />}
      </Box>
    </>
  );
}

export default PlaidTransactionList;
