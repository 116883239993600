import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Swal from "sweetalert2";
import {
  getplaidbankbyid,
  updatePlaidBanks,
} from "../../../services/plaidBanks";
function EditPlaidBanks(props) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const { id } = useParams();
  const navigation = useNavigate();
  const [plaidData, setplaidData] = useState([]);

  useEffect(() => {
    if (id) {
      getplaidbankbyid(id)
        .then((response) => {
          if (response) {
            setplaidData(response);
            reset({
              ignored: response?.ignored,
              blocked: response?.blocked,
              id: response?.id,
            });
          }
        })
        .catch((error) => {
        });
    }
  }, [id]);

  const updatePlaidBanksData = (data) => {
    const buttonType = window.event.submitter.name;
    if (buttonType === "save") {
      updatePlaidBanks(data)
        .then((response) => {
          Swal.fire({
            title: "Success",
            text: "Plaid Banks Has been Updated successfully",
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              navigation(-1);
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "Oops",
            text: "Something went wrong",
            icon: "warning",
            confirmButtonText: "Ok",
          }).then((result) => {});

        });
    }
  };

  return (
    <>
      <Box sx={{ ml: "4", pt: "14 !important", pb: "5", px: 3 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "25px",
            marginTop: "20px",
            gap: "16px",
          }}
        >
          <Link to="/app/plaidbanks">
            <ArrowBackIcon
              fontSize="large"
              color="secondary"
              sx={{ background: "#040721", p: 1, borderRadius: "50%" }}
            />
          </Link>
          <Typography
            variant="h5"
            component="h5"
            fontWeight={"bold"}
            color={"#3d3d3d"}
          >
            Edit Plaid Bank
          </Typography>
        </div>
        <Box sx={{ background: "#ffffff", p: 2, mb: 4, pb: 4 }}>
          <form onSubmit={handleSubmit(updatePlaidBanksData)}>
            <div className="row">
              <div
                className="col-md-3 d-flex align-items-end"
                style={{ marginTop: "-32px !important" }}
              >
                <div className="form-groupform-check d-flex align-items-center gap-4 ">
                  <input
                    {...register("ignored")}
                    className={`form-check-input`}
                    type="checkbox"
                    id="is_active"
                  />
                  <label
                    className="form-check-label fw-bold text-secondary"
                    htmlFor="is_active"
                    style={{ lineHeight: "1" }}
                  >
                    Is Ignored
                  </label>
                </div>
              </div>
              <div
                className="col-md-3 d-flex align-items-end"
                style={{ marginTop: "-32px !important" }}
              >
                <div className="form-groupform-check d-flex align-items-center gap-4 ">
                  <input
                    {...register("blocked")}
                    className={`form-check-input`}
                    type="checkbox"
                    id="is_active"
                  />
                  <label
                    className="form-check-label fw-bold text-secondary"
                    htmlFor="is_active"
                    style={{ lineHeight: "1" }}
                  >
                    Is Blocked
                  </label>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="price"
                  >
                    Count:
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    defaultValue={plaidData.count}
                    id="price"
                    readOnly
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="price"
                  >
                    First Added Date:
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="price"
                    defaultValue={plaidData.firstAddedDate}
                    readOnly
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="price"
                  >
                    Last Added Date:
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="price"
                    readOnly
                    defaultValue={plaidData.lastAddedDate}
                  />
                </div>
              </div>
              <input
                {...register("id")}
                className={`form-control`}
                type="hidden"
                id="name"
              />

              <div className="col-md-12">
                <label
                  className="form-label fw-bold text-secondary user-select-none"
                  htmlFor="name"
                >
                  Account Details:
                </label>
              </div>
              {plaidData && (
                <div
                  className="col-md-12"
                  style={{
                    border: "2px solid #f1f1f1",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    background: "#f1f1f1",
                    width: "96%",
                    marginLeft: "1%",
                  }}
                >
                  <span>
                    {plaidData.accountDetails?.map((m, index) => {
                      return (
                        <React.Fragment key={index}>
                          {" "}
                          {m.bankName}|{m.subType}|{m.mask}|{m.bankType}
                        </React.Fragment>
                      );
                    })}
                  </span>
                </div>
              )}
            </div>
            <hr style={{ height: "0.5px", marginTop: "32px" }} />
            <div>
              <button
                type="submit"
                className="btn btn-primary py-2 px-5"
                name="save"
                variant="contained"
                sx={{ width: "25%" }}
              >
                Save or Update
              </button>
            </div>
          </form>
        </Box>
      </Box>
    </>
  );
}

export default EditPlaidBanks;
