import * as React from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import LogoFill from "../../../assets/images/logoFill_balck.svg";
import MuiAppBar from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";

function AnonymousUserLayout() {

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: 0,
      width: `100%`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));
  return (
    <>
      <AppBar position="fixed" sx={{ background: "#f5f5f5" }}>
        <Toolbar
          sx={{
            display: "flex",
            alignItem: "center",
            justifyContent: "space-between",
          }}
        >
          <img src={LogoFill} alt="logo" />
          <Typography component="h1" variant="h5" color="#ccc">
            CMS
          </Typography>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default AnonymousUserLayout;
