import React from "react";
import { useLocation } from "react-router-dom";
import { ROUTE_PERSMISSION } from "../../constants";
import { hasRole } from "../../libs/utils";
import Unauthorized from "../errors/Unauthorized";

const RoleAccess = ({ children, roles }) => {
  const { pathname } = useLocation();
  console.log("location", pathname);

  const hasRoleAccess = () => {
    let result = false;
    const userRoles = hasRole() || [];
    const appliedRoutesRoles = ROUTE_PERSMISSION.find((route) =>
      pathname.includes(route.path)
    );

    const allowedOnROles = appliedRoutesRoles?.allowedOnROles || [];
    const hasAccess = allowedOnROles.some((role) => userRoles?.includes(role));
    if (hasAccess) {
      result = true;
    }
    return result;
  };

  const isAuthorized = hasRoleAccess();
  if (!isAuthorized) {
    return <Unauthorized />;
  }
  return children;
};

RoleAccess.defaultProps = {
  roles: [],
};

export default RoleAccess;
