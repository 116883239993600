import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { TextField, Button, Container } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { MenuItem } from "@mui/material";

function AdvertiserCategory() {
  return (
    <>
      <Box component="form" noValidate autoComplete="off">
        <div style={{ marginBottom: "25px" }}>
          <Typography>Advertiser Category</Typography>
        </div>
        <div
          className="formDiv"
          style={{
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <InputLabel>Name:</InputLabel>
          <TextField
            id="outlined-basic"
            label="Name"
            variant="outlined"
            size="small"
            sx={{ width: "30%" }}
          />
        </div>
        <hr></hr>
        <div
          className="formDiv"
          style={{
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <InputLabel>Affiliate Partner:</InputLabel>
          <TextField
            select
            id="outlined-basic"
            label="Affiliate Partner"
            variant="outlined"
            size="small"
            sx={{ width: "30%" }}
          >
            <MenuItem>cj</MenuItem>
            <MenuItem>vComission</MenuItem>
          </TextField>
        </div>
        <hr></hr>

        <div
          className="formDiv"
          style={{
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <InputLabel>CategoryId:</InputLabel>
          <TextField
            id="outlined-basic"
            label="CategoryId"
            variant="outlined"
            size="small"
            sx={{ width: "30%" }}
          />
        </div>
        <hr></hr>
      </Box>

      <Container sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button variant="contained" sx={{ width: "25%" }}>
          Save
        </Button>
        <Button variant="contained" sx={{ width: "25%" }}>
          Save And Add Another
        </Button>
        <Button variant="contained" sx={{ width: "25%" }}>
          Save and Continue Editing
        </Button>
      </Container>
    </>
  );
}

export default AdvertiserCategory;
