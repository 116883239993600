import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import localforage from "localforage";
import {
  TextField,
  Tooltip,
  IconButton,
  Menu,
  styled,
  Button,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import MenuItem from "@mui/material/MenuItem";
import { filterList } from "../../../libs/utils";
import CustomTable from "../../../components/CustomTable";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { getFxRateCompareData } from "../../../services/fxRateCampaignService";
import {
  LocalizationProvider,
  DesktopDatePicker,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

function FxCompareList() {
  const [rows, setRows] = useState();
  const [loading, setLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [isDynamicTable, setIsDynamicTable] = useState(false);
  const [startTime, setStartTime] = useState("");
  const [buttondisabled, setbuttondisabled] = useState(false);
  const [endTime, setEndTime] = useState(false);
  const columns = [
    {
      id: "provider_name",
      label: "Partner",
      minWidth: 80,
      enableSort: true,
    },
    {
      id: "fx_rate",
      label: "FX Rate",
      minWidth: 80,
      enableSort: true,
    },
    {
      id: "transfer_fee",
      label: "Fee",
      minWidth: 100,
      align: "center",
    },
    {
      id: "last_data_sync",
      label: "Last synced",
      minWidth: 100,
      align: "center",
      enableSort: true,
    },
    {
      id: "last_fetched_at",
      label: "Last Fetched",
      minWidth: 100,
      align: "center",
      enableSort: true,
    },
    {
      id: "mark_up",
      label: "Mark Up",
      minWidth: 100,
      align: "center",
    },
  ];

  const dataGenerator = (data) => {
    const {
      provider_name,
      fx_rate,
      last_data_sync,
      last_fetched_at,
      mark_up,
      transfer_fee,
    } = data;
    return {
      provider_name,
      fx_rate,
      last_data_sync,
      last_fetched_at,
      mark_up,
      transfer_fee,
    };
  };

  const setDynamicTable = (parentWidth, tableWidth) => {
    setIsDynamicTable(true);
  };

  const searchData = (e) => {
    setSearchKey(e.target.value);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // const data = [
  //   {
  //     lastUpdatedAt: "1AM",
  //     data: [
  //       {
  //         fx_rate: 82,
  //         fee: 0,
  //         last_synced: "1AM",
  //         last_data_collected: "1AM",
  //         mark_up: "1%",
  //         partner_name: "Remittly",
  //       },
  //       {
  //         fx_rate: 82,
  //         fee: 0,
  //         last_synced: "1AM",
  //         last_data_collected: "1AM",
  //         mark_up: "1%",
  //         partner_name: "Remittly",
  //       },
  //       {
  //         fx_rate: 82,
  //         fee: 0,
  //         last_synced: "1AM",
  //         last_data_collected: "1AM",
  //         mark_up: "1%",
  //         partner_name: "Remittly",
  //       },
  //     ],
  //   },
  //   {
  //     lastUpdatedAt: "2AM",
  //     data: [
  //       {
  //         fx_rate: 82,
  //         fee: 0,
  //         last_synced: "2AM",
  //         last_data_collected: "2AM",
  //         mark_up: "1%",
  //         partner_name: "Remittly",
  //       },
  //       {
  //         fx_rate: 82,
  //         fee: 0,
  //         last_synced: "2AM",
  //         last_data_collected: "2AM",
  //         mark_up: "1%",
  //         partner_name: "Remittly",
  //       },
  //       {
  //         fx_rate: 82,
  //         fee: 0,
  //         last_synced: "2AM",
  //         last_data_collected: "2AM",
  //         mark_up: "1%",
  //         partner_name: "Remittly",
  //       },
  //     ],
  //   },
  //   {
  //     lastUpdatedAt: "2AM",
  //     data: [
  //       {
  //         fx_rate: 82,
  //         fee: 0,
  //         last_synced: "2AM",
  //         last_data_collected: "2AM",
  //         mark_up: "1%",
  //         partner_name: "Remittly",
  //       },
  //       {
  //         fx_rate: 82,
  //         fee: 0,
  //         last_synced: "2AM",
  //         last_data_collected: "2AM",
  //         mark_up: "1%",
  //         partner_name: "Remittly",
  //       },
  //       {
  //         fx_rate: 82,
  //         fee: 0,
  //         last_synced: "2AM",
  //         last_data_collected: "2AM",
  //         mark_up: "1%",
  //         partner_name: "Remittly",
  //       },
  //     ],
  //   },
  // ];

  const StyledDatePicker = styled(DesktopDatePicker)({
    ".MuiInput-underline:before": {
      borderBottom: "1px solid #6F719B !important",
    },
    ".MuiInput-underline:after": {
      borderBottom: "2px solid #F9DA87",
    },
  });

  const DateTextField = styled(TextField)({
    width: "200px",
    marginRight: "28px",
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
    "& .MuiSvgIcon-root": {
      color: "#6F719B",
    },
  });
  useEffect(() => {
    setLoading(true);
    localforage.getItem("@fxcompare", (error, data) => {
      if (data) {
        if (data?.length > 0 && typeof data === "object") {
          const temp = [];
          for (let fxData in data) {
            temp.push(data[fxData]);
          }
          setRows(
            temp.map((obj) => {
              return obj.map((fxItem) =>
                dataGenerator({
                  ...fxItem,
                  last_fetched_at: moment
                    .utc(moment(fxItem.last_fetched_at).utc())
                    .format(),
                  last_data_sync: moment
                    .utc(moment(fxItem.last_data_sync).utc())
                    .format(),
                })
              );
            })
          );
        } else {
          setRows([]);
        }
      }
    });
    getFxRateCompareData({
      start_time: startTime ? new Date(startTime).getTime() : "",
      end_time: endTime ? new Date(endTime).getTime() : "",
    })
      .then((response) => {
        setLoading(false);
        const resp = response?.data;

        if (resp) {
          const temp = [];
          for (let data in resp) {
            temp.push(resp[data]);
          }
          setRows(
            temp.map((obj) => {
              return obj.map((fxItem) =>
                dataGenerator({
                  ...fxItem,
                  last_fetched_at: moment
                    .utc(moment(fxItem.last_fetched_at).utc())
                    .format(),
                  last_data_sync: moment
                    .utc(moment(fxItem.last_data_sync).utc())
                    .format(),
                })
              );
            })
          );
        } else {
          setRows([]);
        }
        localforage.setItem("@fxcompare", response.data, (error, data) => {
          if (error) {
            throw error;
          }
        });
      })
      .catch((error) => {
        setLoading(false);
        throw error;
      });
  }, []);

  const generateCompareTable = () => {
    if (rows?.length) {
      return rows?.map((item, index) => {
        return (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              // aria-controls="panel1a-content"
              id={index}
            >
              {item[0].last_fetched_at}
            </AccordionSummary>
            <AccordionDetails>
              <CustomTable
                setDynamicTable={setDynamicTable}
                data={filterList(item, searchKey) || []}
                columns={columns}
                isPaginationRequired={false}
              />
            </AccordionDetails>
          </Accordion>
        );
      });
    } else {
      return (
        <CustomTable
          setDynamicTable={setDynamicTable}
          data={filterList(rows) || []}
          columns={columns}
          isPaginationRequired={false}
        />
      );
    }
  };

  const handleDateChange = (e) => {
    const date = e.target.value;
    setStartTime(date);
  };

  const handleEndDate = (e) => {
    const date = e.target.value;

    setEndTime(date);
  };

  const updateFilter = (data) => {
    const date = new Date(endTime);
    date.setDate(date.getDate()+1)
    setbuttondisabled(true);
    getFxRateCompareData({
      start_time: startTime ? new Date(startTime).getTime() : "",

      end_time: endTime ? new Date(date).getTime() : "",
    })
      .then((response) => {
        setbuttondisabled(false);
        setLoading(false);

        const resp = response.data;
        if (resp) {
          const temp = [];
          for (let data in resp) {
            temp.push(resp[data]);
          }
          setRows(
            temp.map((obj) => {
              return obj.map((fxItem) =>
                dataGenerator({
                  ...fxItem,
                  last_fetched_at: moment
                    .utc(moment(fxItem.last_fetched_at).utc())
                    .format(),
                  last_data_sync: moment
                    .utc(moment(fxItem.last_data_sync).utc())
                    .format(),
                })
              );
            })
          );
        } else {
          setRows([]);
        }
      })
      .catch((error) => {
        setbuttondisabled(false);
        throw error;
      });
  };

  const clearFilter = () => {
    setbuttondisabled(false);
    setStartTime("");
    setEndTime("");
    setRows([]);
  };
  return (
    <>
      <Box
        sx={{ px: 6 }}
        className={`main-box ${isDynamicTable ? "dynamicTableStyle" : ""}`}
      >
        <div
          className={`bankAddRuleHeader mb-3 ${
            isDynamicTable ? "listHeaderPaddingRight" : ""
          }`}
        >
          <div
            style={{
              display: "flex",
              padding: "1.5rem 0",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: "#33343a" }} variant="h4" component="h4">
              FX Rate
            </Typography>
            {loading && (
              <div
                style={{
                  marginLeft: "12px",
                  color: "#a0a0a0",
                  fontSize: "24px",
                }}
              >
                <i
                  className={`fa fa-refresh ${
                    loading === true ? "fa-spin" : ""
                  }`}
                  aria-hidden="true"
                ></i>
              </div>
            )}
          </div>
          <div style={{ display: "flex", alignItems: "end" }}>
            <TextField
              id="standard-basic"
              label="Search"
              variant="standard"
              sx={{ mr: 1 }}
              onKeyUp={(e) => searchData(e)}
              InputProps={{
                endAdornment: <SearchIcon sx={{ color: "#8a8a8a" }} />,
              }}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "baseline",
            marginBottom: "40px",
          }}
        >
          <div>
            <label
              className="form-label fw-bold text-secondary user-select-none"
              htmlFor="end_time"
            >
              Start Time:
            </label>

            <input
              value={startTime}
              onChange={handleDateChange}
              className={`form-control`}
              type="date"
              id="start_time"
              max={new Date()?.toISOString()?.slice(0, 10)}
            />
          </div>{" "}
          &nbsp;&nbsp;&nbsp;&nbsp;
          <div>
            <label
              className="form-label fw-bold text-secondary user-select-none"
              htmlFor="end_time"
            >
              End Time:
            </label>
            <input
              value={endTime}
              className={`form-control`}
              type="date"
              onChange={handleEndDate}
              id="end_time"
              max={new Date()?.toISOString()?.slice(0, 10)}
            />
          </div>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Button
            onClick={updateFilter}
            variant="contained"
            sx={{ width: "16%", height: "45px" }}
            disabled={buttondisabled}
          >
            Submit
          </Button>
          &nbsp; &nbsp; &nbsp; &nbsp;
          <Button
            type="button"
            variant="contained"
            sx={{ width: "16%", height: "45px" }}
            onClick={clearFilter}
            disabled={buttondisabled}
          >
            Clear
          </Button>
        </div>

        {generateCompareTable()}
      </Box>
    </>
  );
}

export default FxCompareList;
