import * as React from "react";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";

import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getOfferStoresDataById } from "../../../redux/slices/offerStoreSlice";
import { updateOfferStoresRecord } from "../../../services/stores";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Swal from "sweetalert2";

function EditOfferStores(props) {
  const dispatch = useDispatch();
  const offerStoresDataId = useSelector((state) => state.offerStoresData);
  const { single: OfferStoresDataItem } = offerStoresDataId;
  const { register, handleSubmit, reset } = useForm();
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      getOfferStoresDataById({
        id: id,
      })
    );
  }, [dispatch, id]);
  useEffect(() => {
    if (OfferStoresDataItem.data) {
      reset({
        businessStatus: OfferStoresDataItem.data[0]?.businessStatus,
        name: OfferStoresDataItem.data[0]?.name,
        formattedAddress: OfferStoresDataItem.data[0]?.formattedAddress,
        placeId: OfferStoresDataItem.data[0]?.placeId,
        stateName: OfferStoresDataItem.data[0]?.stateName,
        cityName: OfferStoresDataItem.data[0]?.cityName,
        rating: OfferStoresDataItem.data[0]?.rating,
        userRatingsTotal: OfferStoresDataItem.data[0]?.userRatingsTotal,
        types: OfferStoresDataItem.data[0]?.types,
        keywords: JSON.stringify(
          OfferStoresDataItem.data[0]?.keywords,
          null,
          4
        ),
        geometry: OfferStoresDataItem.data[0]?.geometry,
      });
    }
  }, [OfferStoresDataItem]);

  const updateOfferstores = (data) => {
    data.keywords = JSON.parse(data.keywords);
    updateOfferStoresRecord({
      id: id,
      businessStatus: data.businessStatus,
      name: data.name,
      formattedAddress: data.formattedAddress,
      placeId: data.placeId,
      stateName: data.stateName,
      cityName: data.cityName,
      rating: data.rating,
      userRatingsTotal: data.userRatingsTotal,
      types: data.types,
      keywords: data.keywords,
      geometry: data.geometry,
    })
      .then((response) => {
        Swal.fire({
          title: "Success",
          text: "Stores has been Saved successfully",
          icon: "success",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/app/list-stores");
          }
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Oops",
          text: "Something went wrong",
          icon: "error",
          confirmButtonText: "Ok",
        }).then((result) => {});
        throw error;
      });
  };

  return (
    <>
      <Box sx={{ ml: "4", pt: "14 !important", pb: "5", px: 3 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "25px",
            marginTop: "20px",
            gap: "16px",
          }}
        >
          <Link to="/app/list-stores">
            <ArrowBackIcon
              fontSize="large"
              color="secondary"
              sx={{ background: "#040721", p: 1, borderRadius: "50%" }}
            />
          </Link>
          <Typography
            variant="h5"
            component="h5"
            fontWeight={"bold"}
            color={"#3d3d3d"}
          >
            Edit Store
          </Typography>
        </div>
        <Box sx={{ background: "#ffffff", p: 2, mb: 4, pb: 4 }}>
          <form onSubmit={handleSubmit(updateOfferstores)}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="businessStatus"
                  >
                    Business Status
                  </label>
                  <input
                    {...register("businessStatus")}
                    className={`form-control `}
                    type="text"
                    id="businessStatus"
                    readOnly
                  />
                </div>
              </div>
              <input
                defaultValue={OfferStoresDataItem.data?.id}
                {...register("id")}
                type="hidden"
              />

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="name"
                  >
                    Name <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("name", { required: true })}
                    className={`form-control`}
                    type="text"
                    id="name"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="formattedAddress"
                  >
                    FormattedAddress:
                  </label>
                  <input
                    {...register("formattedAddress")}
                    className={`form-control`}
                    type="text"
                    id="formattedAddress"
                    readOnly
                  />
                </div>
              </div>
              <div className="col-md-6" style={{ display: "none" }}>
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="placeId"
                  >
                    Place Id:
                  </label>
                  <input
                    {...register("placeId")}
                    className={`form-control`}
                    type="text"
                    id="placeId"
                    readOnly
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="stateName"
                  >
                    State Name:
                  </label>
                  <input
                    {...register("stateName")}
                    className={`form-control`}
                    type="text"
                    id="stateName"
                    readOnly
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="cityName"
                  >
                    City Name:
                  </label>
                  <input
                    {...register("cityName")}
                    className={`form-control`}
                    type="text"
                    id="cityName"
                    readOnly
                  />
                </div>
              </div>
              <div className="col-md-6" style={{ display: "none" }}>
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="rating"
                  >
                    Rating:
                  </label>
                  <input
                    {...register("rating")}
                    className={`form-control`}
                    type="text"
                    id="rating"
                    readOnly
                  />
                </div>
              </div>
              <div className="col-md-6" style={{ display: "none" }}>
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="userRatingsTotal"
                  >
                    userRatingsTotal:
                  </label>
                  <input
                    {...register("userRatingsTotal")}
                    className={`form-control`}
                    type="text"
                    id="userRatingsTotal"
                    readOnly
                  />
                </div>
              </div>
              <div className="col-md-12" style={{ display: "none" }}>
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="types"
                  >
                    Types:
                  </label>
                  <textarea
                    {...register("types")}
                    className={`form-control`}
                    type="text"
                    id="types"
                    rows="6"
                    cols="60"
                    readOnly
                  >
                    {" "}
                  </textarea>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="keywords"
                  >
                    Keywords: <small className="text-danger fs-6">*</small>
                  </label>
                  <textarea
                    {...register("keywords", { required: true })}
                    className={`form-control`}
                    type="text"
                    id="keywords"
                    rows="4"
                    cols="60"
                  >
                    {" "}
                  </textarea>
                </div>
              </div>
              <div className="col-md-6" style={{ display: "none" }}>
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="geometry"
                  >
                    Geometry:
                  </label>
                  <textarea
                    {...register("geometry")}
                    className={`form-control`}
                    type="text"
                    id="geometry"
                    rows="4"
                    cols="60"
                    readOnly
                  >
                    {" "}
                  </textarea>
                </div>
              </div>
            </div>
            <hr style={{ height: "0.5px", marginTop: "32px" }} />
            <div>
              <button
                type="submit"
                className="btn btn-primary py-2 px-5"
                name="save"
              >
                Save or Update
              </button>
            </div>
          </form>
        </Box>
      </Box>
    </>
  );
}

export default EditOfferStores;
