import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getRemittanceBlockedUsers } from "../../services/blockedUsersService";

export const getBlockedUsersList = createAsyncThunk(
  "/remittance-blocked-users",
  async ({ rejectWithValue }) => {
    try {
      const response = await getRemittanceBlockedUsers();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
const initialState = {
  list: {
    data: [],
    error: null,
    status: null,
  },
  single: {
    data: null,
    error: null,
    status: null,
  },
  blockedUsers: {
    data: null,
    error: null,
    status: null,
  },
};

export const BlockedUsersDataSlice = createSlice({
  name: "blockedUsersData",
  initialState: initialState,
  reducers: {
    setBlockedUsers: (state, action) => {
      state.blockedUsers= action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getBlockedUsersList.pending, (state, action) => {
        state.single = { ...state.single, status: "loading" };
      })
      .addCase(getBlockedUsersList.fulfilled, (state, action) => {
        state.single = {
          ...state.single,
          status: "succeeded",
          data: action.payload,
          error: null,
        };
      })
      .addCase(getBlockedUsersList.rejected, (state, action) => {
        state.single = {
          ...state.single,
          status: "failed",
          error: action.payload,
          data: [],
        };
      });
  },
});

export const { setBlockedUsers } = BlockedUsersDataSlice.actions;

export default BlockedUsersDataSlice.reducer;
