import { useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ImageUploadUtility from "../../../utils/ImageUploadUtility";


function AddImage() {
  const {
    formState: { errors },
  } = useForm();

  const [imageName, setImageName] = useState();

  return (
    <>
      <Box sx={{ ml: "4", pt: "14 !important", pb: "5", px: 3 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "25px",
            marginTop: "20px",
            gap: "16px",
          }}
        >
          <Link to="/app/image-upload">
            <ArrowBackIcon
              fontSize="large"
              color="secondary"
              sx={{ background: "#040721", p: 1, borderRadius: "50%" }}
            />
          </Link>
          <Typography
            variant="h5"
            component="h5"
            fontWeight={"bold"}
            color={"#3d3d3d"}
          >
            Add Image
          </Typography>
        </div>
        <Box sx={{ background: "#ffffff", p: 2, mb: 4, pb: 4 }}>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label fw-bold text-secondary user-select-none">
                  Image Name
                </label>
                <input
                  value={imageName? imageName: ''}
                  type="text"
                  className={`form-control ${
                    errors.item_id ? "invalid-form-input" : ""
                  }`}
                  placeholder="Enter Image Name"
                  onChange={(e) => setImageName(e.target.value)}
                />
              </div>
            </div>
            <ImageUploadUtility
              uploadType="GENERIC"
              label="Image"
              name={imageName}
            />
          </div>
        </Box>
      </Box>
    </>
  );
}

export default AddImage;
