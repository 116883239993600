import { useSelector } from "react-redux";
import { useState } from "react";
import { useParams } from "react-router-dom";

import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { Modal } from "@mui/material";
import Box from "@mui/material/Box";
import { imageDownload } from "../services/offers";
import { Swal } from "sweetalert2";

const ImageData = ({ inputFiletype, fileType, imageUrl }) => {
  const { id } = useParams();
  const [opens, setOpens] = useState(false);
  const [imageLink, setImageLink] = useState(null);

  const imageData = useSelector((state) => state.imageData);
  const link = imageData?.imageData;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    minHeight: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  };

  const handleOpen = (e, r) => {
    setOpens(true);
  };

  const handleCloses = () => setOpens(false);

  const getImage = (e) => {
    handleOpen(e);
    setImageLink(null);
    imageDownload(imageUrl ? imageUrl : link)
      .then((response) => {
        setImageLink(response.content);
      })
      .catch(function (error) {
        setOpens(false);
        setImageLink(null);
        Swal.fire({
          title: "Oops",
          text: "This file cannot be displayed",
          icon: "warning",
          confirmButtonText: "Ok",
        }).then((result) => {});
        throw error;
      });
  };

  return (
    <>
      {(link || imageUrl) && (
        <>
          <div className="col-md-12 mb-4">
            {/* <div
              className="rounded-3 justify-content-between"
              style={{ background: "#eee" }}
            > */}
              <div
                className="d-flex rounded-3 justify-content-between mb-2"
                style={{ background: "#eee" }}
              >
                <div
                  className="text-truncate text-secondary p-2 bg-primary text-white rounded-3 text-center"
                  style={{ width: "250px" }}
                >
                  {inputFiletype ? inputFiletype : fileType}
                </div>
                <div className="d-flex justify-content-between">
                  <div
                    className="text-truncate text-secondary p-2"
                    style={{ cursor: "pointer" }}
                    title="Click to Copy"
                  >
                    {imageUrl ? imageUrl : link}
                  </div>
                  <button
                    type="button"
                    onClick={(e) => getImage(e)}
                    className="btn download-btn"
                    style={{ display: "inline-flex" }}
                  >
                    <CloudDownloadIcon />
                  </button>
                </div>
              </div>
            {/* </div> */}
          </div>
        </>
      )}
      <Modal
        open={opens}
        onClose={handleCloses}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {imageLink === null && (
            <>
              <i
                className={`mt-4 fa fa-circle-o-notch fa-2x ${
                  true ? "fa-spin" : ""
                }`}
                aria-hidden="true"
              ></i>
            </>
          )}
          {imageLink !== null && (
            <img
              style={{ maxWidth: "100%", maxHeight: "80vh" }}
              src={`data:image/png;base64, ${imageLink}`}
              alt="img"
            />
          )}
        </Box>
      </Modal>
    </>
  );
};
export default ImageData;
