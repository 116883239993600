import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import moment from "moment";
import { Link } from "react-router-dom";
import { TextField, Button } from "@mui/material";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { filterList } from "../../../libs/utils";
import TransDupCustomTable from "../../../components/transdupcustomtable";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getTransactionsList } from "../../../services/merchant-mapping";
import CircularProgress from "@mui/material/CircularProgress";
import SearchIcon from "@mui/icons-material/Search";

function ListTransaction({ email }) {
  const navigation = useNavigate();
  const [rows, setRows] = useState();
  const [searchKey, setSearchKey] = useState("");
  const { register, handleSubmit, reset } = useForm();
  const [isLoading, setisLoading] = useState(false);
  const [expanded, setExpanded] = useState("panel1");
  const [buttondisabled, setbuttondisabled] = useState(false);
  const loading = false;
  const columns = [
    { id: "Name", label: "Name", minWidth: 110, align: "left" },
    {
      id: "Date",
      label: "Date",
      minWidth: 130,
      align: "center",
    },
    {
      id: "Commission",
      label: "Commission",
      minWidth: 50,
      align: "center",
    },
    {
      id: "ActionTime",
      label: "ActionTime",
      minWidth: 45,
      align: "center",
    },
    {
      id: "amount",
      label: "Amount",
      minWidth: 50,
      align: "center",
    },
    {
      id: "status",
      label: "Status",
      minWidth: 50,
      align: "center",
    },
    {
      id: "processed",
      label: "Processed",
      minWidth: 50,
      align: "center",
    },
    {
      id: "offerId",
      label: "Offer Id",
      minWidth: 50,
      align: "center",
    },
    {
      id: "email",
      label: "Email",
      minWidth: 50,
      align: "center",
    },
    {
      id: "Bankname",
      label: "Bank Name",
      minWidth: 50,
      align: "center",
    },
    {
      id: "Advertisername",
      label: "Advertiser Name",
      minWidth: 50,
      align: "center",
    },
    {
      id: "Action",
      label: "Action",
      minWidth: 50,
      align: "center",
    },
  ];

  const searchData = (e) => {
    setSearchKey(e.target.value);
  };

  const dataGenerator = (data) => {
    return {
      Name: data.name,
      Date: moment(data.date).format("MMM D, YYYY"),
      Commission: data.commission,
      ActionTime: data.actionTime,
      amount: data.amount,
      status: data.status,
      processed: (
        <>
          {" "}
          {data.processed === true && <span>true</span>}
          {data.processed === false && <span>false</span>}
        </>
      ),
      offerId: data.localOffersId,
      email: data.email,
      Bankname: data.bankName,
      Advertisername: data.advertiserName,
      Action: (
        <Link
          to={`/app/transaction/${data.id}`}
          style={{ textDecoration: "none" }}
        >
          <Button variant="contained" size="small" color="warning">
            Edit
          </Button>
        </Link>
      ),
    };
  };
  useEffect((data) => {
    setisLoading(true);
    setbuttondisabled(true);
    getTransactionsList({
      offset: 0,
      size: 1000,
      email: email ? email : data?.email,
      name: data?.storeName,
      processed: data?.processed,
      status: data?.status,
      fromDate: data?.fromDate,
      toDate: data?.endDate,
    })
      .then((response) => {
        setbuttondisabled(false);
        {
          !email &&
            navigation(
              `/app/transactions/0/100?status=${data?.status}&processed=${data?.processed}`
            );
        }
        setisLoading(false);
        setRows(response.map((data) => dataGenerator(data.response)));
      })
      .catch((error) => {
        setbuttondisabled(false);
      });
  }, []);
  const updateFilter = (data) => {
    setisLoading(true);
    setbuttondisabled(true);
    getTransactionsList({
      offset: 0,
      size: 1000,

      email: email ? email : data.email,
      name: data.storeName,
      processed: data.processed,
      status: data.status,

      fromDate: data.fromDate,
      toDate: data.endDate,
    })
      .then((response) => {
        setbuttondisabled(false);
        {
          !email &&
            navigation(
              `/app/transactions/0/100?status=${data.status}&processed=${data.processed}`
            );
        }
        setisLoading(false);
        setRows(response.map((data) => dataGenerator(data)));
      })
      .catch((error) => {
        setbuttondisabled(false);
        throw error;
      });
  };

  const clearFilter = () => {
    setisLoading(true);
    setbuttondisabled(true);
    getTransactionsList({
      offset: 0,
      size: 100,

      email: email ? email : null,
      name: null,
      processed: "all",
      status: "all",

      fromDate: null,
      toDate: null,
    })
      .then((response) => {
        setbuttondisabled(false);
        reset({
          storeName: "",
          email: "",
          fromDate: "",
          endDate: "",
          processed:"all",
          status:"all"
        });
        if (!email) {
          navigation(`/app/transactions/0/100?status=all&processed=all`);
        }
        setisLoading(false);
        setRows(response.map((data) => dataGenerator(data)));
      })
      .catch((error) => {
        setbuttondisabled(false);
        throw error;
      });
  };

  const handleChanges = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <Box sx={{ px: 6 }} className="dynamicTableStyle">
        {isLoading === true && (
          <Box
            sx={{
              display: "flex",
              position: "absolute",
              top: "60% !important",
              width: "40%",
              left: "50%",
            }}
          >
            <CircularProgress fontSize="large" />
          </Box>
        )}
        <div
          className="bankAddRuleHeader mb-3"
          style={{ paddingRight: "48px" }}
        >
          <div
            style={{
              display: "flex",
              padding: "1.5rem 0",
              alignItems: "center",
            }}
          >
            {!email && (
              <Typography sx={{ color: "#33343a" }} variant="h4" component="h4">
                User Plaid Historical Transactions
              </Typography>
            )}
            {loading && (
              <div
                style={{
                  marginLeft: "12px",
                  color: "#a0a0a0",
                  fontSize: "24px",
                }}
              >
                <i
                  className={`fa fa-refresh ${
                    loading === true ? "fa-spin" : ""
                  }`}
                  aria-hidden="true"
                ></i>
              </div>
            )}
          </div>
          <div style={{ display: "flex", alignItems: "end" }}>
            <TextField
              id="standard-basic"
              label="Search"
              variant="standard"
              sx={{ mr: 1 }}
              onKeyUp={(e) => searchData(e)}
              InputProps={{
                endAdornment: <SearchIcon sx={{ color: "#8a8a8a" }} />,
              }}
            />
          </div>
        </div>

        <Box sx={{ marginBottom: "40px" }} style={{ paddingRight: "48px" }}>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChanges("panel1")}
            sx={{
              boxShadow: "none !important",
              background: "white",
              border: "2px solid #f1f1f1",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Filter Transactions</Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <form onSubmit={handleSubmit(updateFilter)}>
                <div style={{ display: "flex" }}>
                  <div style={{ position: "absolute", left: 40, top: 44 }}>
                    <label style={{ fontSize: "14px" }}>By Status</label>
                  </div>
                  <select
                    className="form-control"
                    {...register("status")}
                    style={{
                      width: "140px",
                      marginLeft: "",
                      marginTop: "10px",
                    }}
                  >
                    <option value="all">All</option>
                    <option value="Accept">Accept</option>
                    <option value="Reject">Rejected</option>
                    <option value="Cancelled">Cancelled</option>
                  </select>{" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <div style={{ position: "absolute", left: 167, top: 44 }}>
                    <label style={{ fontSize: "14px" }}>Processed</label>
                  </div>
                  <select
                    className="form-control"
                    {...register("processed")}
                    style={{
                      width: "140px",
                      marginLeft: "",
                      marginTop: "10px",
                    }}
                  >
                    <option value="all">All</option>
                    <option value="yes">yes</option>
                    <option value="no">no</option>
                  </select>{" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  {!email && (
                    <TextField
                      type="text"
                      label="Enter Email"
                      name="email"
                      {...register("email")}
                      placeholder="Enter Email"
                      autoFocus
                      variant="standard"
                    />
                  )}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <TextField
                    type="text"
                    label="Enter Store Name"
                    name="storeName"
                    {...register("storeName")}
                    placeholder="Enter Store Name"
                    autoFocus
                    variant="standard"
                  />{" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <div>
                    <div
                      style={{
                        position: "absolute",
                        top: 60,
                        fontSize: "14px",
                        left: "20",
                      }}
                    >
                      From Date
                    </div>
                    <TextField
                      type="date"
                      sx={{ marginTop: "17px" }}
                      {...register("fromDate")}
                      autoFocus
                      variant="standard"
                    />
                  </div>{" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <div>
                    <div
                      style={{
                        position: "absolute",
                        top: 60,
                        fontSize: "14px",
                        left: "20",
                      }}
                    >
                      End Date
                    </div>
                    <TextField
                      type="date"
                      sx={{ marginTop: "17px" }}
                      {...register("endDate")}
                      autoFocus
                      variant="standard"
                    />
                  </div>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ width: "16%", height: "45px" }}
                    disabled={buttondisabled}
                  >
                    Submit
                  </Button>
                  &nbsp; &nbsp; &nbsp; &nbsp;
                  <Button
                    type="button"
                    variant="contained"
                    sx={{ width: "16%", height: "45px" }}
                    onClick={clearFilter}
                    disabled={buttondisabled}
                  >
                    Clear
                  </Button>
                </div>
              </form>
            </AccordionDetails>
          </Accordion>
        </Box>
        {rows && (
          <TransDupCustomTable
            data={filterList(rows, searchKey) || []}
            columns={columns}
          />
        )}
      </Box>
    </>
  );
}

export default ListTransaction;
