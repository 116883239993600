import appConfig from "../config/app.config";
import axios from "axios";
import {getUserToken } from "../libs/user";
import {
  getPureJSON,
  handleAPIError,
  startLoader,
  stopLoader,
} from "../libs/utils";

const getMerchantList = () => {
  startLoader();
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: appConfig.apiUrl.merchant,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader();
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader();
        reject(getPureJSON(error.response || error));
      });
  });
};
const getMerchantById = (id) => {
  startLoader(1);
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${appConfig.apiUrl.merchant}/${id}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader(1);
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader(1);
        handleAPIError(error.status);
        reject(getPureJSON(error.response || error));
      });
  });
};
const editMerchant = (id, obj) => {
  startLoader(1);
  return new Promise((resolve, reject) => {
    var data = JSON.stringify(Object.assign({ id: id }, obj));

    var config = {
      method: "post",
      url: `${appConfig.apiUrl.merchant}/save`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        stopLoader(1);
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader(1);
        reject(getPureJSON(error.response || error));
      });
  });
};
const addMerchant = (obj) => {
  startLoader(1);
  return new Promise((resolve, reject) => {
    const data = JSON.stringify(obj);

    const config = {
      method: "post",
      url: `${appConfig.apiUrl.merchant}/save`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        stopLoader(1);
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader(1);
        reject(getPureJSON(error.response || error));
      });
  });
};

const getMerchantTxnById = (id) => {
  startLoader(1);
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${appConfig.apiUrl.getmerchantMappingAuditList}/${id}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader(1);
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader(1);
        handleAPIError(error.status);
        reject(getPureJSON(error.response || error));
      });
  });
};

const getTxnStoreList = () => {
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${appConfig.apiUrl.getTxnsStoreslists}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(getPureJSON(error.response || error));
      });
  });
};

const editTxnMerchant = (id, obj) => {
  return new Promise((resolve, reject) => {
    const data = JSON.stringify(Object.assign({ id: id }, obj));

    const config = {
      method: "post",
      url: `${appConfig.apiUrl.updatetxns}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(getPureJSON(error.response || error));
      });
  });
};

export {
  getMerchantList,
  getMerchantById,
  editMerchant,
  addMerchant,
  getMerchantTxnById,
  getTxnStoreList,
  editTxnMerchant,
};
