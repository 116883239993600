import appConfig from "../config/app.config";
import axios from "axios";
import { getUserToken } from "../libs/user";
import {
  getPureJSON,
  handleAPIError,
  startLoader,
  stopLoader,
} from "../libs/utils";

const getFxRateData = () => {
  startLoader();
  return new Promise((resolve, reject) => {
    var config = {
      method: "get",
      url: appConfig.apiUrl.fxRateCampaignList,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader();
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader();
        reject(error);
      });
  });
};

const getFxRateCompareData = (data) => {
  const { start_time, end_time } = data;
  startLoader();
  return new Promise((resolve, reject) => {
    var config = {
      method: "get",
      url: `${appConfig.apiUrl.fxRateCompare}?start_time=${
        start_time ? start_time : ""
      }&end_time=${end_time ? end_time : ""}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader();
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader();
        reject(error);
      });
  });
};

const getFxRateDatabyId = (id) => {
  startLoader(1);
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${appConfig.apiUrl.fxRateCampaign}/${id}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader(1);
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader(1);
        handleAPIError(error.status);
        reject(getPureJSON(error.response || error));
      });
  });
};

const updateFxRateData = (obj) => {
  startLoader(1);
  const authToken = getUserToken();
  return new Promise((resolve, reject) => {
    var data = JSON.stringify(obj);

    var config = {
      method: "post",
      url: `${appConfig.apiUrl.fxRateCampaign}/save`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        stopLoader(1);
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader(1);
        reject(getPureJSON(error.response || error));
      });
  });
};

export {
  getFxRateData,
  getFxRateDatabyId,
  updateFxRateData,
  getFxRateCompareData,
};
