import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getTimesPrimeData,
  getTimesPrimeDataById as getTimesPrimeDataSingle,
} from "../../services/timesPrime";

export const getTimesPrimeDataList = createAsyncThunk(
  "reward/getTimesPrimeDataList",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await getTimesPrimeData();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getTimesPrimeDataById = createAsyncThunk(
  "reward/getTimesPrimeDataById",
  async (obj, { rejectWithValue }) => {
    try {
      const { id } = obj;
      const response = await getTimesPrimeDataSingle(id);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  list: {
    data: [],
    error: null,
    status: null,
  },
  single: {
    data: null,
    error: null,
    status: null,
  },
};

export const timesPrimeSlice = createSlice({
  name: "timesPrime",
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getTimesPrimeDataList.pending, (state, action) => {
        state.list = { ...state.list, status: "loading" };
      })
      .addCase(getTimesPrimeDataList.fulfilled, (state, action) => {
        state.list = {
          ...state.list,
          status: "succeeded",
          data: action.payload,
          error: null,
        };
      })
      .addCase(getTimesPrimeDataList.rejected, (state, action) => {
        state.list = {
          ...state.list,
          status: "failed",
          error: action.payload,
          data: [],
        };
      })
      .addCase(getTimesPrimeDataById.pending, (state, action) => {
        state.single = { ...state.single, status: "loading" };
      })
      .addCase(getTimesPrimeDataById.fulfilled, (state, action) => {
        state.single = {
          ...state.single,
          status: "succeeded",
          data: action.payload,
          error: null,
        };
      })
      .addCase(getTimesPrimeDataById.rejected, (state, action) => {
        state.single = {
          ...state.single,
          status: "failed",
          error: action.payload,
          data: [],
        };
      });
  },
});

export default timesPrimeSlice.reducer;
