import * as React from "react";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { updateaddCmsUser } from "../../services/user";
import Swal from "sweetalert2";
import { ROLES } from "../../constants";

function AddCmsUser(props) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const getSelectedRoles = (data) => {
    const selectedRoles = [];
    for (let role of ROLES) {
      if (data[role]) {
        selectedRoles.push(role);
      }
    }
    return selectedRoles;
  };

  useEffect(() => {
    reset({
      username: "",
      roles: [],
      email: "",
    });
  }, []);

  const updateCMSusers = (data) => {
    const selectedRoles = getSelectedRoles(data);
    if (!selectedRoles.length) {
      Swal.fire({
        title: "Oops",
        text: "Please select at least one role to create CMS user.",
        icon: "warning",
        confirmButtonText: "Ok",
      });
      return;
    }
    updateaddCmsUser({
      username: data.username,
      email: data.email,
      roles: selectedRoles,
      is_active: false,
    })
      .then((response) => {
        Swal.fire({
          title: "Success",
          text: "CMS user has been added successfully",
          icon: "success",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/app/list-cms-users");
          }
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Oops",
          text: error?.data?.error?.message || "Something went wrong",
          icon: "warning",
          confirmButtonText: "Ok",
        }).then((result) => {});
        throw error;
      });
  };

  return (
    <Box sx={{ ml: "4", pt: "14 !important", pb: "5", px: 3 }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "25px",
          marginTop: "20px",
          gap: "16px",
        }}
      >
        <Link to="/app/list-cms-users">
          <ArrowBackIcon
            fontSize="large"
            l
            color="secondary"
            sx={{ background: "#040721", p: 1, borderRadius: "50%" }}
          />
        </Link>
        <Typography
          variant="h5"
          component="h5"
          fontWeight={"bold"}
          color={"#3d3d3d"}
        >
          Add CMS User
        </Typography>
      </div>
      <Box sx={{ background: "#ffffff", p: 2, mb: 4, pb: 4 }}>
        <form onSubmit={handleSubmit(updateCMSusers)}>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none"
                  htmlFor="username"
                >
                  Username: <small className="text-danger fs-6">*</small>
                </label>
                <input
                  {...register("username", { required: true })}
                  className={`form-control ${
                    errors.username && "invalid-form-input"
                  }`}
                  type="text"
                  id="username"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none"
                  htmlFor="email"
                >
                  Email: <small className="text-danger fs-6">*</small>
                </label>
                <input
                  {...register("email", { required: true })}
                  className={`form-control ${
                    errors.email && "invalid-form-input"
                  }`}
                  type="text"
                  id="email"
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none"
                  htmlFor="roles"
                >
                  Roles: <small className="text-danger fs-6">*</small>
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            {ROLES.map((role) => (
              <div className="col-md-4">
                <div className="row">
                  <div className="col-md-6 d-flex align-items-center">
                    <label
                      className="form-check-label fw-bold text-secondary"
                      htmlFor="roles"
                      style={{ lineHeight: "1" }}
                    >
                      {role}
                    </label>
                  </div>
                  <div className="col-md-6">
                    <input
                      {...register(`${role}`)}
                      className="form-check-input"
                      type="checkbox"
                      id={role}
                      style={{ marginLeft: "35px" }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>

          <hr style={{ height: "0.5px", marginTop: "32px" }} />
          <div>
            <button
              type="submit"
              className="btn btn-primary py-2 px-5"
              name="save"
            >
              Save
            </button>
          </div>
        </form>
      </Box>
    </Box>
  );
}

export default AddCmsUser;
