import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getFxRateDatabyId as getFxRateDataSingle } from "../../services/fxRateCampaignService";

export const getFxRateDataById = createAsyncThunk(
  "app/fx-rate-campaign",
  async (obj, { rejectWithValue }) => {
    try {
      const { id } = obj;
      const response = await getFxRateDataSingle(id);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  list: {
    data: [],
    error: null,
    status: null,
  },
  single: {
    data: null,
    error: null,
    status: null,
  },
};

export const FxCampaignDataSlice = createSlice({
  name: "fxCampaignData",
  initialState: initialState,
  reducers: {
    setCompaignId: (state, action) => {
      state.campaign_id = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getFxRateDataById.pending, (state, action) => {
        state.single = { ...state.single, status: "loading" };
      })
      .addCase(getFxRateDataById.fulfilled, (state, action) => {
        state.single = {
          ...state.single,
          status: "succeeded",
          data: action.payload,
          error: null,
        };
      })
      .addCase(getFxRateDataById.rejected, (state, action) => {
        state.single = {
          ...state.single,
          status: "failed",
          error: action.payload,
          data: [],
        };
      });
  },
});
export const { setCompaignId } = FxCampaignDataSlice.actions;
export default FxCampaignDataSlice.reducer;
