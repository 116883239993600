import Modal from "@mui/material/Modal";


function AppModal({
  handleClose,
children
}) {
  return (
    <>
      <Modal open={true} onClose={handleClose}>

        {children}
      </Modal>
    </>
  );
}

export default AppModal;
