import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { Button, Container } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import CancelIcon from "@mui/icons-material/Cancel";

function EditRewardsFaq() {
  return (
    <>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        sx={{ position: "relative" }}
      >
        <Button
          variant="contained"
          color="secondary"
          component={Link}
          to="/app/list-card-sequence"
          sx={{ position: "absolute", right: "1%" }}
        >
          <CancelIcon fontSize="large" />
        </Button>
        <div style={{ marginBottom: "25px" }}>
          <Typography>Edit Card Sequence</Typography>
        </div>
        <div
          className="formDiv"
          style={{
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <InputLabel>Id:</InputLabel>
        </div>
        <hr></hr>
        <div
          className="formDiv"
          style={{
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <InputLabel>Big Card:</InputLabel>
          <input
            type="number"
            className="form-control"
            style={{ width: "30%" }}
          />
        </div>
        <hr></hr>
        <div
          className="formDiv"
          style={{
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <InputLabel>Medium Card:</InputLabel>
          <input
            type="number"
            className="form-control"
            style={{ width: "30%" }}
          />
        </div>
        <hr></hr>
        <div
          className="formDiv"
          style={{
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <InputLabel>Small Card:</InputLabel>
          <input
            type="number"
            className="form-control"
            style={{ width: "30%" }}
          />
        </div>
        <hr></hr>
        <div
          className="formDiv"
          style={{
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <InputLabel>Local Card:</InputLabel>
          <input
            type="number"
            className="form-control"
            style={{ width: "30%" }}
          />
        </div>
        <hr></hr>
        <div
          className="formDiv"
          style={{
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <InputLabel>Category Offer Card:</InputLabel>
          <input
            type="number"
            className="form-control"
            style={{ width: "30%" }}
          />
        </div>
        <hr></hr>
        <div
          className="formDiv"
          style={{
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <InputLabel>Category List Card:</InputLabel>
          <input
            type="number"
            className="form-control"
            style={{ width: "30%" }}
          />
        </div>
        <hr></hr>
        <div
          className="formDiv"
          style={{
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <InputLabel>Offer List Card:</InputLabel>
          <input
            type="number"
            className="form-control"
            style={{ width: "30%" }}
          />
        </div>
        <hr></hr>
        <div
          className="formDiv"
          style={{
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <InputLabel>Refferal Card:</InputLabel>
          <input
            type="number"
            className="form-control"
            style={{ width: "30%" }}
          />
        </div>
        <hr></hr>
        <div
          className="formDiv"
          style={{
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <InputLabel>Logo Card:</InputLabel>
          <input
            type="number"
            className="form-control"
            style={{ width: "30%" }}
          />
        </div>
        <hr></hr>
      </Box>

      <Container sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button variant="contained" sx={{ width: "25%" }}>
          Update
        </Button>
      </Container>
    </>
  );
}

export default EditRewardsFaq;
