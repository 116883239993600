import { useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  addRiskConfig,
  updateRiskConfig,
} from "../../../services/riskConfigurationService";
import { getRiskConfigById } from "../../../redux/slices/riskConfigSlice";
import { handleApiSuccess } from "../../../libs/utils";

function AddEditRiskConfiguration(props) {
  const riskConfigsData = useSelector((state) => state.riskConfigsData);
  const {
    single: { data },
  } = riskConfigsData || {};

  const { risk_profile: riskProfile } = useParams();
  const [searchParams] = useSearchParams();
  const sourceParam = searchParams.get("source")?.replace(/\ /g, "+");
  const { risk_profile } = data || {};

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (riskConfigsData) {
      if (riskProfile !== "0") {
        reset({
          ...data,
        });
      } else {
        reset({
          risk_profile: "",
          remittance_delay_days: "",
          banking_delay_days: "",
          daily_ach_limit: "",
          monthly_ach_limit: "",
          yearly_ach_limit: "",
          daily_ach_out_limit: "",
          monthly_ach_out_limit: "",
          yearly_ach_out_limit: "",
          ach_allowed: "",
          instant_ach_allowed: "",
          title: "",
          description: "",
          debit_card_allowed: "",
          debit_card_allow_foreign_transactions: "",
          daily_cash_limit: "",
          daily_transaction_limit: "",
          ach_out_allowed: "",
        });
      }
    }
  }, [riskConfigsData]);

  useEffect(() => {
    dispatch(
      getRiskConfigById({
        riskProfile,
        sourceParam,
      })
    );
  }, []);

  const updateAppConfigs = (data) => {
    if (riskProfile !== "0") {
      updateRiskConfig(
        {
          ...data,
        },
        sourceParam
      )
        .then((response) => {
          handleApiSuccess(
            response.success,
            "Risk configuration updated successfully"
          );
          navigate(
            sourceParam === "BANKING"
              ? "/app/neobank-risk-configuration"
              : "/app/risk-configuration"
          );
        })
        .catch((error) => {
          throw error;
        });
    } else {
      addRiskConfig(
        {
          ...data,
        },
        sourceParam
      )
        .then((response) => {
          handleApiSuccess(
            response.success,
            "Risk configuration added successfully"
          );
          navigate("/app/risk-configuration");
        })
        .catch((error) => {
          throw error;
        });
    }
  };

  return (
    <>
      <Box sx={{ ml: "4", pt: "14 !important", pb: "5", px: 3 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "25px",
            marginTop: "20px",
            gap: "16px",
          }}
        >
          <Link
            to={
              sourceParam === "BANKING"
                ? "/app/neobank-risk-configuration"
                : "/app/risk-configuration"
            }
          >
            <ArrowBackIcon
              fontSize="large"
              l
              color="secondary"
              sx={{ background: "#040721", p: 1, borderRadius: "50%" }}
            />
          </Link>
          <Typography
            variant="h5"
            component="h5"
            fontWeight={"bold"}
            color={"#3d3d3d"}
          >
            {riskProfile !== "0"
              ? "Edit Risk Configuration"
              : "Add Risk Configuration"}
          </Typography>
        </div>
        <Box sx={{ background: "#ffffff", p: 2, mb: 4, pb: 4 }}>
          <form onSubmit={handleSubmit(updateAppConfigs)}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="risk_profile"
                  >
                    Risk Profile:
                    {risk_profile ? (
                      <input
                        defaultValue={risk_profile}
                        {...register("risk_profile")}
                        type="hidden"
                      />
                    ) : (
                      <small className="text-danger fs-6">*</small>
                    )}
                  </label>
                  <input
                    {...register("risk_profile", { required: true })}
                    className={`form-control ${
                      errors.risk_profile && "invalid-form-input"
                    }`}
                    type="number"
                    min={1}
                    max={9}
                    id="risk_profile"
                    readonly={riskProfile !== "0" ? "readonly" : null}
                  />
                </div>
              </div>
              {sourceParam === "REMITTANCE" && (
                <div className="col-md-6">
                  <div className="form-group">
                    <label
                      className="form-label fw-bold text-secondary user-select-none"
                      htmlFor="remittance_delay_days"
                    >
                      Remittance Delay Days:{" "}
                      <small className="text-danger fs-6">*</small>
                    </label>
                    <input
                      {...register("remittance_delay_days", { required: true })}
                      className={`form-control ${
                        errors.value && "invalid-form-input"
                      }`}
                      type="number"
                      id="remittance_delay_days"
                    />
                  </div>
                </div>
              )}
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="value"
                  >
                    Banking Delay Days:{" "}
                    <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("banking_delay_days", { required: true })}
                    className={`form-control ${
                      errors.value && "invalid-form-input"
                    }`}
                    type="number"
                    id="banking_delay_days"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="daily_ach_limit"
                  >
                    Daily ACH Limit:{" "}
                    <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("daily_ach_limit", { required: true })}
                    className={`form-control ${
                      errors.daily_ach_limit && "invalid-form-input"
                    }`}
                    type="decimal"
                    id="daily_ach_limit"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="monthly_ach_limit"
                  >
                    Monthly ACH Limit:{" "}
                    <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("monthly_ach_limit", { required: true })}
                    className={`form-control ${
                      errors.monthly_ach_limit && "invalid-form-input"
                    }`}
                    type="decimal"
                    id="monthly_ach_limit"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="yearly_ach_limit"
                  >
                    Yearly ACH Limit:{" "}
                    <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("yearly_ach_limit", { required: true })}
                    className={`form-control ${
                      errors.yearly_ach_limit && "invalid-form-input"
                    }`}
                    type="decimal"
                    id="yearly_ach_limit"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="daily_ach_out_limit"
                  >
                    Daily AchOut Limit:{" "}
                    <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("daily_ach_out_limit", { required: true })}
                    className={`form-control ${
                      errors.daily_ach_out_limit && "invalid-form-input"
                    }`}
                    type="decimal"
                    id="daily_ach_out_limit"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="monthly_ach_out_limit"
                  >
                    Monthly AchOut Limit:{" "}
                    <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("monthly_ach_out_limit", { required: true })}
                    className={`form-control ${
                      errors.monthly_ach_out_limit && "invalid-form-input"
                    }`}
                    type="decimal"
                    id="monthly_ach_out_limit"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="yearly_ach_out_limit"
                  >
                    Yearly AchOut Limit:{" "}
                    <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("yearly_ach_out_limit", { required: true })}
                    className={`form-control ${
                      errors.yearly_ach_out_limit && "invalid-form-input"
                    }`}
                    type="decimal"
                    id="yearly_ach_out_limit"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="ach_allowed"
                  >
                    Ach Allowed:
                  </label>
                  <select
                    {...register("ach_allowed")}
                    className={`form-control ${
                      errors.ach_allowed && "invalid-form-input"
                    }`}
                    type="select"
                    id="ach_allowed"
                  >
                    <option value="">Select</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="instant_ach_allowed"
                  >
                    Instant Ach Allowed:
                  </label>
                  <select
                    {...register("instant_ach_allowed")}
                    className={`form-control ${
                      errors.instant_ach_allowed && "invalid-form-input"
                    }`}
                    type="select"
                    id="instant_ach_allowed"
                  >
                    <option value="">Select</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="title"
                  >
                    Title:
                  </label>
                  <input
                    {...register("title")}
                    className={`form-control ${
                      errors.title && "invalid-form-input"
                    }`}
                    type="text"
                    id="title"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="description"
                  >
                    Description:
                  </label>
                  <input
                    {...register("description")}
                    className={`form-control ${
                      errors.description && "invalid-form-input"
                    }`}
                    type="text"
                    id="description"
                  />
                </div>
              </div>
              {sourceParam === "BANKING" && (
                <>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label
                        className="form-label fw-bold text-secondary user-select-none"
                        htmlFor="debit_card_daily_cash_limit"
                      >
                        Daily Cash Limit:
                      </label>
                      <input
                        {...register("debit_card_daily_cash_limit")}
                        className={`form-control ${
                          errors.debit_card_daily_cash_limit &&
                          "invalid-form-input"
                        }`}
                        type="decimal"
                        id="debit_card_daily_cash_limit"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label
                        className="form-label fw-bold text-secondary user-select-none"
                        htmlFor="debit_card_allowed"
                      >
                        Debit Card Allowed:
                      </label>
                      <select
                        {...register("debit_card_allowed")}
                        className={`form-control ${
                          errors.debit_card_allowed && "invalid-form-input"
                        }`}
                        type="select"
                        id="debit_card_allowed"
                      >
                        <option value="">Select</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label
                        className="form-label fw-bold text-secondary user-select-none"
                        htmlFor="daily_card_transaction_limit"
                      >
                        Daily Transaction Limit:
                      </label>
                      <input
                        {...register("daily_card_transaction_limit")}
                        className={`form-control ${
                          errors.daily_card_transaction_limit &&
                          "invalid-form-input"
                        }`}
                        type="decimal"
                        id="daily_card_transaction_limit"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label
                        className="form-label fw-bold text-secondary user-select-none"
                        htmlFor="debit_card_allow_foreign_transactions"
                      >
                        Foreign Transactions:
                      </label>
                      <select
                        {...register("debit_card_allow_foreign_transactions")}
                        className={`form-control ${
                          errors.debit_card_allow_foreign_transactions &&
                          "invalid-form-input"
                        }`}
                        type="select"
                        id="debit_card_allow_foreign_transactions"
                      >
                        <option value="">Select</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label
                        className="form-label fw-bold text-secondary user-select-none"
                        htmlFor="ach_out_allowed"
                      >
                        Ach Out Allowed:
                      </label>
                      <select
                        {...register("ach_out_allowed")}
                        className={`form-control ${
                          errors.ach_out_allowed && "invalid-form-input"
                        }`}
                        type="select"
                        id="ach_out_allowed"
                      >
                        <option value="">Select</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    </div>
                  </div>
                </>
              )}
            </div>
            <hr style={{ height: "0.5px", marginTop: "32px" }} />
            <div>
              <button
                type="submit"
                className="btn btn-primary py-2 px-5"
                name="save"
                variant="contained"
                sx={{ width: "25%" }}
              >
                {riskProfile !== "0" ? "Save or Update" : "Save"}
              </button>
            </div>
          </form>
        </Box>
      </Box>
    </>
  );
}

export default AddEditRiskConfiguration;
