import update from "immutability-helper";
import { Component } from "react";
import { Card } from "./Card.js";
const style = {
  width: "100%",
};

export class Container extends Component {
  requestedFrame;
  constructor(props) {
    super(props);

    this.state = STATE;
  }

  componentDidMount() {
    this.timer0 = setTimeout(() => {
      this.setState({ cardState: this.props.dataList });
    }, 2000);
  }

  componentDidUpdate() {
  }

  componentWillUnmount() {
    clearTimeout(this.timer0);
    if (this.requestedFrame !== undefined) {
      cancelAnimationFrame(this.requestedFrame);
    }
  }

  render() {
    const { cardsByIndex } = this.state?.cardState || {};
    return (
      <>
        {!cardsByIndex && (
          <>
            <span style={{ color: "#969696" }}>Loading... </span>
            <i
              style={{ color: "#969696" }}
              className={`fa fa-circle-o-notch ${true ? "fa-spin" : ""}`}
              aria-hidden="true"
            ></i>
          </>
        )}

        {cardsByIndex?.length === 0 && (
          <>
            <span style={{ color: "#969696" }}>No data found</span>
          </>
        )}

        <div style={style}>
          {(cardsByIndex || []).map((card) => (
            <Card
              key={card.id}
              id={card.id}
              text={card.text}
              moveCard={this.moveCard}
            />
          ))}
        </div>
      </>
    );
  }
  moveCard = (id, afterId) => {
    const { cardsById, cardsByIndex } = this.state.cardState;
    const card = cardsById[id];
    const afterCard = cardsById[afterId];
    const cardIndex = cardsByIndex.indexOf(card);
    const afterIndex = cardsByIndex.indexOf(afterCard);
    const cardState = update(this.state.cardState, {
      cardsByIndex: {
        $splice: [
          [cardIndex, 1],
          [afterIndex, 0, card],
        ],
      },
    });
    this.setState({
      cardState: cardState,
    });
    this.props.getSortedData(cardState);
    this.scheduleUpdate();
  };
  scheduleUpdate() {
    if (!this.requestedFrame) {
      this.requestedFrame = requestAnimationFrame(this.drawFrame);
    }
  }
  drawFrame = () => {
    this.setState(STATE);
    this.requestedFrame = undefined;
  };
}
const STATE = {};
