import RiskConfigurationList from "./RiskConfigurationList";

function NeobankRiskConfigurationList() {
    const nbRiskColums = [
        {
            id: "risk_profile",
            label: "Risk Profile",
            minWidth: 20,
            enableSort: true,
          },
          {
            id: "banking_delay_days",
            label: "Banking Delay Days",
            minWidth: 35,
            align: "left",
          },
          {
            id: "daily_ach_limit",
            label: "Daily ACH Limit",
            minWidth: 85,
            align: "left",
          },
          {
            id: "monthly_ach_limit",
            label: "Monthly Ach Limit",
            minWidth: 35,
            align: "left",
          },
          {
            id: "yearly_ach_limit",
            label: "Yearly Ach Limit",
            minWidth: 35,
            align: "left",
          },
          {
            id: "daily_ach_out_limit",
            label: "Daily AchOut Limit",
            minWidth: 35,
            align: "left",
          },
          {
            id: "monthly_ach_out_limit",
            label: "Monthly AchOut Limit",
            minWidth: 35,
            align: "left",
          },
          {
            id: "yearly_ach_out_limit",
            label: "Yearly AchOut Limit",
            minWidth: 35,
            align: "left",
          },
          {
            id: "ach_allowed",
            label: "ACH Allowed",
            minWidth: 35,
            align: "left",
          },
          {
            id: "instant_ach_allowed",
            label: "Instant ACH Allowed",
            minWidth: 35,
            align: "left",
          },
          {
            id: "debit_card_daily_cash_limit",
            label: "Daily Cash Limit",
            minWidth: 35,
            align: "left",
          },
          {
            id: "daily_card_transaction_limit",
            label: "Daily Transaction Limit",
            minWidth: 35,
            align: "left",
          },
          {
            id: "debit_card_allowed",
            label: "Debit Card Allowed",
            minWidth: 35,
            align: "left",
          },
          {
            id: "debit_card_allow_foreign_transactions",
            label: "Foreign Transactions",
            minWidth: 35,
            align: "left",
          },
          {
            id: "ach_out_allowed",
            label: "ACH Out Allowed",
            minWidth: 35,
            align: "left",
          },
          {
            id: "title",
            label: "Title",
            minWidth: 35,
            align: "left",
          },
          {
            id: "description",
            label: "Description",
            minWidth: 45,
            align: "left",
          },
          {
            id: "Action",
            label: "Action",
            minWidth: 100,
            align: "left",
          }
    ]
  return (
    <>
      <RiskConfigurationList nbRiskColums={nbRiskColums} source="BANKING"/>
    </>
  );
}

export default NeobankRiskConfigurationList;
