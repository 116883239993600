import React from "react";
import { Navigate } from "react-router-dom";
import { checkAuth } from "../../libs/auth";

const Protected = ({ children }) => {
  const isLoggedIn = checkAuth();
  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

export default Protected;
