import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  editTxnMerchant,
  getMerchantTxnById,
  getTxnStoreList,
} from "../../../services/merchants";
import { ToastContainer } from "react-toastify";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Swal from "sweetalert2";
import { startLoader, stopLoader } from "../../../libs/utils";
import Select from "react-select";

function EditMerchantTransactionMapping() {
  const navigation = useNavigate();
  const { id } = useParams();
  const [response, setResponse] = useState({});
  const [storeList, setStoreList] = useState([]);
  const [storeError, setStoreError] = useState(null);
  const [storeName, setStoreName] = useState(null);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const updateTxnMerchant = (data) => {
    setStoreError(null);
    data.merchantId = null;
    data.newStoreFlow = null;
    data.businessStatus = null;
    data.name = null;
    data.types = null;
    data.keywords = null;

    if (data.storeId === "" || !data.storeId) {
      setStoreError("Please select store");
      return false;
    }

    startLoader(1);
    editTxnMerchant(id, data)
      .then((response) => {
        stopLoader(1);
        if (response.status === "Please select Approve/Reject ") {
          Swal.fire({
            title: "Oops",
            text: response.status,
            icon: "warning",
            confirmButtonText: "Ok",
          }).then((result) => {});
        } else if (response.status === "Transaction Not Found") {
          Swal.fire({
            title: "Oops",
            text: response.status,
            icon: "warning",
            confirmButtonText: "Ok",
          }).then((result) => {});
        } else {
          Swal.fire({
            title: "Success",
            text: response.status,
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              navigation(-1);
            }
          });
        }
      })
      .catch((error) => {
        stopLoader(1);
        if (error.status === 404) {
          Swal.fire({
            title: "Oops",
            text: "Not found",
            icon: "warning",
            confirmButtonText: "Ok",
          }).then((result) => {});
        } else {
          Swal.fire({
            title: "Oops",
            text: "Something went wrong",
            icon: "warning",
            confirmButtonText: "Ok",
          }).then((result) => {});
        }
        throw error;
      });
  };

  useEffect(() => {
    if (id) {
      getMerchantTxnById(id)
        .then((response) => {
          setResponse(response);

          if (response) {
            reset({
              transactionStatus: response.transactionStatus,
            });
          }
        })
        .catch((error) => {
          throw error;
        });

      getTxnStoreList()
        .then((response) => {
          setStoreList(response);
        })
        .catch((error) => {
          throw error;
        });
    }
  }, [id]);

  useEffect(() => {
    reset({
      storeId: "",
    });
    if (response && storeList) {
      // reset({
      //   storeId: response.storeId,
      // });
    }
    if (storeList.length > 0 && response.storeId) {
      const store1 = storeList.filter((store) => store.id === response.storeId);

      if (store1) {
        setStoreName(`${store1[0].name} | ${store1[0].id}`);
        reset({
          storeId: response.storeId,
        });
      }
    }
  }, [response, storeList]);

  useEffect(() => {
    if (watch("storeId") != "" || !watch("storeId")) {
      setStoreError(null);
    }
  }, [watch("storeId")]);

  return (
    <>
      <Box sx={{ ml: "4", pt: "14 !important", pb: "5", px: 3 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "25px",
            marginTop: "20px",
            gap: "16px",
          }}
        >
          <button className="btn btn-sm" onClick={() => navigation(-1)}>
            <ArrowBackIcon
              fontSize="large"
              color="secondary"
              sx={{ background: "#040721", p: 1, borderRadius: "50%" }}
            />
          </button>

          <Typography
            variant="h5"
            component="h5"
            fontWeight={"bold"}
            color={"#3d3d3d"}
          >
            Edit Transaction
          </Typography>
        </div>
        <Box sx={{ background: "#ffffff", p: 2, mb: 4, pb: 4 }}>
          <form onSubmit={handleSubmit(updateTxnMerchant)}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="businessStatus"
                  >
                    Transaction id
                  </label>
                  <input
                    className={`form-control `}
                    type="text"
                    id="businessStatus"
                    defaultValue={response.transactionId}
                    readOnly
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="transName"
                  >
                    Transaction Name
                  </label>
                  <input
                    className={`form-control `}
                    type="text"
                    id="transName"
                    defaultValue={response.transactionName}
                    readOnly
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="transMer"
                  >
                    TransactionMerchantName
                  </label>
                  <input
                    className={`form-control `}
                    type="text"
                    id="transMer"
                    defaultValue={response.transactionMerchantName}
                    readOnly
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="mapped"
                  >
                    MappedStoreName:
                  </label>
                  <input
                    className={`form-control `}
                    type="text"
                    id="mapped"
                    defaultValue={response.mappedMerchantName}
                    readOnly
                  />
                </div>
              </div>
              {/* //
               */}

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="partialRatio"
                  >
                    PartialRatioScore:
                  </label>
                  <input
                    className={`form-control `}
                    type="text"
                    id="partialRatio"
                    defaultValue={response.partialRatioScore}
                    readOnly
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="ratioScore"
                  >
                    RatioScore:
                  </label>
                  <input
                    className={`form-control `}
                    type="text"
                    id="ratioScore"
                    defaultValue={response.ratioScore}
                    readOnly
                  />
                </div>
              </div>
              {/* // */}

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="cityName"
                  >
                    TransactionStatus:
                  </label>
                  <select
                    {...register("transactionStatus", { required: true })}
                    className={`form-control ${
                      errors.transactionStatus ? "inputError" : ""
                    }`}
                  >
                    <option value="MANUALLY">MANUALLY</option>
                    <option value="ACCEPTED">ACCEPTED</option>
                    <option value="REJECTED">REJECTED</option>
                  </select>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="userRatingsTotal"
                  >
                    InsertEpochMillis:
                  </label>
                  <input
                    defaultValue={response.insertEpochMillis}
                    className={`form-control`}
                    type="text"
                    id="userRatingsTotal"
                    readOnly
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="fallBAckPartialScoreMerchantName"
                  >
                    fallBAckPartialScoreMerchantName:
                  </label>
                  <input
                    defaultValue={response.fallBAckPartialScoreMerchantName}
                    className={`form-control`}
                    type="text"
                    id="fallBAckPartialScoreMerchantName"
                    readOnly
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="fallBAckPartialRatio"
                  >
                    FallBAckPartialRatio:
                  </label>
                  <input
                    defaultValue={response.fallBAckPartialRatio}
                    className={`form-control`}
                    type="text"
                    id="fallBAckPartialRatio"
                    readOnly
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="fallbackRatioScore"
                  >
                    FallbackRatioScore:
                  </label>
                  <input
                    defaultValue={response.fallbackRatioScore}
                    className={`form-control`}
                    type="text"
                    id="fallbackRatioScore"
                    readOnly
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="fallBackRatioScoreMerchantName"
                  >
                    FallBackRatioScoreMerchantName:
                  </label>
                  <input
                    defaultValue={response.fallBackRatioScoreMerchantName}
                    className={`form-control`}
                    type="text"
                    id="fallBackRatioScoreMerchantName"
                    readOnly
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="rating"
                  >
                    Store:
                  </label>
                </div>
              </div>
              <div className="col-md-12">
                {storeName && (
                  <Select
                    className={`basic-single ${storeError ? "inputError" : ""}`}
                    classNamePrefix="select"
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    defaultValue={{
                      label: storeName,
                      value: response.storeId,
                    }}
                    name="storeId"
                    onChange={(val) => {
                      if (val !== null) {
                        reset({ storeId: val.value });
                      }
                    }}
                    options={[
                      {
                        label: `Select`,
                        value: "",
                      },
                      ...storeList.map((store) => {
                        return {
                          label: `${store.name} | ${store.id}`,
                          value: store.id,
                        };
                      }),
                    ]}
                  />
                )}
                {storeName === null && (
                  <Select
                    className={`basic-single ${storeError ? "inputError" : ""}`}
                    classNamePrefix="select"
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    name="storeId"
                    onChange={(val) => {
                      if (val !== null) {
                        reset({ storeId: val.value });
                      }
                    }}
                    options={[
                      {
                        label: `Select`,
                        value: "",
                      },
                      ...storeList.map((store) => {
                        return {
                          label: `${store.name} | ${store.id}`,
                          value: store.id,
                        };
                      }),
                    ]}
                  />
                )}
              </div>
            </div>
            <hr style={{ height: "0.5px", marginTop: "32px" }} />
            <div>
              <button
                type="submit"
                className="btn btn-primary py-2 px-5"
                name="save"
              >
                Save or Update
              </button>
            </div>
          </form>
        </Box>
      </Box>
      <ToastContainer />
    </>
  );
}

export default EditMerchantTransactionMapping;
