import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import moment from "moment";
import Swal from "sweetalert2";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  updateFxRateData,
  getFxRateDatabyId,
} from "../../../services/fxRateCampaignService";
import { setCompaignId } from "../../../redux/slices/fxRateCampaignSlice";

function AddEditFxCampaign() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();

  const fxCampaignData = useSelector((state) => state.fxCampaignData);
  const { campaign_id } = fxCampaignData;
  const [errorMsg, setErrorMsg] = useState("");

  const navigation = useNavigate();
  const [savedResponse, setSavedResponse] = useState({});

  useEffect(() => {
    if (campaign_id) {
      getFxRateDatabyId(campaign_id)
        .then((response) => {
          const { start_time, end_time, active } = response.fxRateCampaign;
          if (response) {
            reset({
              ...response.fxRateCampaign,
              start_time: start_time
                ? moment(start_time).local().format("YYYY-MM-DD HH:mm")
                : null,
              end_time: end_time
                ? moment(end_time).local().format("YYYY-MM-DD HH:mm")
                : null,
            });
          }
        })
        .catch((error) => {
          throw error;
        });
    }
    return () => {
      dispatch(setCompaignId(""));
      setErrorMsg("");
    };
  }, [campaign_id]);

  const updateFxCampaignData = (data) => {
    const buttonType = window.event.submitter.name;
    updateFxRateData({
      ...data,
      start_time: moment(data.start_time).format("x"),
      end_time: moment(data.end_time).format("x"),
      value: Number(data.value),
      priority: Number(data.priority),
      max_campaign_count: Number(data.max_campaign_count),
      users_availed_count: Number(data.users_availed_count),
    })
      .then((response) => {
        setSavedResponse(response);
        if (response?.error?.code === "RE_037") {
          setErrorMsg(response.error.message);
        } else {
          setErrorMsg("");
          Swal.fire({
            title: "Success",
            text: "Campaign Data has been saved successfully",
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              if (buttonType === "save") {
                navigation(-1);
              }
              if (buttonType === "savegoanother") {
                reset({
                  campaign_name: "",
                  campaign_type: "",
                  campaign_id: "",
                  campaign_fx_rate_type: "",
                  fx_rate_eligibility: "",
                  value: "",
                  start_time: "",
                  end_time: "",
                  active: false,
                  max_campaign_count: "",
                  users_availed_count: "",
                  min_amount: "",
                  max_amount: "",
                  priority: "",
                  current_fx_rate_margin: "",
                  effective_fx_rate_margin: "",
                  transaction_default_value: "",
                });
                navigation("/app/fx-rate-campaign/0");
              }
              if (campaign_id) {
                if (buttonType === "savecontinue") {
                  const ids = response.data.campaign_id;
                  navigation(`/app/fx-rate-campaign/${ids}`);
                }
              }
            }
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Oops",
          text: "Something went wrong",
          icon: "warning",
          confirmButtonText: "Ok",
        }).then((result) => {});
        throw error;
      });
  };

  return (
    <>
      <Box sx={{ ml: "4", pt: "14 !important", pb: "5", px: 3 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "25px",
            marginTop: "20px",
            gap: "16px",
          }}
        >
          <Link to="/app/fx-rate-campaign">
            <ArrowBackIcon
              fontSize="large"
              l
              color="secondary"
              sx={{ background: "#040721", p: 1, borderRadius: "50%" }}
            />
          </Link>

          <Typography
            variant="h5"
            component="h5"
            fontWeight={"bold"}
            color={"#3d3d3d"}
          >
            {campaign_id ? "Edit Campaign Data" : "Add Campaign Data"}
          </Typography>
        </div>
        <Box sx={{ background: "#ffffff", p: 2, mb: 4, pb: 4 }}>
          <form onSubmit={handleSubmit(updateFxCampaignData)}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="campaign_name"
                  >
                    Campaign Name: <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("campaign_name", { required: true })}
                    className={`form-control ${
                      errors.name && "invalid-form-input"
                    }`}
                    type="text"
                    id="campaign_name"
                  />
                </div>
              </div>

              {campaign_id && (
                <div className="col-md-6">
                  <div className="form-group">
                    <label
                      className="form-label fw-bold text-secondary user-select-none"
                      htmlFor="campaign_id"
                    >
                      Campaign Id: <small className="text-danger fs-6">*</small>
                    </label>
                    <input
                      {...register("campaign_id")}
                      className={`form-control ${
                        errors.name && "invalid-form-input"
                      }`}
                      id="campaign_id"
                      type="text"
                      disabled
                    />
                    {errorMsg && (
                      <div className="text-danger fs-6">{errorMsg}</div>
                    )}
                  </div>
                </div>
              )}
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="campaign_type"
                  >
                    Campaign Type: <small className="text-danger fs-6">*</small>
                  </label>
                  <select
                    {...register("campaign_type", { required: true })}
                    className={`form-control ${
                      errors.categoryType && "invalid-form-input"
                    }`}
                    type="select"
                    id="campaign_type"
                  >
                    <option value="">Select</option>
                    <option value="PROMOTIONAL">PROMOTIONAL</option>
                    <option value="GUARANTEED_EXCHANGE_RATE">
                      GUARANTEED_EXCHANGE_RATE
                    </option>
                  </select>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="fx_rate_eligibility"
                  >
                    FX Rate Eligibility:{" "}
                    <small className="text-danger fs-6">*</small>
                  </label>
                  <select
                    {...register("fx_rate_eligibility", { required: true })}
                    className={`form-control ${
                      errors.categoryType && "invalid-form-input"
                    }`}
                    type="select"
                    id="fx_rate_eligibility"
                  >
                    <option value="">Select</option>
                    <option value="ONCE_IN_LIFETIME">ONCE_IN_LIFETIME</option>
                    <option value="ALL_TRANSACTIONS">ALL_TRANSACTIONS</option>
                  </select>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="campaign_fx_rate_type"
                  >
                    Campaign FX Rate Type :{" "}
                    <small className="text-danger fs-6">*</small>
                  </label>
                  <select
                    {...register("campaign_fx_rate_type", { required: true })}
                    className={`form-control ${
                      errors.categoryType && "invalid-form-input"
                    }`}
                    type="select"
                    id="campaign_fx_rate_type"
                  >
                    <option value="">Select</option>
                    <option value="DEFAULT_EXCHANGE_RATE">
                      DEFAULT_EXCHANGE_RATE
                    </option>
                    <option value="FIXED_EXCHANGE_RATE">
                      FIXED_EXCHANGE_RATE
                    </option>
                    <option value="VARIABLE_EXCHANGE_RATE">
                      VARIABLE_EXCHANGE_RATE
                    </option>
                  </select>
                </div>
              </div>
              {/* <div className="col-md-12"> */}

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="value"
                  >
                    Value:
                  </label>
                  <input
                    {...register("value", {
                      pattern: /[0-9]+/,
                    })}
                    className={`form-control ${
                      errors.value && "invalid-form-input"
                    }`}
                    type="decimal"
                    id="value"
                  />
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary"
                    htmlFor="priority"
                  >
                    Priority
                  </label>
                  <input
                    {...register("priority", {
                      pattern: /[0-9]+/,
                    })}
                    className={`form-control`}
                    type="number"
                    id="priority"
                    min={0}
                  />
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <div className="form-groupform-check d-flex align-items-center gap-3  mt-5">
                    <input
                      {...register("active")}
                      className={`form-check-input ${
                        errors.isActive && "invalid-form-input"
                      }`}
                      type="checkbox"
                      id="active"
                    />
                    <label
                      className="form-check-label fw-bold text-secondary"
                      htmlFor="active"
                      style={{ lineHeight: "1" }}
                    >
                      Is Active
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="start_time"
                  >
                    Start Time: <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("start_time", { required: true })}
                    className={`form-control`}
                    type="datetime-local"
                    id="start_time"
                  />
                </div>
              </div>
              {/* </div> */}
              <div className="col-md-6 ">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="end_time"
                  >
                    End Time: <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("end_time", { required: true })}
                    className={`form-control`}
                    type="datetime-local"
                    id="end_time"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary"
                    htmlFor="max_campaign_count"
                  >
                    Max Campaign Count:
                    <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("max_campaign_count", {
                      pattern: /[0-9]+/,
                      required: true,
                    })}
                    className={`form-control`}
                    type="number"
                    id="max_campaign_count"
                    min={0}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary"
                    htmlFor="users_availed_count"
                  >
                    Users Availed Count
                  </label>
                  <input
                    {...register("users_availed_count", {
                      pattern: /[0-9]+/,
                    })}
                    className={`form-control`}
                    type="number"
                    id="users_availed_count"
                    min={0}
                  />
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary"
                    htmlFor="min_amount"
                  >
                    Min Amount: <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("min_amount", {
                      pattern: /^\d*\.?\d*$/,
                      required: true,
                    })}
                    className={`form-control`}
                    type="decimal"
                    id="min_amount"
                  />
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary"
                    htmlFor="max_amount"
                  >
                    Max Amount: <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("max_amount", {
                      pattern: /^\d*\.?\d*$/,
                      required: true,
                    })}
                    className={`form-control`}
                    type="decimal"
                    id="max_amount"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary"
                    htmlFor="transaction_default_value"
                  >
                    Transaction Default Value
                  </label>
                  <input
                    {...register("transaction_default_value", {
                      pattern: /^\d*\.?\d*$/,
                    })}
                    className={`form-control`}
                    type="decimal"
                    id="transaction_default_value"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary"
                    htmlFor="current_fx_rate_margin"
                  >
                    Current FX rate Margin
                  </label>
                  <input
                    {...register("current_fx_rate_margin", {
                      pattern: /^\d*\.?\d*$/,
                    })}
                    className={`form-control`}
                    type="decimal"
                    id="current_fx_rate_margin"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary"
                    htmlFor="effective_fx_rate_margin"
                  >
                    Effective FX rate Margin
                  </label>
                  <input
                    {...register("effective_fx_rate_margin", {
                      pattern: /^\d*\.?\d*$/,
                    })}
                    className={`form-control`}
                    type="decimal"
                    id="effective_fx_rate_margin"
                  />
                </div>
              </div>
            </div>
            <hr style={{ height: "0.5px", marginTop: "32px" }} />
            <div>
              <button
                type="submit"
                className="btn btn-primary py-2 px-5"
                name="save"
                variant="contained"
                sx={{ width: "25%" }}
              >
                Save
              </button>
              <button
                variant="contained"
                type="submit"
                className="btn btn-primary py-2 px-5"
                name="savegoanother"
                sx={{ width: "25%" }}
              >
                Save And Add Another
              </button>
              <button
                variant="contained"
                type="submit"
                className="btn btn-primary py-2 px-5"
                name="savecontinue"
                sx={{ width: "25%" }}
              >
                Save and Continue Editing
              </button>
            </div>
          </form>
        </Box>
      </Box>
    </>
  );
}

export default AddEditFxCampaign;
