import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useState } from "react";

import localforage from "localforage";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CustomTable from "../../../components/CustomTable";
import SearchIcon from "@mui/icons-material/Search";
import { TextField, Menu } from "@mui/material";
import { filterList } from "../../../libs/utils";
import { getRemittanceBlockedUsers } from "../../../services/blockedUsersService";

function EditBlockedUser() {
  const { email } = useParams();
  console.log(email, "email");
  const [isDynamicTable, setIsDynamicTable] = useState(false);
  const [rows, setRows] = useState();
  const [loading, setLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const columns = [
    { id: "transaction_ref_id", label: "Transaction Ref Id", minWidth: 250 },
    { id: "ach_completed_time", label: "ACH Completed Time", minWidth: 80 },
    { id: "ach_initiated_time", label: "ACH Initiated Time" },
    { id: "amount_inr", label: "INR Amount", minWidth: 60 },
    { id: "amount_usd", label: "USD Amount", minWidth: 60 },
    { id: "cash_in_status", label: "Cash-In Status", minWidth: 100 },
    { id: "cash_out_status", label: "Cash-Out Status", minWidth: 100 },
  ];

  const handleClose = () => {
    setAnchorEl(null);
  };
  const setDynamicTable = (parentWidth, tableWidth) => {
    setIsDynamicTable(true);
  };

  const searchData = (e) => {
    setSearchKey(e.target.value);
  };

  useEffect(() => {
    localforage.getItem("@blockedUsers", (error, data) => {
      if (data) {
        if (data?.length) {
          setRows(data);
        } else {
          setRows([]);
        }
      }
    });
    getRemittanceBlockedUsers()
      .then((response) => {
        if (response) {
          if (response?.length) {
            const filteredCurrentTxn = response.filter((data) => data.email === email);
            setRows(filteredCurrentTxn[0].pending_transactions);
          } else {
            setRows([]);
          }
          localforage.setItem("@blockedUsers", response, (error, data) => {
            if (error) {
              throw error;
            }
          });
        }
      })
      .catch((error) => {
        throw error;
      });
  }, []);

  return (
    <Box sx={{ ml: "4", pt: "14 !important", pb: "5", px: 3 }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "25px",
          marginTop: "20px",
          gap: "16px",
        }}
      >
        <Link to={`/app/blocked-remittance-users`}>
          <ArrowBackIcon
            fontSize="large"
            color="secondary"
            sx={{ background: "#040721", p: 1, borderRadius: "50%" }}
          />
        </Link>
        <Typography
          variant="h5"
          component="h5"
          fontWeight={"bold"}
          color={"#3d3d3d"}
        >
          Edit CMS User
        </Typography>
      </div>
      <>
        <Box
          sx={{ px: 6 }}
          className={`main-box ${isDynamicTable ? "dynamicTableStyle" : ""}`}
        >
          <div
            className={`bankAddRuleHeader mb-3 ${
              isDynamicTable ? "listHeaderPaddingRight" : ""
            }`}
          >
            <div
              style={{
                display: "flex",
                padding: "1.5rem 0",
                alignItems: "center",
              }}
            >
              {loading && (
                <div
                  style={{
                    marginLeft: "12px",
                    color: "#a0a0a0",
                    fontSize: "24px",
                  }}
                >
                  <i
                    className={`fa fa-refresh ${
                      loading === true ? "fa-spin" : ""
                    }`}
                    aria-hidden="true"
                  ></i>
                </div>
              )}
            </div>

            <div style={{ display: "flex", alignItems: "end" }}>
              <TextField
                id="standard-basic"
                label="Search"
                variant="standard"
                sx={{ mr: 1 }}
                onKeyUp={(e) => searchData(e)}
                InputProps={{
                  endAdornment: <SearchIcon sx={{ color: "#8a8a8a" }} />,
                }}
              />
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
              </Menu>
            </div>
          </div>

          <CustomTable
            setDynamicTable={setDynamicTable}
            data={filterList(rows, searchKey) || []}
            columns={columns}
            showTooltip={false}
          />
        </Box>
      </>
    </Box>
  );
}

export default EditBlockedUser;
