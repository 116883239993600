import appConfig from "../config/app.config";
import axios from "axios";
import { getUserToken } from "../libs/user";
import {
  getPureJSON,
  handleAPIError,
  startLoader,
  stopLoader,
} from "../libs/utils";

const getOffersList = () => {
  startLoader();
  return new Promise((resolve, reject) => {
    var config = {
      method: "get",
      url: appConfig.apiUrl.OffersList,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader();
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader();
        reject(error);
      });
  });
};

const getOffersDataById = (id) => {
  startLoader(1);
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${appConfig.apiUrl.getOfferDataById}/${id}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader(1);
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader(1);
        handleAPIError(error.status);
        reject(getPureJSON(error.response || error));
      });
  });
};

const getFreeOffers = () => {
  // const item = itemId;
  return new Promise((resolve, reject) => {
    var config = {
      method: "get",
      url: appConfig.apiUrl.getFreeOffers,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

const getTiers = () => {
  // const item = itemId;
  return new Promise((resolve, reject) => {
    var config = {
      method: "get",
      url: appConfig.apiUrl.geTtiers,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};
const getStateCodes = () => {
  // const item = itemId;
  return new Promise((resolve, reject) => {
    var config = {
      method: "get",
      url: appConfig.apiUrl.stateCode,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};
const getAllCategories = () => {
  // const item = itemId;
  return new Promise((resolve, reject) => {
    var config = {
      method: "get",
      url: appConfig.apiUrl.getallactivecategories,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

const updateOffersRecord = (obj) => {
  startLoader(1);
  return new Promise((resolve, reject) => {
    var data = JSON.stringify(obj);

    var config = {
      method: "post",
      url: appConfig.apiUrl.updateOffersRecord,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        Accept: " application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        stopLoader(1);
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader(1);
        reject(getPureJSON(error.response || error));
      });
  });
};
const getAllOfferGroups = () => {
  return new Promise((resolve, reject) => {
    var config = {
      method: "get",
      url: appConfig.apiUrl.getalloffergroups,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

const GetOfferstoresLinked = () => {
  // const item = itemId;
  return new Promise((resolve, reject) => {
    var config = {
      method: "get",
      url: appConfig.apiUrl.OffersStoresLinked,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

const OffersUpload = (type, file, id,name,uploadType,imageName) => {
  return new Promise((resolve, reject) => {
    const data = new FormData();
    data.append("type", type);
    data.append("file", file);
    data.append("id", id);
    data.append("name", name);
    data.append("uploadType",uploadType);
    data.append("imageName", imageName)
    const config = {
      method: "post",
      url: `${appConfig.apiUrl.offersupload}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        // Accept: "application/json, text/plain, */*",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(getPureJSON(error.response || error));
      });
  });
};

const imageDownload = (url) => {
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${appConfig.apiUrl.offersimagedownload}?url=${url}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

const getForOffer = (id) => {
  return new Promise((resolve, reject) => {
    var config = {
      method: "get",
      url: appConfig.apiUrl.getforoffer + id,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};
const OffersGroupIds = (id) => {
  return new Promise((resolve, reject) => {
    var config = {
      method: "get",
      url: appConfig.apiUrl.offergroupid + id,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export {
  getForOffer,
  getOffersList,
  getFreeOffers,
  getTiers,
  getAllCategories,
  getOffersDataById,
  getAllOfferGroups,
  updateOffersRecord,
  OffersUpload,
  imageDownload,
  GetOfferstoresLinked,
  OffersGroupIds,
  getStateCodes
};
