import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import localforage from "localforage";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CustomTable from "../../../components/CustomTable";
import { filterList } from "../../../libs/utils";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import { getBankList } from "../../../services/bank";

function ListBank() {
  const [rows, setRows] = useState();
  const [loading, setLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [isDynamicTable, setIsDynamicTable] = useState(false);

  const columns = [
    {
      id: "institutionId",
      label: "Institution Id",
      minWidth: 200,
      align: "left",
      enableSort: true,
    },
    {
      id: "institutionName",
      label: "Institution Name",
      minWidth: 100,
      align: "left",
      enableSort: true,
    },
    {
      id: "bankStatus",
      label: "Bank Status",
      minWidth: 100,
      align: "left",
      enableSort: true,
    },
    {
      id: "Action",
      label: "Action",
      minWidth: 100,
      align: "left",
    },
  ];

  const setDynamicTable = (parentWidth, tableWidth) => {
    setIsDynamicTable(true);
  };

  const searchData = (e) => {
    setSearchKey(e.target.value);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dataGenerator = (data) => {
    return {
      institutionId: data.institutionId,
      institutionName: data.institutionName,
      bankStatus: data.bankStatus,
      Action: (
        <Link
          to={`/app/bank/${data.institutionId}`}
          style={{ textDecoration: "none" }}
        >
          <Button variant="contained" size="small" color="warning">
            Edit
          </Button>
        </Link>
      ),
    };
  };

  useEffect(() => {
    setLoading(true);
    localforage.getItem("@banks", (error, data) => {
      if (data) {
        if (data?.length > 0 && typeof data === "object") {
          setRows(data.map((data) => dataGenerator(data)));
        } else {
          setRows([]);
        }
      }
    });
    getBankList()
      .then((response) => {
        setLoading(false);
        if (response.banks?.length > 0 && typeof response.banks === "object") {
          setRows(response.banks.map((data) => dataGenerator(data)));
        } else {
          setRows([]);
        }
        localforage.setItem("@banks", response.banks, (error, data) => {
          if (error) {
            throw error;
          }
        });
      })
      .catch((error) => {
        setLoading(false);
        throw error;
      });
  }, []);

  return (
    <>
      <Box
        sx={{ px: 6 }}
        className={`main-box ${isDynamicTable ? "dynamicTableStyle" : ""}`}
      >
        <div
          className={`bankAddRuleHeader mb-3 ${
            isDynamicTable ? "listHeaderPaddingRight" : ""
          }`}
        >
          <div
            style={{
              display: "flex",
              padding: "1.5rem 0",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: "#33343a" }} variant="h4" component="h4">
              Bank List
            </Typography>
            {loading && (
              <div
                style={{
                  marginLeft: "12px",
                  color: "#a0a0a0",
                  fontSize: "24px",
                }}
              >
                <i
                  className={`fa fa-refresh ${
                    loading === true ? "fa-spin" : ""
                  }`}
                  aria-hidden="true"
                ></i>
              </div>
            )}
          </div>
          <div style={{ display: "flex", alignItems: "end" }}>
            <TextField
              id="standard-basic"
              label="Search"
              variant="standard"
              sx={{ mr: 1 }}
              onKeyUp={(e) => searchData(e)}
              InputProps={{
                endAdornment: <SearchIcon sx={{ color: "#8a8a8a" }} />,
              }}
            />
          </div>
        </div>

        <CustomTable
          setDynamicTable={setDynamicTable}
          data={filterList(rows, searchKey) || []}
          columns={columns}
        />
      </Box>
    </>
  );
}

export default ListBank;
