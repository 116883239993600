import appConfig from "../config/app.config";
import axios from "axios";
import { getUserToken } from "../libs/user";
import {
  getPureJSON,
  handleAPIError,
  startLoader,
  stopLoader,
} from "../libs/utils";
import { isObject } from "@mui/x-data-grid/utils/utils";
const authToken = getUserToken();

const getTiersList = () => {
  startLoader();
  return new Promise((resolve, reject) => {
    var config = {
      method: "get",
      url: appConfig.apiUrl.tierslist,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader();
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader();
        reject(error);
      });
  });
};

const gettierbyid = (id) => {
  startLoader(1);
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${appConfig.apiUrl.tierslist}/tier/${id}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader(1);
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader(1);
        handleAPIError(error.status);
        reject(getPureJSON(error.response || error));
      });
  });
};

const updateTiers = (obj) => {
  startLoader(1);
  const authToken = getUserToken();
  return new Promise((resolve, reject) => {
    var data = JSON.stringify({
      id: obj.id,
      name: obj.name,
      price: obj.price,
      trialPeriodNumber: obj.trialPeriodNumber,
      appleProductId: obj.appleProductId,
      active: obj.active,
      level: obj.level,
      description: obj.description,
      headerImg: obj.headerImg,
      priceSubTitle: obj.priceSubTitle,
      subtitle: obj.subtitle,
      trialPeriodUnit: obj.trialPeriodUnit,
      singleColour: obj.singleColour,
      singleColourCode: obj.singleColourCode,
      gradientStartColourCode: obj.gradientStartColourCode,
      gradientEndColourCode: obj.gradientEndColourCode,
      tierTextColour: obj.tierTextColour,
      trialPeriodBackGroundColour: obj.trialPeriodBackGroundColour,
      profileSmallCardImg: obj.profileSmallCardImg,
      profileSubscriptionLogo: obj.profileSubscriptionLogo,
      profileSubscriptionDeepLink: obj.profileSubscriptionDeepLink,
      entitlementId: obj.entitlementId,
      termsAndCondition: {
        link1: obj.link1,
        link2: obj.link2,
        linkText: obj.linkText,
      },
    });

    var config = {
      method: "post",
      url: appConfig.apiUrl.tierslist + "/" + "save",
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        stopLoader(1);
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader(1);
        reject(getPureJSON(error.response || error));
      });
  });
};
export { getTiersList, gettierbyid, updateTiers };
