const offerModel = {
  id: null,
  advertiserName: "",
  advertiserId: "",
  offerId: "",
  title: "",
  subTitle: "",
  commission: "",
  category: [],
  logo: "",
  expiryDate: "",
  affiliatePartner: "",
  couponType: "",
  couponCode: "",
  rewardCampaignId: "",
  promoType: "",
  cardNo: 0,
  bigCardNo: 0,
  smallCardNo: 0,
  listNo: 0,
  popularity: 0,
  placeholder: "",
  image: "",
  destinationUrl: "",
  clickUrl: "",
  description: "",
  epoch: 0,
  isActive: false,
  isIosOffer: false,
  isAndroidOffer: false,
  bigImage: "",
  smallImage: "",
  cashbackMaxTimes: 0,
  cashbackMinAmount: 0,
  country: "",
  timings: "",
  address: "",
  allHaveKeyWords: "",
  anyHaveKeyWords: "",
  noneHaveKeyWords: "",
  autoApproval: false,
  latitude: "",
  longitude: "",
  newSignUpText: "",
  newSignUpFlow: false,
  newSignUpFlowSequenceNumber: 0,
  timesclubCommission: "",
  cashbackMaxAmount: 0,
  shortDescription: "",
  campaignId: "",
  locationBased: false,
  offerStoreIds: [],
  locationMandatory: false,
  sponserLogo: "",
  sponserTitle: "",
  sponserSubTitle: "",
  sponserLink: "",
  startDate:"",
  firstBankOffer: false,
  merchantName: "",
  cashBackTitle: "",
  strikeThroughCashBackTitle: "",
  tags: [],
  preActivated: false,
  smallLogoImage: "",
  logoCardNo: 0,
  rakutenType: "",
  categoryOffer: false,
  categoryId: "",
  displayMerchantName: null,
  categoryKeywords: "",
  cashbackPriority: "",
  offerWallBigCardPosition: 0,
  offerWallLogoCardImage: "",
  offerWallLogoCardPosition: 0,
  mappedOfferId: null,
  tierId: null,
  termsConditions: "",
  monthlyCashbackLimit: null,
  offerLinkExpirationDays: null,
  merchantId: null,
  linkedGroups: [],
  isMemberBenefit: null,
};

export { offerModel };
