import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Box, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Swal from "sweetalert2";
import {
  addAffiliatePartnerService,
  getAffiliatePartnerById,
} from "../../../services/affiliatePartner";

function AddEditAffiliatePartner() {
  const navigation = useNavigate();
  const { id } = useParams();
  const [headersMap1, setHeadersMap1] = useState([]);
  const [headersMap2, setHeadersMap2] = useState([]);
  const [validationCheck, setValidationCheck] = useState([]);
  const [multiValueMapDetails, setMultiValueMapDetails] = useState([]);

  const [handleKeyChange1, setHandleKeyChange1] = useState("");
  const [handleValueChange1, setHandleValueChange1] = useState("");
  const [handleKeyChange1Error, setHandleKeyChange1Error] = useState(null);
  const [handleValueChange1Error, setHandleValueChange1Error] = useState(null);

  const [handleKeyChange2, setHandleKeyChange2] = useState("");
  const [handleValueChange2, setHandleValueChange2] = useState("");
  const [handleKeyChange2Error, setHandleKeyChange2Error] = useState(null);
  const [handleValueChange2Error, setHandleValueChange2Error] = useState(null);

  const [handleKeyChange3, setHandleKeyChange3] = useState("");
  const [handleValueChange3, setHandleValueChange3] = useState("");
  const [handleKeyChange3Error, setHandleKeyChange3Error] = useState(null);
  const [handleValueChange3Error, setHandleValueChange3Error] = useState(null);

  const [handleKeyChange4, setHandleKeyChange4] = useState("");
  const [handleValueChange4, setHandleValueChange4] = useState("");
  const [handleKeyChange4Error, setHandleKeyChange4Error] = useState(null);
  const [handleValueChange4Error, setHandleValueChange4Error] = useState(null);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const addHeadersMapData1 = () => {
    if (!handleKeyChange1) {
      setHandleKeyChange1Error("Please fill key");
    } else {
      setHandleKeyChange1Error(null);
    }
    if (!handleValueChange1) {
      setHandleValueChange1Error("Please fill value");
    } else {
      setHandleValueChange1Error(null);
    }
    if (handleKeyChange1 && handleValueChange1) {
      const obj = { key: handleKeyChange1, value: handleValueChange1 };

      setHeadersMap1(() => {
        const arr = [...headersMap1, obj];
        setHandleKeyChange1("");
        setHandleValueChange1("");
        return arr;
      });
    }
  };

  const addHeadersMapData2 = () => {
    if (!handleKeyChange2) {
      setHandleKeyChange2Error("Please fill key");
    } else {
      setHandleKeyChange2Error(null);
    }
    if (!handleValueChange2) {
      setHandleValueChange2Error("Please fill value");
    } else {
      setHandleValueChange2Error(null);
    }
    if (handleKeyChange2 && handleValueChange2) {
      const obj = { key: handleKeyChange2, value: handleValueChange2 };

      setHeadersMap2(() => {
        const arr = [...headersMap2, obj];
        setHandleKeyChange2("");
        setHandleValueChange2("");
        return arr;
      });
    }
  };

  const addMultipalData3 = () => {
    if (!handleKeyChange3) {
      setHandleKeyChange3Error("Please fill key");
    } else {
      setHandleKeyChange3Error(null);
    }
    if (!handleValueChange3) {
      setHandleValueChange3Error("Please fill value");
    } else {
      setHandleValueChange3Error(null);
    }
    if (handleKeyChange3 && handleValueChange3) {
      const obj = { key: handleKeyChange3, value: handleValueChange3 };

      setValidationCheck(() => {
        const arr = [...validationCheck, obj];
        setHandleKeyChange3("");
        setHandleValueChange3("");
        return arr;
      });
    }
  };

  const addMultipalData4 = () => {
    if (!handleKeyChange4) {
      setHandleKeyChange4Error("Please fill key");
    } else {
      setHandleKeyChange4Error(null);
    }
    if (!handleValueChange4) {
      setHandleValueChange4Error("Please fill value");
    } else {
      setHandleValueChange4Error(null);
    }
    if (handleKeyChange4 && handleValueChange4) {
      const obj = { key: handleKeyChange4, value: handleValueChange4 };

      setMultiValueMapDetails(() => {
        const arr = [...multiValueMapDetails, obj];
        setHandleKeyChange4("");
        setHandleValueChange4("");
        return arr;
      });
    }
  };

  const removeHeadersMapData1 = (key) => {
    setHeadersMap1(() => headersMap1.filter((data, index) => index !== key));
  };

  const removeHeadersMapData2 = (key) => {
    setHeadersMap2(() => headersMap2.filter((data, index) => index !== key));
  };

  const removeMultipalData3 = (key) => {
    setValidationCheck(() =>
      validationCheck.filter((data, index) => index !== key)
    );
  };

  const removeMultipalData4 = (key) => {
    setMultiValueMapDetails(() =>
      multiValueMapDetails.filter((data, index) => index !== key)
    );
  };

  const editAffiliatePartner = (data) => {
    const obj = {
      id: id !== "0" ? data.id : null,
      name: data.name,
      active: data.active,
      cash_back_days: data.cash_back_days,
      url: data.url,
      cashback_node_key_path: data.cashback_node_key_path,
      validation_checks: null,
      partner_mapping_details: null,
      token_mapping_details: null,
      txn_fetch_mapping_details: null,
      token_required: data.token_required,
    };

    if (validationCheck.length) {
      obj.validation_checks = validationCheck.reduce((prev, data) => {
        prev[data.key] = data.value;
        return prev;
      }, {});
    }

    //PartnerMappingDetails
    let partner_mapping_details = {};
    if (data.user_id) {
      partner_mapping_details.user_id = data.user_id;
    }

    if (data.offer_id) {
      partner_mapping_details.offer_id = data.offer_id;
    }

    if (data.spent_amount) {
      partner_mapping_details.spent_amount = data.spent_amount;
    }

    if (data.commission_id) {
      partner_mapping_details.commission_id = data.commission_id;
    }

    if (data.action_date) {
      partner_mapping_details.action_date = data.action_date;
    }

    if (data.currency) {
      partner_mapping_details.currency = data.currency;
    }

    if (data.reversal_node_key) {
      partner_mapping_details.reversal_node_key = data.reversal_node_key;
    }

    if (data.reversal_node_value) {
      partner_mapping_details.reversal_node_value = data.reversal_node_value;
    }

    if (data.date_format1) {
      partner_mapping_details.date_format = data.date_format1;
    }

    if (JSON.stringify(partner_mapping_details) !== "{}") {
      obj.partner_mapping_details = partner_mapping_details;
    }

    //TokenMappingDetails
    let token_mapping_details = {};
    if (headersMap1.length) {
      token_mapping_details.headers_map = headersMap1.reduce((prev, data) => {
        prev[data.key] = data.value;
        return prev;
      }, {});
    }

    if (data.url1) {
      token_mapping_details.url = data.url1;
    }

    if (multiValueMapDetails.length) {
      token_mapping_details.multi_value_map_details =
        multiValueMapDetails.reduce((prev, data) => {
          prev[data.key] = data.value;
          return prev;
        }, {});
    }

    if (data.token_node_name) {
      token_mapping_details.token_node_name = data.token_node_name;
    }

    if (JSON.stringify(token_mapping_details) !== "{}") {
      obj.token_mapping_details = token_mapping_details;
    }

    //TxnFetchMappingDetails
    let txn_fetch_mapping_details = {};
    if (headersMap2.length) {
      txn_fetch_mapping_details.headers_map = headersMap2.reduce(
        (prev, data) => {
          prev[data.key] = data.value;
          return prev;
        },
        {}
      );
    }

    if (data.url2) {
      txn_fetch_mapping_details.url = data.url2;
    }

    if (data.http_method) {
      txn_fetch_mapping_details.http_method = data.http_method;
    }

    if (data.request_body) {
      txn_fetch_mapping_details.request_body = data.request_body;
    }

    if (data.date_format2) {
      txn_fetch_mapping_details.date_format = data.date_format2;
    }

    if (JSON.stringify(txn_fetch_mapping_details) !== "{}") {
      obj.txn_fetch_mapping_details = txn_fetch_mapping_details;
    }

    addAffiliatePartnerService(obj)
      .then((response) => {
        Swal.fire({
          title: "Success",
          text: "Affiliate partner has been saved successfully",
          icon: "success",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            navigation(-1);
          }
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Oops",
          text: "Something went wrong",
          icon: "warning",
          confirmButtonText: "Ok",
        }).then((result) => {});
        throw error;
      });
  };

  useEffect(() => {
    if (id !== "0") {
      getAffiliatePartnerById(id)
        .then((response) => {
          if (response) {
            reset({
              id: response.id,
              name: response.name,
              cash_back_days: response.cash_back_days,
              url: response.url,
              cashback_node_key_path: response.cashback_node_key_path,
              token_required: response.token_required,
              active: response.active,
              user_id: response.partner_mapping_details?.user_id,
              offer_id: response.partner_mapping_details?.offer_id,
              spent_amount: response.partner_mapping_details?.spent_amount,
              commission_id: response.partner_mapping_details?.commission_id,
              action_date: response.partner_mapping_details?.action_date,
              currency: response.partner_mapping_details?.currency,
              reversal_node_key:
                response.partner_mapping_details?.reversal_node_key,
              reversal_node_value:
                response.partner_mapping_details?.reversal_node_value,
              date_format1: response.partner_mapping_details?.date_format,
              url1: response.token_mapping_details?.url,
              token_node_name: response.token_mapping_details?.token_node_name,
              url2: response.txn_fetch_mapping_details?.url,
              http_method: response.txn_fetch_mapping_details?.http_method,
              request_body: response.txn_fetch_mapping_details?.request_body,
              date_format2: response.txn_fetch_mapping_details?.date_format,
            });
            setValidationCheck(
              Object.keys(response.validation_checks || []).map((key) => {
                return { key: key, value: response.validation_checks[key] };
              })
            );

            setHeadersMap1(
              Object.keys(
                response.token_mapping_details?.headers_map || []
              ).map((key) => {
                return {
                  key: key,
                  value: response.token_mapping_details?.headers_map[key],
                };
              })
            );

            setMultiValueMapDetails(
              Object.keys(
                response.token_mapping_details?.multi_value_map_details || []
              ).map((key) => {
                return {
                  key: key,
                  value:
                    response.token_mapping_details?.multi_value_map_details[
                      key
                    ],
                };
              })
            );

            setHeadersMap2(
              Object.keys(
                response.txn_fetch_mapping_details?.headers_map || []
              ).map((key) => {
                return {
                  key: key,
                  value: response.txn_fetch_mapping_details?.headers_map[key],
                };
              })
            );
          }
        })
        .catch((error) => {
          Swal.fire({
            title: "Oops",
            text: "Something went wrong",
            icon: "warning",
            confirmButtonText: "Ok",
          }).then((result) => {
            navigation(-1);
          });
          throw error;
        });
    }
  }, [id]);

  return (
    <Box sx={{ ml: "4", pt: "14 !important", pb: "5", px: 3 }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "25px",
          marginTop: "20px",
          gap: "16px",
        }}
      >
        <Link to="/app/affiliate-partner">
          <ArrowBackIcon
            fontSize="large"
            color="secondary"
            sx={{ background: "#040721", p: 1, borderRadius: "50%" }}
          />
        </Link>
        <Typography
          variant="h5"
          component="h5"
          fontWeight={"bold"}
          color={"#3d3d3d"}
        >
          {id !== "0" ? "Edit" : "Add"} Affiliate Partner
        </Typography>
      </div>
      <Box sx={{ background: "#ffffff", p: 2, mb: 4, pb: 4 }}>
        <form onSubmit={handleSubmit(editAffiliatePartner)} noValidate>
          <div className="row">
            {id !== "0" && (
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none w-100"
                    htmlFor="id"
                  >
                    ID <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    disabled
                    {...register("id", { required: true })}
                    className={`form-control ${
                      errors.id && "invalid-form-input"
                    }`}
                    type="text"
                    id="id"
                  />
                </div>
              </div>
            )}

            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none w-100"
                  htmlFor="name"
                >
                  Name <small className="text-danger fs-6">*</small>
                </label>
                <input
                  {...register("name", { required: true })}
                  className={`form-control ${
                    errors.name && "invalid-form-input"
                  }`}
                  type="text"
                  id="name"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none w-100"
                  htmlFor="cash_back_days"
                >
                  Cashback Days <small className="text-danger fs-6">*</small>
                </label>
                <input
                  {...register("cash_back_days", { required: true })}
                  className={`form-control ${
                    errors.cash_back_days && "invalid-form-input"
                  }`}
                  type="number"
                  id="cash_back_days"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none w-100"
                  htmlFor="url"
                >
                  URL
                </label>
                <input
                  {...register("url")}
                  className={`form-control ${
                    errors.url && "invalid-form-input"
                  }`}
                  type="text"
                  id="url"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none w-100"
                  htmlFor="cashback_node_key_path"
                >
                  Root Cashback Node Path
                </label>
                <input
                  {...register("cashback_node_key_path")}
                  className={`form-control ${
                    errors.cashback_node_key_path && "invalid-form-input"
                  }`}
                  type="text"
                  id="cashback_node_key_path"
                />
              </div>
            </div>
            <div className="col-md-6 mt-5">
              <div className="row">
                <div
                  className="col-md-6 d-flex align-items-end"
                  style={{ marginBottom: "12px" }}
                >
                  <div className="form-groupform-check d-flex align-items-center gap-3 ">
                    <input
                      {...register("active")}
                      className={`form-check-input ${
                        errors.active && "invalid-form-input"
                      }`}
                      type="checkbox"
                      id="active"
                    />
                    <label
                      className="form-check-label fw-bold text-secondary"
                      htmlFor="active"
                      style={{ lineHeight: "1" }}
                    >
                      Active
                    </label>
                  </div>
                </div>
                <div
                  className="col-md-6 d-flex align-items-end"
                  style={{ marginBottom: "12px" }}
                >
                  <div className="form-groupform-check d-flex align-items-center gap-3 ">
                    <input
                      {...register("token_required")}
                      className={`form-check-input ${
                        errors.token_required && "invalid-form-input"
                      }`}
                      type="checkbox"
                      id="token_required"
                    />
                    <label
                      className="form-check-label fw-bold text-secondary"
                      htmlFor="token_required"
                      style={{ lineHeight: "1" }}
                    >
                      Token Required
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <label
              className="form-label fw-bold text-secondary user-select-none w-100 mb-0"
              htmlFor="headers_map1"
            >
              Validation Checks
            </label>
            <div className="col-md-12">
              <div className="row">
                {validationCheck.map((data, index) => (
                  <div className="col-md-6" key={index}>
                    <div className="row mt-3" key={index}>
                      <div className="col-md-5">
                        <div className="form-group">
                          <input
                            value={data.key}
                            className={`form-control`}
                            type="text"
                            id="headers_map1"
                            placeholder="Key"
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="form-group">
                          <input
                            value={data.value}
                            className={`form-control`}
                            type="text"
                            id="headers_map1"
                            placeholder="Value"
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-2 ">
                        <button
                          type="button"
                          className="btn btn-danger btn-lg btn-block w-100"
                          onClick={() => removeMultipalData3(index)}
                        >
                          <i className="fa fa-trash"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="col-md-6">
                  <div className="row mt-3">
                    <div className="col-md-5">
                      <div className="form-group">
                        <input
                          value={handleKeyChange3}
                          className={`form-control ${
                            handleKeyChange3Error && "invalid-form-input"
                          }`}
                          type="text"
                          id="headers_map1"
                          placeholder="Key"
                          onChange={(e) => setHandleKeyChange3(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="form-group">
                        <input
                          value={handleValueChange3}
                          className={`form-control ${
                            handleValueChange3Error && "invalid-form-input"
                          }`}
                          type="text"
                          id="headers_map1"
                          placeholder="Value"
                          onChange={(e) =>
                            setHandleValueChange3(e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-2 ">
                      <button
                        type="button"
                        className="btn btn-success btn-lg btn-block w-100"
                        onClick={addMultipalData3}
                      >
                        <i className="fa fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr style={{ height: "0.5px", marginTop: "32px" }} />
          </div>
          <div className="row">
            <div className="col-md-12">
              <h4>Partner Mapping Details</h4>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none w-100"
                  htmlFor="user_id"
                >
                  User Id
                </label>
                <input
                  {...register("user_id")}
                  className={`form-control ${
                    errors.user_id && "invalid-form-input"
                  }`}
                  type="text"
                  id="user_id"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none w-100"
                  htmlFor="offer_id"
                >
                  Offer Id
                </label>
                <input
                  {...register("offer_id")}
                  className={`form-control ${
                    errors.offer_id && "invalid-form-input"
                  }`}
                  type="text"
                  id="offer_id"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none w-100"
                  htmlFor="spent_amount"
                >
                  Spent Amount
                </label>
                <input
                  {...register("spent_amount")}
                  className={`form-control ${
                    errors.spent_amount && "invalid-form-input"
                  }`}
                  type="text"
                  id="spent_amount"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none w-100"
                  htmlFor="commission_id"
                >
                  Commission Id
                </label>
                <input
                  {...register("commission_id")}
                  className={`form-control ${
                    errors.commission_id && "invalid-form-input"
                  }`}
                  type="text"
                  id="commission_id"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none w-100"
                  htmlFor="action_date"
                >
                  Action Date
                </label>
                <input
                  {...register("action_date")}
                  className={`form-control ${
                    errors.action_date && "invalid-form-input"
                  }`}
                  type="text"
                  id="action_date"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none w-100"
                  htmlFor="currency"
                >
                  Currency
                </label>
                <input
                  {...register("currency")}
                  className={`form-control ${
                    errors.currency && "invalid-form-input"
                  }`}
                  type="text"
                  id="currency"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none w-100"
                  htmlFor="reversal_node_key"
                >
                  Reversal Node Key
                </label>
                <input
                  {...register("reversal_node_key")}
                  className={`form-control ${
                    errors.reversal_node_key && "invalid-form-input"
                  }`}
                  type="text"
                  id="reversal_node_key"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none w-100"
                  htmlFor="reversal_node_value"
                >
                  Reversal Node Value
                </label>
                <input
                  {...register("reversal_node_value")}
                  className={`form-control ${
                    errors.reversal_node_value && "invalid-form-input"
                  }`}
                  type="text"
                  id="reversal_node_value"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none w-100"
                  htmlFor="date_format1"
                >
                  Date Format
                </label>
                <input
                  {...register("date_format1")}
                  className={`form-control ${
                    errors.date_format1 && "invalid-form-input"
                  }`}
                  type="text"
                  id="date_format1"
                />
              </div>
            </div>
            <hr style={{ height: "0.5px", marginTop: "32px" }} />
          </div>
          <div className="row">
            <div className="col-md-12">
              <h4>Token Mapping Details</h4>
            </div>
            <label
              className="form-label fw-bold text-secondary user-select-none w-100 mb-0"
              htmlFor="headers_map1"
            >
              Headers Map
            </label>
            <div className="col-md-12">
              <div className="row">
                {headersMap1.map((data, index) => (
                  <div className="col-md-6" key={index}>
                    <div className="row mt-3" key={index}>
                      <div className="col-md-5">
                        <div className="form-group">
                          <input
                            value={data.key}
                            className={`form-control`}
                            type="text"
                            id="headers_map1"
                            placeholder="Key"
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="form-group">
                          <input
                            value={data.value}
                            className={`form-control`}
                            type="text"
                            id="headers_map1"
                            placeholder="Value"
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-2 ">
                        <button
                          type="button"
                          className="btn btn-danger btn-lg btn-block w-100"
                          onClick={() => removeHeadersMapData1(index)}
                        >
                          <i className="fa fa-trash"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="col-md-6">
                  <div className="row mt-3">
                    <div className="col-md-5">
                      <div className="form-group">
                        <input
                          value={handleKeyChange1}
                          className={`form-control ${
                            handleKeyChange1Error && "invalid-form-input"
                          }`}
                          type="text"
                          id="headers_map1"
                          placeholder="Key"
                          onChange={(e) => setHandleKeyChange1(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="form-group">
                        <input
                          value={handleValueChange1}
                          className={`form-control ${
                            handleValueChange1Error && "invalid-form-input"
                          }`}
                          type="text"
                          id="headers_map1"
                          placeholder="Value"
                          onChange={(e) =>
                            setHandleValueChange1(e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-2 ">
                      <button
                        type="button"
                        className="btn btn-success btn-lg btn-block w-100"
                        onClick={addHeadersMapData1}
                      >
                        <i className="fa fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr style={{ height: "0.5px", marginTop: "32px" }} />

            <label
              className="form-label fw-bold text-secondary user-select-none w-100 mb-0"
              htmlFor="headers_map1"
            >
              Multi Value Map Details
            </label>
            <div className="col-md-12">
              <div className="row">
                {multiValueMapDetails.map((data, index) => (
                  <div className="col-md-6" key={index}>
                    <div className="row mt-3" key={index}>
                      <div className="col-md-5">
                        <div className="form-group">
                          <input
                            value={data.key}
                            className={`form-control`}
                            type="text"
                            id="headers_map1"
                            placeholder="Key"
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="form-group">
                          <input
                            value={data.value}
                            className={`form-control`}
                            type="text"
                            id="headers_map1"
                            placeholder="Value"
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-2 ">
                        <button
                          type="button"
                          className="btn btn-danger btn-lg btn-block w-100"
                          onClick={() => removeMultipalData4(index)}
                        >
                          <i className="fa fa-trash"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="col-md-6">
                  <div className="row mt-3">
                    <div className="col-md-5">
                      <div className="form-group">
                        <input
                          value={handleKeyChange4}
                          className={`form-control ${
                            handleKeyChange4Error && "invalid-form-input"
                          }`}
                          type="text"
                          id="headers_map1"
                          placeholder="Key"
                          onChange={(e) => setHandleKeyChange4(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="form-group">
                        <input
                          value={handleValueChange4}
                          className={`form-control ${
                            handleValueChange4Error && "invalid-form-input"
                          }`}
                          type="text"
                          id="headers_map1"
                          placeholder="Value"
                          onChange={(e) =>
                            setHandleValueChange4(e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-2 ">
                      <button
                        type="button"
                        className="btn btn-success btn-lg btn-block w-100"
                        onClick={addMultipalData4}
                      >
                        <i className="fa fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none w-100"
                  htmlFor="url1"
                >
                  URL
                </label>
                <input
                  {...register("url1")}
                  className={`form-control ${
                    errors.url1 && "invalid-form-input"
                  }`}
                  type="text"
                  id="url1"
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none w-100"
                  htmlFor="token_node_name"
                >
                  Token Node Name
                </label>
                <input
                  {...register("token_node_name")}
                  className={`form-control ${
                    errors.token_node_name && "invalid-form-input"
                  }`}
                  type="text"
                  id="token_node_name"
                />
              </div>
            </div>
            <hr style={{ height: "0.5px", marginTop: "32px" }} />
          </div>
          <div className="row">
            <div className="col-md-12">
              <h4>Txn Fetch Mapping Details</h4>
            </div>

            <label
              className="form-label fw-bold text-secondary user-select-none w-100 mb-0"
              htmlFor="headers_map1"
            >
              Headers Map
            </label>
            <div className="col-md-12">
              <div className="row">
                {headersMap2.map((data, index) => (
                  <div className="col-md-6" key={index}>
                    <div className="row mt-3" key={index}>
                      <div className="col-md-5">
                        <div className="form-group">
                          <input
                            value={data.key}
                            className={`form-control`}
                            type="text"
                            id="headers_map1"
                            placeholder="Key"
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="form-group">
                          <input
                            value={data.value}
                            className={`form-control`}
                            type="text"
                            id="headers_map1"
                            placeholder="Value"
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-2 ">
                        <button
                          type="button"
                          className="btn btn-danger btn-lg btn-block w-100"
                          onClick={() => removeHeadersMapData2(index)}
                        >
                          <i className="fa fa-trash"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="col-md-6">
                  <div className="row mt-3">
                    <div className="col-md-5">
                      <div className="form-group">
                        <input
                          value={handleKeyChange2}
                          className={`form-control ${
                            handleKeyChange2Error && "invalid-form-input"
                          }`}
                          type="text"
                          id="headers_map1"
                          placeholder="Key"
                          onChange={(e) => setHandleKeyChange2(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="form-group">
                        <input
                          value={handleValueChange2}
                          className={`form-control ${
                            handleValueChange2Error && "invalid-form-input"
                          }`}
                          type="text"
                          id="headers_map1"
                          placeholder="Value"
                          onChange={(e) =>
                            setHandleValueChange2(e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-2 ">
                      <button
                        type="button"
                        className="btn btn-success btn-lg btn-block w-100"
                        onClick={addHeadersMapData2}
                      >
                        <i className="fa fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr
              style={{
                height: "0.5px",
                marginTop: "32px",
              }}
            />
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none w-100"
                  htmlFor="url2"
                >
                  URL
                </label>
                <input
                  {...register("url2")}
                  className={`form-control ${
                    errors.url2 && "invalid-form-input"
                  }`}
                  type="text"
                  id="url2"
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none w-100"
                  htmlFor="http_method"
                >
                  HTTP Method
                </label>
                <select
                  {...register("http_method")}
                  className={`form-select ${
                    errors.http_method && "invalid-form-input"
                  }`}
                  id="http_method"
                  defaultValue="GET"
                >
                  <option value="GET">GET</option>
                  <option value="POST">POST</option>
                </select>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none w-100"
                  htmlFor="date_format2"
                >
                  Date Format
                </label>
                <input
                  {...register("date_format2")}
                  className={`form-control ${
                    errors.date_format2 && "invalid-form-input"
                  }`}
                  type="text"
                  id="date_format2"
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none w-100"
                  htmlFor="request_body"
                >
                  Request Body
                </label>
                <textarea
                  rows="6"
                  {...register("request_body")}
                  className={`form-control ${
                    errors.request_body && "invalid-form-input"
                  }`}
                  type="text"
                  id="request_body"
                />
              </div>
            </div>
            <hr style={{ height: "0.5px", marginTop: "32px" }} />
          </div>
          <div>
            <button className="btn btn-primary py-2 px-5" name="save">
              Save
            </button>
          </div>
        </form>
      </Box>
    </Box>
  );
}

export default AddEditAffiliatePartner;
