import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import PersonIcon from "@mui/icons-material/Person";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AnonymousUserLayout from "../../common/anonymous-user-layout/anonymous-user-layout";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAuth, userLogin } from "../../../redux/slices/authSlice";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Colors from "../../../config/colors";
import { getUserToken } from "../../../libs/user";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loginError, setLoginError] = useState("");
  const [isLogin, setIsLogin] = useState(false);

  const auth = useSelector((state) => state.auth);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const login = (data) => {
    setIsLogin(true);
    setLoginError("");

    if (data.username && data.password) {
      navigate("/app/auth", {
        state: { username: data.username, password: data.password },
      });
    } else {
      setIsLogin(false);
      setLoginError("Invalid Credentials");
    }
  };

  useEffect(() => {
    if (auth.user.data) {
      navigate("/app/offers");
    }
  }, [auth.user]);

  const theme = createTheme({
    palette: {
      primary: {
        main: Colors.primary,
      },
      secondary: {
        main: Colors.secondary,
      },
    },
    components: {
      MuiInputLabel: {
        defaultProps: {
          sx: {
            fontSize: "20px",
          },
        },
      },
      MuiOutlinedInput: {
        defaultProps: {
          sx: {
            fontSize: "20px",
          },
        },
      },
    },
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <AnonymousUserLayout />
        <Container
          component="main"
          sx={{
            height: "calc(100vh - 64px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "64px",
          }}
        >
          <Box
            style={{
              width: "fit-content",
            }}
          >
            <Box
              style={{
                width: "500px",
                padding: "54px 28px",
                border: "1px solid rgb(204, 204, 204)",
                borderRadius: "8px",
                background: "#fff",
              }}
            >
              <form onSubmit={handleSubmit(login)}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="h4"
                    style={{
                      color: "#000",
                      marginBottom: "24px",
                      fontWeight: "bold",
                    }}
                  >
                    Login
                  </Typography>
                </Box>

                <TextField
                  {...register("username", {
                    required: true,
                  })}
                  error={errors.username}
                  helperText={errors.username ? "Please Enter Username" : ""}
                  margin="normal"
                  fullWidth
                  label="Username"
                  autoFocus
                  InputProps={{
                    endAdornment: <PersonIcon sx={{ color: "#ccc" }} />,
                    autoComplete: "off",
                  }}
                />
                <TextField
                  {...register("password", {
                    required: true,
                    minLength: 8,
                  })}
                  error={errors.password}
                  helperText={errors.password ? "Please Enter Password" : ""}
                  margin="normal"
                  fullWidth
                  label="Password"
                  type="password"
                  InputProps={{
                    endAdornment: <LockOutlinedIcon sx={{ color: "#ccc" }} />,
                    autoComplete: "off",
                  }}
                />
                <Button
                  fullWidth
                  size="large"
                  sx={{
                    fontSize: "20px",
                    marginTop: "16px",
                    color: "#edc865",
                    fontWeight: "bold",
                    height: "56px",
                    "&:hover": {
                      background: "#edc865",
                      color: "#7a5700",
                    },
                  }}
                  variant="contained"
                  type="submit"
                >
                  Login
                  {isLogin === true && (
                    <i
                      style={{ marginLeft: "auto" }}
                      className={`fa fa-circle-o-notch ${
                        true ? "fa-spin" : ""
                      }`}
                      aria-hidden="true"
                    ></i>
                  )}
                  {isLogin === false && (
                    <ArrowForwardIcon style={{ marginLeft: "auto" }} />
                  )}
                </Button>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ mt: 2, color: "#676767" }}>
                    <span
                      style={{ textDecoration: "none" }}
                      className="text-danger"
                    >
                      &nbsp;{loginError}
                    </span>
                  </Typography>
                  <Typography sx={{ mt: 5, color: "#676767" }}>
                    <Link
                      to="/forget-password"
                      style={{ textDecoration: "none", color: "#676767" }}
                    >
                      Forgot Password
                      <ArrowForwardIcon
                        style={{ height: "16px", marginLeft: "auto" }}
                      />
                    </Link>
                  </Typography>
                </Box>
              </form>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </>
  );
}

export default Login;
