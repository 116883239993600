import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link, useNavigate , useParams } from "react-router-dom";

import { useForm } from "react-hook-form";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Swal from "sweetalert2";
import { getRules, updateRules } from "../../../services/Rules";
import { useEffect } from "react";
import { useState } from "react";

function AddRules(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { id } = useParams();
  const navigation = useNavigate();
  const [geTRules, setRules] = useState([]);

  const updateRuleData = (data) => {
    if (data.active === "") data.active = false;
    data.id = null;
    updateRules(data)
   
      .then((response) => {
        Swal.fire({
          title: "Success",
          text: "Rule has been added successfully",
          icon: "success",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
             navigation(-1);
          }
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Oops",
          text: "Something went wrong",
          icon: "error",
          confirmButtonText: "Ok",
        }).then((result) => {});
        throw error;
      });
  };

  useEffect(() => {
    getRules()
      .then((response) => {
        setRules(response.eligible_csm_rules || []) ;
      })
      .catch(function (error) {
        throw error;
      });
  }, []);

  return (
    <Box sx={{ ml: "4", pt: "14 !important", pb: "5", px: 3 }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "25px",
          marginTop: "20px",
          gap: "16px",
        }}
      >
        <Link to="/app/rules">
          <ArrowBackIcon
            fontSize="large"
            color="secondary"
            sx={{ background: "#040721", p: 1, borderRadius: "50%" }}
          />
        </Link>
        <Typography
          variant="h5"
          component="h5"
          fontWeight={"bold"}
          color={"#3d3d3d"}
        >
          Add Rule
        </Typography>
      </div>
      <Box sx={{ background: "#ffffff", p: 2, mb: 4, pb: 4 }}>
        <form onSubmit={handleSubmit(updateRuleData)}>
        <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="rule_name"
                  >
                    Rule Name: <small className="text-danger fs-6">*</small>
                  </label>
                  <select
                    {...register("rule_name", { required: true })}
                    className={`form-control ${
                      errors.rule_name && "invalid-form-input"
                    }`}
                    type="select"
                    id="rule_name"
                    disabled = {geTRules.length===0 ? true : false}
                  >
                      {geTRules.map((value, index) => (
                    <option key={index} value={value}>
                      {value}
                    </option>
                  ))}
                  </select>
                </div>
              </div>
              <input
                {...register("id")}
                className={`form-control`}
                type="hidden"
                id="name"
              />

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="txn_days"
                  >
                    Transaction Days: <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("txn_days", { required: true })}
                    className={`form-control ${
                      errors.txn_days && "invalid-form-input"
                    }`}
                    type="number"
                    id="txn_days"
                    min="0"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="active"
                  >
                    Active: <small className="text-danger fs-6">*</small>
                  </label>
                  <select
                    {...register("active", { required: true })}
                    className={`form-control ${
                      errors.active && "invalid-form-input"
                    }`}
                    type="select"
                    id="active"
                  >
                    <option value="">Select</option>
                    <option value="true">True</option>
                    <option value="false">False</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="max_value"
                  >
                    MaxValue:
                  </label>
                  <input
                    {...register("max_value")}
                    className={`form-control`}
                    type="text"
                    id="max_value"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="weight"
                  >
                    Weight:
                  </label>
                  <input
                    {...register("weight")}
                    className={`form-control`}
                    type="text"
                    id="weight"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                <label
                    className="form-label fw-bold text-secondary user-select-none w-100"
                    htmlFor="normalization_factor"
                  >
                    Normalization Factor:
                  </label>
                  <input
                    {...register("normalization_factor")}
                    className={`form-control`}
                    type="text"
                    id="normalization_factor"
                  />
                </div>
              </div>
            </div>
            <hr style={{ height: "0.5px", marginTop: "32px" }} />
          <div>
            <button
              type="submit"
              className="btn btn-primary py-2 px-5"
              name="save"
            >
              Save 
            </button>
          </div>
        </form>
      </Box>
    </Box>
  );
}

export default AddRules
