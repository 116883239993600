import "./reward.css";
function Reward() {
  return (
    <>
      <div style={{ width: "100%", paddingLeft: "1%", paddingRight: "1%" }}>
        <h2>Edit rewards</h2>
        <form>
          <input hidden type="text" formcontrolname="id" />
          <div className="form-row">
            <label className="required">Image:</label>
            <div>
              <input type="text" formcontrolname="image" />
            </div>
          </div>
          <div className="form-row">
            <label className="required">Logo:</label>
            <div>
              <input type="text" formcontrolname="logo" />
            </div>
          </div>
          <div className="form-row">
            <label className="required">Reward Name:</label>
            <div>
              <select formcontrolname="rewardName">
                <option value>---------</option>
                <option>Test</option>
              </select>
            </div>
          </div>
          <div className="form-row">
            <label className="required">Custom Name:</label>
            <div>
              <input type="text" formcontrolname="customName" />
            </div>
          </div>
          <div className="form-row">
            <label className="required">Reward Period:</label>
            <div>
              <input type="text" formcontrolname="rewardPeriod" />
            </div>
          </div>
          <div className="form-row">
            <label className="required">Url:</label>
            <div>
              <input type="text" formcontrolname="url" />
            </div>
          </div>
          <div className="form-row">
            <label className="required">Title:</label>
            <div>
              <input type="text" formcontrolname="title" />
            </div>
          </div>
          <div className="form-row">
            <label className="required">Description:</label>
            <div>
              <textarea
                cols={40}
                rows={10}
                maxLength={500}
                type="text"
                formcontrolname="description"
                defaultValue={""}
              />
            </div>
          </div>
          <div className="form-row">
            <label className="required">Expiry Date:</label>
            <div>
              <input type="text" formcontrolname="expiryDate" />
              <small>Note: You are 5.5 hours ahead of server time.</small>
            </div>
          </div>
          <div className="form-row">
            <label className="required">Required Steps:</label>
            <div>
              <input type="text" formcontrolname="requiredSteps" />
            </div>
          </div>
          <div className="form-row">
            <label className="required">Promo value:</label>
            <div>
              <input type="text" formcontrolname="promoValue" />
            </div>
          </div>
          <div className="form-row">
            <label className="required">Reward Type:</label>
            <div>
              <input type="text" formcontrolname="rewardType" />
            </div>
          </div>
          <div className="form-row">
            <label className="required">Controller Image:</label>
            <div>
              <input type="text" formcontrolname="controllerImage" />
            </div>
          </div>
          <div className="form-row">
            <label className="required">Popularity:</label>
            <div>
              <input type="text" formcontrolname="popularity" />
            </div>
          </div>
          <div className="form-row">
            <label className="required">Willow url:</label>
            <div>
              <input type="text" formcontrolname="willowUrl" />
            </div>
          </div>
          <div className="form-row">
            <label>Is active:</label>
            {/* <div> <input type="text" formControlName="isActive"></div> */}
            <input
              formcontrolname="isActive"
              className=""
              defaultValue="isActive"
              type="checkbox"
            />
          </div>
          <div className="submit-row">
            <input
              type="submit"
              defaultValue="Save"
              className="default"
              name="_save"
            />
            <input
              type="submit"
              defaultValue="Save and add another"
              name="_addanother"
            />
            <input
              type="submit"
              defaultValue="Save and continue editing"
              name="_continue"
            />
          </div>
        </form>
      </div>
    </>
  );
}

export default Reward;
