import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {useState } from "react";
import Box from "@mui/material/Box";
import PersonIcon from "@mui/icons-material/Person";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// import AnonymousUserLayout from "../../common/anonymous-user-layout/anonymous-user-layout";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import "react-toastify/dist/ReactToastify.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AnonymousUserLayout from "../common/anonymous-user-layout/anonymous-user-layout";
import Colors from "../../config/colors";
import { ArrowBack } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { getAuth, userLogin } from "../../redux/slices/authSlice";

function FactorAuthenticate() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state = {} } = useLocation();

  const [loginError, setLoginError] = useState("");
  const [message, setMessage] = useState("");
  const [isLogin, setIsLogin] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const getVerifyCode = (data) => {
    setIsLogin(true);
    setLoginError("");
    setMessage("");
    dispatch(
      userLogin({
        username: state.username,
        password: state.password,
        code: data.code,
      })
    )
      .unwrap()
      .then((response) => {
        setIsLogin(false);
        dispatch(getAuth());
        navigate("/app/offers");
      })
      .catch((error) => {
        setIsLogin(false);
        try {
          setLoginError(error.data.error.message);
        } catch (err) {
          setLoginError(
            "Not Valid! Please verify your entered credentials or verification Code."
          );
        }
      });
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: Colors.primary,
      },
      secondary: {
        main: Colors.secondary,
      },
    },
    components: {
      MuiInputLabel: {
        defaultProps: {
          sx: {
            fontSize: "20px",
          },
        },
      },
      MuiOutlinedInput: {
        defaultProps: {
          sx: {
            fontSize: "20px",
          },
        },
      },
    },
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <AnonymousUserLayout />
        <Container
          component="main"
          sx={{
            height: "calc(100vh - 64px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "64px",
          }}
        >
          <Box
            style={{
              width: "fit-content",
            }}
          >
            <Box
              style={{
                width: "500px",
                padding: "54px 28px",
                border: "1px solid rgb(204, 204, 204)",
                borderRadius: "8px",
                background: "#fff",
              }}
            >
              <form onSubmit={handleSubmit(getVerifyCode)}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="h4"
                    style={{
                      color: "#000",
                      marginBottom: "24px",
                      fontWeight: "bold",
                    }}
                  >
                    Code Verification
                  </Typography>
                </Box>

                <TextField
                  {...register("code", {
                    required: true,
                  })}
                  error={Boolean(errors.email)}
                  helperText={errors.code ? "Please Enter Code" : ""}
                  margin="normal"
                  fullWidth
                  label="Code"
                  autoFocus
                  InputProps={{
                    endAdornment: <PersonIcon sx={{ color: "#ccc" }} />,
                    autoComplete: "off",
                  }}
                />

                <Button
                  fullWidth
                  size="large"
                  sx={{
                    fontSize: "20px",
                    marginTop: "16px",
                    color: "#edc865",
                    fontWeight: "bold",
                    height: "56px",
                    "&:hover": {
                      background: "#edc865",
                      color: "#7a5700",
                    },
                  }}
                  variant="contained"
                  type="submit"
                >
                  Proceed
                  {isLogin === true && (
                    <i
                      style={{ marginLeft: "auto" }}
                      className={`fa fa-circle-o-notch ${
                        true ? "fa-spin" : ""
                      }`}
                      aria-hidden="true"
                    ></i>
                  )}
                  {isLogin === false && (
                    <ArrowForwardIcon style={{ marginLeft: "auto" }} />
                  )}
                </Button>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ mt: 2, color: "#676767" }}>
                    <span
                      style={{ textDecoration: "none" }}
                      className="text-danger"
                    >
                      &nbsp;{loginError}
                    </span>
                  </Typography>
                  {message && (
                    <p
                      style={{ textDecoration: "none" }}
                      className="text-success"
                    >
                      &nbsp;{message}
                    </p>
                  )}

                  <Link
                    to="/login"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "32px",
                      textDecoration: "none",
                      color: "#676767",
                    }}
                  >
                    <Typography sx={{ color: "#676767" }}>Back</Typography>
                    <ArrowBack style={{ height: "16px", marginLeft: "auto" }} />
                  </Link>
                </Box>
              </form>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </>
  );
}

export default FactorAuthenticate;
