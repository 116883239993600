import * as React from "react";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { neobankUpdateAppConfig } from "../../../services/neobank-application-config";
import { getNeobankConfigsDataById } from "../../../redux/slices/neobankAppConfigSlice";

function AddEditAppConfiguration(props) {
  const appConfigsDataId = useSelector((state) => state.appConfigsData);
  const { single: AppConfigsDataItem } = appConfigsDataId;
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (AppConfigsDataItem.data) {
      if (id !== "0") {
        reset({
          key_name: AppConfigsDataItem.data?.key_name,
          value: AppConfigsDataItem.data?.value,
        });
      } else {
        reset({
          key_name: "",
          value: "",
        });
      }
    }
  }, [AppConfigsDataItem]);

  useEffect(() => {
    dispatch(
      getNeobankConfigsDataById({
        id,
      })
    );
  }, [id]);

  const updateAppConfigs = (data) => {
    const buttonType = window.event.submitter.name;
    const { key_name, value } = data;

    neobankUpdateAppConfig({
      id: id !== "0" ? id : null,
      key_name: key_name,
      value: value,
    })
      .then((response) => {
        if (buttonType === "save") {
          navigate("/app/neobank-app-config");
        }

        if (buttonType === "savegoanother") {
          navigate("/app/neobank-app-config/0");
          reset({
            key_name: "",
            value: "",
          });
        }
        if (id) {
          if (buttonType === "savecontinue") {
            const ids = response.data.id;
            navigate(`/app/neobank-app-config/${ids}`);
          }
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  return (
    <>
      <Box sx={{ ml: "4", pt: "14 !important", pb: "5", px: 3 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "25px",
            marginTop: "20px",
            gap: "16px",
          }}
        >
          <Link to="/app/neobank-app-config">
            <ArrowBackIcon
              fontSize="large"
              l
              color="secondary"
              sx={{ background: "#040721", p: 1, borderRadius: "50%" }}
            />
          </Link>
          <Typography
            variant="h5"
            component="h5"
            fontWeight={"bold"}
            color={"#3d3d3d"}
          >
            Add Application Configuration
          </Typography>
        </div>
        <Box sx={{ background: "#ffffff", p: 2, mb: 4, pb: 4 }}>
          <form onSubmit={handleSubmit(updateAppConfigs)}>
            <div className="row">
              <div className="col-md-7">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="key_name"
                  >
                    Key:
                    {id ? (
                      <input
                        defaultValue={AppConfigsDataItem.data?.id}
                        {...register("id")}
                        type="hidden"
                      />
                    ) : (
                      <small className="text-danger fs-6">*</small>
                    )}
                  </label>
                  <input
                    {...register("key_name", { required: true })}
                    className={`form-control ${
                      errors.key_name && "invalid-form-input"
                    }`}
                    type="text"
                    id="key_name"
                    readonly={id !== "0" ? "readonly" : null}
                  />
                </div>
              </div>
              <div className="col-md-7">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="value"
                  >
                    Value: <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("value", { required: true })}
                    className={`form-control ${
                      errors.value && "invalid-form-input"
                    }`}
                    type="text"
                    id="value"
                  />
                </div>
              </div>
            </div>
            <hr style={{ height: "0.5px", marginTop: "32px" }} />
            <div>
              <button
                type="submit"
                className="btn btn-primary py-2 px-5"
                name="save"
                variant="contained"
                sx={{ width: "25%" }}
              >
                Save
              </button>
              <button
                variant="contained"
                type="submit"
                className="btn btn-primary py-2 px-5"
                name="savegoanother"
                sx={{ width: "25%" }}
              >
                Save And Add Another
              </button>
              <button
                variant="contained"
                type="submit"
                className="btn btn-primary py-2 px-5"
                name="savecontinue"
                sx={{ width: "25%" }}
              >
                Save and Continue Editing
              </button>
            </div>
          </form>
        </Box>
      </Box>
    </>
  );
}

export default AddEditAppConfiguration;
