import AppModal from "../../components/modal/AppModal";
import style from "../../assets/css/appModal.module.scss";
import { returnTransaction } from "../../services/bank";
import { useEffect, useState } from "react";

const RemittanceReturnModal = ({ id, getTxnList, handleClose }) => {
  const [returnReferenceId, setReturnReferenceId] = useState("");
  const [returnReferenceRequired, setReturnReferenceRequired] = useState(false);
  const [response, setResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const txn_ref_id = id;

  const fetchReturnData = (response) => {
    setResponse(response);
  };

  const handleRefIdChange = (e) => {
    setReturnReferenceId(e.target.value);
  };

  const handleAction = (type) => {
    if (!returnReferenceId && type === "MANUAL") {
      setReturnReferenceRequired(true);
      return;
    }
    setIsLoading(true);
    returnTransaction(
      txn_ref_id,
      returnReferenceId,
      type === "IGNORE" ? true : false
    )
      .then((data) => {
        const { error, response } = data || {};
        setIsLoading(false);
        if (error) {
          fetchReturnData({ error });
          return;
        }
        fetchReturnData(response);
        getTxnList();
      })
      .catch((error) => {
        setIsLoading(false);
        fetchReturnData(error.data);
        throw error;
      });
  };

  return (
    <div>
      <AppModal handleClose={handleClose}>
        <div className={style.container}>
          <div className={style.bodyBox}>
            <div className={style.closeIcon} onClick={() => handleClose()}>
              <span className=" fa fa-close"></span>
            </div>

            {!response ? (
              <div className={style.header}>Choose an action</div>
            ) : (
              <div className={style.header}>
                {response?.error ? "Oops!" : "Success!"}
              </div>
            )}

            {!response ? (
              <>
                <div className={style.inputContainer}>
                  <div className={style.label}>Return Ref Id</div>

                  <input
                    className={style.inputBox}
                    type="text"
                    maxLength={50}
                    onChange={(e) => handleRefIdChange(e)}
                    required={returnReferenceRequired ? true : false}
                  />
                </div>

                {returnReferenceRequired && (
                  <span className={style.errorMsg}>Please enter ref id</span>
                )}

                <div className={style.footer}>
                  <>
                    <div className={style.btn1}>
                      <button
                        className="btn btn-primary text-nowrap py-2 px-5"
                        onClick={() => handleAction("MANUAL")}
                        disabled={isLoading}
                      >
                        Manual Return
                      </button>
                    </div>

                    <div className={style.btn1}>
                      <button
                        className="btn btn-primary py-2 px-5"
                        onClick={() => handleAction("RETURN")}
                        disabled={isLoading}
                      >
                        Return
                      </button>
                    </div>

                    <div className={style.btn2}>
                      <button
                        className="btn btn-primary text-nowrap py-2 px-5"
                        onClick={() => handleAction("IGNORE")}
                        disabled={isLoading}
                      >
                        Ignore Return
                      </button>
                    </div>
                  </>
                </div>
                <div className={style.errorMsg}>{response?.error?.message}</div>
              </>
            ) : (
              <div className={style.heading}>
                {response?.error ? response.error?.message : response}{" "}
              </div>
            )}
            {response && (
              <div className={style.successBtn}>
                <button
                  className="btn btn-primary text-nowrap py-2 px-5"
                  onClick={() => handleClose()}
                >
                  Okay
                </button>
              </div>
            )}
          </div>
        </div>
      </AppModal>
    </div>
  );
};

export default RemittanceReturnModal;
