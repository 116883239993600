import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import localforage from "localforage";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CustomTable from "../../../components/CustomTable";
import { filterList } from "../../../libs/utils";
import { listBonusReferral } from "../../../services/bonusReferral";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";

function ListBonusReferral() {
  const [rows, setRows] = useState();
  const [loading, setLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [isDynamicTable, setIsDynamicTable] = useState(false);

  const columns = [
    {
      id: "CampaignName",
      label: "Campaign Name",
      minWidth: 200,
      align: "left",
      enableSort: true,
    },
    {
      id: "CampaignStartDate",
      label: "Campaign Start Date",
      minWidth: 100,
      align: "center",
      enableSort: true,
    },

    {
      id: "CampaignEndDate",
      label: "Campaign End Date",
      minWidth: 100,
      align: "center",
      enableSort: true,
    },
    {
      id: "ReferralBonusAmount",
      label: "Referral Bonus Amount",
      minWidth: 100,
      align: "center",
      enableSort: true,
    },
    {
      id: "TotalReferralNo",
      label: "Total Referral No",
      minWidth: 100,
      align: "left",
      enableSort: true,
    },
    {
      id: "Title",
      label: "Title",
      minWidth: 300,
      align: "left",
    },
    {
      id: "SubTitle",
      label: "Sub Title",
      minWidth: 300,
      align: "left",
    },
    {
      id: "ReferralLabel",
      label: "Referral Label",
      minWidth: 100,
      align: "left",
    },
    {
      id: "Action",
      label: "Action",
      minWidth: 100,
      align: "left",
    },
  ];

  const setDynamicTable = (parentWidth, tableWidth) => {
    setIsDynamicTable(true);
  };

  const searchData = (e) => {
    setSearchKey(e.target.value);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dataGenerator = (data) => {
    return {
      CampaignName: data.campaignName,
      CampaignStartDate: moment
        .unix(data.campaignStartDate / 1000)
        .format("MMM DD ,YYYY"),
      CampaignEndDate: moment
        .unix(data.campaignEndDate / 1000)
        .format("MMM DD ,YYYY"),
      ReferralBonusAmount: data.referralBonusAmount,
      TotalReferralNo: data.referralLabel,
      Title: data.title,
      SubTitle: data.subTitle,
      ReferralLabel: data.referralLabel,
      Action: (
        <Link
          to={`/app/bonus-referal/${data.id}`}
          style={{ textDecoration: "none" }}
        >
          <Button variant="contained" size="small" color="warning">
            Edit
          </Button>
        </Link>
      ),
    };
  };

  useEffect(() => {
    setLoading(true);
    localforage.getItem("@bonusReferral", (error, data) => {
      if (data) {
        if (data?.length > 0 && typeof data === "object") {
          setRows(data.map((data) => dataGenerator(data)));
        } else {
          setRows([]);
        }
      }
    });
    listBonusReferral()
      .then((response) => {
        setLoading(false);
        if (response?.length > 0 && typeof response === "object") {
          setRows(response.map((data) => dataGenerator(data)));
        } else {
          setRows([]);
        }
        localforage.setItem("@bonusReferral", response, (error, data) => {
          if (error) {
            throw error;
          }
        });
      })
      .catch((error) => {
        setLoading(false);
        throw error;
      });
  }, []);

  return (
    <>
      <Box
        sx={{ px: 6 }}
        className={`main-box ${isDynamicTable ? "dynamicTableStyle" : ""}`}
      >
        <div
          className={`bankAddRuleHeader mb-3 ${
            isDynamicTable ? "listHeaderPaddingRight" : ""
          }`}
        >
          <div
            style={{
              display: "flex",
              padding: "1.5rem 0",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: "#33343a" }} variant="h4" component="h4">
              Bonus Referral
            </Typography>
            {loading && (
              <div
                style={{
                  marginLeft: "12px",
                  color: "#a0a0a0",
                  fontSize: "24px",
                }}
              >
                <i
                  className={`fa fa-refresh ${
                    loading === true ? "fa-spin" : ""
                  }`}
                  aria-hidden="true"
                ></i>
              </div>
            )}
          </div>
          <div style={{ display: "flex", alignItems: "end" }}>
            <TextField
              id="standard-basic"
              label="Search"
              variant="standard"
              sx={{ mr: 1 }}
              onKeyUp={(e) => searchData(e)}
              InputProps={{
                endAdornment: <SearchIcon sx={{ color: "#8a8a8a" }} />,
              }}
            />
            {/* <input
                onKeyUp={(e) => searchData(e)}
                type="search"
                placeholder="Search"
                className="form-control searchInput"
              /> */}
            {/* <Link to="/app/merchant/0">
                <Button size="large" variant="contained" color="primary">
                  Add New Merchant
                </Button>
              </Link> */}
            <Tooltip title="Choose Option">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <MoreVertIcon sx={{ width: 32, height: 32 }} />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem
                sx={{
                  "&:hover": {
                    background: "#f9da8792",
                    color: "#000",
                  },
                }}
              >
                <Link to="/app/bonus-referal/0" style={{ color: "#040721" }}>
                  Add New Bonus Referral
                </Link>
              </MenuItem>
              {/* <Divider /> */}
              {/* <MenuItem>
                  <ListItemIcon>
                    <PersonAdd fontSize="small" />
                  </ListItemIcon>
                  Add another account
                </MenuItem> */}
            </Menu>
          </div>
        </div>
        {/* <Box sx={{ pb: 4 }}>
            <TextField id="standard-basic" label="Search" variant="standard" />
          </Box> */}
        <CustomTable
          setDynamicTable={setDynamicTable}
          data={filterList(rows, searchKey) || []}
          columns={columns}
        />
      </Box>
    </>
  );
}

export default ListBonusReferral;
