import { memo, useMemo, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { ItemTypes } from "./itemTypes";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

const style = {
  border: "1px solid #ced4da",
  padding: "0.7rem 1rem",
  backgroundColor: "white",
  cursor: "move",
  display: "flex",
  paddingLeft: "4px",
  fontSize: "1.3rem",
  borderRadius: "0.25rem",
  marginBottom: "16px",
};
export const Card = memo(function Card({ id, text, moveCard }) {
  const ref = useRef(null);
  const [{ isDragging, handlerId }, connectDrag] = useDrag({
    type: ItemTypes.CARD,
    item: { id },
    collect: (monitor) => {
      const result = {
        handlerId: monitor.getHandlerId(),
        isDragging: monitor.isDragging(),
      };
      return result;
    },
  });
  const [, connectDrop] = useDrop({
    accept: ItemTypes.CARD,
    hover({ id: draggedId }) {
      if (draggedId !== id) {
        moveCard(draggedId, id);
      }
    },
  });
  connectDrag(ref);
  connectDrop(ref);
  const opacity = isDragging ? 0 : 1;
  const containerStyle = useMemo(() => ({ ...style, opacity }), [opacity]);
  return (
    <div ref={ref} style={containerStyle} data-handler-id={handlerId}>
      <div>
        <DragIndicatorIcon style={{ marginRight: "6px", color: "#919191" }} />
      </div>
      <div>{text}</div>
    </div>
  );
});
