import React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Swal from "sweetalert2";
import {
  gethowitworksbyid,
  updatehowitwork,
} from "../../../services/howitwork";
function HowItWorkS(props) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const { id } = useParams();
  const [howitwork, sethowitwork] = useState();
  const navigation = useNavigate();

  useEffect(() => {
    if (id) {
      gethowitworksbyid(id)
        .then((response) => {
          sethowitwork(response);
          if (response) {
            reset({
              question: response?.question,
              promoType: response?.promoType,
              answer: response?.answer,
              id: response?.id,
              objectId: response?.objectId,
            });
          }
        })
        .catch((error) => {
          throw error;
        });
    }
  }, [id]);

  const updateCategoryData = (data) => {
    const buttonType = window.event.submitter.name;
    if (buttonType === "save") {
      updatehowitwork(data)
        .then((response) => {
          Swal.fire({
            title: "Success",
            text: "How It Work Has been saved successfully",
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              navigation(-1);
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "Oops",
            text: "Something went wrong",
            icon: "warning",
            confirmButtonText: "Ok",
          }).then((result) => {});
          throw error;
        });
    }
    if (buttonType === "savegoanother") {
      updatehowitwork(data)
        .then((response) => {
          Swal.fire({
            title: "Success",
            text: "How It Work Has been saved successfully",
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              navigation("/app/how-it-works/0");
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "Oops",
            text: "Something went wrong",
            icon: "warning",
            confirmButtonText: "Ok",
          }).then((result) => {});
          throw error;
        });
    }
    if (buttonType === "savecontinue") {
      updatehowitwork(data)
        .then((response) => {
          Swal.fire({
            title: "Success",
            text: "How It Work Has been saved successfully",
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {});
        })
        .catch((error) => {
          Swal.fire({
            title: "Oops",
            text: "Something went wrong",
            icon: "warning",
            confirmButtonText: "Ok",
          }).then((result) => {});
          throw error;
        });
    }
  };

  return (
    <>
      <Box sx={{ ml: "4", pt: "14 !important", pb: "5", px: 3 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "25px",
            marginTop: "20px",
            gap: "16px",
          }}
        >
          <Link to="/app/list-how-it-works">
            <ArrowBackIcon
              fontSize="large"
              color="secondary"
              sx={{ background: "#040721", p: 1, borderRadius: "50%" }}
            />
          </Link>
          <Typography
            variant="h5"
            component="h5"
            fontWeight={"bold"}
            color={"#3d3d3d"}
          >
            Edit How it Works
          </Typography>
        </div>
        <Box sx={{ background: "#ffffff", p: 2, mb: 4, pb: 4 }}>
          <form onSubmit={handleSubmit(updateCategoryData)}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="name"
                  >
                    Question: <small className="text-danger fs-6">*</small>
                  </label>
                  <textarea
                    {...register("question", { required: true })}
                    className={`form-control ${
                      errors.question && "invalid-form-input"
                    }`}
                    type="text"
                    id="name"
                    cols="60"
                    rows="7"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="name"
                  >
                    Answer: <small className="text-danger fs-6">*</small>
                  </label>
                  <textarea
                    {...register("answer", { required: true })}
                    className={`form-control ${
                      errors.answer && "invalid-form-input"
                    }`}
                    type="text"
                    id="name"
                    cols="60"
                    rows="7"
                  />
                </div>
              </div>
              <input
                {...register("id")}
                className={`form-control`}
                type="hidden"
                id="name"
              />
              <input
                {...register("objectId")}
                className={`form-control`}
                type="hidden"
                id="name"
              />

              <div className="col-md-12">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="name"
                  >
                    Promo Type:
                  </label>
                  <select
                    {...register("promoType")}
                    className={`form-control ${
                      errors.promoType && "invalid-form-input"
                    }`}
                    type="select"
                    id="name"
                  >
                    <option value="">Select</option>
                    <option value="o">Online</option>
                    <option value="l">Local</option>
                    <option value="b">Both</option>
                    <option value="r">Reward</option>
                  </select>
                </div>
              </div>
            </div>
            <hr style={{ height: "0.5px", marginTop: "32px" }} />
            <div>
              <button
                type="submit"
                className="btn btn-primary py-2 px-5"
                name="save"
                variant="contained"
                sx={{ width: "25%" }}
              >
                Save
              </button>
              <button
                variant="contained"
                type="submit"
                className="btn btn-primary py-2 px-5"
                name="savegoanother"
                sx={{ width: "25%" }}
              >
                Save And Add Another
              </button>
              <button
                variant="contained"
                type="submit"
                className="btn btn-primary py-2 px-5"
                name="savecontinue"
                sx={{ width: "25%" }}
              >
                Save and Continue Editing
              </button>
            </div>
          </form>
        </Box>
      </Box>
    </>
  );
}

export default HowItWorkS;
