import appConfig from "../config/app.config";
import axios from "axios";
import { getUserToken } from "../libs/user";
import {
  getPureJSON,
  handleAPIError,
  startLoader,
  stopLoader,
} from "../libs/utils";
import { isObject } from "@mui/x-data-grid/utils/utils";
const authToken = getUserToken();

const getPlaidBanksList = () => {
  startLoader();
  return new Promise((resolve, reject) => {
    var config = {
      method: "get",
      url: appConfig.apiUrl.plaidbankslist,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader();
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader();
        reject(error);
      });
  });
};

const getplaidbankbyid = (id) => {
  startLoader(1);
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${appConfig.apiUrl.plaidbankslist}/plaid-bank/${id}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader(1);
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader(1);
        handleAPIError(error.status);
        reject(getPureJSON(error.response || error));
      });
  });
};

const updatePlaidBanks = (obj) => {
  startLoader(1);
  const authToken = getUserToken();
  return new Promise((resolve, reject) => {
    var data = JSON.stringify({
      id: obj.id,
      ignored: obj.ignored,
      blocked: obj.blocked,
    });

    var config = {
      method: "post",
      url: appConfig.apiUrl.plaidbankslist + "/" + "update",
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        stopLoader(1);
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader(1);
        reject(getPureJSON(error.response || error));
      });
  });
};
export { getPlaidBanksList, getplaidbankbyid, updatePlaidBanks };
