import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getRiskConfigDataById } from "../../services/riskConfigurationService";


export const getRiskConfigById = createAsyncThunk(
    "reward/getAppConfigsDataById",
    async (obj,{ rejectWithValue }) => {
      try {
        const { riskProfile ,sourceParam} = obj;
        const response = await getRiskConfigDataById(riskProfile,sourceParam);
        return response;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  
const initialState = {
  list: {
    data: [],
    error: null,
    status: null,
  },
  single: {
    data: null,
    error: null,
    status: null,
  },
};

export const RiskConfigsDataSlice = createSlice({
  name: "riskConfigsData",
  initialState: initialState,
  extraReducers: (builder) => {
    builder
   
      .addCase(getRiskConfigById.pending, (state, action) => {
        state.single = { ...state.single, status: "loading" };
      })
      .addCase(getRiskConfigById.fulfilled, (state, action) => {
        state.single = {
          ...state.single,
          status: "succeeded",
          data: action.payload,
          error: null,
        };
      })
      .addCase(getRiskConfigById.rejected, (state, action) => {
        state.single = {
          ...state.single,
          status: "failed",
          error: action.payload,
          data: [],
        };
      });
  },
});

export default RiskConfigsDataSlice.reducer;
