import React, { useEffect, useState, useLayoutEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  editOfferGroups,
  getOfferGroupsById,
} from "../../../services/offerGroups";
import { Box, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Container } from "../../../components/dnd/Container";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Swal from "sweetalert2";

function EditOfferGroup() {
  const navigation = useNavigate();
  const { id } = useParams();

  const [shouldRender, setShouldRender] = useState(false);
  const [sortedOffer, setSortedOffer] = useState([]);

  const templateTypeList = [
    { value: "bigCard", label: "Big Card" },
    { value: "card", label: "Card" },
    { value: "local", label: "Local" },
    { value: "smallCard", label: "Small Card" },
    { value: "categoryOffersList", label: "Category Offers List" },
    { value: "list", label: "List" },
    { value: "gpsToActivate", label: "GPS To Activate" },
    { value: "activateNotifications", label: "Activate Notifications" },
    { value: "referList", label: "Refer List" },
    { value: "linkPlaidCard", label: "Link Plaid Card" },
    { value: "seeAllOffers", label: "See All Offers" },
    { value: "offerLocals", label: "Offer Locals" },
    { value: "cashbackLoader", label: "Cashback Loader" },
    { value: "noCashback", label: "No Cashback" },
    { value: "getCashback", label: "Get Cashback" },
    { value: "referCard", label: "Refer Card" },
    { value: "invalidCard", label: "Invalid Card" },
    { value: "logoCard", label: "Logo Card" },
    { value: "carouselCards", label: "Carousel Cards" },
  ];

  const [offerList, setOfferList] = useState([]);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const updateOfferGroup = (data) => {
    data.offerIds = sortedOffer;

    editOfferGroups(id, data)
      .then((response) => {
        Swal.fire({
          title: "Success",
          text: "Offer group has been saved successfully",
          icon: "success",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            navigation(-1);
          }
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Oops",
          text: "Something went wrong",
          icon: "warning",
          confirmButtonText: "Ok",
        }).then((result) => {});
        throw error;
      });
  };

  const buildCardData = (data) => {
    const cardsById = {};
    const cardsByIndex = [];
    for (let i = 0; i < data.length; i += 1) {
      const card = { id: i, text: data[i].offerName, offerId: data[i].offerId };
      cardsById[card.id] = card;
      cardsByIndex[i] = card;
    }
    return {
      cardsById,
      cardsByIndex,
    };
  };

  const getSortedData = (data) => {
    if (data) {
      const sortedOffer = data.cardsByIndex.map((v, i) => {
        return { offerId: v.offerId, position: i, text: v.text };
      });
      sortedOffer.current = sortedOffer;
      setSortedOffer(sortedOffer);
    }
  };

  useEffect(() => {
    if (id) {
      getOfferGroupsById(id)
        .then((response) => {
          if (response) {
            reset({
              groupTitle: response.groupTitle,
              description: response.description,
              templateType: response.templateType,
              groupType: response.groupType,
              icon: response.icon,
              position: response.position,
              isActive: response.isActive,
            });

            setSortedOffer(
              response.offerIds.map((v) => {
                return {
                  offerId: v.offerId,
                  position: v.position,
                };
              })
            );

            setOfferList(
              response.offerIds.map((v) => {
                return {
                  offerId: v.offerId,
                  offerName: v.offerName,
                  position: v.position,
                };
              })
            );
          }
        })
        .catch((error) => {
          throw error;
        });
    }
  }, [id]);

  useLayoutEffect(() => {
    setShouldRender(true);
  }, [offerList]);

  return (
    <>
      <Box sx={{ ml: "4", pt: "14 !important", pb: "5", px: 3 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "25px",
            marginTop: "20px",
            gap: "16px",
          }}
        >
          <Link to="/app/list-offer-groups">
            <ArrowBackIcon
              fontSize="large"
              color="secondary"
              sx={{ background: "#040721", p: 1, borderRadius: "50%" }}
            />
          </Link>
          <Typography
            variant="h5"
            component="h5"
            fontWeight={"bold"}
            color={"#3d3d3d"}
          >
            Edit Offer Group
          </Typography>
        </div>
        <Box sx={{ background: "#ffffff", p: 2, mb: 4, pb: 4 }}>
          <form onSubmit={handleSubmit(updateOfferGroup)} noValidate>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="group_title"
                  >
                    Group Title
                  </label>
                  <input
                    {...register("groupTitle")}
                    className={`form-control ${
                      errors.groupTitle && "invalid-form-input"
                    }`}
                    type="text"
                    id="group_title"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="group_type"
                  >
                    Group Type
                  </label>
                  <input
                    {...register("groupType")}
                    className="form-control"
                    type="text"
                    id="group_type"
                    readOnly
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="template_type"
                  >
                    Template Type <small className="text-danger fs-6">*</small>
                  </label>
                  <select
                    id="template_type"
                    {...register("templateType", { required: true })}
                    className={`form-select ${
                      errors.templateType && "invalid-form-input"
                    }`}
                  >
                    <option value="">Select</option>
                    {templateTypeList.map((data, index) => {
                      return (
                        <option key={index} value={data.value}>
                          {data.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="icon"
                  >
                    Icon
                  </label>
                  <input
                    {...register("icon")}
                    className={`form-control ${
                      errors.icon && "invalid-form-input"
                    }`}
                    type="text"
                    id="icon"
                  />
                </div>
              </div>
              {watch("templateType") === "categoryOffersList" && (
                <div className="col-md-12">
                  <div className="form-group">
                    <label
                      className="form-label fw-bold text-secondary user-select-none"
                      htmlFor="description"
                    >
                      Description
                    </label>
                    <textarea
                      {...register("description")}
                      className={`form-control ${
                        errors.description && "invalid-form-input"
                      }`}
                      type="text"
                      id="description"
                    />
                  </div>
                </div>
              )}

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="position"
                  >
                    Position <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("position", { required: true })}
                    className={`form-control ${
                      errors.position && "invalid-form-input"
                    }`}
                    type="text"
                    id="position"
                  />
                </div>
              </div>
              <div
                className="col-md-3 d-flex align-items-end"
                style={{ marginBottom: "12px" }}
              >
                <div className="form-groupform-check d-flex align-items-center gap-3 ">
                  <input
                    {...register("isActive")}
                    className="form-check-input"
                    type="checkbox"
                    id="isActive"
                  />
                  <label
                    className="form-check-label fw-bold text-secondary"
                    htmlFor="isActive"
                    style={{ lineHeight: "1" }}
                  >
                    Active
                  </label>
                </div>
              </div>
            </div>
            <hr style={{ height: "0.5px", marginTop: "32px" }} />
            <div className="row">
              <div className="col-md-12">
                {" "}
                <label
                  className="form-check-label fw-bold text-secondary mb-3"
                  htmlFor="offer_position"
                  style={{ lineHeight: "1" }}
                >
                  Offer Position
                </label>
              </div>
              <div className="col-md-12">
                <>
                  <DndProvider backend={HTML5Backend}>
                    {shouldRender && (
                      <Container
                        getSortedData={getSortedData}
                        dataList={buildCardData(offerList)}
                      />
                    )}
                  </DndProvider>
                </>
              </div>
            </div>

            <hr style={{ height: "0.5px", marginTop: "32px" }} />
            <div>
              <button className="btn btn-primary py-2 px-5" name="save">
                Save
              </button>
            </div>
          </form>
        </Box>
      </Box>
    </>
  );
}

export default EditOfferGroup;
