import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import { OffersUpload } from "../services/offers";
import Swal from "sweetalert2";
import { IMAGE_LABEL_MAPPING } from "../constants";
import {
  setSelectedFileType,
  setUploadImageLink,
} from "../redux/slices/offersSlice";

const ImageUploadUtility = ({
  uploadType,
  name,
  inputFiletype,
  label,
  inputImageName,
  disabled,
  fileAccepted = "image/*",
  handleUpload,
  fileUploaded,
  isUpload=false
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [uploadImage, setuploadImage] = useState();
  const [fileType, setFileType] = useState();

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const handleFileSelect = (evt) => {
    var files = evt.target.files;

    if (files[0].size > 5e6) {
      Swal.fire({
        title: "Oops",
        text: "File size exceeded, Allowed maximum size is 5 MB",
        icon: "warning",
        confirmButtonText: "Ok",
      }).then((result) => {});
    }
    setuploadImage(files[0]);
  };

  const imageUpload = () => {
    let flag = true;
    if (fileType === "" || inputFiletype === "") {
      flag = false;
      Swal.fire({
        title: "Oops",
        text: "Please choose file type",
        icon: "warning",
        confirmButtonText: "Ok",
      }).then((result) => {});
    } else if (!uploadImage) {
      flag = false;
      Swal.fire({
        title: "Oops",
        text: "Please choose file",
        icon: "warning",
        confirmButtonText: "Ok",
      }).then((result) => {});
    } else if (uploadImage?.size > 5e6) {
      flag = false;
      Swal.fire({
        title: "Oops",
        text: "File size exceeded, Allowed maximum size is 5 MB",
        icon: "warning",
        confirmButtonText: "Ok",
      }).then((result) => {});
    }

    if (flag === true) {
      setIsUploading(true);
      OffersUpload(
        inputFiletype
          ? inputFiletype
          : uploadType === "GENERIC"
          ? []
          : fileType,
        uploadImage,
        id,
        name ? name : [null],
        uploadType,
        inputImageName
      )
        .then((response) => {
          dispatch(setUploadImageLink(response));
          setIsUploading(false);
          Swal.fire({
            title: "Success",
            text: `${
              inputFiletype
                ? inputFiletype
                : uploadType === "GENERIC"
                ? "Image"
                : fileType
            } uploaded successfully`,
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              if (uploadType === "GENERIC") {
                navigation(-1);
              }
            }
          });
        })
        .catch(function (error) {
          setIsUploading(false);
          setFileType(null);
          if (error?.data?.error?.code === 7030) {
            Swal.fire({
              title: "Oops",
              text: "Image with same name already exists",
              icon: "warning",
              confirmButtonText: "Ok",
            }).then((result) => {});
          } else {
            Swal.fire({
              title: "Oops",
              text: "Something went wrong",
              icon: "error",
              confirmButtonText: "Ok",
            }).then((result) => {});
          }
          throw error;
        });
    }
  };

  const handleChange = (e) => {
    dispatch(setSelectedFileType(e.target.value));
    setFileType(e.target.value);
  };

  return (
    <>
      <div className="col-md-4">
        <div className="form-group">
          {uploadType !== "GENERIC" && (
            <label
              className="form-label fw-bold text-secondary user-select-none"
              htmlFor="file_type"
            >
              File Type
            </label>
          )}
          {uploadType !== "GENERIC" && (
            <select
              className={`form-select`}
              id="file_type"
              value={inputFiletype ? inputFiletype : fileType}
              onChange={(e) => handleChange(e)}
              disabled={disabled ? true : false}
            >
              {inputFiletype ? (
                <option value="filetype">{inputFiletype}</option>
              ) : (
                <>
                  <option value="">Select</option>

                  {IMAGE_LABEL_MAPPING.map((data, index) => {
                    return (
                      <>
                        <option key={index} value={data.value}>
                          {data.label}
                        </option>
                      </>
                    );
                  })}
                </>
              )}
            </select>
          )}
        </div>
      </div>
      <div className="col-md-8">
        <div className="form-group">
          {label && (
            <label
              className="form-label fw-bold text-secondary user-select-none"
              htmlFor="name"
            >
              {label}:
            </label>
          )}
          <div className="input-group mb-3" style={{ alignItems: "center" }}>
            <input
              onChange={(event) => handleFileSelect(event)}
              type="file"
              className="form-control"
              id="image"
              accept={fileAccepted}
              disabled={disabled ? true : false}
            />
            {(isUploading === true || fileUploaded === true) && (
              <button
                style={{ width: "150px" }}
                className="btn btn-primary btn-block"
                type="button"
              >
                Uploading{" "}
                <i
                  className={`fa fa-refresh ${true ? "fa-spin" : ""}`}
                  aria-hidden="true"
                ></i>
              </button>
            )}
            {(isUploading === false || fileUploaded === false) && (
              <button
                style={{ width: "150px" }}
                className="btn btn-primary btn-block"
                type="button"
                onClick={
                  isUpload
                    ? () => handleUpload(uploadImage)
                    : () => imageUpload()
                }
                disabled={disabled ? true : false}
              >
                Upload
              </button>
            )}
          </div>
        </div>
      </div>

      {disabled && (
        <div className="col-md-12">
          <p className="text-primary">
            Before uploading the file, you have to click on the save and
            continue editing option, after that you can upload the file.
          </p>
        </div>
      )}
    </>
  );
};

export default ImageUploadUtility;
