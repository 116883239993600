import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Box, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Swal from "sweetalert2";
import { getActiveOffers } from "../../../services/cashback";
import { addPPO } from "../../../services/ppo";
function AddPartnerPromotionalOffers() {
  const navigate = useNavigate();
  const [activeGroups, setActiveGroups] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const addNewPPO = (data) => {
    const buttonType = window.event.submitter.name;
    if (buttonType === "save") {
      addPPO(data)
        .then((response) => {
          Swal.fire({
            title: "Success",
            text: `${data.offerName} has been saved successfully`,
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate(-1);
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "Oops",
            text: "Something went wrong",
            icon: "warning",
            confirmButtonText: "Ok",
          }).then((result) => {});
          throw error;
        });
    }

    if (buttonType === "savegoanother") {
      addPPO(data)
        .then((response) => {
          Swal.fire({
            title: "Success",
            text: `${data.offerName} has been saved successfully`,
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate(`/app/partner-promotional-offer/0`);
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "Oops",
            text: "Something went wrong",
            icon: "warning",
            confirmButtonText: "Ok",
          }).then((result) => {});
          throw error;
        });
    }
    if (buttonType === "savecontinue") {
      addPPO(data)
        .then((response) => {
          Swal.fire({
            title: "Success",
            text: `${data.offerName} has been saved successfully`,
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate(`/app/partner-promotional-offer/${response.id}`);
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "Oops",
            text: "Something went wrong",
            icon: "warning",
            confirmButtonText: "Ok",
          }).then((result) => {});
          throw error;
        });
    }
  };

  useEffect(() => {
    getActiveOffers()
      .then((list) => {
        setActiveGroups(list);
      })
      .catch(function (error) {
        throw error;
      });
  }, []);

  return (
    <>
      <Box sx={{ ml: "4", pt: "14 !important", pb: "5", px: 3 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "25px",
            marginTop: "20px",
            gap: "16px",
          }}
        >
          <Link to="/app/list-partner-promotional-offers">
            <ArrowBackIcon
              fontSize="large"
              color="secondary"
              sx={{ background: "#040721", p: 1, borderRadius: "50%" }}
            />
          </Link>
          <Typography
            variant="h5"
            component="h5"
            fontWeight={"bold"}
            color={"#3d3d3d"}
          >
            Add Partner Promotional Offer
          </Typography>
        </div>
        <Box sx={{ background: "#ffffff", p: 2, mb: 4, pb: 4 }}>
          <form onSubmit={handleSubmit(addNewPPO)} noValidate>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="title"
                  >
                    Title <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("title", { required: true })}
                    className={`form-control ${
                      errors.title && "invalid-form-input"
                    }`}
                    type="text"
                    id="title"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="subTitle"
                  >
                    Sub Title <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("subTitle", { required: true })}
                    className={`form-control ${
                      errors.subTitle && "invalid-form-input"
                    }`}
                    type="text"
                    id="subTitle"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="offerName"
                  >
                    Offer Name <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("offerName", { required: true })}
                    className={`form-control ${
                      errors.offerName && "invalid-form-input"
                    }`}
                    type="text"
                    id="offerName"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="offerId"
                  >
                    Offer Linked
                  </label>
                  <select
                    {...register("offerId")}
                    className={`form-select ${
                      errors.offerId && "invalid-form-input"
                    }`}
                    type="text"
                    id="offerId"
                  >
                    <option value="">Select</option>
                    {activeGroups.map((d, index) => (
                      <option key={index} value={d.id}>
                        {d.advertiserName} | {d.affiliatePartner}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="partnerName"
                  >
                    Partner Name <small className="text-danger fs-6">*</small>
                  </label>

                  <select
                    {...register("partnerName", { required: true })}
                    className={`form-select ${
                      errors.partnerName && "invalid-form-input"
                    }`}
                    type="text"
                    id="partnerName"
                  >
                    <option value="">Select</option>
                    <option value="TOI">TOI</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="clickUrl"
                  >
                    Click Url <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("clickUrl", { required: true })}
                    className={`form-control ${
                      errors.clickUrl && "invalid-form-input"
                    }`}
                    type="text"
                    id="clickUrl"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="priority"
                  >
                    Priority <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("priority", { required: true })}
                    className={`form-control ${
                      errors.priority && "invalid-form-input"
                    }`}
                    type="text"
                    id="priority"
                  />
                </div>
              </div>
              <div
                className="col-md-3 d-flex align-items-end mt-4"
                style={{ marginBottom: "12px" }}
              >
                <div className="form-groupform-check d-flex align-items-center gap-3 ">
                  <input
                    {...register("active")}
                    className={`form-check-input ${
                      errors.active && "invalid-form-input"
                    }`}
                    type="checkbox"
                    id="active"
                  />
                  <label
                    className="form-check-label fw-bold text-secondary"
                    htmlFor="active"
                    style={{ lineHeight: "1" }}
                  >
                    Is Active
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="file_type"
                  >
                    File Type
                  </label>
                  <select disabled className={`form-select`} id="file_type">
                    <option value="">Select</option>
                  </select>
                </div>
              </div>
              <div className="col-md-8">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="image"
                  >
                    File
                  </label>
                  <div className="input-group mb-3">
                    <input
                      disabled
                      type="file"
                      className="form-control"
                      id="image"
                    />
                    <button disabled className="btn btn-primary" type="button">
                      Upload
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <p className="text-primary">
                  Before uploading the file, you have to click on the save and
                  continue editing option, after that you can upload the file.
                </p>
              </div>
            </div>

            <hr style={{ height: "0.5px", marginTop: "32px" }} />
            <div>
              <button className="btn btn-primary py-2 px-5" name="save">
                Save
              </button>
              <button
                className="btn btn-warning py-2 px-5"
                name="savegoanother"
              >
                Save And Add Another
              </button>
              <button className="btn btn-success py-2 px-5" name="savecontinue">
                Save and Continue Editing
              </button>
            </div>
          </form>
        </Box>
      </Box>
    </>
  );
}

export default AddPartnerPromotionalOffers;
