import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as BankServices from "../../services/bank";

export const getPendingBankList = createAsyncThunk(
  "bank/getPendingBankList",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await BankServices.getPendingBankList();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  list: {
    data: [],
    error: null,
    status: null,
  },
};

export const bankSlice = createSlice({
  name: "bank",
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getPendingBankList.pending, (state, action) => {
        state.list = { ...state.list, status: "loading" };
      })
      .addCase(getPendingBankList.fulfilled, (state, action) => {
        state.list = {
          ...state.list,
          status: "succeeded",
          data: action.payload,
          error: null,
        };
      })
      .addCase(getPendingBankList.rejected, (state, action) => {
        state.list = {
          ...state.list,
          status: "failed",
          error: action.payload,
          data: [],
        };
      });
  },
});

export default bankSlice.reducer;
