import appConfig from "../config/app.config";
import axios from "axios";
import { getUserToken } from "../libs/user";
import { getPureJSON } from "../libs/utils";

const getCardSequenceList = () => {
  return new Promise((resolve, reject) => {
    var config = {
      method: "get",
      url: appConfig.apiUrl.cardSequence,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(getPureJSON(error.response || error));
      });
  });
};

const getCardSequenceById = (id) => {
  return new Promise((resolve, reject) => {
    var config = {
      method: "get",
      url: `${appConfig.apiUrl.cardSequence}/${id}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(getPureJSON(error.response || error));
      });
  });
};

const editCardSequence = (id, obj) => {
  return new Promise((resolve, reject) => {
    var data = JSON.stringify(Object.assign({ objectId: id }, obj));

    var config = {
      method: "post",
      url: `${appConfig.apiUrl.cardSequence}/save`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(getPureJSON(error.response || error));
      });
  });
};

export { getCardSequenceList, getCardSequenceById, editCardSequence };
