import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Box, Typography } from "@mui/material";
import Swal from "sweetalert2";
import { getTCStaticJSON, setTCStaticJSON } from "../../../services/staticJSON";
import HomeIcon from "@mui/icons-material/Home";
import { Link } from "react-router-dom";

function TopCardsStaticJson() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [mount, setMount] = useState(false);
  const updateTCStaticJSON = (request) => {
    const data = JSON.parse(request.json);

    setTCStaticJSON(data)
      .then((response) => {
        Swal.fire({
          title: "Success",
          text: "Top Card Static JSON has been saved successfully",
          icon: "success",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Oops",
          text: "Something went wrong",
          icon: "warning",
          confirmButtonText: "Ok",
        }).then((result) => {});
        throw error;
      });
  };

  useEffect(() => {
    setMount(true);
    if (mount) {
      getTCStaticJSON()
        .then((response) => {
          if (response) {
            reset({
              json: JSON.stringify(response, null, 12),
            });
          }
        })
        .catch((error) => {
          throw error;
        });
    }
  }, [mount]);

  return (
    <>
      <Box sx={{ ml: "4", pt: "14 !important", pb: "5", px: 3 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "25px",
            marginTop: "20px",
            gap: "16px",
          }}
        >
          <Link to="/app">
            <HomeIcon
              fontSize="large"
              color="secondary"
              sx={{ background: "#040721", p: 1, borderRadius: "50%" }}
            />
          </Link>
          <Typography
            variant="h5"
            component="h5"
            fontWeight={"bold"}
            color={"#3d3d3d"}
          >
            Edit Top Card Static JSON
          </Typography>
        </div>
        <Box sx={{ background: "#ffffff", p: 2, mb: 4, pb: 4 }}>
          <form onSubmit={handleSubmit(updateTCStaticJSON)} noValidate>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="json"
                  >
                    JSON
                  </label>
                  <textarea
                    rows={16}
                    {...register("json")}
                    className={`form-control ${
                      errors.json && "invalid-form-input"
                    }`}
                    type="text"
                    id="json"
                  />
                </div>
              </div>
            </div>

            <hr style={{ height: "0.5px", marginTop: "32px" }} />
            <div>
              <button className="btn btn-primary py-2 px-5" name="save">
                Save
              </button>
            </div>
          </form>
        </Box>
      </Box>
    </>
  );
}

export default TopCardsStaticJson;
