import * as React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Swal from "sweetalert2";
import { updateTiers } from "../../../services/tiers";
import { tierModel } from "../../../models/tierDataModel";
import ImageUploadUtility from "../../../utils/ImageUploadUtility";

function AddTiers() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigation = useNavigate();

  const updateTiersData = (data) => {
    const tierDataModel = tierModel;

    for (var key of Object.keys(tierDataModel)) {
      if (
        data[key] === "" ||
        data[key] === undefined ||
        data[key] === null ||
        data[key] === 0 ||
        data[key] === false ||
        Array.isArray(data[key]) !== Array.isArray(tierDataModel[key])
      ) {
        data[key] = tierDataModel[key];
      }
    }

    data.id = null;
    data.termsAndCondition = {
      link1: data.link1,
      link2: data.link2,
      linkText: data.linkText,
    };

    const buttonType = window.event.submitter.name;

    if (buttonType === "save") {
      updateTiers(data)
        .then((response) => {
          Swal.fire({
            title: "Success",
            text: "Tiers Has been saved successfully",
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              navigation(-1);
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "Oops",
            text: "Something went wrong",
            icon: "warning",
            confirmButtonText: "Ok",
          }).then((result) => {});
        });
    }
  };

  return (
    <>
      <Box sx={{ ml: "4", pt: "14 !important", pb: "5", px: 3 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "25px",
            marginTop: "20px",
            gap: "16px",
          }}
        >
          <Link to="/app/tiers">
            <ArrowBackIcon
              fontSize="large"
              color="secondary"
              sx={{ background: "#040721", p: 1, borderRadius: "50%" }}
            />
          </Link>
          <Typography
            variant="h5"
            component="h5"
            fontWeight={"bold"}
            color={"#3d3d3d"}
          >
            Add Tier
          </Typography>
        </div>
        <Box sx={{ background: "#ffffff", p: 2, mb: 4, pb: 4 }}>
          <form onSubmit={handleSubmit(updateTiersData)}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="name"
                  >
                    Name: <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("name", { required: true })}
                    className={`form-control ${
                      errors.name && "invalid-form-input"
                    }`}
                    type="text"
                    id="name"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="price"
                  >
                    Price: <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("price", { required: true })}
                    className={`form-control ${
                      errors.price && "invalid-form-input"
                    }`}
                    type="number"
                    id="price"
                  />
                </div>
              </div>
              <input
                {...register("id")}
                className={`form-control`}
                type="hidden"
                id="name"
              />

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="trialPeriodNo"
                  >
                    TrialPeriodNumber:
                  </label>
                  <input
                    {...register("trialPeriodNo")}
                    className={`form-control ${
                      errors.trialPeriodNo && "invalid-form-input"
                    }`}
                    type="text"
                    id="trialPeriodNo"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="appleProductId"
                  >
                    Apple Subs Id: <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("appleProductId", { required: true })}
                    className={`form-control ${
                      errors.appleProductId && "invalid-form-input"
                    }`}
                    type="text"
                    id="appleProductId"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="level"
                  >
                    Level: <small className="text-danger fs-6">*</small>
                  </label>
                  <input
                    {...register("level", { required: true })}
                    className={`form-control ${
                      errors.level && "invalid-form-input"
                    }`}
                    type="number"
                    id="level"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="description"
                  >
                    Description:
                  </label>
                  <input
                    {...register("description")}
                    className={`form-control ${
                      errors.description && "invalid-form-input"
                    }`}
                    type="text"
                    id="description"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="name"
                  >
                    Entitlement Id:
                  </label>
                  <select
                    {...register("entitlementId")}
                    className={`form-control ${
                      errors.entitlementId && "invalid-form-input"
                    }`}
                    type="select"
                    id="name"
                  >
                    <option value="">Select</option>
                    <option value="Pro">Pro</option>
                    <option value="Elite">Elite</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="name"
                  >
                    Active:
                  </label>
                  <select
                    {...register("active")}
                    className={`form-control ${
                      errors.active && "invalid-form-input"
                    }`}
                    type="select"
                    id="name"
                  >
                    <option value="">Select</option>
                    <option value="true">True</option>
                    <option value="false">False</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="name"
                  >
                    Single Colour:
                  </label>
                  <select
                    {...register("singleColour")}
                    className={`form-control ${
                      errors.singleColour && "invalid-form-input"
                    }`}
                    type="select"
                    id="name"
                  >
                    <option value="">Select</option>
                    <option value="true">True</option>
                    <option value="false">False</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <ImageUploadUtility
                  uploadType="TIER"
                  name="tierName"
                  inputFiletype="IMAGE"
                  label="Header Img"
                  disabled={true}
                />
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="priceSubTitle"
                  >
                    Price Sub Title:
                  </label>
                  <input
                    {...register("priceSubTitle")}
                    className={`form-control ${
                      errors.priceSubTitle && "invalid-form-input"
                    }`}
                    type="text"
                    id="priceSubTitle"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="subTitle"
                  >
                    Sub Title:
                  </label>
                  <input
                    {...register("subtitle")}
                    className={`form-control ${
                      errors.subtitle && "invalid-form-input"
                    }`}
                    type="text"
                    id="subTitle"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="tcLink1"
                  >
                    T & C Link 1:
                  </label>
                  <input
                    {...register("link1")}
                    className={`form-control ${
                      errors.link1 && "invalid-form-input"
                    }`}
                    type="text"
                    id="tcLink1"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="tcLink2"
                  >
                    T & C Link 2:
                  </label>
                  <input
                    {...register("link2")}
                    className={`form-control ${
                      errors.link2 && "invalid-form-input"
                    }`}
                    type="text"
                    id="tcLink2"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="tcLinktext"
                  >
                    T & C Link Text:
                  </label>
                  <input
                    {...register("linkText")}
                    className={`form-control ${
                      errors.linkText && "invalid-form-input"
                    }`}
                    type="text"
                    id="tcLinktext"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="trialPeriodUnit"
                  >
                    Trial Period Unit:
                  </label>
                  <input
                    {...register("trialPeriodUnit")}
                    className={`form-control ${
                      errors.trialPeriodUnit && "invalid-form-input"
                    }`}
                    type="text"
                    id="trialPeriodUnit"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="singleColorCode"
                  >
                    SingleColourCode:
                  </label>
                  <input
                    {...register("singleColourCode")}
                    className={`form-control ${
                      errors.singleColourCode && "invalid-form-input"
                    }`}
                    type="text"
                    id="singleColorCode"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="gradientStartColourCode"
                  >
                    GradientStartColourCode:
                  </label>
                  <input
                    {...register("gradientStartColourCode")}
                    className={`form-control ${
                      errors.gradientStartColourCode && "invalid-form-input"
                    }`}
                    type="text"
                    id="gradientStartColourCode"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="gradientEndColourCode"
                  >
                    GradientEndColourCode:
                  </label>
                  <input
                    {...register("gradientEndColourCode")}
                    className={`form-control ${
                      errors.gradientEndColourCode && "invalid-form-input"
                    }`}
                    type="text"
                    id="gradientEndColourCode"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="tierTextColour"
                  >
                    TierTextColour:
                  </label>
                  <input
                    {...register("tierTextColour")}
                    className={`form-control ${
                      errors.tierTextColour && "invalid-form-input"
                    }`}
                    type="text"
                    id="tierTextColour"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="trialPeriodBackGroundColour"
                  >
                    TrialPeriodBackgroundColour
                  </label>
                  <input
                    {...register("trialPeriodBackGroundColour")}
                    className={`form-control ${
                      errors.trialPeriodBackGroundColour && "invalid-form-input"
                    }`}
                    type="text"
                    id="trialPeriodBackGroundColour"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="profileSmallCardImg"
                  >
                    ProfileSmallCardImg
                  </label>
                  <input
                    {...register("profileSmallCardImg")}
                    className={`form-control ${
                      errors.profileSmallCardImg && "invalid-form-input"
                    }`}
                    type="text"
                    id="profileSmallCardImg"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="profileSubscriptionLogo"
                  >
                    ProfileSubscriptionLogo
                  </label>
                  <input
                    {...register("profileSubscriptionLogo")}
                    className={`form-control ${
                      errors.profileSubscriptionLogo && "invalid-form-input"
                    }`}
                    type="text"
                    id="profileSubscriptionLogo"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="profileSubscriptionDeepLink"
                  >
                    ProfileSubscriptionDeepLink
                  </label>
                  <input
                    {...register("profileSubscriptionDeepLink")}
                    className={`form-control ${
                      errors.profileSubscriptionDeepLink && "invalid-form-input"
                    }`}
                    type="text"
                    id="profileSubscriptionDeepLink"
                  />
                </div>
              </div>
            </div>
            <hr style={{ height: "0.5px", marginTop: "32px" }} />
            <div>
              <button
                type="submit"
                className="btn btn-primary py-2 px-5"
                name="save"
                variant="contained"
                sx={{ width: "25%" }}
              >
                Save or Update
              </button>
            </div>
          </form>
        </Box>
      </Box>
    </>
  );
}

export default AddTiers;
