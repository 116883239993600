import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import localforage from "localforage";
import React, { useEffect, useState } from "react";
import CustomTable from "../../../components/CustomTable";
import { filterList, searchExactList } from "../../../libs/utils";
import SearchIcon from "@mui/icons-material/Search";
import {
  getRemittanceReturnList,
  returnTransaction,
} from "../../../services/bank";
import Swal from "sweetalert2";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

import RemittanceReturnModal from "../../../components/modal/RemittanceReturnActionModal";

const StyledDatePicker = styled(DesktopDatePicker)({
  ".MuiInput-underline:before": {
    borderBottom: "1px solid #6F719B !important",
  },
  ".MuiInput-underline:after": {
    borderBottom: "2px solid #F9DA87",
  },
});

const DateTextField = styled(TextField)({
  width: "200px",
  marginRight: "28px",
  "& .MuiInput-underline:after": {
    borderBottom: "none",
  },
  "& .MuiSvgIcon-root": {
    color: "#6F719B",
  },
});

const styleBtn = {
  whiteSpace: "noWrap",
};
function RemittanceReturn() {
  const [rows, setRows] = useState();
  const [prevRows, setPrevRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [isDynamicTable, setIsDynamicTable] = useState(false);
  const [date, setDate] = useState(null);
  const [status, setStatus] = useState("");
  const [returnModalOpened, setReturnModalOpened] = useState(false);
  const [txnRefId, setTxnRefId] = useState("");

  const columns = [
    {
      id: "id",
      label: "Transaction ID",
      minWidth: 200,
      align: "left",
      enableSort: true,
    },
    {
      id: "email",
      label: "Email",
      minWidth: 200,
      align: "left",
      enableSort: true,
    },
    {
      id: "phone",
      label: "Phone",
      minWidth: 200,
      align: "left",
      enableSort: true,
    },

    {
      id: "return_reason",
      label: "Return Reason",
      minWidth: 200,
      align: "left",
      enableSort: true,
    },

    {
      id: "remitter",
      label: "Remitter Name",
      minWidth: 200,
      align: "left",
      enableSort: true,
    },
    {
      id: "beneficiary_name",
      label: "Beneficiary’s Name",
      minWidth: 200,
      align: "left",
      enableSort: true,
    },
    {
      id: "amount_in_usd",
      label: "Amount in USD",
      minWidth: 200,
      align: "left",
      enableSort: true,
    },
    {
      id: "amount_in_inr",
      label: "Amount in INR",
      minWidth: 200,
      align: "left",
      enableSort: true,
    },
    {
      id: "type_of_txn",
      label: "Type of transaction",
      minWidth: 200,
      align: "left",
      enableSort: true,
    },
    {
      id: "nium_payment_id",
      label: "NUIM Payment ID",
      minWidth: 200,
      align: "left",
      enableSort: true,
    },
    {
      id: "status",
      label: "Status",
      minWidth: 200,
      align: "left",
      enableSort: true,
    },
    {
      id: "created_date",
      label: "Created Date",
      minWidth: 200,
      align: "left",
      enableSort: true,
    },
    {
      id: "action",
      label: "Action",
      minWidth: 100,
      align: "left",
    },
  ];

  const setDynamicTable = (parentWidth, tableWidth) => {
    setIsDynamicTable(true);
  };

  const searchData = (e) => {
    setSearchKey(e.target.value);
  };

  const handleClose = () => {
    setReturnModalOpened(false);
  };

  const getTxnList = () => {
    getRemittanceReturnList()
      .then((response) => {
        setLoading(false);
        if (response?.data.length > 0 && typeof response === "object") {
          setRows(response.data.map((data) => dataGenerator(data)));
          setPrevRows(response.data.map((data) => dataGenerator(data)));
        } else {
          setRows([]);
        }
        localforage.setItem("@remittanceReturn", response, (error, data) => {
          if (error) {
            throw error;
          }
        });
      })
      .catch((error) => {
        setLoading(false);
        localforage.setItem("@remittanceReturn", []);
        throw error;
      });
  };

  const returnTxn = (refId) => {
    setReturnModalOpened(true);
    setTxnRefId(refId);
  };

  const dataGenerator = (data) => {
    return {
      id: <span className="txnId">{data.transaction_ref_id}</span>,
      email: data.email,
      nium_payment_id: data.nium_payment_id,
      phone: data.phone_no,
      created_date: moment(data.created_date).format("ll"),
      remitter: data.remitter_name,
      beneficiary_name: data.beneficiary_name,
      amount_in_usd: data.source_amount,
      amount_in_inr: data.destination_amount,
      type_of_txn: data.transaction_type,
      failure_reason: data.failure_reason,
      status: data.status,
      return_reason:data.return_reason,
      action: (
        <Button
          onClick={() => returnTxn(data.transaction_ref_id)}
          variant="contained"
          size="small"
          color="error"
          sx={styleBtn}
        >
          Take Action
        </Button>
      ),
    };
  };

  const handleSelectChange = (event) => {
    setStatus(event.target.value);

    if (event.target.value) {
      if (date) {
        const formattedDate = date.format("ll");
        setRows(
          searchExactList(
            prevRows.filter((item) => {
              return item.created_date === formattedDate;
            }),
            event.target.value
          )
        );
        return;
      }
      setRows(searchExactList(prevRows, event.target.value));
      return;
    }
    if (date) {
      const formattedDate = date.format("ll");
      setRows(
        prevRows.filter((item) => {
          return item.created_date === formattedDate;
        })
      );
      return;
    }
    setRows(prevRows);
  };

  const handleDateChange = (date) => {
    setDate(date);
    if (date) {
      const formattedDate = date.format("ll");
      if (status) {
        setRows(
          searchExactList(prevRows, status).filter((item) => {
            return item.created_date === formattedDate;
          })
        );
        return;
      }
      setRows(
        prevRows.filter((item) => {
          return item.created_date === formattedDate;
        })
      );
      return;
    }
    if (status) {
      setRows(searchExactList(prevRows, status));
      return;
    }
    setRows(prevRows);
  };

  useEffect(() => {
    setLoading(true);
    localforage.getItem("@remittanceReturn", (error, data) => {
      if (data) {
        if (data?.length > 0 && typeof data === "object") {
          setRows(data?.map((data) => dataGenerator(data)));
          setPrevRows(data?.map((data) => dataGenerator(data)));
        } else {
          setRows([]);
        }
      }
    });
    getTxnList();
  }, []);

  return (
    <>
      <Box
        sx={{ px: 6 }}
        className={`main-box ${isDynamicTable ? "dynamicTableStyle" : ""}`}
      >
        <div
          className={`bankAddRuleHeader mb-3 ${
            isDynamicTable ? "listHeaderPaddingRight" : ""
          }`}
        >
          <div
            style={{
              display: "flex",
              padding: "1.5rem 0",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: "#33343a" }} variant="h4" component="h4">
              Remittance Transactions
            </Typography>
            {loading && (
              <div
                style={{
                  marginLeft: "12px",
                  color: "#a0a0a0",
                  fontSize: "24px",
                }}
              >
                <i
                  className={`fa fa-refresh ${
                    loading === true ? "fa-spin" : ""
                  }`}
                  aria-hidden="true"
                ></i>
              </div>
            )}
          </div>
          <div style={{ display: "flex", alignItems: "end" }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <StyledDatePicker
                inputFormat="DD/MM/YYYY"
                value={date}
                onChange={handleDateChange}
                componentsProps={{
                  actionBar: {
                    actions: ["clear"],
                  },
                }}
                renderInput={(params) => {
                  return (
                    <DateTextField
                      fullWidth
                      variant="standard"
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "Created Date",
                      }}
                    />
                  );
                }}
                disableFuture
              />
            </LocalizationProvider>
            <FormControl variant="standard" sx={{ mr: 3, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-standard-label">
                Status
              </InputLabel>
              <Select
                sx={{ width: "200px" }}
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={status}
                onChange={handleSelectChange}
                label="Age"
              >
                <MenuItem value="">None</MenuItem>
                <MenuItem value="FAILED_AT_PARTNER">Failed at partner</MenuItem>
                <MenuItem value="RETURNED">Returned</MenuItem>
              </Select>
            </FormControl>
            <TextField
              id="standard-basic"
              label="Search"
              variant="standard"
              sx={{ mr: 1 }}
              onKeyUp={(e) => searchData(e)}
              InputProps={{
                endAdornment: <SearchIcon sx={{ color: "#8a8a8a" }} />,
              }}
            />
          </div>
        </div>

        <CustomTable
          setDynamicTable={setDynamicTable}
          data={filterList(rows, searchKey) || []}
          columns={columns}
        />
      </Box>
      {returnModalOpened && (
        <RemittanceReturnModal
          id={txnRefId}
          getTxnList={() => getTxnList()}
          handleClose={() => handleClose()}
        />
      )}
    </>
  );
}

export default RemittanceReturn;
