import appConfig from "../config/app.config";
import axios from "axios";
import { getUserToken } from "../libs/user";
import { getPureJSON } from "../libs/utils";

const getFAQList = () => {
  return new Promise((resolve, reject) => {
    var config = {
      method: "get",
      url: appConfig.apiUrl.getfaqList,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

const getFaqDataById = (id) => {
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${appConfig.apiUrl.getfaqList}/${id}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(getPureJSON(error.response || error));
      });
  });
};

const updateFaqdata = (obj) => {
  const authToken = getUserToken();
  return new Promise((resolve, reject) => {
    var data = JSON.stringify({
      objectId: obj.objectId,
      id: obj.id,
      question: obj.question,
      answer: obj.answer,
      popularity: obj.popularity,
      isActive: obj.isActive,
      promoType: obj.promoType,
      offerIdList: obj.offerIdList,
    });

    var config = {
      method: "post",
      url: `${appConfig.apiUrl.getfaqList}/save`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(getPureJSON(error.response || error));
      });
  });
};
export { getFAQList, getFaqDataById, updateFaqdata };
