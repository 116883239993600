import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { addMerchant, getMerchantById } from "../../../services/merchants";
import { Box, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Swal from "sweetalert2";

function AddMerchant() {
  const navigation = useNavigate();
  const { id } = useParams();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const updateMerchant = (data) => {
    data.linked_store_ids = JSON.parse(data.linked_store_ids);
    data.stores = null;

    addMerchant(data)
      .then((response) => {
        Swal.fire({
          title: "Success",
          text: "Merchant has been saved successfully",
          icon: "success",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            navigation(-1);
          }
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Oops",
          text: "Something went wrong",
          icon: "warning",
          confirmButtonText: "Ok",
        }).then((result) => {});
        throw error;
      });
  };

  useEffect(() => {
    if (id) {
      getMerchantById(id)
        .then((response) => {
          if (response) {
            reset({
              merchant_name: response[0].merchant_name,
              merchant_id: response[0].merchant_id,
              linked_store_ids: JSON.stringify(
                response[0].linked_store_ids,
                null,
                2
              ),
              stores: JSON.stringify(response[0].stores, null, 4),
            });
          }
        })
        .catch((error) => {
          throw error;
        });
    }
  }, [id]);

  return (
    <Box sx={{ ml: "4", pt: "14 !important", pb: "5", px: 3 }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "25px",
          marginTop: "20px",
          gap: "16px",
        }}
      >
        <Link to="/app/list-merchants">
          <ArrowBackIcon
            fontSize="large"
            color="secondary"
            sx={{ background: "#040721", p: 1, borderRadius: "50%" }}
          />
        </Link>
        <Typography
          variant="h5"
          component="h5"
          fontWeight={"bold"}
          color={"#3d3d3d"}
        >
          Add Merchant
        </Typography>
      </div>
      <Box sx={{ background: "#ffffff", p: 2, mb: 4, pb: 4 }}>
        <form onSubmit={handleSubmit(updateMerchant)} noValidate>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none"
                  htmlFor="merchant_name"
                >
                  Merchant Name <small className="text-danger fs-6">*</small>
                </label>
                <input
                  {...register("merchant_name", { required: true })}
                  className={`form-control ${
                    errors.merchant_name && "invalid-form-input"
                  }`}
                  type="text"
                  id="merchant_name"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none"
                  htmlFor="merchant_id"
                >
                  Merchant ID <small className="text-danger fs-6">*</small>
                </label>
                <input
                  {...register("merchant_id", { required: true })}
                  className={`form-control ${
                    errors.merchant_id && "invalid-form-input"
                  }`}
                  type="text"
                  id="merchant_id"
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none"
                  htmlFor="event_name"
                >
                  Event Name
                </label>
                <input
                  {...register("event_name")}
                  className={`form-control ${
                    errors.event_name && "invalid-form-input"
                  }`}
                  type="text"
                  id="event_name"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none"
                  htmlFor="linked_stores_id"
                >
                  Linked Store IDs:{" "}
                  <small className="text-danger fs-6">*</small>
                </label>
                <textarea
                  rows={10}
                  {...register("linked_store_ids", { required: true })}
                  className={`form-control ${
                    errors.linked_store_ids && "invalid-form-input"
                  }`}
                  type="text"
                  id="linked_stores_id"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none"
                  htmlFor="stores"
                >
                  Stores: <small className="text-danger fs-6">*</small>
                </label>
                <textarea
                  rows={10}
                  {...register("stores", { required: true })}
                  className={`form-control ${
                    errors.stores && "invalid-form-input"
                  }`}
                  type="text"
                  id="stores"
                />
              </div>
            </div>
          </div>
          <hr style={{ height: "0.5px", marginTop: "32px" }} />
          <div>
            <button className="btn btn-primary py-2 px-5" name="save">
              Save
            </button>
          </div>
        </form>
      </Box>
    </Box>
  );
}

export default AddMerchant;
