import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getNeobankConfigDataById } from "../../services/neobank-application-config";
import { getUserAchData } from "../../services/user";

export const getNeobankConfigsDataById = createAsyncThunk(
  "reward/getAppConfigsDataById",
  async (configData, { rejectWithValue }) => {
    try {
      const { id } = configData;
      const response = await getNeobankConfigDataById(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  list: {
    data: [],
    error: null,
    status: null,
  },
  single: {
    data: null,
    error: null,
    status: null,
  },
};

export const NeobankConfigsDataSlice = createSlice({
  name: "neobankConfigsData",
  initialState: initialState,

  reducers: {
    setUserAchData: (state, action) => {
      state.achData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getNeobankConfigsDataById.pending, (state, action) => {
        state.single = { ...state.single, status: "loading" };
      })
      .addCase(getNeobankConfigsDataById.fulfilled, (state, action) => {
        state.single = {
          ...state.single,
          status: "succeeded",
          data: action.payload,
          error: null,
        };
      })
      .addCase(getNeobankConfigsDataById.rejected, (state, action) => {
        state.single = {
          ...state.single,
          status: "failed",
          error: action.payload,
          data: [],
        };
      });
  },
});
export const { setUserAchData } = NeobankConfigsDataSlice.actions;
export default NeobankConfigsDataSlice.reducer;
