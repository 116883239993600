import { Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import { Box, Snackbar, Typography } from "@mui/material";

import CustomTable from "../../../components/CustomTable";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import localforage from "localforage";
import { TextField, Tooltip, IconButton, Menu, Button } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { filterList, startLoader } from "../../../libs/utils";
import { getUploadedImagesList } from "../../../services/imageService";

const GenericImageUpload = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    minHeight: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  };

  const [isDynamicTable, setIsDynamicTable] = useState(false);
  const [rows, setRows] = useState();
  const [loading, setLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [copySuccess, setCopySuccess] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const columns = [
    { id: "image_name", label: "Image Name", minWidth: 90, enableSort: true },
    { id: "url", label: "URL", minWidth: 140 },
    { id: "copyText", minWidth: 50 },
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const setDynamicTable = (parentWidth, tableWidth) => {
    setIsDynamicTable(true);
  };

  const searchData = (e) => {
    setSearchKey(e.target.value);
  };

  const handleCopy = (data) => {
    navigator.clipboard.writeText(data.url);
    setCopySuccess(true);
  };

  const dataGenerator = (data) => {
    return {
      image_name: data.image_name || "",
      url: data.url,
      copyText: (
        <>
          <button
            style={{
              border: "none",
              background: "none",
            }}
            onClick={() => handleCopy(data)}
            title="Click to copy"
          >
            <span className="fa fa-copy"></span>
          </button>
        </>
      ),
    };
  };

  useEffect(() => {
    setLoading(true);
    localforage.getItem("@genericImage", (error, data) => {
      if (data) {
        if (data?.length && typeof data === "object") {
          setRows(data.map((data) => dataGenerator(data)));
        } else {
          setRows([]);
        }
      }
    });
    getUploadedImagesList()
      .then((response) => {
        setLoading(false);
        if (response?.length && typeof response === "object") {
          setRows(response.map((data) => dataGenerator(data)));
        } else {
          setRows([]);
        }
        localforage.setItem("@genericImage", response, (error, data) => {
          if (error) {
            throw error;
          }
        });
      })
      .catch((error) => {
        setLoading(false);
        throw error;
      });
  }, []);

  return (
    <>
      <Box
        sx={{ px: 6 }}
        className={`main-box ${isDynamicTable ? "dynamicTableStyle" : ""}`}
      >
        <div
          className={`bankAddRuleHeader mb-3 ${
            isDynamicTable ? "listHeaderPaddingRight" : ""
          }`}
        >
          <div
            style={{
              display: "flex",
              padding: "1.5rem 0",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: "#33343a" }} variant="h4" component="h4">
              Upload Image
            </Typography>
            {loading && (
              <div
                style={{
                  marginLeft: "12px",
                  color: "#a0a0a0",
                  fontSize: "24px",
                }}
              >
                <i
                  className={`fa fa-refresh ${
                    loading === true ? "fa-spin" : ""
                  }`}
                  aria-hidden="true"
                ></i>
              </div>
            )}
          </div>
          <div style={{ display: "flex", alignItems: "end" }}>
            <TextField
              id="standard-basic"
              label="Search"
              variant="standard"
              sx={{ mr: 1 }}
              onKeyUp={(e) => searchData(e)}
              InputProps={{
                endAdornment: <SearchIcon sx={{ color: "#8a8a8a" }} />,
              }}
            />

            <Tooltip title="Choose Option">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <MoreVertIcon sx={{ width: 32, height: 32 }} />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem
                sx={{
                  "&:hover": {
                    background: "#f9da8792",
                    color: "#000",
                  },
                }}
              >
                <Link to="/app/image-upload/add" style={{ color: "#040721" }}>
                  Add New Image
                </Link>
              </MenuItem>
            </Menu>
          </div>
        </div>
        {copySuccess && (
          <Snackbar
            message="Copied!"
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            autoHideDuration={1000}
            onClose={() => setCopySuccess(false)}
            open={copySuccess}
          />
        )}
        <CustomTable
          setDynamicTable={setDynamicTable}
          data={filterList(rows, searchKey) || []}
          columns={columns}
          showTooltip={false}
        />
      </Box>
    </>
  );
};
export default GenericImageUpload;
