import * as React from "react";
import { useState } from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import LogoFill from "../../../assets/images/logoFill_balck.svg";
import { Menu, IconButton, Tooltip, Avatar, MenuItem } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Collapse } from "@mui/material";
import { menus } from "./menu";
import { hasChildren } from "./utils";
import { Link, useNavigate } from "react-router-dom";
import "./authorized-user-layout.css";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";
import { Logout } from "@mui/icons-material";
import Colors from "../../../config/colors";
import { capitalizeFirstLetter, getUserInfo } from "../../../libs/utils";
import { useIdleTimer } from "react-idle-timer";
import appConfig from "../../../config/app.config";
import { userLogout } from "../../../libs/auth";
import CloseIcon from "@mui/icons-material/Close";

const drawerWidth = 300;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: 0,
    width: `100%`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("md")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const theme = createTheme({
  typography: {
    fontFamily: '"Nunito", sans-serif',
  },
  palette: {
    primary: {
      main: Colors.primary,
    },
    secondary: {
      main: Colors.secondary,
    },
  },
  components: {
    MuiInputLabel: {
      defaultProps: {
        sx: {
          fontSize: "16px",
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        sx: {
          fontSize: "16px",
        },
      },
    },
  },
});

function AuthorizedUserLayout(props) {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const [openProfileMenu, setOpenProfileMenu] = React.useState(true);

  const CustomMenuItem = ({ item }) => {
    if (item) {
      const Component = hasChildren(item) ? MultiLevel : SingleLevel;
      return <Component item={item} />;
    }
  };

  const SingleLevel = ({ item }) => {
    return (
      <ul className="menuUl" style={{ marginBottom: "0px" }}>
        <li
          className="menuUi"
          style={{ padding: "8px 0px", marginLeft: "8px" }}
        >
          <Link className="menuLink" to={item.to} onClick={() => setOpen(true)}>
            <LabelImportantIcon
              sx={{ width: "12px", mr: 1, color: "#5e5e5e" }}
            />
            <span style={{ color: "#5e5e5e" }}>{item.title}</span>
          </Link>
        </li>
      </ul>
    );
  };

  const MultiLevel = ({ item }) => {
    const { items: children } = item;
    const [openm, setOpenm] = useState(false);

    const handleClick = () => {
      if (!open) {
        setOpenm((prev) => !prev);
      } else {
        setOpen(!open);
      }
    };
    return (
      <React.Fragment>
        <ListItem href={item.to} onClick={handleClick}>
          <ListItemIcon style={{ marginLeft: "8px" }}>{item.icon}</ListItemIcon>
          <ListItemText primary={item.title} />
          {openm ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        <Collapse sx={{ mt: 1 }} in={openm} timeout="auto" unmountOnExit>
          <List sx={{ fontSize: "0.9rem" }} component="div" disablePadding>
            {children.map((child, key) => (
              <CustomMenuItem key={key} item={child} />
            ))}
          </List>
        </Collapse>
      </React.Fragment>
    );
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleLogout = () => {
    userLogout()
      .then(() => {
        navigate("/login");
      })
      .catch((error) => {});
  };

  const [manageProfile, setManageProfile] = useState(null);
  const openProfile = Boolean(manageProfile);
  const handleManageProfileClick = (event) => {
    setManageProfile(event.currentTarget);
    setOpenProfileMenu(true);
  };
  const handleManageProfileClose = () => {
    setManageProfile(null);
  };

  const onIdle = () => {
    handleLogout();
  };

  const idleTimer = useIdleTimer({
    onIdle,
    timeout: 1000 * 60 * appConfig.timeoutDuration,
    promptTimeout: 0,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: "idle-timer",
    syncTimers: 0,
    leaderElection: false,
  });

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex", paddingRight: "0" }}>
        <CssBaseline />
        <AppBar position="fixed" open={open} sx={{ background: "#f5f5f5" }}>
          <Toolbar>
            <IconButton
              edge="start"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "22px",
                color: "black",
                marginLeft: "-9px",
              }}
            >
              {open ? <MenuIcon /> : <CloseIcon />}
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              <img src={LogoFill} alt="logo" />
            </Typography>

            <Typography component="h1" variant="h6" color="black" noWrap>
              Welcome, {capitalizeFirstLetter(getUserInfo().user)}
            </Typography>

            <Tooltip title="Account settings">
              <IconButton
                onClick={handleManageProfileClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={openProfile ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openProfile ? "true" : undefined}
              >
                <Avatar sx={{ width: 32, height: 32 }} />
              </IconButton>
            </Tooltip>
            {openProfile === true && (
              <Menu
                anchorEl={manageProfile}
                id="account-menu"
                open={openProfileMenu}
                onClose={handleManageProfileClose}
                onClick={handleManageProfileClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    zIndex: 99999,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem
                  onClick={() => handleLogout()}
                  sx={{
                    "&:hover": {
                      background: "#f9da8792",
                      color: "#000",
                    },
                  }}
                >
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            )}
          </Toolbar>
        </AppBar>
        <div className="drawer-menu">
          <Drawer
            variant="permanent"
            open={!open}
            style={{ height: "100%" }}
            sx={{ background: "white" }}
          >
            <div className="drawer-menu-items">
              {menus().map((item, key) => (
                <CustomMenuItem
                  key={key}
                  item={item}
                  component={Link}
                  to={item.to}
                />
              ))}
            </div>
          </Drawer>
        </div>
        <Box
          component="div"
          sx={{
            background: "#fafafa",
            overflow: "hidden",
          }}
        >
          <Toolbar />
          <Box
            component="main"
            style={{
              maxWidth: "none",
              paddingLeft: open ? "71px" : "300px",
              transition: "0.3s all ease-in",
              minHeight: "calc(100vh - 128px)",
            }}
            maxWidth="xl"
            sx={{
              mt: 4,
              mb: 4,

              mr: 1,
            }}
          >
            {props.children}
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default AuthorizedUserLayout;
