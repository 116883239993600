import appConfig from "../config/app.config";
import axios from "axios";
import { getUserToken } from "../libs/user";
import {
  getPureJSON,
  handleAPIError,
  startLoader,
  stopLoader,
} from "../libs/utils";

const listTierBenefit = () => {
  startLoader();
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${appConfig.apiUrl.tierBenefit}/all`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader();
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader();
        handleAPIError(error.status);
        reject(getPureJSON(error.response || error));
      });
  });
};

const getTierBenefitById = (id) => {
  startLoader(1);
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${appConfig.apiUrl.tierBenefit}?id=${id}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader(1);
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader(1);
        reject(getPureJSON(error.response || error));
      });
  });
};

const updateTierBenefit = (obj) => {
  startLoader(1);
  return new Promise((resolve, reject) => {
    var data = JSON.stringify(obj);

    var config = {
      method: "post",
      url: `${appConfig.apiUrl.tierBenefit}/save`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        stopLoader(1);
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader(1);
        reject(getPureJSON(error.response || error));
      });
  });
};

export { listTierBenefit, getTierBenefitById, updateTierBenefit };
