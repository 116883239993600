import { getUserToken, removeUser, removeUserToken } from "./user";
const checkAuth = () => {
  return getUserToken();
};
const setLoggedInUser = () => {};
const getLoggedInUser = () => {};
const userLogout = () => {
  return new Promise((resolve, reject) => {
    removeUser()
      .then((response) => {
        removeUserToken();
        localStorage.clear();
        sessionStorage.clear();
        resolve("user logged out");
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export { checkAuth, setLoggedInUser, getLoggedInUser, userLogout };
