const tierModel = {
  id: null,
  name: "",
  price: null,
  appleProductId: "",
  trialPeriodNumber: 0,
  active: "",
  level: null,
  description: "",
  headerImg: "",
  priceSubTitle: "",
  subtitle: "",
  link1: "",
  link2: "",
  linkText: "",
  trialPeriodUnit: "",
  singleColour: "",
  singleColourCode: "",
  gradientStartColourCode: "",
  gradientEndColourCode: "",
  tierTextColour: "",
  trialPeriodBackGroundColour: "",
  profileSmallCardImg: "",
  profileSubscriptionLogo: "",
  profileSubscriptionDeepLink: "",
};

export { tierModel };
