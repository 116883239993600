import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";

import { useForm } from "react-hook-form";

import { updateOfferStoresRecord } from "../../../services/stores";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Swal from "sweetalert2";

function AddOfferStores(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const updateOfferstores = (data) => {
    updateOfferStoresRecord({
      id: null,
      businessStatus: "OPERATIONAL",
      name: data.name,
      formattedAddress: null,
      placeId: null,
      stateName: null,
      cityName: null,
      rating: 0,
      userRatingsTotal: 0,
      types: [],
      keywords: JSON.parse(data.keywords),
      geometry: null,
    })
      .then((response) => {
        Swal.fire({
          title: "Success",
          text: "Store has been added successfully",
          icon: "success",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/app/list-stores");
          }
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Oops",
          text: "Something went wrong",
          icon: "error",
          confirmButtonText: "Ok",
        }).then((result) => {});
        throw error;
      });
  };

  return (
    <Box sx={{ ml: "4", pt: "14 !important", pb: "5", px: 3 }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "25px",
          marginTop: "20px",
          gap: "16px",
        }}
      >
        <Link to="/app/list-stores">
          <ArrowBackIcon
            fontSize="large"
            color="secondary"
            sx={{ background: "#040721", p: 1, borderRadius: "50%" }}
          />
        </Link>
        <Typography
          variant="h5"
          component="h5"
          fontWeight={"bold"}
          color={"#3d3d3d"}
        >
          Add Store
        </Typography>
      </div>
      <Box sx={{ background: "#ffffff", p: 2, mb: 4, pb: 4 }}>
        <form onSubmit={handleSubmit(updateOfferstores)}>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none"
                  htmlFor="name"
                >
                  Name <small className="text-danger fs-6">*</small>
                </label>
                <input
                  {...register("name", { required: true })}
                  className={`form-control ${
                    errors.name && "invalid-form-input"
                  }`}
                  type="text"
                  id="name"
                />
              </div>
            </div>
          </div>
          <div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none"
                  htmlFor="keywords"
                >
                  Keywords: <small className="text-danger fs-6">*</small>
                </label>
                <textarea
                  {...register("keywords", { required: true })}
                  className={`form-control ${
                    errors.keywords && "invalid-form-input"
                  }`}
                  type="text"
                  id="keywords"
                  rows="4"
                  cols="60"
                />
              </div>
            </div>
          </div>
          <hr style={{ height: "0.5px", marginTop: "32px" }} />
          <div>
            <button
              type="submit"
              className="btn btn-primary py-2 px-5"
              name="save"
            >
              Save or Update
            </button>
          </div>
        </form>
      </Box>
    </Box>
  );
}

export default AddOfferStores;
