import { useState } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ImageUploadUtility from "../../../utils/ImageUploadUtility";
import { processBulkCash } from "../../../services/cashbackRequest";
import Swal from "sweetalert2";

function ProcessCashback() {
  const [isUploading, setIsUploading] = useState(false);

  const handleCashback = (file) => {
    let flag = true;
    if (!file) {
      flag = false;
      Swal.fire({
        title: "Oops",
        text: "Please choose file",
        icon: "warning",
        confirmButtonText: "Ok",
      }).then((result) => {});
    }
    if (flag) {
      setIsUploading(true);
      processBulkCash(file)
        .then((response) => {
          setIsUploading(false);
          Swal.fire({
            title: `${
              response?.url
                ? `<a href=${response.url} download=${response.url} target=_blank>Download the pdf</a>`
                : "Success"
            }`,
            text: `${response?.message}`,
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
            }
          });
        })
        .catch(function (error) {
          setIsUploading(false);
        });
    }
  };

  return (
    <>
      <Box sx={{ ml: "4", pt: "14 !important", pb: "5", px: 3 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "25px",
            marginTop: "20px",
            gap: "16px",
          }}
        >
          <Typography
            variant="h5"
            component="h5"
            fontWeight={"bold"}
            color={"#3d3d3d"}
          >
            Process Cashback
          </Typography>
        </div>
        <Box sx={{ background: "#ffffff", p: 2, mb: 4, pb: 4 }}>
          <div>
            <ImageUploadUtility
              uploadType="GENERIC"
              label="Upload File"
              fileAccepted=".csv"
              handleUpload={(file) => handleCashback(file)}
              fileUploaded={isUploading}
              isUpload={true}
            />
          </div>
          <div>
            <div>Please enter sample CSV</div>
            <table>
              <thead>
                <tr>
                  <th className="csvData">amount_type</th>
                  <th className="csvData">email</th>
                  <th className="csvData">offer_id</th>
                  <th className="csvData">pending</th>
                  <th className="csvData">spent_amount</th>
                  <th className="csvData">status</th>
                  <th className="csvData">transaction_date</th>
                </tr>
                <tr>
                  <td className="csvData">Spend/Earned</td>
                  <td className="csvData">test7june1@gmail.com</td>
                  <td className="csvData">5dfd1c891265a2ddf08bc70f</td>
                  <td className="csvData">FALSE/TRUE</td>
                  <td className="csvData">10</td>
                  <td className="csvData">w/r</td>
                  <td className="csvData">2024-04-02</td>
                </tr>
              </thead>
            </table>
          </div>
        </Box>
      </Box>
    </>
  );
}

export default ProcessCashback;
