import appConfig from "../config/app.config";
import axios from "axios";
import { getUserToken } from "../libs/user";
import { startLoader, stopLoader } from "../libs/utils";

const getRemittanceBlockedUsers = () => {
  startLoader();
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${appConfig.apiUrl.getBlockedUsersList}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader();
        resolve(response.data.data);
      })
      .catch(function (error) {
        stopLoader();
        reject(error);
      });
  });
};

const unBlockRemittanceUser = (email) => {
  startLoader();
  return new Promise((resolve, reject) => {
    const config = {
      method: "post",
      url: `${appConfig.apiUrl.unblockRemittanceUser}/${email}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
    .then(function (response) {
      stopLoader();
      resolve(response.data);
    })
    .catch(function (error) {
      stopLoader();
      reject(error);
    });
  });
};
export { getRemittanceBlockedUsers , unBlockRemittanceUser};
