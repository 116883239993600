import appConfig from "../config/app.config";
import axios from "axios";
import { getUserToken } from "../libs/user";
import { getPureJSON } from "../libs/utils";

const getActiveCouponList = () => {
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: appConfig.apiUrl.activeCoupons,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(getPureJSON(error.response || error));
      });
  });
};
const getActiveCoupon = (id) => {
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${appConfig.apiUrl.activeCoupons}/${id}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(getPureJSON(error.response || error));
      });
  });
};

export { getActiveCouponList, getActiveCoupon };
