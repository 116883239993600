import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../slices/authSlice";
import timesPrimeReducers from "../slices/timesPrimeSlice";
import activeCouponReducers from "../slices/activeCouponSlice";
import bankReducers from "../slices/bankSlice";
import offersDataReducers from "../slices/offersSlice";
import neobankConfigsDataReducers from "../slices/neobankAppConfigSlice";
import appConfigsDataReducers from "../slices/appConfigSlice";
import offerStoresDataReducers from "../slices/offerStoreSlice";
import fxCampaignDataReducers from "../slices/fxRateCampaignSlice";
import blockedUsersDataReducers from "../slices/remittanceBlockedUserSlice";
import riskConfigsDataReducers from "../slices/riskConfigSlice";
const store = configureStore({
  reducer: {
    auth: authReducer,
    timesPrime: timesPrimeReducers,
    activeCoupon: activeCouponReducers,
    bank: bankReducers,
    imageData: offersDataReducers,
    appConfigsData: appConfigsDataReducers,
    offerStoresData: offerStoresDataReducers,
    neobankConfigsData: neobankConfigsDataReducers,
    fxCampaignData: fxCampaignDataReducers,
    blockedUsersData: blockedUsersDataReducers,
    riskConfigsData: riskConfigsDataReducers,
  },
  devTools: true,
});

export default store;
