import React, { useEffect } from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { TextField, Button, Container } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getActiveCouponDataList } from "../../../redux/slices/activeCouponSlice";
import moment from "moment";
import { Link } from "react-router-dom";

function ListActiveCoupons() {
  const dispatch = useDispatch();

  const activeCoupon = useSelector((state) => state.activeCoupon);
  const { list: activeCouponList } = activeCoupon;

  const [rows, setRows] = useState([]);

  const columns = [
    { id: "CouponId", label: "Coupon Id", minWidth: 170 },
    { id: "ExpiryDate", label: "Expiry Date", minWidth: 100 },
    {
      id: "Activatedon",
      label: "Activated On",
      minWidth: 100,
      align: "right",
      format: (value) => value.toFixed(2),
    },
    {
      id: "Email",
      label: "Email",
      minWidth: 170,
      align: "right",
      format: (value) => value.toFixed(2),
    },
    {
      id: "RewardType",
      label: "Reward Type",
      minWidth: 170,
      align: "right",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "status",
      label: "Status",
      minWidth: 170,
      align: "right",
      format: (value) => value.toFixed(2),
    },
    {
      id: "action",
      label: "Action",
      minWidth: 100,
      align: "right",
      format: (value) => value.toFixed(2),
    },
  ];

  function createData(
    CouponId,
    ExpiryDate,
    Activatedon,
    Email,
    RewardType,
    status,
    id
  ) {
    return {
      CouponId,
      ExpiryDate,
      Activatedon,
      Email,
      RewardType,
      status,
      action: <Link to={`/app/active-coupons/${id}`}>View</Link>,
      id: id,
    };
  }

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    dispatch(getActiveCouponDataList());
  }, [dispatch]);

  useEffect(() => {
    const data = activeCouponList.data.map((v) => {
      return createData(
        v.couponId,
        moment(v.expiryDate).format("MMM D, YYYY"),
        moment(v.activatedOn).format("MMM D, YYYY"),
        v.email,
        `${v.rewardType} months Willow`,
        v.status,
        v.id
      );
    });
    setRows(data);
  }, [activeCouponList]);

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <Typography
          component="h4"
          variant="h4"
          color="inherit"
          noWrap
          sx={{ ml: 3, fontSize: "22px", fontWeight: "700" }}
        >
          Select Active Coupons to change
        </Typography>

        <div className="dataHeader">
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "space-between", // keep right padding when drawer closed
            }}
          >
            <TextField
              margin="normal"
              id="filter"
              label="Search"
              name="filter"
              placeholder=""
              autoFocus
              variant="standard"
            />
          </Toolbar>
          <Container
            style={{ paddingRight: "6px", marginRight: 0 }}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 3,
              mb: 3,
            }}
          >
            <input
              type="file"
              id="csv_file"
              className="form-control"
              name="csv_file"
              accept=".csv"
              style={{ width: "30%", border: "none" }}
            />

            <Button type="submit" variant="contained" sx={{ ml: 4 }}>
              Upload Coupons
            </Button>
          </Container>
        </div>
      </Box>

      {/* ll */}

      <Paper
        sx={{
          width: "98%",
          overflow: "hidden",
          mx: 3,
          boxShadow:
            "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
        }}
      >
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[20, 50, 75, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      {/* ll */}
    </>
  );
}

export default ListActiveCoupons;
