import appConfig from "../config/app.config";
import axios from "axios";
import { getUserToken } from "../libs/user";
import { getPureJSON, startLoader, stopLoader } from "../libs/utils";

export const getAffiliatePartnerList = () => {
  startLoader();
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: appConfig.apiUrl.affiliatePartner,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader();
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader();
        reject(getPureJSON(error.response || error));
      });
  });
};

export const getAffiliatePartnerById = (id) => {
  startLoader();
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${appConfig.apiUrl.affiliatePartner}/${id}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader();
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader();
        reject(getPureJSON(error.response || error));
      });
  });
};

export const addAffiliatePartnerService = (obj) => {
  startLoader();
  return new Promise((resolve, reject) => {
    const data = JSON.stringify(obj);

    const config = {
      method: "post",
      url: `${appConfig.apiUrl.affiliatePartner}/save`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        stopLoader();
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader();
        reject(getPureJSON(error.response || error));
      });
  });
};
