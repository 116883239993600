import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getActiveOffers } from "../../../services/cashback";
import { getFaqDataById } from "../../../services/faq";
import Swal from "sweetalert2";
import { updateFaqdata } from "../../../services/faq";
import {
  getBankById,
  getBankStatusList,
  updateBank,
} from "../../../services/bank";

function EditBank(props) {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const { id } = useParams();
  const [response, setResponse] = useState();
  const [bankStatus, setBankStatus] = useState([]);
  const navigation = useNavigate();

  useEffect(() => {
    if (response) {
      setValue("bankStatus", response.bankStatus);
    }
  }, [response, bankStatus]);

  useEffect(() => {
    getBankStatusList()
      .then((response) => {
        setBankStatus(response);
      })
      .catch((error) => {
        throw error;
      });
    if (id) {
      getBankById(id)
        .then((response) => {
          setResponse(response);

          if (response) {
            reset({
              institutionId: response.institutionId,
              institutionName: response.institutionName,
            });
          }
        })
        .catch((error) => {
          throw error;
        });
    }
  }, [id]);

  const editBank = (data) => {
    const buttonType = window.event.submitter.name;

    if (buttonType === "save") {
      updateBank(data)
        .then((response) => {
          Swal.fire({
            title: "Success",
            text: "Bank has been saved successfully",
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              navigation(-1);
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "Oops",
            text: "Something went wrong",
            icon: "warning",
            confirmButtonText: "Ok",
          }).then((result) => {});
          throw error;
        });
    }
  };

  return (
    <>
      <Box sx={{ ml: "4", pt: "14 !important", pb: "5", px: 3 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "25px",
            marginTop: "20px",
            gap: "16px",
          }}
        >
          <Link to="/app/banks">
            <ArrowBackIcon
              fontSize="large"
              color="secondary"
              sx={{ background: "#040721", p: 1, borderRadius: "50%" }}
            />
          </Link>
          <Typography
            variant="h5"
            component="h5"
            fontWeight={"bold"}
            color={"#3d3d3d"}
          >
            Edit Bank
          </Typography>
        </div>
        <Box sx={{ background: "#ffffff", p: 2, mb: 4, pb: 4 }}>
          <form onSubmit={handleSubmit(editBank)}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="institutionId"
                  >
                    Institution Id
                  </label>

                  <input
                    {...register("institutionId", { required: true })}
                    className={`form-control ${
                      errors.institutionId && "invalid-form-input"
                    }`}
                    type="text"
                    id="institutionId"
                    rows="5"
                    cols="60"
                    readOnly
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="institutionName"
                  >
                    Institution Name
                  </label>

                  <input
                    {...register("institutionName", { required: true })}
                    className={`form-control ${
                      errors.institutionName && "invalid-form-input"
                    }`}
                    type="text"
                    id="institutionName"
                    rows="5"
                    cols="60"
                    readOnly
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label
                    className="form-label fw-bold text-secondary user-select-none"
                    htmlFor="bankStatus"
                  >
                    Bank Status <small className="text-danger fs-6">*</small>
                  </label>
                  <select
                    {...register("bankStatus", { required: true })}
                    className={`form-select ${
                      errors.bankStatus && "invalid-form-input"
                    }`}
                  >
                    <option value="">Select</option>
                    {bankStatus.map((bank, index) => {
                      return (
                        <option key={index} value={bank}>
                          {bank}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <hr style={{ height: "0.5px", marginTop: "32px" }} />
            <div>
              <button
                type="submit"
                className="btn btn-primary py-2 px-5"
                name="save"
                variant="contained"
                sx={{ width: "25%" }}
              >
                Save
              </button>
            </div>
          </form>
        </Box>
      </Box>
    </>
  );
}

export default EditBank;
