import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  getBonusReferralById,
  updateBonusReferral,
} from "../../../services/bonusReferral";
import { Box, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Swal from "sweetalert2";
import moment from "moment";

function EditTierBenefits() {
  const navigation = useNavigate();
  const { id } = useParams();

  const [response, setResponse] = useState();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const editBonusReferral = (data) => {
    data.campaignStartDate = moment(data.campaignStartDate).unix() * 1000;
    data.campaignEndDate = moment(data.campaignEndDate).unix() * 1000;
    data.id = id;
    if (!data.referralBonusGoodCohortUpperLimit) {
      data.referralBonusGoodCohortUpperLimit = 0.0;
    }
    if (!data.referralBonusGoodCohortLowerLimit) {
      data.referralBonusGoodCohortLowerLimit = 0.0;
    }
    if (!data.referralBonusBadCohortUpperLimit) {
      data.referralBonusBadCohortUpperLimit = 0.0;
    }
    if (!data.referralBonusBadCohortLowerLimit) {
      data.referralBonusBadCohortLowerLimit = 0.0;
    }
    if (!data.fixedBonusAmount) {
      data.fixedBonusAmount = false;
    }
    updateBonusReferral(data)
      .then((response) => {
        Swal.fire({
          title: "Success",
          text: "Bonus referral has been saved successfully",
          icon: "success",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            navigation(-1);
          }
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Oops",
          text: "Something went wrong",
          icon: "warning",
          confirmButtonText: "Ok",
        }).then((result) => {});
        throw error;
      });
  };

  useEffect(() => {
    if (id) {
      getBonusReferralById(id)
        .then((response) => {
          setResponse(response);
          if (response) {
            reset({
              id: response.id,
              title: response.title,
              campaignName: response.campaignName,
              campaignStartDate: response.campaignStartDate
                ? moment
                    .unix(response.campaignStartDate / 1000)
                    .format("YYYY-MM-DD")
                : null,
              campaignEndDate: response.campaignEndDate
                ? moment
                    .unix(response.campaignEndDate / 1000)
                    .format("YYYY-MM-DD")
                : null,
              referralBonusAmount: response.referralBonusAmount,
              totalReferralNo: response.totalReferralNo,
              subTitle: response.subTitle,
              referralLabel: response.referralLabel,
              referralBonusGoodCohortUpperLimit:
                response.referralBonusGoodCohortUpperLimit,
              referralBonusGoodCohortLowerLimit:
                response.referralBonusGoodCohortLowerLimit,
              referralBonusBadCohortUpperLimit:
                response.referralBonusBadCohortUpperLimit,
              referralBonusBadCohortLowerLimit:
                response.referralBonusBadCohortLowerLimit,
              fixedBonusAmount: response.fixedBonusAmount,
            });
          }
        })
        .catch((error) => {
          throw error;
        });
    }
  }, [id]);

  return (
    <Box sx={{ ml: "4", pt: "14 !important", pb: "5", px: 3 }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "25px",
          marginTop: "20px",
          gap: "16px",
        }}
      >
        <Link to="/app/list-bonus-referal">
          <ArrowBackIcon
            fontSize="large"
            color="secondary"
            sx={{ background: "#040721", p: 1, borderRadius: "50%" }}
          />
        </Link>
        <Typography
          variant="h5"
          component="h5"
          fontWeight={"bold"}
          color={"#3d3d3d"}
        >
          Edit Bonus Referral
        </Typography>
      </div>
      <Box sx={{ background: "#ffffff", p: 2, mb: 4, pb: 4 }}>
        <form onSubmit={handleSubmit(editBonusReferral)} noValidate>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none"
                  htmlFor="id"
                >
                  Referral ID
                </label>
                <input
                  {...register("id")}
                  className={`form-control ${
                    errors.id && "invalid-form-input"
                  }`}
                  type="text"
                  id="title"
                  readOnly
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none"
                  htmlFor="campaignName"
                >
                  Campaign Name
                  <small className="text-danger fs-6">*</small>
                </label>
                <input
                  {...register("campaignName", { required: true })}
                  className={`form-control ${
                    errors.campaignName && "invalid-form-input"
                  }`}
                  type="text"
                  id="campaignName"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none w-100"
                  htmlFor="campaignStartDate"
                >
                  Campaign Start Epoch Time
                </label>
                <input
                  {...register("campaignStartDate")}
                  className={`form-control ${
                    errors.campaignStartDate && "invalid-form-input"
                  }`}
                  type="date"
                  id="campaignStartDate"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none w-100"
                  htmlFor="campaignEndDate"
                >
                  Campaign End Epoch Time
                </label>
                <input
                  {...register("campaignEndDate")}
                  className={`form-control ${
                    errors.campaignEndDate && "invalid-form-input"
                  }`}
                  type="date"
                  id="campaignEndDate"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label fw-bold w-100 text-secondary user-select-none"
                  htmlFor="referralBonusAmount"
                >
                  Referal Bonus amount
                  <small className="text-danger fs-6">*</small>
                </label>
                <input
                  {...register("referralBonusAmount", { required: true })}
                  className={`form-control ${
                    errors.referralBonusAmount && "invalid-form-input"
                  }`}
                  type="number"
                  id="referralBonusAmount"
                />
              </div>
            </div>
            <div
              className="col-md-6 d-flex align-items-end"
              style={{ marginBottom: "12px" }}
            >
              <div className="form-groupform-check d-flex align-items-center gap-3 ">
                <input
                  {...register("fixedBonusAmount")}
                  className="form-check-input"
                  type="checkbox"
                  id="fixedBonusAmount"
                />
                <label
                  className="form-check-label fw-bold text-secondary w-100"
                  htmlFor="fixedBonusAmount"
                  style={{ lineHeight: "1" }}
                >
                  Fixed Bonus Amount
                </label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none w-100"
                  htmlFor="referralBonusGoodCohortUpperLimit"
                >
                  Good Cohort Upper Limit
                </label>
                <input
                  {...register("referralBonusGoodCohortUpperLimit")}
                  className={`form-control ${
                    errors.referralBonusGoodCohortUpperLimit &&
                    "invalid-form-input"
                  }`}
                  type="number"
                  step=".0000000001"
                  id="referralBonusGoodCohortUpperLimit"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none w-100"
                  htmlFor="referralBonusGoodCohortLowerLimit"
                >
                  Good Cohort Lower Limit
                </label>
                <input
                  {...register("referralBonusGoodCohortLowerLimit")}
                  className={`form-control ${
                    errors.referralBonusGoodCohortLowerLimit &&
                    "invalid-form-input"
                  }`}
                  type="number"
                  step=".0000000001"
                  id="referralBonusGoodCohortLowerLimit"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none w-100"
                  htmlFor="referralBonusBadCohortUpperLimit"
                >
                  Bad Cohort Upper Limit
                </label>
                <input
                  {...register("referralBonusBadCohortUpperLimit")}
                  className={`form-control ${
                    errors.referralBonusBadCohortUpperLimit &&
                    "invalid-form-input"
                  }`}
                  type="number"
                  step=".0000000001"
                  id="referralBonusBadCohortUpperLimit"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none w-100"
                  htmlFor="referralBonusBadCohortLowerLimit"
                >
                  Bad Cohort Lower Limit
                </label>
                <input
                  {...register("referralBonusBadCohortLowerLimit")}
                  className={`form-control ${
                    errors.referralBonusBadCohortLowerLimit &&
                    "invalid-form-input"
                  }`}
                  type="number"
                  step=".0000000001"
                  id="referralBonusBadCohortLowerLimit"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none"
                  htmlFor="totalReferralNo"
                >
                  Total Referal No
                  <small className="text-danger fs-6">*</small>
                </label>
                <input
                  {...register("totalReferralNo", { required: true })}
                  className={`form-control ${
                    errors.totalReferralNo && "invalid-form-input"
                  }`}
                  type="number"
                  id="totalReferralNo"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none"
                  htmlFor="title"
                >
                  Title <small className="text-danger fs-6">*</small>
                </label>
                <input
                  {...register("title", { required: true })}
                  className={`form-control ${
                    errors.title && "invalid-form-input"
                  }`}
                  type="text"
                  id="title"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none"
                  htmlFor="subTitle"
                >
                  Sub Title <small className="text-danger fs-6">*</small>
                </label>
                <input
                  {...register("subTitle", { required: true })}
                  className={`form-control ${
                    errors.subTitle && "invalid-form-input"
                  }`}
                  type="text"
                  id="subTitle"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label fw-bold text-secondary user-select-none"
                  htmlFor="referralLabel"
                >
                  Referral Label
                  <small className="text-danger fs-6">*</small>
                </label>
                <input
                  {...register("referralLabel", { required: true })}
                  className={`form-control ${
                    errors.referralLabel && "invalid-form-input"
                  }`}
                  type="text"
                  id="referralLabel"
                />
              </div>
            </div>
          </div>
          <hr style={{ height: "0.5px", marginTop: "32px" }} />
          <div>
            <button className="btn btn-primary py-2 px-5" name="save">
              Save
            </button>
          </div>
        </form>
      </Box>
    </Box>
  );
}

export default EditTierBenefits;
