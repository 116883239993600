import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  editCardSequence,
  getCardSequenceById,
} from "../../../services/cardSequence";

function EditCardSequence() {
  const navigation = useNavigate();
  const { id } = useParams();

  const {
    register,
    handleSubmit,

    reset,
    formState: { errors },
  } = useForm();

  const updateMerchant = (data) => {
    editCardSequence(id, data)
      .then((response) => {
        navigation(-1);
      })
      .catch((error) => {
        throw error;
      });
  };

  useEffect(() => {
    if (id) {
      getCardSequenceById(id)
        .then((response) => {
          if (response) {
            reset({
              id: response.id,
              bigCard: response.bigCard,
              mediumCard: response.mediumCard,
              smallCard: response.smallCard,
              localCard: response.localCard,
              categoryOfferCard: response.categoryOfferCard,
              categoryListCard: response.categoryListCard,
              offerListCard: response.offerListCard,
              referralCard: response.referralCard,
              logoCard: response.logoCard,
            });
          }
        })
        .catch((error) => {
          throw error;
        });
    }
  }, [id]);

  return (
    <div className="mx-3">
      <div className="bankAddRuleHeader mb-4">
        <h5>Edit Card Sequence</h5>
        <button
          className="btn btn-danger btn-sm"
          onClick={() => navigation(-1)}
        >
          Back
        </button>
      </div>
      <form onSubmit={handleSubmit(updateMerchant)}>
        <div className="listBox">
          <div className="listItem">
            <div className="itemHead">ID:</div>
            <div>
              <input
                {...register("id", { required: true })}
                className={`form-control ${errors.id ? "inputError" : ""}`}
              />
            </div>
          </div>
          <div className="listItem">
            <div className="itemHead">Big Card:</div>
            <div>
              <input
                {...register("bigCard")}
                className={`form-control ${errors.bigCard ? "inputError" : ""}`}
              />
            </div>
          </div>
          <div className="listItem">
            <div className="itemHead">Medium Card:</div>
            <div>
              <input
                {...register("mediumCard")}
                className={`form-control ${
                  errors.mediumCard ? "inputError" : ""
                }`}
              />
            </div>
          </div>
          <div className="listItem">
            <div className="itemHead">Small Card:</div>
            <div>
              <input
                {...register("smallCard")}
                className={`form-control ${
                  errors.smallCard ? "inputError" : ""
                }`}
              />
            </div>
          </div>
          <div className="listItem">
            <div className="itemHead">Local Card:</div>
            <div>
              <input
                {...register("localCard")}
                className={`form-control ${
                  errors.localCard ? "inputError" : ""
                }`}
              />
            </div>
          </div>
          <div className="listItem">
            <div className="itemHead">Category Offer Card:</div>
            <div>
              <input
                {...register("categoryOfferCard")}
                className={`form-control ${
                  errors.categoryOfferCard ? "inputError" : ""
                }`}
              />
            </div>
          </div>
          <div className="listItem">
            <div className="itemHead">Category List Card:</div>
            <div>
              <input
                {...register("categoryListCard")}
                className={`form-control ${
                  errors.categoryListCard ? "inputError" : ""
                }`}
              />
            </div>
          </div>
          <div className="listItem">
            <div className="itemHead">Offer List Card:</div>
            <div>
              <input
                {...register("offerListCard")}
                className={`form-control ${
                  errors.offerListCard ? "inputError" : ""
                }`}
              />
            </div>
          </div>
          <div className="listItem">
            <div className="itemHead">Referral Card:</div>
            <div>
              <input
                {...register("referralCard")}
                className={`form-control ${
                  errors.referralCard ? "inputError" : ""
                }`}
              />
            </div>
          </div>
          <div className="listItem">
            <div className="itemHead">Logo Card:</div>
            <div>
              <input
                {...register("logoCard")}
                className={`form-control ${
                  errors.logoCard ? "inputError" : ""
                }`}
              />
            </div>
          </div>

          <button className="btn btn-primary btn-sm" style={{ width: "200px" }}>
            Update
          </button>
        </div>
      </form>
    </div>
  );
}

export default EditCardSequence;
