import React, { useEffect, useState } from "react";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPendingBankList } from "../../../redux/slices/bankSlice";
import moment from "moment";
import Modal from "@mui/material/Modal";
import { useForm } from "react-hook-form";
import { FormControl } from "react-bootstrap";
import {
  getCashbackReceipt,
  getPendingcashback,
  updateCashbackRequestRecord,
} from "../../../services/cashbackRequest";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { filterList } from "../../../libs/utils";
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import localforage from "localforage";
import CustomTable from "../../../components/CustomTable";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import Swal from "sweetalert2";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const receiptStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  minHeight: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
};

function CashbackRequestList() {
  const dispatch = useDispatch();
  const bank = useSelector((state) => state.bank);
  const { list: bankList } = bank;

  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [searchKey, setSearchKey] = useState("");
  const [loading, setLoading] = useState(false);
  const [opens, setOpens] = useState(false);
  const [isDynamicTable, setIsDynamicTable] = useState(false);
  const [imageLink, setImageLink] = useState(null);

  const setDynamicTable = (parentWidth, tableWidth) => {
    setIsDynamicTable(true);
  };

  const [opensReceipt, setOpensReceipt] = useState(false);

  const handleOpenReceipt = (e, r) => {
    setOpensReceipt(true);
  };
  const handleClosesReceipt = () => setOpensReceipt(false);

  const handleOpen = (e, remark, cashback_request_id) => {
    reset({
      status: "",
      approval_comment: "",
      remark: remark,
      cashbackRequestId: cashback_request_id,
    });
    setOpens(true);
    setSelectedRow({
      remark: remark,
      cashbackRequestId: cashback_request_id,
    });
  };
  const handleCloses = () => setOpens(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const updateCashbackStatus = (data) => {
    updateCashbackRequestRecord({
      status: data.status,
      approval_comment: data.approval_comment,
      cashback_request_id: data.cashbackRequestId,
      remark: "",
    })
      .then((response) => {
        if (response.status === "Transaction processed successfully") {
          setOpens(false);
          Swal.fire({
            title: "Success",
            text: "Cashback status has been updated",
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              getPendingcashback()
                .then((response) => {
                  setRows(response.map((data) => dataGenerator(data)));

                  if (bankList.data.length > 0) {
                    localforage.setItem(
                      "@cashbackRequest",
                      bankList.data,
                      (error) => {
                        if (error) {
                          throw error;
                        }
                      }
                    );
                  }
                })
                .catch((error) => {
                  setLoading(false);
                  throw error;
                });
              //
            } //if
          });
        } //iff
        else {
          Swal.fire({
            title: "Oops",
            text: "Something went wrong",
            icon: "warning",
            confirmButtonText: "Ok",
          }).then((result) => {});
        }
      })
      .catch((error) => {
        setOpens(false);
        Swal.fire({
          title: "Oops",
          text: "Something went wrong",
          icon: "warning",
          confirmButtonText: "Ok",
        }).then((result) => {});
        throw error;
      });
  };

  const columns = [
    {
      id: "cashbackId",
      label: "Cashback Id",
      minWidth: 400,
      align: "left",
      enableSort: true,
    },
    {
      id: "email",
      label: "Email",
      minWidth: 100,
      align: "center",
    },
    {
      id: "status",
      label: "Status",
      minWidth: 100,
      align: "center",
    },
    {
      id: "remark",
      label: "Remark",
      minWidth: 100,
      align: "center",
    },
    {
      id: "requestedBy",
      label: "Requested By",
      minWidth: 100,
      align: "center",
    },
    {
      id: "approvedBy",
      label: "Approved By",
      minWidth: 100,
      align: "center",
      enableSort: true,
    },

    {
      id: "approvalComment",
      label: "Approval Comment",
      minWidth: 100,
      align: "center",
    },
    {
      id: "storeName",
      label: "Store Name",
      minWidth: 100,
      align: "center",
      enableSort: true,
    },
    {
      id: "amountSpent",
      label: "Amount Spent",
      minWidth: 100,
      align: "center",
      enableSort: true,
    },
    {
      id: "cashback",
      label: "Cashback",
      minWidth: 100,
      align: "center",
      enableSort: true,
    },
    {
      id: "requestedDate",
      label: "Requested Date",
      minWidth: 100,
      align: "center",
      enableSort: true,
    },
    {
      id: "transactionDate",
      label: "Transaction Date",
      minWidth: 100,
      align: "center",
      enableSort: true,
    },
    {
      id: "Action",
      label: "Action",
      minWidth: 100,
      align: "center",
    },
    {
      id: "receipt",
      label: "Receipt",
      minWidth: 100,
      align: "center",
    },
  ];

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const searchData = (e) => {
    setSearchKey(e.target.value);
  };

  const getReceipt = (e, cashbackRequestId) => {
    handleOpenReceipt(e);
    setImageLink(null);
    getCashbackReceipt(cashbackRequestId)
      .then((response) => {
        setImageLink(response.content);
      })
      .catch((error) => {
        setOpensReceipt(false);
        setImageLink(null);
        Swal.fire({
          title: "Oops",
          text: "This file cannot be displayed",
          icon: "warning",
          confirmButtonText: "Ok",
        }).then((result) => {});
        throw error;
      });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const dataGenerator = (data) => {
    return {
      cashbackId: data.cashback_request_id,
      email: data.email,
      status: (
        <>
          {data.status === "PENDING" && (
            <span style={{ color: "blue" }}>{data.status}</span>
          )}
          <>
            {data.status === "APPROVED" && (
              <span style={{ color: "green" }}>{data.status}</span>
            )}
          </>
          <>
            {data.status === "DISAPPROVED" && (
              <span style={{ color: "RED" }}>{data.status}</span>
            )}
          </>
        </>
      ),
      remark: data.remark,
      requestedBy: data.requested_by,
      approvedBy: data.approved_by,
      approvalComment: data.approval_comment,
      storeName: data.store_name,
      amountSpent: data.amount_spent,
      cashback: data.cashback,
      requestedDate: moment(data.requested_date).format("MMM D, YYYY"),
      transactionDate: moment(data.transaction_date).format("MMM D, YYYY"),
      Action: (
        <>
          <>
            {data.status === "PENDING" && (
              <Button
                style={{ whiteSpace: "nowrap" }}
                onClick={(e) =>
                  handleOpen(e, data.remark, data.cashback_request_id)
                }
                color="success"
                variant="contained"
              >
                Accept / Reject
              </Button>
            )}
          </>
          <>
            {data.status === "APPROVED" && (
              <Button
                style={{ whiteSpace: "nowrap" }}
                onClick={(e) =>
                  handleOpen(e, data.remark, data.cashback_request_id)
                }
                color="success"
                variant="contained"
                disabled={true}
              >
                Accept / Reject
              </Button>
            )}
          </>
          <>
            {data.status === "DISAPPROVED" && (
              <Button
                style={{ whiteSpace: "nowrap" }}
                onClick={(e) =>
                  handleOpen(e, data.remark, data.cashback_request_id)
                }
                color="success"
                variant="contained"
                disabled={true}
              >
                Accept / Reject
              </Button>
            )}
          </>
        </>
      ),
      receipt: (
        <Button
          type="submit"
          onClick={(e) => getReceipt(e, data.cashback_request_id)}
        >
          <CloudDownloadIcon></CloudDownloadIcon>
        </Button>
      ),
    };
  };

  useEffect(() => {
    localforage.getItem("@cashbackRequest", (error, data) => {
      if (data) {
        if (data?.length > 0 && typeof data === "object") {
          setRows(data.map((data) => dataGenerator(data)));
        } else {
          setRows([]);
        }
      }
    });
    getPendingcashback()
      .then((response) => {
        setRows(response.map((data) => dataGenerator(data)));

        if (bankList.data.length > 0) {
          localforage.setItem("@cashbackRequest", bankList.data, (error) => {
            if (error) {
              throw error;
            }
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        throw error;
      });
  }, [bankList]);

  return (
    <>
      <Box
        sx={{ px: 6 }}
        className={`main-box ${isDynamicTable ? "dynamicTableStyle" : ""}`}
      >
        <div
          className={`bankAddRuleHeader mb-3 ${
            isDynamicTable ? "listHeaderPaddingRight" : ""
          }`}
        >
          <div
            style={{
              display: "flex",
              padding: "1.5rem 0",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: "#33343a" }} variant="h4" component="h4">
              Cashback Request
            </Typography>
            {loading && (
              <div
                style={{
                  marginLeft: "12px",
                  color: "#a0a0a0",
                  fontSize: "24px",
                }}
              >
                <i
                  className={`fa fa-refresh ${
                    loading === true ? "fa-spin" : ""
                  }`}
                  aria-hidden="true"
                ></i>
              </div>
            )}
          </div>
          <div style={{ display: "flex", alignItems: "end" }}>
            <TextField
              id="standard-basic"
              label="Search"
              variant="standard"
              sx={{ mr: 1 }}
              onKeyUp={(e) => searchData(e)}
              InputProps={{
                endAdornment: <SearchIcon sx={{ color: "#8a8a8a" }} />,
              }}
            />
          </div>
        </div>

        <CustomTable
          setDynamicTable={setDynamicTable}
          data={filterList(rows, searchKey) || []}
          columns={columns}
        />
      </Box>

      {/* ll */}

      <Modal
        open={opens}
        onClose={handleCloses}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="d-flex justify-content-between">
            <Typography
              id="modal-modal-title"
              variant="h4"
              component="h1"
              sx={{ mb: 2 }}
            >
              Update Cashback Status
            </Typography>
            <div onClick={handleCloses} style={{ cursor: "pointer" }}>
              <i className="fa fa-times fa-2x text-danger"></i>
            </div>
          </div>

          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ mb: 2 }}
          >
            ID: {selectedRow.cashbackRequestId}
          </Typography>

          <form onSubmit={handleSubmit(updateCashbackStatus)}>
            <p className="mb-1">
              Update Status: <small className="text-danger fs-6">*</small>
            </p>
            <div className="mb-3">
              <select
                {...register("status", {
                  required: true,
                })}
                className={`form-select ${
                  errors.status && "invalid-form-input"
                }`}
              >
                <option value="">Select</option>
                <option value="APPROVED">Approve</option>
                <option value="DISAPPROVED">Disapprove</option>
              </select>
              {/* {errors.status && (
                <div className="error-msg">This field is required</div>
              )} */}
            </div>

            <input
              type="hidden"
              defaultValue={selectedRow.cashbackRequestId}
              {...register("cashbackRequestId")}
            />
            <input
              type="hidden"
              defaultValue={selectedRow.remark}
              {...register("remark")}
            />

            <div className="mb-4">
              Approval Comment: <small className="text-danger fs-6">*</small>
              <br />
              <textarea
                {...register("approval_comment", {
                  required: true,
                })}
                className={`form-control ${
                  errors.approval_comment && "invalid-form-input"
                }`}
                style={{ width: "100%", height: "150px" }}
              ></textarea>
              {/* {errors.approval_comment && (
                <div className="error-msg">This field is required</div>
              )} */}
            </div>
            <Button type="submit" variant="contained">
              Update
            </Button>
          </form>
        </Box>
      </Modal>
      <Modal
        open={opensReceipt}
        onClose={handleClosesReceipt}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={receiptStyle}>
          {imageLink === null && (
            <>
              <i
                className={`mt-4 fa fa-circle-o-notch fa-2x ${
                  true ? "fa-spin" : ""
                }`}
                aria-hidden="true"
              ></i>
            </>
          )}
          {imageLink !== null && (
            <img
              style={{ maxWidth: "100%", maxHeight: "80vh" }}
              src={`data:image/png;base64, ${imageLink}`}
            />
          )}
        </Box>
      </Modal>
    </>
  );
}

export default CashbackRequestList;
